import { createSlice } from "@reduxjs/toolkit";
import { clearAuthData, setCurrentPartner } from "components/auth/AuthSlice";

const initialState = {
  marketplaceProducts: [],
  marketplaceSearch: {},
  marketplaceProductsTotal: null,
};

export const marketplaceSlice = createSlice({
  name: "marketplace",
  initialState,
  reducers: {
    appendMarketplaceProducts: (state, action) => {
      if (state.marketplaceProducts.length != action.payload.meta?.offset)
        return;
      state.marketplaceProducts = state.marketplaceProducts.concat(
        action.payload.marketplaceProducts
      );
      state.marketplaceProductsTotal = Number(action.payload.meta?.count);
    },
    setMarketplaceProductStatus: (state, action) => {
      const idx = state.marketplaceProducts.findIndex(
        (product) => product.id === action.payload.productId
      );
      state.marketplaceProducts[idx].assigned = true;
    },
    setMarketplaceSearch: (state, action) => {
      state.marketplaceProducts = [];
      state.marketplaceProductsTotal = null;
      state.marketplaceSearch = action.payload;
    },
    clearMarketplaceProducts: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
    builder.addCase(setCurrentPartner, () => initialState);
  },
});

export const {
  appendMarketplaceProducts,
  clearMarketplaceProducts,
  setMarketplaceProductStatus,
  setMarketplaceSearch,
} = marketplaceSlice.actions;
export default marketplaceSlice.reducer;
