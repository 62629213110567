import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import Chips from "common/basicComponents/Chips";
import { ChipsVariant } from "common/basicComponents/Chips";
import { useTranslation } from "react-i18next";

const Cell = styled.td`
  height: 4.5rem;
  padding: 0 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const ButtonsCell = styled(Cell)`
  min-width: 5.5rem;
  button {
    display: inline-flex;
    margin: 0 0.1rem;
  }
`;

const BannerRow = ({ data, onEdit, onDelete }) => {
  const { t } = useTranslation("totems");
  return (
    <>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.banner_name}
          tag="span"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={t(`banners.types.${data.banner_type}`)}
          tag="span"
        />
      </Cell>
      <Cell centered={true}>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.banner_duration}
          tag="span"
        />
      </Cell>
      <Cell centered={true}>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.banner_displayed_time}
          tag="span"
        />
      </Cell>
      <Cell centered={true}>
        <Chips
          text={
            data.banner_active
              ? t("banners.statuses.active")
              : t("banners.statuses.inactive")
          }
          variant={data.banner_active ? ChipsVariant.GREEN : ChipsVariant.ROSE}
        />
      </Cell>

      <ButtonsCell centered={true}>
        <Button
          icon={IconName.EDIT}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onEdit(data)}
        />
        <Button
          icon={IconName.TRASH}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onDelete(data)}
        />
      </ButtonsCell>
    </>
  );
};

BannerRow.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default BannerRow;
