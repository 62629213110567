import React from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import Range, { RangeDisplayMode } from "common/basicComponents/Range";

export { RangeDisplayMode };

const FormRangeField = ({
  id,
  name,
  label,
  disabled,
  min,
  max,
  step,
  markStep,
  displayMode,
}) => {
  const [field, , helpers] = useField(name);
  const { isSubmitting } = useFormikContext();

  return (
    <Range
      id={id}
      name={name}
      label={label}
      value={field.value || 0}
      onChange={(value) => helpers.setValue(value)}
      disabled={isSubmitting || disabled}
      min={min}
      max={max}
      step={step}
      markStep={markStep}
      displayMode={displayMode}
    />
  );
};

FormRangeField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  markStep: PropTypes.number,
  displayMode: PropTypes.string,
};

FormRangeField.defaultProps = {
  label: "",
  disabled: false,
  step: 1,
  markStep: 1,
  displayMode: RangeDisplayMode.AUTO,
};

export default FormRangeField;
