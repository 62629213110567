import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { useViewport, ViewportType } from "utils/ViewportContext";
import Icon, { IconName } from "../Icon";

const AlignType = {
  LEFT: "",
  RIGHT: "right",
  CENTER: "center",
  JUSTIFY: "justify",
};

const ListType = {
  NONE: "",
  ORDERED: "ordered",
};

const getToolbarButtonStyle = (theme, pseudo) =>
  ({
    default: css`
      color: ${theme.colors.black};
      width: 1.5rem;
      height: 1.5rem;
    `,
    hover: css`
      background: transparent;
      background-image: ${({ theme }) => theme.colors.gradient[100]};
      background-clip: text;
      -webkit-background-clip: text;
      & svg {
        fill: url(#gradient);
        opacity: 100%;
      }
    `,
    active: css`
      background: transparent;
      background-image: ${({ theme }) => theme.colors.gradient[80]};
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      border: none;
      & svg {
        fill: url(#gradient);
        opacity: 80%;
      }
    `,
    disabled: css`
      background: none !important;
      color: ${theme.colors.grey[500]};
      & svg {
        fill: ${theme.colors.grey[500]} !important;
        opacity: 100%;
      }
    `,
  }[pseudo]);

const ToolbarButton = styled.div`
  ${({ theme }) => getToolbarButtonStyle(theme, "default")}
  &:hover {
    ${({ theme }) => getToolbarButtonStyle(theme, "hover")}
  }
  &:active {
    ${({ theme }) => getToolbarButtonStyle(theme, "active")}
  }
  ${({ theme, selected }) => selected && getToolbarButtonStyle(theme, "active")}
  ${({ theme, disabled }) =>
    disabled && getToolbarButtonStyle(theme, "disabled")}
  }
`;

const ToolbarContainer = styled.div`
  display: flex;
  row-gap: 1.25rem;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0 1rem 0;
  ${({ viewport }) => viewport !== ViewportType.MOBILE && "column-gap: 3rem;"}
`;

const ToolbarGroup = styled.div`
  display: flex;
  gap: 3rem;
`;

const ToolbarSmallGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

const ToolbarMobileGroup = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-around;
  @media screen and (max-width: 400px) {
    width: 100%;
  }
`;

const EditorToolbar = ({ id, disabled, onChange, currentFormat }) => {
  const { type: viewportType } = useViewport();

  const ToolbarButtons = {
    bold: (
      <ToolbarButton
        selected={currentFormat?.bold}
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          onChange("bold", !currentFormat?.bold);
        }}
      >
        <Icon name={IconName.BOLD} />
      </ToolbarButton>
    ),
    italic: (
      <ToolbarButton
        selected={currentFormat?.italic}
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          onChange("italic", !currentFormat?.italic);
        }}
      >
        <Icon name={IconName.ITALIC} />
      </ToolbarButton>
    ),
    underline: (
      <ToolbarButton
        selected={currentFormat?.underline}
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          onChange("underline", !currentFormat?.underline);
        }}
      >
        <Icon name={IconName.UNDERLINE} />
      </ToolbarButton>
    ),
    list: (
      <ToolbarButton
        selected={currentFormat?.list === ListType.ORDERED}
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          onChange(
            "list",
            currentFormat?.list === ListType.ORDERED
              ? ListType.NONE
              : ListType.ORDERED
          );
        }}
      >
        <Icon name={IconName.LIST} />
      </ToolbarButton>
    ),
    alignJustify: (
      <ToolbarButton
        selected={currentFormat?.align === AlignType.JUSTIFY}
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          onChange("align", AlignType.JUSTIFY);
        }}
      >
        <Icon name={IconName.ALIGN_JUSTIFY} />
      </ToolbarButton>
    ),
    alignLeft: (
      <ToolbarButton
        selected={!currentFormat.align}
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          onChange("align", AlignType.LEFT);
        }}
      >
        <Icon name={IconName.ALIGN_LEFT} />
      </ToolbarButton>
    ),
    alignCenter: (
      <ToolbarButton
        selected={currentFormat?.align === AlignType.CENTER}
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          onChange("align", AlignType.CENTER);
        }}
      >
        <Icon name={IconName.ALIGN_CENTER} />
      </ToolbarButton>
    ),
    alignRight: (
      <ToolbarButton
        selected={currentFormat?.align === AlignType.RIGHT}
        disabled={disabled}
        onClick={() => {
          if (disabled) return;
          onChange("align", AlignType.RIGHT);
        }}
      >
        <Icon name={IconName.ALIGN_RIGHT} />
      </ToolbarButton>
    ),
  };

  return (
    <ToolbarContainer id={id} viewport={viewportType}>
      {viewportType > ViewportType.MOBILE && (
        <ToolbarGroup>
          <ToolbarSmallGroup>
            <>{ToolbarButtons.bold}</>
            <>{ToolbarButtons.italic}</>
            <>{ToolbarButtons.underline}</>
          </ToolbarSmallGroup>
          <ToolbarSmallGroup>{ToolbarButtons.list}</ToolbarSmallGroup>
          <ToolbarSmallGroup>
            <>{ToolbarButtons.alignLeft}</>
            <>{ToolbarButtons.alignCenter}</>
            <>{ToolbarButtons.alignRight}</>
            <>{ToolbarButtons.alignJustify}</>
          </ToolbarSmallGroup>
        </ToolbarGroup>
      )}
      {viewportType === ViewportType.MOBILE && (
        <>
          <ToolbarMobileGroup>
            <>{ToolbarButtons.bold}</>
            <>{ToolbarButtons.italic}</>
            <>{ToolbarButtons.underline}</>
            <>{ToolbarButtons.list}</>
          </ToolbarMobileGroup>
          <ToolbarMobileGroup>
            <>{ToolbarButtons.alignLeft}</>
            <>{ToolbarButtons.alignCenter}</>
            <>{ToolbarButtons.alignRight}</>
            <>{ToolbarButtons.alignJustify}</>
          </ToolbarMobileGroup>
        </>
      )}
    </ToolbarContainer>
  );
};

EditorToolbar.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  currentFormat: PropTypes.object,
};

EditorToolbar.defaultProps = {
  disabled: false,
  currentFormat: null,
};

export default EditorToolbar;
