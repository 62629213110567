import { createSlice } from "@reduxjs/toolkit";
import { clearAuthData, setCurrentPartner } from "components/auth/AuthSlice";

const initialState = {
  report: null,
  visualReport: null,
  importData: null,
};

export const importSlice = createSlice({
  name: "import",
  initialState,
  reducers: {
    setReport: (state, action) => {
      state.report = action.payload;
    },
    setVisualReport: (state, action) => {
      state.visualReport = action.payload;
    },
    setImportData: (state, action) => {
      state.importData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
    builder.addCase(setCurrentPartner, () => initialState);
  },
});

export const { setReport, setVisualReport, setImportData } =
  importSlice.actions;
export default importSlice.reducer;
