import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    bannerDuration: Yup.number()
      .min(1, { code: "common:validator.minValue", params: { min: 1 } })
      .required("common:validator.required"),
    bannerDisplayedTime: Yup.number()
      .min(1, { code: "common:validator.minValue", params: { min: 1 } })
      .required("common:validator.required"),
    bannerActive: Yup.object().shape({
      value: Yup.string().required("common:validator.required"),
    }),
    bannerName: Yup.string(),
    bannerType: Yup.object().shape({
      value: Yup.string().required("common:validator.required"),
    }),
    bannerFile: Yup.object().shape({
      file: Yup.mixed()
        .test(
          "is-not-empty",
          "common:validator.required",
          (value, context) => !!value || !!context.parent.url
        )
        .nullable(),
    }),
  });
};
