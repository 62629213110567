import React from "react";
import styled, { css } from "styled-components";
import Typography, { TypographyVariant, TypographyWeight } from "./Typography";
import PropTypes from "prop-types";
import i18n from "../../i18n/i18n";

export const SwitchVariant = {
  COLOR: "color",
  BLACK: "black",
};

const getSwitchColors = (theme, variant, pseudo, checked) =>
  ({
    [SwitchVariant.COLOR]: {
      default: css`
        &.option-yes {
          color: ${checked ? theme.colors.white : theme.colors.grey[500]};
          background-color: ${checked
            ? theme.colors.success[600]
            : theme.colors.grey[200]};
        }
        &.option-no {
          color: ${checked ? theme.colors.grey[500] : theme.colors.white};
          background-color: ${checked
            ? theme.colors.grey[200]
            : theme.colors.secondary[600]};
        }
      `,
      hover: css`
        &.option-yes {
          background-color: ${checked
            ? theme.colors.success[600]
            : theme.colors.grey[300]};
        }
        &.option-no {
          background-color: ${checked
            ? theme.colors.grey[300]
            : theme.colors.secondary[600]};
        }
      `,
      active: css`
        &.option-yes {
          background-color: ${checked
            ? theme.colors.success[600]
            : theme.colors.grey[100]};
        }
        &.option-no {
          background-color: ${checked
            ? theme.colors.grey[100]
            : theme.colors.secondary[600]};
        }
      `,
      disabled: css`
        &.option-yes {
          color: ${checked ? theme.colors.grey[200] : theme.colors.grey[400]};
          background-color: ${checked
            ? theme.colors.grey[400]
            : theme.colors.grey[200]};
        }
        &.option-no {
          color: ${checked ? theme.colors.grey[400] : theme.colors.grey[200]};
          background-color: ${checked
            ? theme.colors.grey[200]
            : theme.colors.grey[400]};
        }
      `,
    },
    [SwitchVariant.BLACK]: {
      default: css`
        &.option-yes {
          color: ${checked ? theme.colors.white : theme.colors.grey[500]};
          background-color: ${checked
            ? theme.colors.black
            : theme.colors.grey[200]};
        }
        &.option-no {
          color: ${checked ? theme.colors.grey[500] : theme.colors.white};
          background-color: ${checked
            ? theme.colors.grey[200]
            : theme.colors.black};
        }
      `,
      hover: css`
        &.option-yes {
          background-color: ${checked
            ? theme.colors.black
            : theme.colors.grey[300]};
        }
        &.option-no {
          background-color: ${checked
            ? theme.colors.grey[300]
            : theme.colors.black};
        }
      `,
      active: css`
        &.option-yes {
          background-color: ${checked
            ? theme.colors.black
            : theme.colors.grey[100]};
        }
        &.option-no {
          background-color: ${checked
            ? theme.colors.grey[100]
            : theme.colors.black};
        }
      `,
      disabled: css`
        &.option-yes {
          color: ${checked ? theme.colors.grey[200] : theme.colors.grey[400]};
          background-color: ${checked
            ? theme.colors.grey[400]
            : theme.colors.grey[200]};
        }
        &.option-no {
          color: ${checked ? theme.colors.grey[400] : theme.colors.grey[200]};
          background-color: ${checked
            ? theme.colors.grey[200]
            : theme.colors.grey[400]};
        }
      `,
    },
  }[variant][pseudo]);

const StyledLabel = styled.label`
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey[500] : theme.colors.black};
  min-width: fit-content;
  .switch-control {
    width: ${({ width }) => width};
    margin-top: 0.625rem;
    margin-bottom: 1rem;
  }
  .label {
    display: inline-block;
  }
`;

const StyledOption = styled.span`
  display: inline-block;
  height: 1.875rem;
  min-width: 3.75rem;
  width: 50%;
  text-align: ${({ align }) => align};
  padding: 0.5rem 1.25rem;
  cursor: pointer;
  user-select: none;

  ${({ theme, variant, checked, disabled }) =>
    !disabled
      ? getSwitchColors(theme, variant, "default", checked)
      : getSwitchColors(theme, variant, "disabled", checked)}

  &:hover {
    ${({ theme, variant, checked, disabled }) =>
      !disabled && getSwitchColors(theme, variant, "hover", checked)}
  }

  &:active {
    ${({ theme, variant, checked, disabled }) =>
      !disabled && getSwitchColors(theme, variant, "active", checked)}
  }
`;

const Switch = ({
  label,
  leftOption,
  rightOption,
  disabled,
  variant,
  width,
  align,
  onChange,
  value,
}) => {
  const handleClick = (value) => {
    if (disabled) return;
    onChange(value);
  };

  return (
    <StyledLabel
      width={width}
      disabled={disabled}
      variant={variant}
      checked={value}
    >
      {label && (
        <Typography
          variant={TypographyVariant.BODY2}
          weight={TypographyWeight.SEMIBOLD}
          text={label}
          tag="span"
          className="label"
        />
      )}
      <div className="switch-control">
        <StyledOption
          variant={variant}
          align={align}
          checked={value}
          onClick={() => handleClick(true)}
          className="option-yes"
          disabled={disabled}
        >
          <Typography
            variant={TypographyVariant.OVERLINE}
            weight={TypographyWeight.SEMIBOLD}
            text={leftOption}
          />
        </StyledOption>
        <StyledOption
          variant={variant}
          checked={value}
          align={align}
          onClick={() => handleClick(false)}
          className="option-no"
          disabled={disabled}
        >
          <Typography
            variant={TypographyVariant.OVERLINE}
            weight={TypographyWeight.SEMIBOLD}
            text={rightOption}
          />
        </StyledOption>
      </div>
    </StyledLabel>
  );
};

Switch.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(SwitchVariant)),
  value: PropTypes.bool,
  onChange: PropTypes.func,
  leftOption: PropTypes.string,
  rightOption: PropTypes.string,
  width: PropTypes.string,
  align: PropTypes.string,
};

Switch.defaultProps = {
  label: null,
  disabled: false,
  variant: SwitchVariant.COLOR,
  value: false,
  width: "fit-content",
  align: "center",
  onChange: () => {},
  leftOption: i18n.t("yes"),
  rightOption: i18n.t("no"),
};

export default Switch;
