import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Typography, {
  TypographyWeight,
} from "common/basicComponents/Typography";

const Card = styled.div`
  min-width: 40%;
  flex-grow: 1;
  flex-basis: 0;
  min-height: 50px;
  border: 1px solid black;
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding: 20px;
`;

const TableCard = ({ headers, data }) => {
  return (
    <Card>
      <Typography weight={TypographyWeight.BOLD} text={headers[0].text} />
      <Typography text={data.one} />
      <Typography weight={TypographyWeight.BOLD} text={headers[1].text} />
      <Typography text={data.two} />
      <Typography weight={TypographyWeight.BOLD} text={headers[2].text} />
      <Typography text={data.three} />
    </Card>
  );
};

TableCard.propTypes = {
  data: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
};

export default TableCard;
