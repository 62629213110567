import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { TypographyWeight } from "common/basicComponents/Typography";

const Cell = styled.td`
  height: 4.5rem;
  padding: 0 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const TextCell = styled(Cell)`
  min-width: 7.5rem;
`;

const ThumbCell = styled(Cell)`
  width: 0;
  min-width: 9rem;
`;

const ButtonsCell = styled(Cell)`
  width: 0;
  button {
    display: inline-flex;
    margin: 0 0.25rem;
  }
`;

const Thumb = styled.img`
  height: 2.5rem;
  width: 5rem;
  object-fit: contain;
`;

const ThumbPlaceholder = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[400]};
`;

const MarketplaceRow = ({ data, onAssign }) => {
  const { t } = useTranslation("marketplace");
  return (
    <>
      <ThumbCell centered={true}>
        {data.product_thumb_link ? (
          <Thumb src={data.product_thumb_link} />
        ) : (
          <ThumbPlaceholder>{t("notAdded")}</ThumbPlaceholder>
        )}
      </ThumbCell>
      <TextCell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.product_name}
        />
      </TextCell>
      <Cell centered={true}>
        <Typography
          variant={TypographyVariant.BODY2}
          weight={TypographyWeight.BOLD}
          text={data.published_versions}
        />
      </Cell>
      <ButtonsCell centered={true}>
        {data.assigned ? (
          <Typography
            variant={TypographyVariant.BUTTON}
            text={t("search.assigned")}
          />
        ) : (
          <Button
            variant={ButtonVariant.CONTAINED}
            size={ButtonSize.SMALL}
            onClick={() => onAssign(data.id)}
            text={t("assign")}
          />
        )}
      </ButtonsCell>
    </>
  );
};

MarketplaceRow.propTypes = {
  data: PropTypes.object.isRequired,
  onAssign: PropTypes.func.isRequired,
};

export default MarketplaceRow;
