import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import Typography from "common/basicComponents/Typography";
import { TypographyVariant } from "common/basicComponents/Typography";

const Cell = styled.td`
  height: 4rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const ButtonsCell = styled(Cell)`
  min-width: 6.25rem;
  width: 6.25rem;
  button {
    display: inline-flex;
    margin: 0 0.25rem;
  }
`;

const ApplicationLinkRow = ({ data, disabled, onEdit, onDelete }) => {
  return (
    <>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.url}
          tag="span"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.text}
          tag="span"
        />
      </Cell>
      <ButtonsCell centered={true}>
        <Button
          icon={IconName.EDIT}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onEdit(data)}
          disabled={disabled}
        />
        <Button
          icon={IconName.TRASH}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onDelete(data)}
          disabled={disabled}
        />
      </ButtonsCell>
    </>
  );
};

ApplicationLinkRow.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ApplicationLinkRow.defaultProps = {
  disabled: false,
};

export default ApplicationLinkRow;
