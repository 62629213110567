import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import {
  appendMarketplaceProducts,
  setMarketplaceProductStatus,
} from "./MarketplaceSlice";

export const getMarketplaceProducts = (params) => {
  return apiClient
    .get(ApiEndpoints.MARKETPLACE_PRODUCTS, { params })
    .then((res) => {
      const marketplaceProducts = res?.data?.body;
      const meta = res?.data?.meta;
      if (!marketplaceProducts) return false;
      store.dispatch(
        appendMarketplaceProducts({
          marketplaceProducts,
          meta,
        })
      );
      return true;
    })
    .catch(() => false);
};

export const assignProductFromMarketplace = (productId) => {
  return apiClient
    .post(ApiEndpoints.MARKETPLACE_PRODUCTS_ASSIGN, { productId })
    .then(() => {
      store.dispatch(setMarketplaceProductStatus({ productId }));
      return true;
    })
    .catch(() => false);
};
