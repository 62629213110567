import { createSlice } from "@reduxjs/toolkit";
import { clearAuthData, setCurrentPartner } from "components/auth/AuthSlice";

const initialState = {
  pushNotifications: [],
};

export const pushNotificationsSlice = createSlice({
  name: "pushNotifications",
  initialState,
  reducers: {
    setPushNotifications: (state, action) => {
      state.pushNotifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
    builder.addCase(setCurrentPartner, () => initialState);
  },
});

export const { setPushNotifications, setSearch } =
  pushNotificationsSlice.actions;
export default pushNotificationsSlice.reducer;
