import React from 'react';
import Button from 'common/basicComponents/Button';
import {PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';
import { ButtonVariant } from 'common/basicComponents/Button';
import { Box } from '@mui/material';
import { addPaymentMethod } from '../BillingActions';

const CheckoutForm = () => {

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const result = await stripe.confirmSetup({
      elements,
      redirect: 'if_required'
    });
    if (result.error) {
      console.log(result.error.message);
    } else {
      const paymentMethod = result?.setupIntent?.payment_method;
      addPaymentMethod(paymentMethod);
      setTimeout(function(){
        window.location.reload(1);
     }, 1000);
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Box style={{marginTop: "16px"}}>
      <Button disabled={!stripe || !elements} text="save" variant={ButtonVariant.OUTLINED} type="submit"/>
      </Box>
    </form>
  );
};

export default CheckoutForm;