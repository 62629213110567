import { createSlice } from "@reduxjs/toolkit";
import { clearAuthData, setCurrentPartner } from "components/auth/AuthSlice";

const initialState = {
  users: [],
  usersTotal: null,
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    appendUsers: (state, action) => {
      if (state.users.length != action.payload.meta?.offset) return;
      state.users = state.users.concat(action.payload.users);
      state.usersTotal = Number(action.payload.meta?.count);
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    clearUsers: () => initialState,
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
    builder.addCase(setCurrentPartner, () => initialState);
  },
});

export const { appendUsers, setUsers, clearUsers, setUser } = userSlice.actions;
export default userSlice.reducer;
