import React, { useState, useEffect } from "react";
import styled, { css, useTheme } from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import Accordion from "common/basicComponents/Accordion";
import Box from "common/basicComponents/Box";
import rotator from "assets/totem/rotator.jpg";
import poweredBy from "assets/totem/poweredBy.jpg";
import { useViewport, ViewportType } from "utils/ViewportContext";
import FormToggleField from "common/formFields/FormToggleField";
import { ToggleVariant } from "common/formFields/FormToggleField";
import FileExtension from "constants/FileExtension";
import FormFileField from "common/formFields/FormFileField";
import Typography from "common/basicComponents/Typography";
import { TypographyVariant } from "common/basicComponents/Typography";
import { TypographyWeight } from "common/basicComponents/Typography";

const AccordionContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem 0.625rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`;

const Group = styled(Box)`
  display: flex;
  flex-wrap: ${({ viewportType }) =>
    viewportType === ViewportType.MOBILE ? "wrap" : "no-wrap"};
  gap: 1.25rem;
`;

const FilesBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.25rem;
`;

const PreviewContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Preview = styled(Box)`
  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
  box-sizing: content-box;
  margin-top: 0.25rem;
  ${({ aspectRatio }) => `aspect-ratio: ${aspectRatio};`}
`;

const PreviewBox = styled(Box)`
  background-color: ${({ background }) => background};
  color: ${({ theme }) => theme.colors.grey[400]};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ img, imgBackground }) =>
    img
      ? css`
          background-image: url(${img});
          background-color: ${imgBackground};
        `
      : null}
  background-size: cover;

  ${({ aspectRatio }) => `aspect-ratio: ${aspectRatio};`}
`;

const getPreviewWidth = (type) =>
  ({
    [ViewportType.DESKTOP]: "max(40%, 16rem)",
    [ViewportType.TABLET]: "16rem",
    [ViewportType.MOBILE]: "100%",
  }[type]);

const WelcomeScreenForm = ({ disabled }) => {
  const { t } = useTranslation("totems");
  const { type: viewportType } = useViewport();
  const { values } = useFormikContext();
  const theme = useTheme();
  const [accordionHasErrors, setAccordionHasErrors] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(null);

  useEffect(() => {
    setBackgroundColor(
      !values.backgroundColor
        ? "white"
        : `rgb(${values.backgroundColor?.r}, ${values.backgroundColor?.g}, ${values.backgroundColor?.b})`
    );
  }, [values.backgroundColor]);

  useEffect(() => {
    if (accordionHasErrors) setAccordionHasErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Accordion
      text={t("welcomeScreen.title")}
      forceOpen={accordionHasErrors}
      defaultOpen
    >
      <AccordionContent>
        <Box width="100%" padding="0 0.625rem">
          <FormToggleField
            id={"showWelcomeScreen"}
            name={"showWelcomeScreen"}
            label={t("welcomeScreen.show")}
            variant={ToggleVariant.SWITCH_COLOR}
            disabled={disabled}
          />
        </Box>
        <Group
          viewportType={viewportType}
          width="100%"
          padding="0 0.625rem 1.25rem 0.625rem"
        >
          <FilesBox grow={1}>
            <FormFileField
              id={"topImageFile"}
              name={"topImageFile"}
              label={t("welcomeScreen.topImageFile")}
              acceptedExtensions={[FileExtension.PNG]}
              disabled={disabled || !values.showWelcomeScreen}
              onError={() => setAccordionHasErrors(true)}
            />
            <FormFileField
              id={"middleImageFile"}
              name={"middleImageFile"}
              label={t("welcomeScreen.middleImageFile")}
              acceptedExtensions={[FileExtension.PNG]}
              disabled={disabled || !values.showWelcomeScreen}
              onError={() => setAccordionHasErrors(true)}
            />
            <FormFileField
              id={"bottomImageFile"}
              name={"bottomImageFile"}
              label={t("welcomeScreen.bottomImageFile")}
              acceptedExtensions={[FileExtension.PNG]}
              disabled={disabled || !values.showWelcomeScreen}
              onError={() => setAccordionHasErrors(true)}
            />
          </FilesBox>
          <PreviewContainer width={getPreviewWidth(viewportType)}>
            <Box
              width={viewportType === ViewportType.MOBILE ? "100%" : "16rem"}
            >
              <Typography
                text={t("welcomeScreen.preview")}
                variant={TypographyVariant.BODY2}
                weight={TypographyWeight.SEMIBOLD}
              />
            </Box>
            <Preview
              width={viewportType === ViewportType.MOBILE ? "100%" : "16rem"}
              aspectRatio={16 / 28}
            >
              <PreviewBox
                aspectRatio={16 / 6}
                width="100%"
                background={theme.colors.grey[200]}
                img={values.topImageFile?.url}
                imgBackground={backgroundColor}
              >
                {!values.topImageFile?.url && (
                  <Typography
                    text="1080x400"
                    variant={TypographyVariant.BODY2}
                    weight={TypographyWeight.SEMIBOLD}
                  />
                )}
              </PreviewBox>
              <PreviewBox
                aspectRatio={16 / 7.5}
                width="100%"
                background={theme.colors.grey[50]}
                img={values.middleImageFile?.url}
                imgBackground={backgroundColor}
              >
                {!values.middleImageFile?.url && (
                  <Typography
                    text="1080x500"
                    variant={TypographyVariant.BODY2}
                    weight={TypographyWeight.SEMIBOLD}
                  />
                )}
              </PreviewBox>
              <PreviewBox
                aspectRatio={16 / 8.5}
                width="100%"
                imgBackground={backgroundColor}
                img={rotator}
              />
              <PreviewBox
                aspectRatio={16 / 4}
                width="100%"
                background={theme.colors.grey[200]}
                img={values.bottomImageFile?.url}
                imgBackground={backgroundColor}
              >
                {!values.bottomImageFile?.url && (
                  <Typography
                    text="1080x270"
                    variant={TypographyVariant.BODY2}
                    weight={TypographyWeight.SEMIBOLD}
                  />
                )}
              </PreviewBox>
              <PreviewBox
                aspectRatio={16 / 2}
                width="100%"
                imgBackground={backgroundColor}
                img={poweredBy}
              />
            </Preview>
          </PreviewContainer>
        </Group>
      </AccordionContent>
    </Accordion>
  );
};

WelcomeScreenForm.propTypes = {
  disabled: PropTypes.bool,
};

WelcomeScreenForm.defaultProps = {
  disabled: false,
};

export default WelcomeScreenForm;
