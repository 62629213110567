import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Page from "common/page/Page";
import Header from "common/page/Header";
import Button, {
  ButtonVariant,
  ButtonWidth,
} from "common/basicComponents/Button";
import Content from "common/page/Content";
import { IconName } from "common/basicComponents/Icon";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Box from "common/basicComponents/Box";
import VisualReportContent from "./VisualReportContent";

const VisualReport = () => {
  const { t } = useTranslation("import");
  const navigate = useNavigate();
  const { type: viewportType } = useViewport();

  return (
    <Page>
      <Header title={t("report")}>
        <Box padding="1rem 0 0 0">
          <Button
            variant={ButtonVariant.OUTLINED}
            icon={IconName.LEFT_ARROW}
            text={viewportType > ViewportType.MOBILE && t("common:button.back")}
            onClick={() => navigate(-1)}
            width={
              viewportType === ViewportType.TABLET
                ? ButtonWidth.GROW
                : ButtonWidth.AUTO
            }
          />
        </Box>
      </Header>
      <Content>
        <VisualReportContent />
      </Content>
    </Page>
  );
};

export default VisualReport;
