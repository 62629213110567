import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "common/page/Header";
import Content from "common/page/Content";
import Page from "common/page/Page";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import TotemTable from "./list/TotemTable";
import { getTotems } from "./TotemActions";
import { REACT_APP_TOTEM_INSTALLER_LINK } from "constants/env";
import RouterPaths from "constants/RouterPaths";
import { prepareUrl } from "utils/UrlUtils";

const Totems = () => {
  const { t } = useTranslation("totems");
  const navigate = useNavigate();

  const { partner } = useSelector((state) => state.auth);
  const { totems } = useSelector((state) => state.totems);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getTotems().finally(() => {
      setLoading(false);
    });
  }, [partner]);

  return (
    <Page>
      <Header
        title={t("title")}
        button={
          <Button
            minWidth={200}
            variant={ButtonVariant.CONTAINED}
            text={t("common:button.download")}
            onClick={() => window.open(REACT_APP_TOTEM_INSTALLER_LINK)}
          />
        }
      />
      <Content>
        <TotemTable
          totems={totems}
          loading={loading}
          onEdit={(totem) =>
            navigate(prepareUrl(RouterPaths.EDIT_TOTEM, { id: totem.totem_id }))
          }
        />
      </Content>
    </Page>
  );
};

export default Totems;
