import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import Chips, { ChipsVariant } from "common/basicComponents/Chips";
import { ExtendedUserRoles } from "constants/UserRoles";
import { StatusOptions } from "constants/StatusOptions";

const Cell = styled.td`
  height: 4.5rem;
  padding: 0rem 0.75rem 0rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;
const ButtonsCell = styled(Cell)`
  min-width: 6.5rem;
  padding-left: 0.5rem;
  button {
    display: inline-flex;
    margin: 0 0.25rem;
  }
`;

const getColor = (role) =>
  ({
    [ExtendedUserRoles.USER]: ChipsVariant.LIGHT_GREY,
    [ExtendedUserRoles.ADMIN]: ChipsVariant.GREY,
    [ExtendedUserRoles.SUPERADMIN]: ChipsVariant.DARK_GREY,
  }[role]);

const UsersRow = ({ data, number, onEdit, onDelete }) => {
  const { t } = useTranslation("users");
  return (
    <>
      <Cell centered={true}>
        <Typography
          variant={TypographyVariant.BODY2}
          text={number}
          tag="span"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.user_email}
          tag="span"
        />
      </Cell>
      <Cell centered={true}>
        <Chips
          variant={getColor(data.user_role)}
          text={t(`roles.${data.user_role}`)}
        />
      </Cell>
      <Cell centered={true}>
        <Chips
          variant={data.user_active ? ChipsVariant.GREEN : ChipsVariant.ROSE}
          text={
            data.user_active
              ? t(`statuses.${StatusOptions.ACTIVE}`)
              : t(`statuses.${StatusOptions.INACTIVE}`)
          }
        />
      </Cell>
      <ButtonsCell centered={true}>
        <Button
          icon={IconName.EDIT}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onEdit(data)}
        />
        <Button
          icon={IconName.TRASH}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onDelete(data)}
        />
      </ButtonsCell>
    </>
  );
};

UsersRow.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  number: PropTypes.number,
};

UsersRow.defaultProps = {
  number: 0,
};

export default UsersRow;
