import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";
import FormToggleField, {
  ToggleVariant,
} from "common/formFields/FormToggleField";

const Card = styled.div`
  min-width: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "40%" : "100%"};
  background: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const LabelTypography = styled(Typography)`
  word-wrap: break-word;
  width: 50%;
`;

const ValueTypography = styled(Typography)`
  word-wrap: break-word;
`;

const ButtonsContainer = styled.div`
  padding-top: 1.625rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ContentBox = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`;

const ActiveBox = styled.div`
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const EffectCards = ({ data, disabled, index, onEdit, onDelete }) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("effects");

  return (
    <Card viewport={viewportType}>
      <ContentBox>
        <LabelTypography
          text={t("name")}
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          tag="span"
        />
        <ValueTypography text={data.name} variant={TypographyVariant.BODY2} />
      </ContentBox>
      <ContentBox>
        <LabelTypography
          text={t("file")}
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          tag="span"
        />
        <ValueTypography
          text={data.fbxFile?.url || ""}
          variant={TypographyVariant.BODY2}
        />
      </ContentBox>
      <ActiveBox>
        <LabelTypography
          text={t("isActive")}
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          tag="span"
        />
        <FormToggleField
          id={`effects[${index}].active`}
          name={`effects[${index}].active`}
          variant={ToggleVariant.CLASSIC}
        />
      </ActiveBox>
      <ButtonsContainer>
        <Button
          text={t("common:button.edit")}
          variant={ButtonVariant.CONTAINED}
          size={ButtonSize.SMALL}
          onClick={() => onEdit(data)}
          disabled={disabled}
        />
        <Button
          text={t("common:button.delete")}
          variant={ButtonVariant.ERROR}
          size={ButtonSize.SMALL}
          onClick={() => onDelete(data)}
          disabled={disabled}
        />
      </ButtonsContainer>
    </Card>
  );
};

EffectCards.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

EffectCards.defaultProps = {
  disabled: false,
};

export default EffectCards;
