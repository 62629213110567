import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import { Accordion, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Content from 'common/page/Content';

const stripePromise = loadStripe('pk_test_51P6BwxRwx6efe3veZ0uwAyuKr8BL7sLo4cIP3rl9es7S98BJH5HQYo3nr6BPMrjxkk6v3fY3ELVnL0JoHxmOTgup00xZSIApix');

const ElementsProvider= (params) => {
  const { t } = useTranslation("billing");
  const options = {
    clientSecret: params.setup
  };

  return (
    <>
    <Accordion style={{paddingTop: "20px"}}>
      <Content>
      <h3 style={{paddingBottom: "20px"}}>{t("updateCard")}</h3>
    {params.setup && 
    <Elements stripe={stripePromise} options={options}>
        <CheckoutForm />
    </Elements>
    }
    </Content>
    </Accordion>
    </>
  );
};

export default ElementsProvider;