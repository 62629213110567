import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { formatTimestamp } from "utils/DateTimeUtils";
import Box from "common/basicComponents/Box";

const Card = styled.div`
  min-width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 0 0 1.25rem;
  overflow: hidden;
`;

const LogCard = ({ data, onViewDetails }) => {
  const { t } = useTranslation("product");

  let changedBy = "-";
  if (data.changed_by) {
    changedBy = data.changed_by;
  } else if (data.changed_by_api) {
    changedBy = t("changedFromAPI");
  }

  return (
    <Card>
      <Box padding="1rem 0">
        <Typography
          text={t("user")}
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
        />
        <Typography text={changedBy} variant={TypographyVariant.BODY2} />
      </Box>
      <Box padding="1rem 0">
        <Typography
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("timestamp")}
        />
        <Typography
          variant={TypographyVariant.BODY2}
          text={formatTimestamp(data.changed_at)}
        />
      </Box>
      <Button
        text={t("seeDetails")}
        variant={ButtonVariant.CONTAINED}
        size={ButtonSize.SMALL}
        onClick={() => onViewDetails(data)}
      />
    </Card>
  );
};

LogCard.propTypes = {
  data: PropTypes.object.isRequired,
  onViewDetails: PropTypes.func.isRequired,
};

export default LogCard;
