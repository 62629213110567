import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Dialog from "common/basicComponents/Dialog";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import ValidationSchema from "./PushNotificationForm.schema";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { ButtonWidth } from "common/basicComponents/Button";
import Box from "common/basicComponents/Box";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { ButtonType } from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";
import FormDateField from "common/formFields/FormDateField";
import { DateInputSize } from "common/formFields/FormDateField";

const Buttons = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "row" : "column-reverse"};
  width: 100%;
  gap: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "2rem" : "1.25rem"};
  margin-top: 2.5rem;
`;

const EditPushNotificationDialog = ({
  open,
  pushNotifications,
  onSave,
  onClose,
}) => {
  const { t } = useTranslation("notifications");
  const { type: viewportType } = useViewport();

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    pushNotifications?.id
      ? onSave({
          id: pushNotifications?.id,
          ...values,
        })
      : onSave(values);
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding="0 0 2.5rem">
        <Typography
          variant={TypographyVariant.H4}
          weight={TypographyWeight.BOLD}
          text={
            pushNotifications?.id ? t("editNotification") : t("addNotification")
          }
        />
      </Box>
      <Formik
        initialValues={{
          notification_timestamp: "",
          title: pushNotifications?.notification_title,
          message: pushNotifications?.notification_message,
        }}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <Box padding="0.5rem 0 0">
              <FormDateField
                name="notification_timestamp"
                id="notification_timestamp"
                label={t("sendDate")}
                size={DateInputSize.NORMAL}
                disablePastDates
              />
            </Box>
            <Box padding="0.5rem 0 0">
              <FormInputField
                type={FormInputFieldType.TEXT}
                name="title"
                id="title"
                label={t("pushTitle")}
                disabled={isSubmitting}
                limit={25}
              />
            </Box>
            <Box padding="0.5rem 0 0">
              <FormInputField
                type={FormInputFieldType.TEXT}
                name="message"
                id="message"
                label={t("message")}
                disabled={isSubmitting}
                multiline
                rows={3}
                limit={100}
              />
            </Box>
            <Buttons viewport={viewportType}>
              <Button
                variant={ButtonVariant.OUTLINED}
                text={t("common:button.cancel")}
                width={ButtonWidth.GROW}
                onClick={onClose}
                disabled={isSubmitting}
              />
              <Button
                variant={ButtonVariant.GREEN}
                text={
                  pushNotifications?.id
                    ? t("saveNotification")
                    : t("addNotification")
                }
                width={ButtonWidth.GROW}
                type={ButtonType.SUBMIT}
                isLoading={isSubmitting}
              />
            </Buttons>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

EditPushNotificationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  pushNotifications: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

EditPushNotificationDialog.defaultProps = {
  pushNotifications: null,
};

export default EditPushNotificationDialog;
