import Table from "common/Table";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ViewportType } from "utils/ViewportContext";
import PushNotificationRow from "./PushNotificationRow";
import PushNotificationCard from "./PushNotificationCard";

const PushNotificationTable = ({
  pushNotifications,
  total,
  loading,
  onDelete,
}) => {
  const { t } = useTranslation("notifications");

  const headers = [
    {
      text: t("sendDate"),
    },
    {
      text: t("pushTitle"),
    },
    {
      text: t("message"),
    },
    {
      text: t("status"),
      centered: true,
    },
    {
      text: t("receivers"),
      centered: true,
    },
    {
      text: t("actions"),
      maxWidth: "4.5rem",
      centered: true,
    },
  ];

  return (
    <Table
      headers={headers}
      data={pushNotifications}
      total={total}
      tableRow={PushNotificationRow}
      tableCard={PushNotificationCard}
      cardViewportBreakpoint={ViewportType.TABLET}
      keyPropertyName="id"
      loading={loading}
      itemProps={{
        onDelete,
      }}
      noDataText={t("noData")}
    />
  );
};

PushNotificationTable.propTypes = {
  pushNotifications: PropTypes.array,
  total: PropTypes.number,
  loading: PropTypes.bool,
  onDelete: PropTypes.func.isRequired,
};

PushNotificationTable.defaultProps = {
  total: 0,
  loading: false,
};

export default PushNotificationTable;
