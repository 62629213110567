import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";

const Card = styled.div`
  min-width: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "40%" : "100%"};
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const FieldLabel = styled(Typography)`
  text-align: left;
  margin-top: 1rem;
  word-wrap: break-word;
`;

const FieldValue = styled(Typography)`
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 1.125rem;
  word-wrap: break-word;
`;

const ButtonsContainer = styled.div`
  margin-top: 1.125rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DomainCard = ({ data, disabled, onEdit, onDelete }) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("domains");

  return (
    <Card viewport={viewportType}>
      <FieldLabel
        text={t("domainAdress")}
        variant={TypographyVariant.SUBTITLE2}
        weight={TypographyWeight.BOLD}
        tag="span"
      />
      <FieldValue text={data.domainUrl} variant={TypographyVariant.BODY2} />
      <ButtonsContainer>
        <Button
          text={t("common:button.edit")}
          variant={ButtonVariant.CONTAINED}
          size={ButtonSize.SMALL}
          onClick={() => onEdit(data)}
          disabled={disabled}
        />
        <Button
          text={t("common:button.delete")}
          variant={ButtonVariant.ERROR}
          size={ButtonSize.SMALL}
          onClick={() => onDelete(data)}
          disabled={disabled}
        />
      </ButtonsContainer>
    </Card>
  );
};

DomainCard.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DomainCard.defaultProps = {
  disabled: false,
};

export default DomainCard;
