import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Typography, { TypographyVariant } from "./Typography";
import Icon, { IconName } from "./Icon";
import Button, { ButtonSize, ButtonType, ButtonVariant } from "./Button";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";

export const NotificationVariant = {
  SUCCESS: "success",
  INFO: "info",
  ERROR: "error",
};

const getNotificationColors = (theme, variant) =>
  ({
    [NotificationVariant.SUCCESS]: css`
      background: ${theme.colors.success[50]};
      color: ${theme.colors.success[800]};
      border: 1px solid ${theme.colors.success[800]};
      button:hover {
        color: ${theme.colors.black};
      }
      button:active {
        color: ${theme.colors.success[800]};
      }
    `,
    [NotificationVariant.INFO]: css`
      background: ${theme.colors.alert[50]};
      color: ${theme.colors.black};
      border: 1px solid ${theme.colors.black};
      button:hover {
        color: ${theme.colors.alert[800]};
      }
      button:active {
        color: ${theme.colors.alert[700]};
      }
    `,
    [NotificationVariant.ERROR]: css`
      background: ${theme.colors.secondary[50]};
      color: ${theme.colors.secondary[700]};
      border: 1px solid ${theme.colors.secondary[700]};
      button:hover {
        color: ${theme.colors.black};
      }
      button:active {
        color: ${theme.colors.secondary[700]};
      }
    `,
  }[variant]);

const getNotificationAlignment = (viewport, fullWidth) => {
  switch (viewport) {
    case ViewportType.DESKTOP:
      if (fullWidth)
        return css`
          left: 2rem;
          right: 2rem;
        `;
      else
        return css`
          left: 18rem;
          right: 2rem;
        `;
    case ViewportType.TABLET:
      return css`
        left: 2rem;
        right: 2rem;
      `;
    case ViewportType.MOBILE:
      return css`
        left: 1.25rem;
        right: 1.25rem;
      `;
  }
};

const StyledNotification = styled.div`
  padding: 1rem;
  position: fixed;
  height: ${({ height }) => height || "4rem"};
  z-index: 100;
  ${({ viewport, fullWidth }) => getNotificationAlignment(viewport, fullWidth)}

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.25rem;

  .notification-text {
    flex-grow: 1;
  }

  button {
    border: none;
    background: transparent;
    color: inherit;
  }

  bottom: ${({ height }) => (height ? "-" + height : "-4rem")};
  transition: bottom 0.75s;
  &.opened {
    bottom: 1.25rem;
  }

  ${({ theme, variant }) => getNotificationColors(theme, variant)};
`;

const Notification = ({ variant, text, open, onClose, height, fullWidth }) => {
  const { type: viewportType } = useViewport();
  return (
    <StyledNotification
      variant={variant}
      className={open && "opened"}
      viewport={viewportType}
      fullWidth={fullWidth}
      height={height}
    >
      <Icon name={variant} size="2rem" />
      <Typography
        variant={TypographyVariant.BODY1}
        text={text}
        className="notification-text"
      />
      <Button
        icon={IconName.CLOSE}
        variant={ButtonVariant.CUSTOM}
        size={ButtonSize.LARGE}
        onClick={onClose}
        type={ButtonType.BUTTON}
      />
    </StyledNotification>
  );
};

Notification.propTypes = {
  variant: PropTypes.oneOf(Object.values(NotificationVariant)),
  text: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  height: PropTypes.string,
  fullWidth: PropTypes.bool,
};

Notification.defaultProps = {
  variant: NotificationVariant.INFO,
  text: "",
  open: false,
  onClose: () => {},
  height: null,
  fullWidth: false,
};

export default Notification;
