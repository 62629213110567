import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import { setDomains } from "./DomainSlice";

export const getDomains = () => {
  return apiClient
    .get(ApiEndpoints.DOMAINS)
    .then((res) => {
      const domains = res?.data?.body;
      if (!domains) return false;
      store.dispatch(setDomains(domains));
      return true;
    })
    .catch(() => false);
};

export const addDomain = (domain) => {
  return apiClient
    .post(ApiEndpoints.DOMAINS, domain)
    .then((res) => res?.data?.id);
};

export const editDomain = (id, domain) => {
  return apiClient.put(`${ApiEndpoints.DOMAINS}${id}`, domain).then(() => true);
};

export const deleteDomain = (id) => {
  return apiClient.delete(`${ApiEndpoints.DOMAINS}${id}`).then(() => true);
};
