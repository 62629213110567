import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import Accordion from "common/basicComponents/Accordion";
import { useViewport, ViewportType } from "utils/ViewportContext";
import { ToggleVariant } from "common/formFields/FormToggleField";
import FormToggleField from "common/formFields/FormToggleField";
import Box from "common/basicComponents/Box";
import FormFileField from "common/formFields/FormFileField";
import FileExtension from "constants/FileExtension";
import FormInputField from "common/formFields/FormInputField";
import { FormInputFieldType } from "common/formFields/FormInputField";
import Typography from "common/basicComponents/Typography";
import { TypographyVariant } from "common/basicComponents/Typography";
import { TypographyWeight } from "common/basicComponents/Typography";

const AccordionContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem 0.625rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  align-items: baseline;
`;

const FontBox = styled(Box)`
  .switch-control {
    margin-top: 0.25rem;
  }
`;
const Preview = styled(Box)`
  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
  margin-bottom: 1.25rem;
  display: flex;
`;

const PreviewTypography = styled(Typography)`
  margin: auto;
  padding: 0.25rem 0.5rem;
  ${({ color }) => `color: ${color};`}
  ${({ backgroundColor }) => `background-color: ${backgroundColor};`}
`;

const QrTextBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`;
const TryOnScreenForm = ({ disabled }) => {
  const { t } = useTranslation("totems");
  const { type: viewportType } = useViewport();
  const [accordionHasErrors, setAccordionHasErrors] = useState(false);
  const { values } = useFormikContext();

  useEffect(() => {
    if (accordionHasErrors) setAccordionHasErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Accordion
      text={t("tryOnScreen.title")}
      forceOpen={accordionHasErrors}
      defaultOpen
    >
      <AccordionContent>
        <Box
          width={viewportType === ViewportType.MOBILE ? "100%" : "50%"}
          padding="0 0.625rem"
        >
          <FormFileField
            id={"logoFile"}
            name={"logoFile"}
            label={t("tryOnScreen.logoFile")}
            acceptedExtensions={[FileExtension.PNG]}
            disabled={disabled}
            preview
            aspectRatio={1080 / 110}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <FontBox
          width={viewportType === ViewportType.MOBILE ? "100%" : "50%"}
          padding="0 0.625rem"
        >
          <FormToggleField
            id="productNameFontReversed"
            name="productNameFontReversed"
            label={t("tryOnScreen.productNameStyle")}
            leftOption={t("tryOnScreen.reversed")}
            rightOption={t("tryOnScreen.normal")}
            variant={ToggleVariant.SWITCH_BLACK}
            disabled={disabled}
          />
          <Preview width="100%" height="3.375rem">
            <PreviewTypography
              variant={TypographyVariant.BODY2}
              weight={TypographyWeight.REGULAR}
              text={t("tryOnScreen.preview")}
              backgroundColor={
                values.productNameFontReversed
                  ? `rgb(${values.fontColor?.r}, ${values.fontColor?.g}, ${values.fontColor?.b})`
                  : "transparent"
              }
              color={
                values.productNameFontReversed
                  ? "white"
                  : `rgb(${values.fontColor?.r}, ${values.fontColor?.g}, ${values.fontColor?.b})`
              }
            />
          </Preview>
        </FontBox>
        <Box
          width={viewportType === ViewportType.DESKTOP ? "auto" : "100%"}
          padding="0 0.625rem"
        >
          <FormToggleField
            id="qrCodes"
            name="qrCodes"
            label={t("tryOnScreen.qrCodes")}
            variant={ToggleVariant.SWITCH_COLOR}
            disabled={disabled}
          />
        </Box>
        <QrTextBox
          grow={1}
          width={viewportType === ViewportType.DESKTOP ? "auto" : "100%"}
        >
          <Box
            grow={1}
            padding="0 0.625rem"
            width={viewportType === ViewportType.MOBILE ? "100%" : "auto"}
          >
            <FormInputField
              type={FormInputFieldType.TEXT}
              id={"qrCodesTextLine1"}
              name={"qrCodesTextLine1"}
              label={t("tryOnScreen.qrCodesTextLine1")}
              limit={55}
              disabled={disabled || !values.qrCodes}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box
            grow={1}
            padding="0 0.625rem"
            width={viewportType === ViewportType.MOBILE ? "100%" : "auto"}
          >
            <FormInputField
              type={FormInputFieldType.TEXT}
              id={"qrCodesTextLine2"}
              name={"qrCodesTextLine2"}
              label={t("tryOnScreen.qrCodesTextLine2")}
              limit={55}
              disabled={disabled || !values.qrCodes}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
        </QrTextBox>
      </AccordionContent>
    </Accordion>
  );
};

TryOnScreenForm.propTypes = {
  disabled: PropTypes.bool,
};

TryOnScreenForm.defaultProps = {
  disabled: false,
};

export default TryOnScreenForm;
