import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";

import Accordion from "common/basicComponents/Accordion";
import Box from "common/basicComponents/Box";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import FormSelectField from "common/formFields/FormSelectField";
import FormIconSelectField from "common/formFields/FormIconSelectField";
import { useViewport, ViewportType } from "utils/ViewportContext";
import { brandsToSelect } from "utils/BrandUtils";
import { tagGroupsToSelect } from "utils/TagUtils";
import { typesToSelect } from "utils/TypeUtils";
import ModelType from "constants/ModelType";

const Content = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem 0.625rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  align-items: baseline;
`;

const ProductInfoForm = ({ disabled }) => {
  const { t } = useTranslation("product");
  const { type: viewportType } = useViewport();
  const { values, setFieldValue } = useFormikContext();
  const { brands } = useSelector((state) => state.brands);
  const { tagGroups } = useSelector((state) => state.tagGroups);
  const { types } = useSelector((state) => state.types);
  const { partner } = useSelector((state) => state.auth);
  const typeOptions = useMemo(() => typesToSelect(types), [types]);

  const [accordionHasErrors, setAccordionHasErrors] = useState(false);

  useEffect(() => {
    if (accordionHasErrors) setAccordionHasErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Accordion text={t("basicInfo")} defaultOpen forceOpen={accordionHasErrors}>
      <Content>
        <Box padding="0 0.625rem" width="100%">
          <FormIconSelectField
            id="type"
            name="type"
            label={t("type")}
            optionWidth={
              viewportType > ViewportType.MOBILE ? "9.375rem" : "8.125rem"
            }
            options={typeOptions}
            disabled={
              disabled || values.fromMarketplace || values.fromOtherPartner
            }
            onChange={() =>
              setFieldValue("modelType", { value: ModelType.MODEL3D })
            }
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
          grow={1}
        >
          <FormInputField
            type={FormInputFieldType.TEXT}
            name="name"
            id="name"
            label={t("name")}
            disabled={disabled}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
          show={partner?.mobile_active}
          grow={1}
        >
          <FormSelectField
            name="brand"
            id="brand"
            label={t("brand")}
            options={brandsToSelect(brands)}
            disabled={
              disabled || values.fromMarketplace || values.fromOtherPartner
            }
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.MOBILE ? "75%" : "100%"}
          show={partner?.mobile_active}
          grow={1}
        >
          <FormSelectField
            name="tags"
            id="tags"
            label={t("tags")}
            isMulti
            options={tagGroupsToSelect(tagGroups)}
            disabled={disabled}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
      </Content>
    </Accordion>
  );
};

ProductInfoForm.propTypes = {
  disabled: PropTypes.bool,
};

ProductInfoForm.defaultProps = {
  disabled: false,
};

export default ProductInfoForm;
