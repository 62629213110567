import React from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import LabelledCheckbox, {
  CheckboxSize,
  CheckboxType,
} from "common/basicComponents/LabelledCheckbox";

const FormCheckboxField = ({
  id,
  label,
  description,
  disabled,
  readOnly,
  type,
  size,
  onChange,
}) => {
  const { isSubmitting } = useFormikContext();
  const [field, , helpers] = useField(id);

  const changeHandler = (value) => {
    helpers.setValue(value);
    onChange(value);
  };

  return (
    <LabelledCheckbox
      id={id}
      label={label}
      description={description}
      disabled={isSubmitting || disabled}
      readOnly={readOnly}
      type={type}
      size={size}
      value={field.value}
      onChange={changeHandler}
    />
  );
};

FormCheckboxField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(CheckboxType)),
  size: PropTypes.oneOf(Object.values(CheckboxSize)),
  onChange: PropTypes.func,
};

FormCheckboxField.defaultProps = {
  label: null,
  description: "",
  disabled: false,
  readOnly: false,
  type: CheckboxType.CONTAINED,
  size: CheckboxType.NORMAL,
  onChange: () => {},
};

export { CheckboxType };
export default FormCheckboxField;
