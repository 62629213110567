import { Form, useFormikContext } from "formik";
import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import BasicData from "./form/BasicDataForm";
import AvailableFunctionalities from "./form/AvailableFunctionalitiesForm";
import ProductSettings from "./form/ProductSettingsForm";
import Domains from "./form/DomainsForm";
import ApplicationLinks from "./form/ApplicationLinksForm";
import PushNotifications from "./form/PushNotificationsForm";
import Totems from "./form/TotemsForm";
import Effects from "./form/EffectsForm";
import PrivacyPolicy from "./form/PrivacyPolicyForm";
import WebApplication from "./form/WebApplicationForm";
import { ViewportType, useViewport } from "utils/ViewportContext";
import { ExtendedUserRoles } from "constants/UserRoles";
import { scrollToError } from "utils/FormUtils";
import FormObserver from "common/FormObserver";

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ viewport }) =>
    viewport === ViewportType.TABLET ? "2rem" : "1.25rem"};
`;

const SettingForm = () => {
  const { type: viewportType } = useViewport();
  const { user } = useSelector((state) => state.auth);
  const { values } = useFormikContext();

  return (
    <Form id="partner-form" noValidate>
      <SettingsContainer viewport={viewportType}>
        <FormObserver
          onValidationErrors={(errors) =>
            setTimeout(() => scrollToError(errors), 500)
          }
        />
        <BasicData />
        {user.role === ExtendedUserRoles.SUPERADMIN && (
          <>
            <AvailableFunctionalities />
            <ProductSettings />
          </>
        )}
        {user.role === ExtendedUserRoles.SUPERADMIN && values.mobileActive && (
          <PushNotifications />
        )}
        {values.totemActive && <Totems />}
        {user.role === ExtendedUserRoles.SUPERADMIN && values.webActive && (
          <WebApplication />
        )}
        {user.role === ExtendedUserRoles.SUPERADMIN &&
          (values.webActive || values.qrcodesActive) && <Domains />}
        {values.mobileActive &&
          [ExtendedUserRoles.SUPERADMIN, ExtendedUserRoles.ADMIN].includes(
            user.role
          ) && <ApplicationLinks />}
        {values.mobileActive && user.role === ExtendedUserRoles.SUPERADMIN && (
          <Effects />
        )}
        <PrivacyPolicy />
      </SettingsContainer>
    </Form>
  );
};

export default SettingForm;
