import React from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import { translate } from "utils/FormUtils";
import TextEditor from "common/basicComponents/TextEditor";

const FormEditorField = ({ id, name, disabled, placeholder }) => {
  const [field, meta, helpers] = useField(name);
  const { isSubmitting, submitCount } = useFormikContext();

  return (
    <TextEditor
      id={id}
      value={field.value}
      placeholder={placeholder}
      disabled={isSubmitting || disabled}
      onChange={(value) => {
        helpers.setValue(value);
      }}
      error={
        submitCount > 0 && meta.touched && meta.error
          ? translate(meta.error)
          : null
      }
    />
  );
};

FormEditorField.defaultProps = {
  disabled: false,
  placeholder: "",
};

FormEditorField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

export default FormEditorField;
