import { createSlice } from "@reduxjs/toolkit";
import { clearAuthData, setCurrentPartner } from "components/auth/AuthSlice";

const initialState = {
  totems: [],
  totem: null,
  versions: [],
  banners: [],
};

export const totemSlice = createSlice({
  name: "totems",
  initialState,
  reducers: {
    setTotems: (state, action) => {
      state.totems = action.payload;
    },
    setTotem: (state, action) => {
      state.totem = action.payload;
    },
    setVersions: (state, action) => {
      state.versions = action.payload;
    },
    setBanners: (state, action) => {
      state.banners = action.payload;
    },
    setVersionActive: (state, action) => {
      const newVersions = [...(state.versions || [])];
      newVersions.map((version) => {
        if (version.id === action.payload.id) {
          version.version_active = action.payload.active;
        }
        return version;
      });
      state.versions = newVersions;
    },
    clearTotem: (state) => {
      state.totem = initialState.totem;
      state.versions = initialState.versions;
      state.banners = initialState.banners;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
    builder.addCase(setCurrentPartner, () => initialState);
  },
});

export const {
  setTotems,
  setTotem,
  setBanners,
  setVersions,
  setVersionActive,
  clearTotem,
} = totemSlice.actions;
export default totemSlice.reducer;
