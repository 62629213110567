import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import { useViewport, ViewportType } from "utils/ViewportContext";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { NumberType } from "common/formFields/FormInputField";
import Accordion from "common/basicComponents/Accordion";
import FormToggleField, {
  ToggleVariant,
} from "common/formFields/FormToggleField";
import Box from "common/basicComponents/Box";

const WebApplicationContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 1rem;
`;

const WebApplication = () => {
  const { t } = useTranslation("webApplication");
  const { type: viewportType } = useViewport();
  const { values } = useFormikContext();
  const [accordionHasErrors, setAccordionHasErrors] = useState(false);

  useEffect(() => {
    if (accordionHasErrors) setAccordionHasErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Accordion text={t("web")} forceOpen={accordionHasErrors}>
      <WebApplicationContent>
        <FormToggleField
          id="webUsageRemaindersActive"
          name="webUsageRemaindersActive"
          label={t("webUsageRemaindersActive")}
          align="center"
          switchWidth="100%"
          variant={ToggleVariant.SWITCH_COLOR}
        />
        <Box
          grow={viewportType !== ViewportType.MOBILE ? 1 : null}
          width={viewportType === ViewportType.MOBILE ? "100%" : null}
        >
          <FormInputField
            viewportType={viewportType}
            type={FormInputFieldType.NUMBER}
            numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
            name="webRequestsLimit"
            id="webRequestsLimit"
            label={t("webRequestsLimit")}
            disabled={!values.webUsageRemaindersActive}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
      </WebApplicationContent>
    </Accordion>
  );
};

export default WebApplication;
