import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";
import DesktopNavigation from "./DesktopNavigation";
import MobileNavigation from "./MobileNavigation";

const MainContainer = styled.div`
  width: 100%;
  height: ${({ viewportType }) =>
    viewportType >= ViewportType.DESKTOP ? "100vh" : "100svh"};
  display: flex;
  ${({ viewportType }) =>
    viewportType >= ViewportType.DESKTOP ? "" : "flex-direction: column"};
`;

const Content = styled.div`
  flex-grow: 1;
  ${({ viewportType }) =>
    viewportType >= ViewportType.DESKTOP
      ? "overflow-y: hidden"
      : "overflow: hidden"};
`;

const Navigation = ({
  children,
  pages,
  partners,
  partner,
  onPartnerChanged,
  onPageChanged,
  onLogout,
}) => {
  const { type: viewportType } = useViewport();

  const NavigationComponent =
    viewportType >= ViewportType.DESKTOP ? DesktopNavigation : MobileNavigation;

  return (
    <MainContainer viewportType={viewportType}>
      <NavigationComponent
        pages={pages}
        partners={partners}
        partner={partner}
        onPartnerChanged={onPartnerChanged}
        onPageChanged={onPageChanged}
        onLogout={onLogout}
      />
      <Content viewportType={viewportType}>{children}</Content>
    </MainContainer>
  );
};

const PartnerPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  partner_name: PropTypes.string.isRequired,
});

Navigation.propTypes = {
  children: PropTypes.node.isRequired,
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  partners: PropTypes.arrayOf(PartnerPropType).isRequired,
  partner: PartnerPropType,
  onPartnerChanged: PropTypes.func.isRequired,
  onPageChanged: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

Navigation.defaultProps = {
  partner: null,
};

export default Navigation;
