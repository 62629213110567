import _ from "lodash";

export const objectToCamelCase = (obj) => {
  if (!obj) return null;
  const newObj = {};
  for (const key of Object.keys(obj)) {
    const newKey = _.camelCase(key);
    let val = obj[key];
    if (Array.isArray(val)) val = val.map((v) => objectToCamelCase(v));
    else if (typeof val === "object") val = objectToCamelCase(val);

    newObj[newKey] = val;
  }
  return newObj;
};

export const valuesEqual = (object1, object2) => {
  if (!object1 || !object2) return false;
  const keys2 = Object.keys(object2);
  for (const key of keys2) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = typeof val1 === "object" && typeof val2 === "object";
    if (
      (key === "versions" && val1.length !== val2.length) ||
      (areObjects && val1 && val2 && !valuesEqual(val1, val2)) ||
      (!areObjects && val1 !== val2 && val1 != null)
    ) {
      return false;
    }
  }
  return true;
};
