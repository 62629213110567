import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Header from "common/page/Header";
import Content from "common/page/Content";
import Page from "common/page/Page";
import ElementsProvider from "./element/ElementsProvider";
import { getCurrent, getSetup } from "./BillingActions";
import CustomerForm from "./customerForm/CustomerForm";
import { Formik } from "formik";
import { billingDataToValues } from "utils/BillingUtils";

const Billing = () => {
   const { t } = useTranslation("billing");
   const {setup, current} = useSelector((state) => state.billing);

  useEffect(() => {
      getSetup();
      getCurrent();
  }, []);

  return (
    <Page>
      <Header
        title={t("title")}
      />
      <Content>
        <Formik initialValues={billingDataToValues(current)} enableReinitialize>
        <CustomerForm/>
        </Formik>
        <ElementsProvider setup={setup}/>
      </Content>
    </Page>
  );
};

export default Billing;
