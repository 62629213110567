import _ from "lodash";
import i18n from "i18n/i18n";
import TopFiveRow from "components/analytics/charts/TopFiveRow";
import SettingsLabels from "constants/SettingsLabels";
import MonthlyTryOns from "components/analytics/charts/MonthlyTryOns";

export const chartTypes = {
  BAR: "bar",
  LINE: "line",
  PIE: "pie",
  LIST: "list",
};
export const isDayDate = (isoStr) => {
  return isoStr.split("-").length === 3;
};

export const checkRequiredSettings = (settings, requiredSettings) => {
  return requiredSettings.every((v) => settings[v] === true);
};

export const getTopVersionNames = (products, daysAnalytics, key) => {
  // same method as inside backend function
  // change this after backend dashboard controller refactor
  let topVersionIds = {};
  const keys = Object.keys(daysAnalytics);
  if (keys?.length > 0) {
    const data = daysAnalytics[keys[keys.length - 1]];
    if (data[key]) {
      topVersionIds = data[key];
    }
  }

  let topVersionNames = [];
  if (!_.isEmpty(topVersionIds)) {
    const productsWidthDisplayNames = products.map((product) => {
      const newProduct = { ...product };
      newProduct.versions = product.versions.map((version) => {
        const newVersion = { ...version };
        newVersion.display_name = `${product.product_name} ${version.version_name}`;
        return newVersion;
      });
      return newProduct;
    });

    const versions = productsWidthDisplayNames
      .map((product) => product.versions)
      .flat();
    const topVersions = versions
      .filter((v) => Object.keys(topVersionIds).includes(v.id))
      .map((v) => ({
        name: v.display_name,
        count: topVersionIds[v.id],
      }));

    topVersionNames = topVersions.sort((v1, v2) => v2.count - v1.count);
  }
  return topVersionNames;
};

export const getChartData = (analytics, products) => {
  if (_.isEmpty(analytics) || _.isEmpty(products)) return {};
  const chartData = [];

  const onlyDaysData = Object.keys(analytics)
    .filter((key) => isDayDate(key))
    .sort()
    .reduce((obj, key) => {
      obj[key] = analytics[key];
      return obj;
    }, {});

  const campaignIds = [
    ...new Set(
      onlyDaysData
        ? [...Object.values(onlyDaysData)]
            .map((dayEvents) => dayEvents.campaigns)
            .map((campaigns) => (campaigns ? Object.keys(campaigns) : []))
            .flat()
        : []
    ),
  ];

  const campaigns = onlyDaysData
    ? [...Object.values(onlyDaysData)].map((dayEvents) => dayEvents.campaigns)
    : [];

  const campaignDict = {};

  for (const campaignId of campaignIds) {
    const clicksChartData = {
      name: `${i18n.t("analyticsDashboard:campaigns:clicks")} - ${campaignId}`,
      labels: Object.keys(onlyDaysData),
      data: [
        {
          label: i18n.t("analyticsDashboard:android"),
          data: campaigns.map(
            (item) => item?.[campaignId]?.clicked?.android ?? 0
          ),
        },
        {
          label: i18n.t("analyticsDashboard:ios"),
          data: campaigns.map(
            (item) =>
              item?.[campaignId]?.clicked?.ios +
                item?.[campaignId]?.clicked?.iPad ?? 0
          ),
        },
      ],
      type: chartTypes.LINE,
    };
    const installedChartData = {
      name: `${i18n.t(
        "analyticsDashboard:campaigns:installations"
      )} - ${campaignId}`,
      labels: Object.keys(onlyDaysData),
      data: [
        {
          label: i18n.t("analyticsDashboard:android"),
          data: campaigns.map(
            (item) => item?.[campaignId]?.installed?.android ?? 0
          ),
        },
        {
          label: i18n.t("analyticsDashboard:ios"),
          data: campaigns.map(
            (item) =>
              item?.[campaignId]?.installed?.ios +
                item?.[campaignId]?.installed?.iPad ?? 0
          ),
        },
      ],
      type: chartTypes.LINE,
    };

    campaignDict[campaignId] = {
      clicks: clicksChartData,
      installed: installedChartData,
    };
  }

  const topVersionNames = getTopVersionNames(products, onlyDaysData, "top");
  const topVersionNamesWeb = getTopVersionNames(
    products,
    onlyDaysData,
    "topWeb"
  );
  const webAnalyticsActive =
    [...Object.values(onlyDaysData)].filter((obj) => obj.webOpensDesktop !== 0)
      .length > 0 &&
    [...Object.values(onlyDaysData)].filter((obj) => obj.webOpensMobile !== 0)
      .length;

  chartData.push({
    name: i18n.t("analyticsDashboard:appOpenings"),
    data: [
      {
        label: i18n.t("analyticsDashboard:android"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.androidOpens
            )
          : [],
      },
      {
        label: i18n.t("analyticsDashboard:ios"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.iosOpens
            )
          : [],
      },
    ],
    type: chartTypes.BAR,
    requiredSettings: [SettingsLabels.MOBILE_ACTIVE],
  });

  chartData.push({
    name: i18n.t("analyticsDashboard:tryOns"),
    data: [
      {
        label: i18n.t("analyticsDashboard:android"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.androidAR
            )
          : [],
      },
      {
        label: i18n.t("analyticsDashboard:ios"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.iosAR
            )
          : [],
      },
    ],
    type: chartTypes.LINE,
    requiredSettings: [SettingsLabels.MOBILE_ACTIVE],
  });

  chartData.push({
    name: i18n.t("analyticsDashboard:topProducts"),
    data: [
      {
        headers: [
          {
            text: i18n.t("analyticsDashboard:productName"),
          },
          {
            text: i18n.t("analyticsDashboard:views"),
            centered: true,
            maxWidth: "10rem",
          },
        ],
        rowsData: topVersionNames,
        rowComponent: TopFiveRow,
        key: "name",
      },
    ],
    type: chartTypes.LIST,
    requiredSettings: [SettingsLabels.MOBILE_ACTIVE],
  });

  const monthlyKeys = Object.keys(analytics)
    .filter((a) => a.length === 7)
    .sort((a, b) => b.localeCompare(a))
    .slice(1, 6);
  const monthlyTryOns = monthlyKeys.map((a) => ({
    date: a,
    views: analytics[a].webOpens,
  }));

  chartData.push({
    name: i18n.t("analyticsDashboard:monthlyTryOns"),
    data: [
      {
        headers: [
          {
            text: i18n.t("analyticsDashboard:month"),
          },
          {
            text: i18n.t("analyticsDashboard:views"),
            centered: true,
            maxWidth: "10rem",
          },
        ],
        rowsData: monthlyTryOns,
        rowComponent: MonthlyTryOns,
        key: "date",
      },
    ],
    type: chartTypes.LIST,
    requiredSettings: [SettingsLabels.WEB_ACTIVE],
  });

  chartData.push({
    name: i18n.t("analyticsDashboard:previews3D"),
    data: [
      {
        label: i18n.t("analyticsDashboard:android"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.android3D
            )
          : [],
      },
      {
        label: i18n.t("analyticsDashboard:ios"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.ios3D
            )
          : [],
      },
    ],
    type: chartTypes.PIE,
    requiredSettings: [SettingsLabels.MOBILE_ACTIVE],
  });

  chartData.push({
    name: i18n.t("analyticsDashboard:byNameSearches"),
    data: [
      {
        label: i18n.t("analyticsDashboard:android"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.androidSearch
            )
          : [],
      },
      {
        label: i18n.t("analyticsDashboard:ios"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.iosSearch
            )
          : [],
      },
    ],
    type: chartTypes.LINE,
    requiredSettings: [SettingsLabels.MOBILE_ACTIVE],
  });

  chartData.push({
    name: i18n.t("analyticsDashboard:photos"),
    data: [
      {
        label: i18n.t("analyticsDashboard:android"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.androidPhoto
            )
          : [],
      },
      {
        label: i18n.t("analyticsDashboard:ios"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.iosPhoto
            )
          : [],
      },
    ],
    type: chartTypes.BAR,
    requiredSettings: [SettingsLabels.MOBILE_ACTIVE],
  });

  chartData.push({
    name: i18n.t("analyticsDashboard:pushNotifications"),
    data: [
      {
        label: i18n.t("analyticsDashboard:android"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.androidPush
            )
          : [],
      },
      {
        label: i18n.t("analyticsDashboard:ios"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.iosPush
            )
          : [],
      },
    ],
    type: chartTypes.BAR,
    requiredSettings: [SettingsLabels.MOBILE_ACTIVE],
  });

  chartData.push({
    name: i18n.t("analyticsDashboard:buyClick"),
    data: [
      {
        label: i18n.t("analyticsDashboard:android"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.androidProductBuys
            )
          : [],
      },
      {
        label: i18n.t("analyticsDashboard:ios"),
        data: onlyDaysData
          ? [...Object.values(onlyDaysData)].map(
              (daysEvents) => daysEvents.iosProductBuys
            )
          : [],
      },
    ],
    type: chartTypes.LINE,
    requiredSettings: [SettingsLabels.MOBILE_ACTIVE],
  });

  chartData.push({
    name: i18n.t("analyticsDashboard:webTryOns"),
    data: !webAnalyticsActive
      ? [
          {
            label: i18n.t("analyticsDashboard:web"),
            data: onlyDaysData
              ? [...Object.values(onlyDaysData)].map(
                  (daysEvents) => daysEvents.webOpens
                )
              : [],
          },
        ]
      : [
          {
            label: i18n.t("analyticsDashboard:webMobile"),
            data: onlyDaysData
              ? [...Object.values(onlyDaysData)].map(
                  (daysEvents) => daysEvents.webOpensMobile || 0
                )
              : [],
          },
          {
            label: i18n.t("analyticsDashboard:webDesktop"),
            data: onlyDaysData
              ? [...Object.values(onlyDaysData)].map(
                  (daysEvents) => daysEvents.webOpensDesktop || 0
                )
              : [],
          },
        ],
    type: chartTypes.LINE,
    requiredSettings: [SettingsLabels.WEB_ACTIVE],
  });

  if (!_.isEmpty(topVersionNamesWeb)) {
    chartData.push({
      name: i18n.t("analyticsDashboard:topProductsWeb"),
      data: [
        {
          headers: [
            {
              text: i18n.t("analyticsDashboard:productName"),
            },
            {
              text: i18n.t("analyticsDashboard:views"),
              centered: true,
              maxWidth: "10rem",
            },
          ],
          rowsData: topVersionNamesWeb,
          rowComponent: TopFiveRow,
          key: "name",
        },
      ],
      type: chartTypes.LIST,
      requiredSettings: [SettingsLabels.WEB_ACTIVE],
    });
  }

  return {
    data: chartData,
    campaigns: campaignDict,
    labels: Object.keys(onlyDaysData),
  };
};
