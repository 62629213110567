import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import { setTypes } from "./TypeSlice";

export const getTypes = () => {
  return apiClient
    .get(ApiEndpoints.TYPES)
    .then((res) => {
      const types = res?.data?.body;
      if (!types) return false;
      store.dispatch(setTypes(types));
      return true;
    })
    .catch(() => false);
};
