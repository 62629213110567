import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import CurrencyInputField from "react-currency-input-field";
import Typography, { TypographyVariant, TypographyWeight } from "./Typography";
import Button, { ButtonSize, ButtonType, ButtonVariant } from "./Button";
import { IconName } from "./Icon";
import { InputSize, getInputColors, getInputStyle } from "./Input";

const StyledInput = styled(CurrencyInputField)`
  ${({ theme, error }) => getInputColors(theme, error, "default")};
  ${({ size }) => getInputStyle(size)}

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary[200]};
    opacity: 1;
  }

  display: block;
  width: 100%;
  margin: 0.25rem 0;
  resize: none;

  &:hover {
    ${({ theme, error }) => getInputColors(theme, error, "hover")};
  }

  &:focus,
  &:focus-visible {
    ${({ theme, error }) => getInputColors(theme, error, "active")}
  }

  &:disabled {
    ${({ theme, error, readOnly }) =>
      getInputColors(theme, error, readOnly ? "active" : "disabled")};
  }
`;

const StyledLabel = styled.label`
  width: 100%;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey[500] : theme.colors.black};
  .label-text {
    display: inline-block;
  }
`;

const CaptionContainer = styled.div`
  min-height: 1rem;
`;
const InputContainer = styled.div`
  position: relative;
  
  & button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0.75rem auto 0.5rem;
    pointer-events: none};
  }
  & svg {
    fill: ${({ theme, error }) =>
      error ? theme.colors.secondary[600] : theme.colors.black};
  }

  & input {
    padding-right: ${({ error }) => (error ? "2.75rem" : "")};
  }
`;
const CurrencyInput = ({
  id,
  name,
  error,
  caption,
  label,
  disabled,
  readOnly,
  value,
  placeholder,
  currency,
  size,
  onChange,
}) => (
  <div>
    <StyledLabel disabled={disabled}>
      {label && (
        <Typography
          variant={
            size === InputSize.LARGE
              ? TypographyVariant.BODY1
              : TypographyVariant.BODY2
          }
          weight={TypographyWeight.SEMIBOLD}
          text={label}
          tag="span"
          className="label-text"
        />
      )}
      <InputContainer error={error}>
        <StyledInput
          id={id}
          name={name}
          placeholder={placeholder}
          value={value || ""}
          onValueChange={onChange}
          decimalsLimit={2}
          decimalScale={2}
          decimalSeparator="."
          error={error}
          disabled={disabled || readOnly}
          size={size}
          disableAbbreviations
          suffix={currency}
          readOnly={readOnly}
        />
        {error && (
          <Button
            type={ButtonType.BUTTON}
            icon={IconName.ERROR}
            variant={ButtonVariant.TEXT}
            size={ButtonSize.SMALL}
          />
        )}
      </InputContainer>
    </StyledLabel>
    <CaptionContainer error={error}>
      {(caption || error) && (
        <Typography
          variant={TypographyVariant.CAPTION}
          weight={TypographyWeight.LIGHT}
          text={error || caption}
        />
      )}
    </CaptionContainer>
  </div>
);

CurrencyInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  caption: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  currency: PropTypes.string,
  size: PropTypes.oneOf(Object.values(InputSize)),
  onChange: PropTypes.func,
};

CurrencyInput.defaultProps = {
  error: "",
  label: "",
  caption: "",
  disabled: false,
  readOnly: false,
  value: undefined,
  placeholder: null,
  currency: "",
  size: InputSize.NORMAL,
  onChange: () => {},
};

export default CurrencyInput;
