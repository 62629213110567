import * as Yup from "yup";

const colorYupObject = Yup.object()
  .shape({
    r: Yup.number()
      .max(255, "common:validator.maxNumber255")
      .required("common:validator.required"),
    g: Yup.number()
      .max(255, "common:validator.maxNumber255")
      .required("common:validator.required"),
    b: Yup.number()
      .max(255, "common:validator.maxNumber255")
      .required("common:validator.required"),
  })
  .required("common:validator.required");

export default () => {
  return Yup.object().shape({
    totemActive: Yup.boolean().required("common:validator.required"),
    totemName: Yup.string()
      .nullable()
      .max(50, { code: "common:validator.maxChars", params: { max: 50 } }),
    changeProductSeconds: Yup.number().required("common:validator.required"),
    faceDistanceLimit: Yup.number().required("common:validator.required"),
    hideTryonSeconds: Yup.number().required("common:validator.required"),
    showWelcomeScreen: Yup.boolean().required("common:validator.required"),
    fontColor: colorYupObject,
    backgroundColor: colorYupObject,
    progressBarBackground: colorYupObject,
    progressBarFront: colorYupObject,
    productNameFontReversed: Yup.boolean().required(
      "common:validator.required"
    ),
    qrCodes: Yup.boolean().required("common:validator.required"),
    qrCodesTextLine1: Yup.string()
      .max(55, { code: "common:validator.maxChars", params: { max: 55 } })
      .nullable(),
    qrCodesTextLine2: Yup.string()
      .max(55, { code: "common:validator.maxChars", params: { max: 55 } })
      .nullable(),
  });
};
