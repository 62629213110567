import React from "react";
import { Formik, Form } from "formik";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Dialog from "common/basicComponents/Dialog";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonType,
} from "common/basicComponents/Button";
import Box from "common/basicComponents/Box";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { useViewport, ViewportType } from "utils/ViewportContext";
import ValidationSchema from "./BrandForm.schema";

const Buttons = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "row" : "column-reverse"};
  margin-top: 2.5rem;
  width: 100%;
`;

const EditBrandDialog = ({ open, brand, onSave, onClose }) => {
  const { t } = useTranslation("brands");
  const { type: viewportType } = useViewport();

  const onSubmit = (values, form) => {
    form.setSubmitting(true);
    onSave(
      {
        id: values.id,
        brandName: values.brandName,
      },
      form
    );
    form.setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding="0 0 2.5rem">
        <Typography
          variant={TypographyVariant.H4}
          weight={TypographyWeight.BOLD}
          text={brand ? t("editBrand") : t("addBrand")}
        />
      </Box>
      <Formik
        initialValues={{
          id: brand?.id,
          brandName: brand?.brand_name,
        }}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form>
            <Box width="100%">
              <FormInputField
                type={FormInputFieldType.TEXT}
                name="brandName"
                id="brandName"
                label={t("brandName")}
                disabled={isSubmitting}
              />
            </Box>
            <Buttons viewport={viewportType}>
              <Box
                width={viewportType < ViewportType.TABLET ? "100%" : "50%"}
                padding={
                  viewportType < ViewportType.TABLET
                    ? "0.625rem 0"
                    : "0 1rem 0 0"
                }
              >
                <Button
                  variant={ButtonVariant.OUTLINED}
                  text={t("common:button.cancel")}
                  minWidth="13rem"
                  width="100%"
                  onClick={onClose}
                  disabled={isSubmitting}
                />
              </Box>
              <Box
                width={viewportType < ViewportType.TABLET ? "100%" : "50%"}
                padding={
                  viewportType < ViewportType.TABLET
                    ? "0.625rem 0"
                    : "0 0 0 1rem"
                }
              >
                <Button
                  variant={ButtonVariant.GREEN}
                  text={t("common:button.save")}
                  minWidth="13rem"
                  width="100%"
                  type={ButtonType.SUBMIT}
                  isLoading={isSubmitting}
                />
              </Box>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

EditBrandDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  brand: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

EditBrandDialog.defaultProps = {
  brand: null,
};

export default EditBrandDialog;
