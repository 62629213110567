const RouterPaths = {
  ROOT: "/",
  NOT_FOUND: "/notfound",
  STYLE_GUIDE: "/styleguide",
  PRODUCTS: "/products",
  ADD_PRODUCT: "/products/add",
  EDIT_PRODUCT: "/products/:id",
  MARKETPLACE: "/marketplace",
  TAG_GROUPS: "/tags",
  USERS: "/users",
  ADD_USER: "/users/add",
  EDIT_USER: "/users/:id",
  LINKS: "/links",
  BRANDS: "/brands",
  NOTIFICATIONS: "/notifications",
  SETTINGS: "/settings",
  ADD_PARTNER: "/partners/add",
  ANALYTICS_DASHBOARD: "/dashboard",
  TOTEMS: "/totems",
  EDIT_TOTEM: "/totems/:id",
  APPS: "/applications",
  IMPORT: "/import",
  IMPORT_REPORT: "/import/report",
  TERMS: "/terms",
  BILLING: "/billing",
  SETS: "/sets"
};

export default RouterPaths;
export const DefaultRoute = RouterPaths.PRODUCTS;
