import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import Page from "common/page/Page";
import Header from "common/page/Header";
import Content from "common/page/Content";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import { addSet, deleteSet, editSet, getSets } from "./SetsActions.js";
import { getDomains } from "components/domains/DomainActions";
import { getAllProductsWithVersions } from "components/products/ProductActions";
import { domainsToSelect } from "utils/DomainUtils";
import SetTable from "./list/SetTable.js";
import SetSearch from "./list/SetSearch";
import WarningDialog from "common/basicComponents/WarningDialog";
import EditSetDialog from "./dialogs/EditSetDialog.js";
import { PageSizes } from "common/Table/Pagination";
import { clearSets, setSearch } from "./SetsSlice.js";

const Sets = () => {
  const { t } = useTranslation("sets");
  const dispatch = useDispatch();

  const { sets, setsTotal, search } = useSelector((state) => state.sets);
  const { domains } = useSelector((state) => state.domains);
  const { allProducts } = useSelector((state) => state.products);
  const { partner } = useSelector((state) => state.auth);

  const [meta, setMeta] = useState({ page: 1, pageSize: PageSizes[0] });
  const [visibleSets, setVisibleSets] = useState([]);
  const [loading, setLoading] = useState(false);

  const [editDialog, setEditDialog] = useState({
    open: false,
    link: null,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    link: null,
  });

  useEffect(() => {
    setMeta({ page: 1, pageSize: PageSizes[0] });
  }, [partner]);

  useEffect(() => {
    getSets();
    getDomains();
    getAllProductsWithVersions();
  }, []);

  useEffect(() => {
    const maximumVisible = Math.min(setsTotal, meta.page * meta.pageSize);

    if (maximumVisible - sets.length > 0) return;

    setVisibleSets(
      sets.slice((meta.page - 1) * meta.pageSize, maximumVisible)
    );
  }, [meta, sets, setsTotal]);

  useEffect(() => {
    setLoading(true);
    const maximumVisible = Math.min(
      setsTotal || meta.pageSize,
      meta.page * meta.pageSize
    );
    const offset = sets.length;
    const limit = maximumVisible - offset;

    if (setsTotal > 0 && limit <= 0) {
      setLoading(false);
      return;
    }

    getSets({ offset, limit, ...search }).finally(() => {
      setLoading(false);
    });
  }, [meta, search, setsTotal, sets.length]);

  const handleSearch = (searchParams) => {
    dispatch(
      setSearch({
        ...searchParams,
      })
    );
    setMeta((prev) => ({ ...prev, page: 1 }));
  };

  const handleSave = (set, form) => {
    if (set.id) {
      editSet(set.id, set, form).then((ok) => {
        if (!ok) return;
        if (!search) dispatch(clearSets());
        dispatch(
          setSearch({
            ...search,
          })
        );
        setMeta({ page: 1, pageSize: PageSizes[0] });
        setEditDialog({ ...editDialog, open: false });
      });
    } else {
      addSet({ ...set}, form).then(
        (ok) => {
          if (!ok) return;
          dispatch(clearSets());
          setMeta({ page: 1, pageSize: PageSizes[0] });
          setEditDialog({ ...editDialog, open: false });
        }
      );
    }
  };

  const handleDelete = () => {
    deleteSet(deleteDialog.set.set_id).then((ok) => {
      if (!ok) return;
      dispatch(clearSets());
      setMeta({ page: 1, pageSize: PageSizes[0] });
    });
    setDeleteDialog({ ...deleteDialog, open: false });
  };

  return (
    <Page>
      <Header
        title={t("title")}
        button={
          <Button
            variant={ButtonVariant.GREEN}
            text={t("addSetsTitle")}
            icon={IconName.PLUS}
            minWidth="12.5rem"
            onClick={() => setEditDialog({ open: true, set: null })}
          />
        }
      >
        <SetSearch onSearch={handleSearch} />
      </Header>
      <Content>
        <SetTable
          sets={visibleSets}
          total={setsTotal}
          meta={meta}
          onMetaChange={setMeta}
          loading={loading}
          onEdit={(set) => setEditDialog({ open: true, set })}
          onDelete={(set) => setDeleteDialog({ open: true, set })}
        />
      </Content>
      <EditSetDialog
        allProducts={allProducts}
        domainOptions={domainsToSelect(domains)}
        open={editDialog.open}
        set={editDialog.set}
        sets={sets}
        onClose={() => setEditDialog({ ...editDialog, open: false })}
        onSave={handleSave}
      />
      <WarningDialog
        open={deleteDialog.open}
        name={
          deleteDialog.set?.set_name
          + "\n" +
          deleteDialog.set?.domain_name +
          deleteDialog.set?.web_link_url
        }
        onClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
        onAction={handleDelete}
        title={t("deleteAlertTitle")}
        warningButtonText={t("delete")}
      />
    </Page>
  );
};

export default Sets;
