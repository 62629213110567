import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    title: Yup.string()
      .required("common:validator.required")
      .max(25, { code: "common:validator.maxChars", params: { max: 25 } }),
    message: Yup.string()
      .required("common:validator.required")
      .max(100, { code: "common:validator.maxChars", params: { max: 100 } }),
    notification_timestamp: Yup.string()
      .required("common:validator.required")
      .test(
        "is-future",
        "common:validator.pastDate",
        (value) => new Date(value) > new Date()
      ),
  });
};
