import React from "react";
import { Form, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import Box from "common/basicComponents/Box";

const ImportForm = () => {
  const { t } = useTranslation("import");
  const { isSubmitting } = useFormikContext();

  return (
    <Form id="import-form" noValidate>
      <Box width="100%">
        <FormInputField
          type={FormInputFieldType.LINK}
          name="csvUrl"
          id="csvUrl"
          label={t("csvUrl")}
          disabled={isSubmitting}
        />
      </Box>
    </Form>
  );
};

export default ImportForm;
