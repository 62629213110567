import React from "react";
import styled from "styled-components";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import { TypographyWeight } from "common/basicComponents/Typography";
import PaginationButton from "./PaginationButton";
import { IconName } from "common/basicComponents/Icon";
import { range } from "lodash";
import PropTypes from "prop-types";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Select, { SelectSize } from "common/basicComponents/Select";
import { useTranslation } from "react-i18next";

const PaginationLayout = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal",
};

export const PageSizes = [10, 20, 50];
const pageSizeOptions = PageSizes.map((v) => {
  return { value: v, label: v.toString() };
});

const PaginationContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  flex-direction: ${({ layout }) =>
    layout === PaginationLayout.VERTICAL ? "column" : "row"};
  flex-wrap: no-wrap;
  gap: 1.5rem;
  align-items: center;
  white-space: nowrap;
`;

const Records = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 1rem;
  & > div {
    display: flex;
  }
`;

const Results = styled.div`
  order: ${({ layout }) => (layout === PaginationLayout.VERTICAL ? "1" : "0")};
`;

const PagesContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  button.prev {
    margin-right: 0.75rem;
  }

  button.next {
    margin-left: 0.75rem;
  }
`;

const getPagesConfig = (page, pageCount) => {
  const showLeftDots = pageCount > 5 && page > 3;
  const showRightDots = pageCount > 5 && page < pageCount - 2;
  let startPage, endPage;
  if (!showLeftDots && !showRightDots) {
    startPage = 1;
    endPage = pageCount;
  } else if (!showLeftDots && showRightDots) {
    startPage = 1;
    endPage = 4;
  } else if (showLeftDots && !showRightDots) {
    startPage = pageCount - 3;
    endPage = pageCount;
  } else {
    startPage = page - 1;
    endPage = page + 1;
  }
  return { startPage, endPage, showLeftDots, showRightDots };
};

const Pagination = ({
  total,
  page,
  onPageChange,
  pageSize,
  onPageSizeChange,
  loading,
}) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation();
  const layout =
    viewportType === ViewportType.DESKTOP
      ? PaginationLayout.HORIZONTAL
      : PaginationLayout.VERTICAL;
  const pageCount = Math.ceil(total / pageSize);

  const { startPage, endPage, showLeftDots, showRightDots } = getPagesConfig(
    page,
    pageCount
  );

  return (
    <PaginationContainer layout={layout}>
      <Records>
        <Typography
          variant={TypographyVariant.BODY2}
          text={`${t("table.recordsPerPage")}:`}
          tag="span"
        />
        <Select
          disabled={loading}
          id="pagination-select"
          name="pagination-select"
          onlyTextOptions
          isSearchable={false}
          options={pageSizeOptions}
          size={SelectSize.SMALL}
          value={pageSizeOptions.find((opt) => opt.value === pageSize)}
          onChange={(opt) => onPageSizeChange(opt.value)}
        />
      </Records>
      <Results layout={layout}>
        <Typography
          variant={TypographyVariant.BODY2}
          text={`${t("table.results")}: ${(page - 1) * pageSize + 1}-${Math.min(
            total,
            page * pageSize
          )} ${t("table.of")} `}
          tag="span"
        />
        <Typography
          variant={TypographyVariant.BODY2}
          weight={TypographyWeight.BOLD}
          text={total || ""}
          tag="span"
        />
      </Results>
      <PagesContainer>
        {total > pageSize && (
          <PaginationButton
            icon={IconName.LEFT_ARROW}
            className="prev"
            onClick={() => onPageChange(page - 1)}
            disabled={page === 1 || loading}
          />
        )}
        {showLeftDots && (
          <PaginationButton text="..." disabled={true} className="dots" />
        )}
        {range(startPage, endPage + 1).map((pageNo) => (
          <PaginationButton
            key={pageNo}
            text={pageNo}
            selected={page === pageNo}
            onClick={() => onPageChange(pageNo)}
            disabled={loading}
          />
        ))}
        {showRightDots && (
          <PaginationButton text="..." disabled={true} className="dots" />
        )}
        {total > pageSize && (
          <PaginationButton
            icon={IconName.RIGHT_ARROW}
            className="next"
            onClick={() => onPageChange(page + 1)}
            disabled={page === Math.ceil(total / pageSize) || loading}
          />
        )}
      </PagesContainer>
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  total: PropTypes.number,
  page: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

Pagination.defaultProps = {
  total: 0,
};

export default Pagination;
