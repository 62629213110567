import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { constantToSelect } from "utils/FormUtils";

import { AssignedOptions } from "./MarketplaceSearchOptions";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import FormSelectField from "common/formFields/FormSelectField";
import Button, {
  ButtonType,
  ButtonVariant,
} from "common/basicComponents/Button";

const Container = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: flex-end;

  & > *:not(button) {
    flex-grow: 1;
    flex-basis: 0;
    max-height: 4.125rem;
    white-space: nowrap;
  }
`;

const DesktopSearchForm = () => {
  const { t } = useTranslation("marketplace");
  const { partner } = useSelector((state) => state.auth);
  const { resetForm, isSubmitting } = useFormikContext();

  useEffect(() => {
    resetForm();
  }, [partner, resetForm]);

  return (
    <Container>
      <FormInputField
        id="productName"
        name="productName"
        label={t("productName")}
        disabled={isSubmitting}
        type={FormInputFieldType.TEXT}
      />
      <FormSelectField
        id="status"
        name="status"
        label={t("status")}
        disabled={isSubmitting}
        options={constantToSelect(AssignedOptions, "marketplace:search")}
      />

      <Button
        type={ButtonType.SUBMIT}
        minWidth="100px"
        disabled={isSubmitting}
        text={t("common:button.search")}
      />
      <Button
        type={ButtonType.SUBMIT}
        variant={ButtonVariant.OUTLINED}
        minWidth="100px"
        onClick={() => {
          resetForm();
        }}
        text={t("common:button.clear")}
      />
    </Container>
  );
};

export default DesktopSearchForm;
