import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";

import Box from "common/basicComponents/Box";
import { useViewport, ViewportType } from "utils/ViewportContext";
import FormInputField, {
  FormInputFieldType,
  NumberType,
} from "common/formFields/FormInputField";
import Accordion from "common/basicComponents/Accordion";
import FormDateField, { DateInputSize } from "common/formFields/FormDateField";
import { ExtendedUserRoles } from "constants/UserRoles";

const TotemsContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 1.25rem;
  align-items: baseline;
`;

const getWidth = (viewport) =>
  ({
    [ViewportType.DESKTOP]: css`
      width: 30%;
      min-width: 14.5rem;
    `,
    [ViewportType.TABLET]: css`
      width: 30%;
      min-width: 14.5rem;
    `,
    [ViewportType.MOBILE]: css`
      width: 100%;
      min-width: 14.5rem;
    `,
  }[viewport]);

const FieldBox = styled(Box)`
  ${({ viewport }) => getWidth(viewport)}
  }
`;

const Totems = () => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("totems");
  const { user } = useSelector((state) => state.auth);
  const { values } = useFormikContext();
  const [accordionHasErrors, setAccordionHasErrors] = useState(false);

  useEffect(() => {
    if (accordionHasErrors) {
      setAccordionHasErrors(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Accordion text={t("title")} forceOpen={accordionHasErrors}>
      <TotemsContent>
        <Box width="100%" show={!!values.id}>
          <FormInputField
            type={FormInputFieldType.COPY}
            name="totemsLicenseKey"
            id="totemsLicenseKey"
            disabled
            label={t("totemsLicenseKey")}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <FieldBox
          viewport={viewportType}
          grow={1}
          show={user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormDateField
            id="totemsLicenseExpirationDate"
            name="totemsLicenseExpirationDate"
            label={t("licenseExpirationDate")}
            size={DateInputSize.NORMAL}
            disablePastDates
            onError={() => setAccordionHasErrors(true)}
          />
        </FieldBox>
        <FieldBox
          viewport={viewportType}
          grow={1}
          show={user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormInputField
            type={FormInputFieldType.NUMBER}
            numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
            id="numberOfTotems"
            name="numberOfTotems"
            label={t("numberOfTotems")}
            onError={() => setAccordionHasErrors(true)}
          />
        </FieldBox>
        <FieldBox
          viewport={viewportType}
          grow={1}
          show={values.id && user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormInputField
            type={FormInputFieldType.NUMBER}
            id="numberOfTotemsActive"
            name="numberOfTotemsActive"
            disabled
            label={t("numberOfTotemsActive")}
          />
        </FieldBox>
      </TotemsContent>
    </Accordion>
  );
};
export default Totems;
