import * as THREE from "three";
import ProductTypes from "constants/ProductTypes";
import ThreeJSModes from "constants/ThreeJsModes";

const getParams = (productType) => {
  switch (productType) {
    case ProductTypes.HAT:
      return {
        ambientLightColor: 0x888888,
      };
    default:
      return {
        ambientLightColor: 0xaaaaaa,
      };
  }
};

const cameraParams = {
  fov: 25,
  near: 0.01,
  far: 200,
  thumbnailMode: {
    position: [0, 0, 18],
  },
  linesFrontMode: {
    position: [0, 0, 8],
  },
  linesSideMode: {
    position: [-7, 0, 40],
  },
};

const directionalLightParams = {
  color: 0xf0f0f0,
  intensity: 0.2,
  position: [0, 4, 4],
};

const initThreeApp = (canvas, productType, mode) => {
  const params = getParams(productType);

  const renderer = new THREE.WebGLRenderer({
    canvas,
    alpha: true,
    antialias: true,
    logarithmicDepthBuffer: true,
    preserveDrawingBuffer: true,
  });

  renderer.autoClear = false;

  const scene = new THREE.Scene();
  const sceneTop = new THREE.Scene();

  const { width, height } = canvas;
  let camera;
  if (mode === ThreeJSModes.THUMBNAIL_MODE) {
    camera = new THREE.PerspectiveCamera(
      cameraParams.fov,
      width / height,
      cameraParams.near,
      cameraParams.far
    );
    camera.position.set(...cameraParams[mode].position);
  } else {
    camera = new THREE.OrthographicCamera(
      width / -30,
      width / 30,
      height / 30,
      height / -30,
      1,
      500
    );
    camera.position.set(...cameraParams[mode].position);
  }

  const resize = () => {
    camera.updateProjectionMatrix();
  };

  const render = () => {
    requestAnimationFrame(render);
    renderer.clear();
    renderer.render(scene, camera);
    renderer.clearDepth();
    renderer.render(sceneTop, camera);
  };

  // initial resize and render
  resize();
  render();

  // add light
  const light = new THREE.DirectionalLight(
    directionalLightParams.color,
    directionalLightParams.intensity
  );
  light.position.set(...directionalLightParams.position);
  scene.add(light);

  const ambLight = new THREE.AmbientLight(params.ambientLightColor);
  scene.add(ambLight);

  return {
    renderer,
    camera,
    scene,
    sceneTop,
    resize,
    render,
  };
};

export { initThreeApp };
