import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import _ from "lodash";

const CustomFormik = ({
  children,
  countChangedFields,
  onSubmit,
  initialValues,
  enableReinitialize,
  validationSchema,
}) => {
  return (
    <Formik
      onSubmit={(values, helpers) => {
        if (countChangedFields) {
          const uniqueKeys = Object.keys({ ...values, ...initialValues });

          const changedValues = uniqueKeys.filter(
            (key) => !_.isEqual(values?.[key], initialValues?.[key])
          );

          helpers.setStatus({ changedFields: changedValues.length });
        }
        onSubmit(values, helpers);
      }}
      initialValues={initialValues}
      enableReinitialize={enableReinitialize}
      validationSchema={validationSchema}
    >
      {children}
    </Formik>
  );
};

CustomFormik.propTypes = {
  children: PropTypes.node,
  countChangedFields: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  enableReinitialize: PropTypes.bool,
  validationSchema: PropTypes.any,
};

CustomFormik.defaultProps = {
  children: null,
  countChangedFields: false,
  enableReinitialize: false,
  validationSchema: null,
};

export default CustomFormik;
