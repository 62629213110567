import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    email: Yup.string()
      .required("common:validator.required")
      .email("common:validator.emailFormat")
      .max(50, { code: "common:validator.maxChars", params: { max: 50 } }),
  });
};
