import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Typography, { TypographyVariant, TypographyWeight } from "./Typography";
import Checkbox, { CheckboxSize, CheckboxType } from "./Checkbox";

const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey[500] : theme.colors.black};
`;

const LabelledCheckbox = ({
  id,
  label,
  disabled,
  readOnly,
  size,
  type,
  value,
  onChange,
}) => (
  <CheckboxContainer type={type}>
    <Checkbox
      id={id}
      disabled={disabled}
      readOnly={readOnly}
      type={type}
      size={size}
      value={value}
      onChange={onChange}
    />
    <Typography
      type={TypographyVariant.BODY2}
      weight={TypographyWeight.REGULAR}
      text={label}
      tag="span"
    />
  </CheckboxContainer>
);

LabelledCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(CheckboxSize)),
  type: PropTypes.oneOf(Object.values(CheckboxType)),
  onChange: PropTypes.func,
};

LabelledCheckbox.defaultProps = {
  label: null,
  disabled: false,
  readOnly: false,
  value: undefined,
  size: CheckboxSize.NORMAL,
  type: CheckboxType.CONTAINED,
  onChange: () => {},
};

export { CheckboxType, CheckboxSize };
export default LabelledCheckbox;
