import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import { formatTimestamp } from "utils/DateTimeUtils";

const Cell = styled.td`
  height: 2.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const ButtonsCell = styled(Cell)`
  button {
    display: inline-flex;
    margin: 0 0.25rem;
  }
`;

const LogRow = ({ data, onViewDetails }) => {
  const { t } = useTranslation("common");

  let changedBy = "-";
  if (data.changed_by) {
    changedBy = data.changed_by;
  } else if (data.changed_by_api) {
    changedBy = t("changedFromAPI");
  }

  return (
    <>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={changedBy}
          tag="span"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={formatTimestamp(data.changed_at)}
          tag="span"
        />
      </Cell>
      <ButtonsCell centered={true}>
        <Button
          icon={IconName.EYE}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onViewDetails(data)}
        />
      </ButtonsCell>
    </>
  );
};

LogRow.propTypes = {
  data: PropTypes.object.isRequired,
  onViewDetails: PropTypes.func.isRequired,
};

export default LogRow;
