import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import { setCurrent, setSetup } from "./BillingSlice";

export const getSetup = () => {
  return apiClient
    .get(ApiEndpoints.BILLING_SETUP)
    .then((res) => {
      const id = res?.data?.client_secret;
      if (!id) return false;
      store.dispatch(setSetup(id));
      return true;
    })
    .catch(() => {return false});
};

export const addPaymentMethod = (paymentMethodId) => {
  return apiClient
    .post(ApiEndpoints.BILLING_ADD_PAYMENT_METHOD, {paymentMethodId})
    .then(() => true)
    .catch(() => {return false});
};

export const getCurrent = () => {
  return apiClient
    .get(ApiEndpoints.BILLING_INFO)
    .then((res) => {
      const data = res?.data;
      if(!data?.brand) return false;
      store.dispatch(setCurrent({brand: data.brand, last4: data.last4, expMonth: data.exp_month, expYear: data.exp_year}));
      return true;
    })
    .catch(() => {return false});
}