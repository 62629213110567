import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";
import { DraggableCore } from "react-draggable";

import { toBase64, resizeImageToSquare, renderCanvas } from "utils/WizardUtils";
import EditMode from "constants/EditMode";
import { IconName } from "common/basicComponents/Icon";
import Button, {
  ButtonType,
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import Editor from "./editor/Editor";
import CropImage from "./CropImage";
import FileInput from "common/basicComponents/FileInput";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import { PhotoIDs } from "./Wizard";

const PreviewContainer = styled.div`
  width: min(calc((100% - 5rem) / 3), 100vh - 25rem);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FileInputContainer = styled.div`
  width: 100%;
  padding-bottom: calc(100% + 44px);
  margin-bottom: 1.375rem;
  position: relative;
  & > div:first-child {
    position: absolute;
    left: 0;
    right: 0;
  }
`;

const Preview = styled.div`
  border: 1px solid;
  border-color: ${({ theme, error }) =>
    error ? theme.colors.secondary[700] : theme.colors.grey[100]};
  border-image-slice: 1;
  border-image-source: ${({ theme, active }) =>
    active && theme.colors.gradient[100]};

  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-top: 0.25rem;

  &:hover {
    border-image-source: ${({ theme, active }) =>
      !active && theme.colors.gradient[40]};
  }
`;

const PreviewInside = styled.div`
  position: absolute;
  inset: 1px;
`;

const ActionButtons = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 0.625rem;

  & button.delete {
    color: ${({ theme }) => theme.colors.secondary[700]};
  }
`;

const ImagePreview = ({
  title,
  icon,
  id,
  active,
  changeActive,
  modifications,
  cropModeActive,
  setCrop,
  resetPhoto,
  mainCanvasRef,
  frontWithLensesCanvasRef,
  externalError,
  onWheelZoom,
  onMouseDrag,
  showActionButton,
  actionName,
  onAction,
}) => {
  const { t } = useTranslation("wizard");

  const [chosenFile, setChosenFile] = useState(null);
  const [editMode, setEditMode] = useState(EditMode.NONE);
  const [error, setError] = useState(null);
  const [imgSize, setImgSize] = useState(0);
  const imgRef = useRef(null);
  const frameMaskRef = useRef(null);
  const frontWithLensesMaskRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (externalError) setError(externalError);
  }, [externalError]);

  const updateSize = () => {
    if (containerRef.current) {
      setImgSize(containerRef.current.offsetWidth - 4);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    updateSize();
  }, [chosenFile]);

  useEffect(
    () => {
      if (!cropModeActive) {
        renderCanvas(
          mainCanvasRef?.current,
          imgRef.current,
          1024,
          frameMaskRef.current,
          modifications
        );
        renderCanvas(
          frontWithLensesCanvasRef?.current,
          imgRef.current,
          1024,
          frontWithLensesMaskRef.current,
          modifications
        );
      }
    },
    //eslint-disable-next-line
    [
      cropModeActive,
      chosenFile,
      modifications,
      imgRef.current,
      frameMaskRef.current,
      mainCanvasRef?.current,
      frontWithLensesCanvasRef?.current,
    ]
  );

  const handleScroll = (e) => {
    onWheelZoom(e.deltaY * -0.001);
  };

  const handleDrag = (e, data) => {
    const renderScale =
      mainCanvasRef?.current.width /
        mainCanvasRef?.current.getBoundingClientRect().width || 1;
    onMouseDrag(data.deltaX * renderScale, data.deltaY * renderScale);
  };

  const onDrop = async (files) => {
    if (_.isEmpty(files)) return;

    const file = files[0];
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      setError(
        t("common:validator.unsupportedExtension", {
          ext: ".png, .jpg, .jpeg",
        })
      );
      return;
    }

    await new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        file.width = image.width;
        file.height = image.height;
        resolve();
      };
      const url = URL.createObjectURL(file);
      image.src = url;
    });

    if (file.width < 1024 && file.height < 1024) {
      setError(t("common:validator.minImgSize", { minSize: 1024 }));
      return;
    }

    setError(null);
    file.src = await toBase64(file);
    resizeImageToSquare(file, 1024, setChosenFile);
  };

  const onDelete = () => {
    setChosenFile(null);
    resetPhoto(id);
    frameMaskRef.current = null;
    frontWithLensesMaskRef.current = null;
  };

  return (
    <>
      <PreviewContainer>
        {!chosenFile && (
          <FileInputContainer onClick={() => changeActive(null)}>
            <FileInput
              id={`preview-${id}`}
              name={`preview-${id}`}
              label={title}
              hintIcon={icon}
              hintText={t("common:form.fileDragDropHint")}
              smallHintText={t("imageSize", { width: 1024, height: 1024 })}
              disabled={!!chosenFile}
              onChange={onDrop}
              error={error}
              aspectRatio={1}
              accept=".jpg,.png,.jpeg"
              showActionButton={showActionButton}
              actionName={actionName}
              onAction={onAction}
            />
          </FileInputContainer>
        )}
        {chosenFile && (
          <>
            <Typography
              text={title}
              variant={TypographyVariant.BODY2}
              weight={TypographyWeight.SEMIBOLD}
            />
            <Preview
              ref={containerRef}
              onClick={() => {
                if (!chosenFile) open();
                else if (!cropModeActive) changeActive(id);
              }}
              active={active === id}
              error={error}
            >
              <PreviewInside>
                <CropImage
                  src={chosenFile.src}
                  modifications={modifications}
                  onCropReady={setCrop}
                  imgRef={imgRef}
                  style={{
                    position: "absolute",
                    inset: 1,
                    visibility: cropModeActive ? "visible" : "hidden",
                  }}
                />
                <DraggableCore onDrag={handleDrag}>
                  <canvas
                    onWheelCapture={handleScroll}
                    ref={mainCanvasRef}
                    style={{
                      width: imgSize,
                      height: imgSize,
                      display: !cropModeActive ? "block" : "none",
                    }}
                  />
                </DraggableCore>
                {frameMaskRef.current && (
                  <canvas
                    ref={frontWithLensesCanvasRef}
                    style={{
                      visibility: "hidden",
                      position: "absolute",
                      width: imgSize,
                      height: imgSize,
                    }}
                  />
                )}
              </PreviewInside>
            </Preview>
          </>
        )}

        <ActionButtons show={!!chosenFile}>
          <Button
            icon={IconName.MAGIC_WAND}
            variant={ButtonVariant.OUTLINED}
            type={ButtonType.BUTTON}
            size={ButtonSize.SMALL}
            onClick={() => setEditMode(EditMode.FRAME)}
          />
          <Button
            className="delete"
            icon={IconName.TRASH}
            variant={ButtonVariant.OUTLINED}
            type={ButtonType.BUTTON}
            size={ButtonSize.SMALL}
            onClick={onDelete}
          />
        </ActionButtons>
      </PreviewContainer>
      {editMode === EditMode.FRAME && (
        <Editor
          inputFile={chosenFile}
          maskFile={frameMaskRef.current}
          onBack={() => setEditMode(EditMode.NONE)}
          onNext={(mask) => {
            const img = new Image();
            img.src = mask;
            frameMaskRef.current = img;
            if (!frontWithLensesMaskRef.current)
              frontWithLensesMaskRef.current = img;
            setEditMode(
              id === PhotoIDs.FRONT ? EditMode.FRAME_WITH_LENSES : EditMode.NONE
            );
          }}
          nextText={
            id === PhotoIDs.FRONT
              ? t("goToLensesButton")
              : t("common:button.save")
          }
          hintText={t("backgroundAndLensesHint")}
          photoTitle={t(`${id}Title`)}
        />
      )}
      {editMode === EditMode.FRAME_WITH_LENSES && (
        <Editor
          inputFile={chosenFile}
          maskFile={frontWithLensesMaskRef.current}
          onBack={() => setEditMode(EditMode.FRAME)}
          onNext={(mask) => {
            const img = new Image();
            img.src = mask;
            frontWithLensesMaskRef.current = img;
            setEditMode(EditMode.NONE);
          }}
          nextText={t("common:button.save")}
          hintText={t("backgroundHint")}
          photoTitle={t("frontWithLensesTitle")}
        />
      )}
    </>
  );
};

ImagePreview.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  active: PropTypes.string,
  changeActive: PropTypes.func.isRequired,
  modifications: PropTypes.object.isRequired,
  cropModeActive: PropTypes.bool.isRequired,
  setCrop: PropTypes.func.isRequired,
  resetPhoto: PropTypes.func.isRequired,
  mainCanvasRef: PropTypes.object.isRequired,
  frontWithLensesCanvasRef: PropTypes.object,
  externalError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onWheelZoom: PropTypes.func,
  onMouseDrag: PropTypes.func,
  showActionButton: PropTypes.bool,
  onAction: PropTypes.func,
  actionName: PropTypes.string,
};

ImagePreview.defaultProps = {
  active: null,
  frontWithLensesCanvasRef: null,
  externalError: null,
  onWheelZoom: () => {},
  onMouseDrag: () => {},
  showActionButton: false,
  onAction: () => {},
  actionName: null
};

export default ImagePreview;
