/* eslint-disable react/no-unknown-property */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Button from "common/basicComponents/Button";
import { ButtonType } from "common/basicComponents/Button";
import { ButtonVariant } from "common/basicComponents/Button";
import { getCurrentLocale } from "i18n/i18n";

const TryOn = styled.div`
  padding-bottom: 1.25rem;
  height: 100%;
  .label-text {
    display: inline-block;
    padding-bottom: 0.25rem;
  }
`;

const TryOnContent = styled.div`
  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
  padding: 1.25rem 2.5rem;
  height: 100%;
  min-height: 12.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TryOnText = styled.div`
  text-align: center;
  margin-top: 1.25rem;
  height: 2rem;
  max-height: 2rem;
`;

const BrowserTryOn = ({ label, versionKey, globalSettings, data }) => {
  const id = "webfit-modal-container-" + versionKey;
  const { t } = useTranslation("product");

  const loadTryOn = () => {
    if (document.getElementById("load-arlab"))
      document.getElementById("load-arlab").outerHTML = "";
    if (document.getElementById("arlab"))
      document.getElementById("arlab").outerHTML = "";

    if (window.arlab?.q) window.arlab.q = [];

    const script = document.createElement("script");
    script.id = "load-arlab";
    script.async = true;
    script.innerHTML = `
      (function (w,d,s,o,f,js,fjs) {
        w['arlab-site-widgets']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
        if (d.getElementById(o)) return;
        js = d.createElement(s); 
        fjs = d.getElementsByTagName(s)[0];
        js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
      }(window, document, 'script', 'arlab', 'https://connect.ar-labs.io/v2/widget.js'));
      arlab('panel-v2', { id: "${id}", language: '${getCurrentLocale().slice(
      0,
      2
    )}' });
    `;
    document.head.appendChild(script);
  };

  return (
    <TryOn>
      <Typography
        variant={TypographyVariant.BODY2}
        weight={TypographyWeight.SEMIBOLD}
        text={label}
        tag="span"
        className="label-text"
      />
      <TryOnContent>
        <Button
          type={ButtonType.BUTTON}
          variant={ButtonVariant.CONTAINED}
          text={t("tryOn")}
          onClick={loadTryOn}
          width="100%"
        />
        <TryOnText>
          <Typography
            variant={TypographyVariant.CAPTION}
            text={t("tryOnInfo")}
          />
        </TryOnText>
        <div
          id={id}
          model-url={data.modelFbx}
          albedo-url={data.albedo}
          metallic-url={data.metallic}
          normal-url={data.normal}
          front-url={data.front}
          lenses-url={data.lenses}
          left-url={data.left}
          right-url={data.right}
          scale={globalSettings.aspectRatioWeb * data.aspectRatioWeb}
          rotation={globalSettings.rotationWeb + data.rotationWeb}
          position-y={globalSettings.positionYWeb + data.positionYWeb}
          position-z={globalSettings.positionZWeb + data.positionZWeb}
          ear-rotation={data.earRotationWeb}
          earpiece-distance-scale={data.earpieceDistanceScale}
          use-metallic-glass={data.useMetallicGlass ? "true" : null}
          is-2d={data.is2d ? "true" : null}
        >
          <div id={id + "-modal"} />
          <div id={id + "-button"} style={{ display: "none" }} />
        </div>
      </TryOnContent>
    </TryOn>
  );
};

BrowserTryOn.propTypes = {
  label: PropTypes.string.isRequired,
  versionKey: PropTypes.string.isRequired,
  globalSettings: PropTypes.shape({
    aspectRatioWeb: PropTypes.number.isRequired,
    rotationWeb: PropTypes.number.isRequired,
    positionYWeb: PropTypes.number.isRequired,
    positionZWeb: PropTypes.number.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    modelFbx: PropTypes.string,
    albedo: PropTypes.string,
    metallic: PropTypes.string,
    normal: PropTypes.string,
    front: PropTypes.string,
    lenses: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
    aspectRatioWeb: PropTypes.number.isRequired,
    rotationWeb: PropTypes.number.isRequired,
    positionYWeb: PropTypes.number.isRequired,
    positionZWeb: PropTypes.number.isRequired,
    earRotationWeb: PropTypes.number.isRequired,
    earpieceDistanceScale: PropTypes.number.isRequired,
    useMetallicGlass: PropTypes.bool.isRequired,
    is2d: PropTypes.bool.isRequired,
  }).isRequired,
};

export default BrowserTryOn;
