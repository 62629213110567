import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Box from "common/basicComponents/Box";
import TabNavigation from "common/basicComponents/Tabs";
import { TabVariant } from "common/basicComponents/Tabs";
import { useViewport, ViewportType } from "utils/ViewportContext";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { NumberType } from "common/formFields/FormInputField";
import Accordion from "common/basicComponents/Accordion";
import { useFormikContext } from "formik";

const ProductSettingsContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem 1.25rem 1.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
`;

const ContentBox = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 1.25rem;
  align-items: baseline;
`;

const getWidth = (viewport) =>
  ({
    [ViewportType.DESKTOP]: "20%",
    [ViewportType.TABLET]: "40%",
    [ViewportType.MOBILE]: "100%",
  }[viewport]);

const ProductSettings = () => {
  const { t } = useTranslation("productSettings");
  const { type: viewportType } = useViewport();
  const { values } = useFormikContext();
  const [accordionHasErrors, setAccordionHasErrors] = useState(false);

  useEffect(() => {
    if (accordionHasErrors) setAccordionHasErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const ModelSettingsTabs = [
    {
      title: t("ios"),
      longTitle: t("modelSettingsIos"),
      show: values.mobileActive,
      content: (
        <ContentBox key="ios">
          <Box width={getWidth(viewportType)} grow={1}>
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              id="rotationIOS"
              name="rotationIOS"
              label={t("rotation")}
              size="large"
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box width={getWidth(viewportType)} grow={1}>
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              id="aspectRatioIOS"
              name="aspectRatioIOS"
              label={t("scale")}
              size="large"
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box width={getWidth(viewportType)} grow={1}>
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              id="positionYIOS"
              name="positionYIOS"
              label={t("yAxis")}
              size="large"
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box width={getWidth(viewportType)} grow={1}>
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              id="positionZIOS"
              name="positionZIOS"
              label={t("zAxis")}
              size="large"
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
        </ContentBox>
      ),
    },
    {
      title: t("android"),
      longTitle: t("modelSettingsAndroid"),
      show: values.mobileActive,
      content: (
        <ContentBox key="android">
          <Box width={getWidth(viewportType)} grow={1}>
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              id="rotationAndroid"
              name="rotationAndroid"
              label={t("rotation")}
              size="large"
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box width={getWidth(viewportType)} grow={1}>
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              id="aspectRatioAndroid"
              name="aspectRatioAndroid"
              label={t("scale")}
              size="large"
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box width={getWidth(viewportType)} grow={1}>
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              id="positionYAndroid"
              name="positionYAndroid"
              label={t("yAxis")}
              size="large"
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box width={getWidth(viewportType)} grow={1}>
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              id="positionZAndroid"
              name="positionZAndroid"
              label={t("zAxis")}
              size="large"
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
        </ContentBox>
      ),
    },
    {
      title: t("browser"),
      longTitle: t("modelSettingsWeb"),
      show: values.webActive,
      content: (
        <ContentBox key="browser">
          <Box width={getWidth(viewportType)} grow={1}>
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              id="rotationWeb"
              name="rotationWeb"
              label={t("rotation")}
              size="large"
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box width={getWidth(viewportType)} grow={1}>
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              id="aspectRatioWeb"
              name="aspectRatioWeb"
              label={t("scale")}
              size="large"
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box width={getWidth(viewportType)} grow={1}>
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              id="positionYWeb"
              name="positionYWeb"
              label={t("yAxis")}
              size="large"
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box width={getWidth(viewportType)} grow={1}>
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              id="positionZWeb"
              name="positionZWeb"
              label={t("zAxis")}
              size="large"
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
        </ContentBox>
      ),
    },
  ];

  return (
    <Accordion text={t("productSettings")} forceOpen={accordionHasErrors}>
      <ProductSettingsContent>
        <TabNavigation
          variant={TabVariant.INLINE}
          tabs={ModelSettingsTabs.filter((tab) => tab.show).map(
            (tab) => tab.title
          )}
          tabsLongTitles={ModelSettingsTabs.filter((tab) => tab.show).map(
            (tab) => tab.longTitle
          )}
          contents={ModelSettingsTabs.filter((tab) => tab.show).map(
            (tab) => tab.content
          )}
        />
        <Box width="100%">
          <FormInputField
            type={FormInputFieldType.NUMBER}
            numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
            name="thumbObjectScale"
            id="thumbObjectScale"
            label={t("thumbnailScale")}
            size="large"
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
      </ProductSettingsContent>
    </Accordion>
  );
};
export default ProductSettings;
