import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";

import Box from "common/basicComponents/Box";
import FormInputField, {
  FormInputFieldType,
  InputSize,
} from "common/formFields/FormInputField";
import FormSelectField from "common/formFields/FormSelectField";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Accordion from "common/basicComponents/Accordion";

const Content = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem 0.625rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  align-items: baseline;
`;

const CustomerForm = () => {
  const { t } = useTranslation("billing");
  const { values } = useFormikContext();
  const [accordionHasErrors, setAccordionHasErrors] = useState(false);


  return (
    <Accordion text={t("currentCard")} lockedOpen>
      <Content>
        <Box padding="0 0.625rem" width="100%">
          <FormInputField
            type={FormInputFieldType.TEXT}
            name="brand"
            id="brand"
            disabled={true}
            label={t("brand")}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
           <Box padding="0 0.625rem" width="100%">
          <FormInputField
            type={FormInputFieldType.TEXT}
            name="last4"
            id="last4"
            disabled={true}
            label={t("last4")}
            onError={() => setAccordionHasErrors(true)}
          />
          </Box>
          <Box padding="0 0.625rem" width="100%">
          <FormInputField
            type={FormInputFieldType.TEXT}
            name="expDate"
            id="expDate"
            disabled={true}
            label={t("expDate")}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
      </Content>
    </Accordion>
  );
};


export default CustomerForm;