const ProductTypes = {
  HAT: "HAT",
  GLASSES: "GLASSES",
  JEWELLERY: "JEWELLERY",
  GOGGLES: "GOGGLES",
  EARRINGS: "EARRINGS",
  NECKLACE: "NECKLACE",
  DOOR: "DOOR",
  OBJECT: "OBJECT",
};

export default ProductTypes;
