import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ViewportMinBreakpoints } from "utils/ViewportContext";

const ContentContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 1.25rem;
  padding-top: 0.25rem;
  scrollbar-gutter: stable;
  overflow: hidden;
  @media screen and (min-width: ${ViewportMinBreakpoints.TABLET}px) {
    padding: 2rem;
    padding-top: 0;
    margin-top: 0.25rem;
  }
  @media screen and (min-width: ${ViewportMinBreakpoints.DESKTOP}px) {
    overflow: hidden auto;
    width: calc(100% - 0.75rem);
    padding-right: 0.75rem;
    margin-right: 0.75rem;
  }
`;

const Content = ({ children }) => {
  return <ContentContainer id="content">{children}</ContentContainer>;
};

Content.propTypes = {
  children: PropTypes.node,
};

Content.defaultProps = {
  children: null,
};

export default Content;
