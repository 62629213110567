import React, { useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Button, { ButtonVariant } from "common/basicComponents/Button";
import Page from "common/page/Page";
import Header from "common/page/Header";
import PartnerForm from "components/partners/PartnerForm";
import { ButtonType } from "common/basicComponents/Button";
import Content from "common/page/Content";
import {
  partnerDataToValues,
  partnerValuesToPayload,
} from "utils/PartnerUtils";
import ValidationSchema from "components/partners/PartnerForm.schema";
import { addPartner } from "./PartnerActions";
import { setPartnerById } from "components/auth/AuthActions";
import RouterPaths from "constants/RouterPaths";
import {
  addNotification,
  NotificationVariant,
} from "components/notifications/NotificationSlice";
import { getUserPartners } from "components/auth/AuthActions";

const AddPartner = () => {
  const { t } = useTranslation("partners");
  const { partner, user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = (values, form) => {
    setLoading(true);
    const editedData = partnerValuesToPayload({}, values);
    addPartner(editedData, form).then((id) => {
      form.setSubmitting(false);
      setLoading(false);
      if (id) {
        dispatch(
          addNotification({
            variant: NotificationVariant.SUCCESS,
            message: t("common:form.savedSuccessfully"),
          })
        );
        getUserPartners(user.id).then(() => {
          setPartnerById(id);
          navigate(RouterPaths.ROOT);
        });
      }
    });
  };

  return (
    <Page>
      <Header
        title={t("addTitle")}
        button={
          <Button
            minWidth="13rem"
            type={ButtonType.SUBMIT}
            variant={ButtonVariant.GREEN}
            text={t("common:button.save")}
            form="partner-form"
            isLoading={loading}
          />
        }
      />
      <Content>
        <Formik
          initialValues={partnerDataToValues({
            types: partner.types.map((t) => ({ ...t, type_show: false })),
          })}
          validationSchema={ValidationSchema}
          onSubmit={onSubmit}
        >
          <PartnerForm />
        </Formik>
      </Content>
    </Page>
  );
};

export default AddPartner;
