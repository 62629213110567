import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import { setTagGroups } from "./TagGroupSlice";
import { handleFormError } from "api/ApiClient";
import {
  tagGroupToPropertyGroup,
  propertyGroupToTagGroup,
} from "utils/TagUtils";

export const getTagGroups = () => {
  return apiClient
    .get(ApiEndpoints.TAG_GROUPS)
    .then((res) => {
      const tagGroups = res?.data?.propertyGroups.map(propertyGroupToTagGroup);
      if (!tagGroups) return false;
      store.dispatch(setTagGroups(tagGroups));
      return true;
    })
    .catch(() => false);
};

export const addTagGroup = (tagGroup, form) => {
  return apiClient
    .post(ApiEndpoints.TAG_GROUPS, tagGroupToPropertyGroup(tagGroup))
    .then((res) => res?.data?.id)
    .catch((err) => {
      handleFormError(err, form);
    });
};

export const editTagGroup = (tagGroup, form) => {
  return apiClient
    .put(
      `${ApiEndpoints.TAG_GROUPS}${tagGroup.id}`,
      tagGroupToPropertyGroup(tagGroup)
    )
    .then(() => true)
    .catch((err) => handleFormError(err, form));
};

export const deleteTagGroup = (tagGroup, form) => {
  return apiClient
    .delete(`${ApiEndpoints.TAG_GROUPS}${tagGroup.id}`)
    .then(() => true)
    .catch((err) => {
      handleFormError(err, form);
    });
};
