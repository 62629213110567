import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import { IconName } from "common/basicComponents/Icon";
import Box from "common/basicComponents/Box";
import Toggle from "common/basicComponents/Toggle";

const Card = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  padding: 1.25rem;
  gap: 0.6rem;
  min-height: 9.5rem;
`;

const Thumb = styled.img`
  height: 3.25rem;
  width: 7.5rem;
  min-width: 7.5rem;
  object-fit: contain;
  align-self: center;
`;

const ThumbPlaceholder = styled.div`
  height: 3.25rem;
  width: 7.5rem;
  min-width: 7.5rem;
  align-self: center;
  text-transform: uppercase;
  text-align: center;
  line-height: 3.25rem;
  color: ${({ theme }) => theme.colors.grey[400]};
`;

const ProductData = styled.div`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
`;

const DataCell = styled.div`
  width: calc(50% - 1.2rem);
  display: flex;
  margin: 0 0.6rem;
  padding: ${({ top }) => (top ? "0 0 1.25rem 0" : "1.25rem 0 0 0")};
  border-bottom: ${({ theme, top }) =>
    top ? "1px solid " + theme.colors.grey[300] : "none"};
  ${({ theme, top }) => (top ? "1px solid " + theme.colors.grey[300] : "none")};
`;

const TextDataCell = styled(DataCell)`
  flex-direction: column;
  align-items: flex-start;
`;

const ToggleDataCell = styled(DataCell)`
  align-items: center;
  justify-content: space-between;
`;

const Buttons = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const GreyTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

const VersionMediumCard = ({
  data,
  onDelete,
  onChangeActiveVersion,
  enableReorder,
  moveUpEnabled,
  onMoveUp,
  moveDownEnabled,
  onMoveDown,
}) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("totems");

  return (
    <Card viewport={viewportType}>
      {data.product_thumb_link ? (
        <Thumb src={data.product_thumb_link} />
      ) : (
        <ThumbPlaceholder>{t("common:notAdded")}</ThumbPlaceholder>
      )}
      <ProductData>
        <TextDataCell top>
          <Typography
            variant={TypographyVariant.SUBTITLE2}
            weight={TypographyWeight.BOLD}
            text={t("versions.product")}
          />
          <Typography
            variant={TypographyVariant.BODY2}
            text={data.product_name}
          />
          {data.product_name_owner !== data.product_name && (
            <GreyTypography
              variant={TypographyVariant.CAPTION}
              weight={TypographyWeight.LIGHT}
              text={data.product_name_owner}
            />
          )}
        </TextDataCell>
        <TextDataCell top>
          <Typography
            variant={TypographyVariant.SUBTITLE2}
            weight={TypographyWeight.BOLD}
            text={t("versions.version")}
          />
          <Typography
            variant={TypographyVariant.BODY2}
            text={data.version_name}
          />
          {data.version_name_owner !== data.version_name && (
            <GreyTypography
              variant={TypographyVariant.CAPTION}
              weight={TypographyWeight.LIGHT}
              text={data.version_name_owner}
            />
          )}
        </TextDataCell>
        <ToggleDataCell>
          <Typography
            variant={TypographyVariant.SUBTITLE2}
            weight={TypographyWeight.BOLD}
            text={t("versions.active")}
          />
          <Toggle
            id="totemVersionActive"
            name="totemVersionActive"
            value={data.version_active}
            onChange={() => onChangeActiveVersion(data)}
          />
        </ToggleDataCell>
      </ProductData>
      <Buttons>
        {enableReorder && (
          <>
            <Box padding="0 0 0.375rem">
              <Button
                icon={IconName.UP_ARROW}
                variant={ButtonVariant.OUTLINED}
                size={ButtonSize.SMALL}
                onClick={onMoveUp}
                disabled={!moveUpEnabled}
              />
            </Box>
            <Button
              icon={IconName.DOWN_ARROW}
              variant={ButtonVariant.OUTLINED}
              size={ButtonSize.SMALL}
              onClick={onMoveDown}
              disabled={!moveDownEnabled}
            />
          </>
        )}
        <Box grow={1} />
        <Button
          icon={IconName.DELETE}
          variant={ButtonVariant.CONTAINED}
          size={ButtonSize.SMALL}
          onClick={() => onDelete(data)}
        />
      </Buttons>
    </Card>
  );
};
VersionMediumCard.propTypes = {
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeActiveVersion: PropTypes.func.isRequired,
  enableReorder: PropTypes.bool,
  moveUpEnabled: PropTypes.bool,
  onMoveUp: PropTypes.func,
  moveDownEnabled: PropTypes.bool,
  onMoveDown: PropTypes.func,
};

VersionMediumCard.defaultProps = {
  enableReorder: false,
  moveUpEnabled: false,
  onMoveUp: () => {},
  moveDownEnabled: false,
  onMoveDown: () => {},
};

export default VersionMediumCard;
