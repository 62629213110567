import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

import Page from "common/page/Page";
import Header from "common/page/Header";
import Content from "common/page/Content";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import { getAnalytics } from "components/analytics/AnalyticsActions";
import { getDomains } from "components/domains/DomainActions";
import { getAllProductsWithVersions } from "components/products/ProductActions";
import { domainsToSelect } from "utils/DomainUtils";
import { getLinks, deleteLink, addLink, editLink } from "./LinkActions";
import { setSearch, clearLinks } from "./LinkSlice";
import LinkTable from "./list/LinkTable";
import LinkSearch from "./list/LinkSearch";
import EditLinkDialog from "./dialogs/EditLinkDialog";
import WarningDialog from "common/basicComponents/WarningDialog";
import { PageSizes } from "common/Table/Pagination";

const Links = () => {
  const { t } = useTranslation("links");
  const dispatch = useDispatch();

  const { links, linksTotal, search } = useSelector((state) => state.links);
  const { analytics, date: analyticsDate } = useSelector(
    (state) => state.analytics
  );
  const { domains } = useSelector((state) => state.domains);
  const { allProducts } = useSelector((state) => state.products);
  const { partner } = useSelector((state) => state.auth);

  const [meta, setMeta] = useState({ page: 1, pageSize: PageSizes[0] });
  const [visibleLinks, setVisibleLinks] = useState([]);
  const [loading, setLoading] = useState(false);

  const [editDialog, setEditDialog] = useState({
    open: false,
    link: null,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    link: null,
  });

  const thisMonth = new Date().toISOString().substring(0, 7);

  useEffect(() => {
    setMeta({ page: 1, pageSize: PageSizes[0] });
  }, [partner]);

  useEffect(() => {
    getLinks();
    getAnalytics();
    getDomains();
    getAllProductsWithVersions();
  }, []);

  useEffect(() => {
    const maximumVisible = Math.min(linksTotal, meta.page * meta.pageSize);

    if (maximumVisible - links.length > 0) return;

    setVisibleLinks(
      links.slice((meta.page - 1) * meta.pageSize, maximumVisible)
    );
  }, [meta, links, linksTotal]);

  useEffect(() => {
    setLoading(true);
    const maximumVisible = Math.min(
      linksTotal || meta.pageSize,
      meta.page * meta.pageSize
    );
    const offset = links.length;
    const limit = maximumVisible - offset;

    if (linksTotal > 0 && limit <= 0) {
      setLoading(false);
      return;
    }

    getLinks({ offset, limit, ...search }).finally(() => {
      setLoading(false);
    });
  }, [meta, search, linksTotal, links.length]);

  const handleSearch = (searchParams) => {
    dispatch(
      setSearch({
        ...searchParams,
      })
    );
    setMeta((prev) => ({ ...prev, page: 1 }));
  };

  const handleSave = (link, form) => {
    if (link.id) {
      editLink(link.id, link, form).then((ok) => {
        if (!ok) return;
        if (!search) dispatch(clearLinks());
        dispatch(
          setSearch({
            ...search,
          })
        );
        setMeta({ page: 1, pageSize: PageSizes[0] });
        setEditDialog({ ...editDialog, open: false });
      });
    } else {
      addLink({ ...link, partnersVersionId: link.versionId }, form).then(
        (ok) => {
          if (!ok) return;
          dispatch(clearLinks());
          setMeta({ page: 1, pageSize: PageSizes[0] });
          setEditDialog({ ...editDialog, open: false });
        }
      );
    }
  };

  const handleDelete = () => {
    deleteLink(deleteDialog.link.web_link_id).then((ok) => {
      if (!ok) return;
      dispatch(clearLinks());
      setMeta({ page: 1, pageSize: PageSizes[0] });
    });
    setDeleteDialog({ ...deleteDialog, open: false });
  };

  return (
    <Page>
      <Header
        title={t("title")}
        subtitles={[
          [t("tryOnCount") + ":", analytics?.[thisMonth]?.webOpens || "-"],
          [t("lastUpdate") + ":", analyticsDate || "-"],
        ]}
        button={
          <Button
            variant={ButtonVariant.GREEN}
            text={t("addLinkTitle")}
            icon={IconName.PLUS}
            minWidth="12.5rem"
            onClick={() => setEditDialog({ open: true, link: null })}
          />
        }
      >
        <LinkSearch onSearch={handleSearch} />
      </Header>
      <Content>
        <LinkTable
          links={visibleLinks}
          total={linksTotal}
          meta={meta}
          onMetaChange={setMeta}
          loading={loading}
          onEdit={(link) => setEditDialog({ open: true, link })}
          onDelete={(link) => setDeleteDialog({ open: true, link })}
        />
      </Content>
      <EditLinkDialog
        allProducts={allProducts}
        domainOptions={domainsToSelect(domains)}
        open={editDialog.open}
        link={editDialog.link}
        links={links}
        onClose={() => setEditDialog({ ...editDialog, open: false })}
        onSave={handleSave}
      />
      <WarningDialog
        open={deleteDialog.open}
        name={
          deleteDialog.link?.product_name +
          "\n" +
          deleteDialog.link?.domain_name +
          deleteDialog.link?.web_link_url
        }
        onClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
        onAction={handleDelete}
        title={t("deleteAlertTitle")}
        warningButtonText={t("delete")}
      />
    </Page>
  );
};

export default Links;
