import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";

const Container = styled.div`
  width: 100%;
`;

const ValueBlock = styled.div`
  width: 100%;
  height: 2.5rem;
  margin-top: 0.25rem;
  margin-bottom: 1.25rem;

  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, value }) =>
    value ? theme.colors.success[50] : theme.colors.secondary[50]};
  color: ${({ theme, value }) =>
    value ? theme.colors.success[700] : theme.colors.secondary[700]};
`;

const BooleanChangeItem = ({ label, value }) => {
  return (
    <Container>
      <Typography
        variant={TypographyVariant.BODY2}
        weight={TypographyWeight.SEMIBOLD}
        text={label}
        tag="span"
      />
      <ValueBlock value={value}>
        <Typography
          variant={TypographyVariant.BUTTON}
          weight={TypographyWeight.SEMIBOLD}
          text={(value || false).toString().toUpperCase()}
          tag="span"
        />
      </ValueBlock>
    </Container>
  );
};

BooleanChangeItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
};

export default BooleanChangeItem;
