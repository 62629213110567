import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import { IconName } from "common/basicComponents/Icon";
import { getProductTags } from "utils/ProductUtils";
import Chips from "common/basicComponents/Chips";
import Box from "common/basicComponents/Box";

const Card = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  overflow: hidden;
  height: 10rem;
  padding: 1.25rem;
  gap: 1.25rem;
`;

const Thumb = styled.img`
  height: 3.25rem;
  width: 7.5rem;
  object-fit: contain;
  align-self: center;
`;

const ThumbPlaceholder = styled.div`
  height: 3.25rem;
  width: 7.5rem;
  align-self: center;
  text-transform: uppercase;
  text-align: center;
  line-height: 3.25rem;
  color: ${({ theme }) => theme.colors.grey[400]};
`;

const ProductData = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const DataCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${({ top }) => (top ? "flex-start" : "flex-end")};
  border-bottom: ${({ theme, top }) =>
    top ? "1px solid " + theme.colors.grey[300] : "none"};
  flex-grow: 1;
  flex-basis: 0;
`;

const DataColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  flex-grow: 1;
  flex-basis: 0;
`;

const TextContent = styled(Typography)`
  min-height: 1.5rem;
  word-wrap: break-word;
`;

const Tags = styled.div`
  min-height: 1.5rem;
  & > span {
    margin-right: 0.25rem;
  }
`;

const Buttons = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ProductMediumCard = ({
  data,
  user,
  partner,
  onEdit,
  enableReorder,
  moveUpEnabled,
  onMoveUp,
  moveDownEnabled,
  onMoveDown,
}) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("product");

  const externalProduct =
    (!partner.main_partner && data.from_main_partner) ||
    data.from_other_partner;

  return (
    <Card viewport={viewportType}>
      {data.product_thumb_link ? (
        <Thumb src={data.product_thumb_link} />
      ) : (
        <ThumbPlaceholder>{t("common:notAdded")}</ThumbPlaceholder>
      )}
      <ProductData>
        <DataColumn>
          <DataCell top>
            <Typography
              variant={TypographyVariant.BODY2}
              weight={TypographyWeight.BOLD}
              text={t("productName")}
            />
            <TextContent
              variant={TypographyVariant.BODY2}
              text={data.product_name}
            />
          </DataCell>
          <DataCell top>
            <Typography
              variant={TypographyVariant.BODY2}
              weight={TypographyWeight.BOLD}
              text={t("publishedVersions")}
            />
            <div>
              <Typography
                variant={TypographyVariant.BODY2}
                tag="span"
                text={
                  externalProduct
                    ? data.assigned_versions
                    : data.published_versions
                }
              />
              <Typography
                variant={TypographyVariant.BODY2}
                weight={TypographyWeight.BOLD}
                tag="span"
                text={` / ${
                  externalProduct ? data.published_versions : data.all_versions
                }`}
              />
            </div>
          </DataCell>
        </DataColumn>
        <DataColumn>
          <DataCell>
            <Typography
              variant={TypographyVariant.BODY2}
              weight={TypographyWeight.BOLD}
              text={t("info")}
            />
            <Tags>
              {getProductTags(user, partner, data).map(
                (tag) =>
                  tag.show && (
                    <Chips
                      key={tag.text}
                      variant={tag.variant}
                      text={tag.text}
                    />
                  )
              )}
            </Tags>
          </DataCell>
        </DataColumn>
      </ProductData>
      <Buttons>
        {enableReorder && (
          <>
            <Box padding="0 0 0.375rem">
              <Button
                icon={IconName.UP_ARROW}
                variant={ButtonVariant.OUTLINED}
                size={ButtonSize.SMALL}
                onClick={onMoveUp}
                disabled={!moveUpEnabled}
              />
            </Box>
            <Button
              icon={IconName.DOWN_ARROW}
              variant={ButtonVariant.OUTLINED}
              size={ButtonSize.SMALL}
              onClick={onMoveDown}
              disabled={!moveDownEnabled}
            />
          </>
        )}
        <Box grow={1} />
        <Button
          icon={IconName.EDIT}
          variant={ButtonVariant.CONTAINED}
          size={ButtonSize.SMALL}
          onClick={() => onEdit(data)}
        />
      </Buttons>
    </Card>
  );
};
ProductMediumCard.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  enableReorder: PropTypes.bool,
  moveUpEnabled: PropTypes.bool,
  onMoveUp: PropTypes.func,
  moveDownEnabled: PropTypes.bool,
  onMoveDown: PropTypes.func,
};

ProductMediumCard.defaultProps = {
  enableReorder: false,
  moveUpEnabled: false,
  onMoveUp: () => {},
  moveDownEnabled: false,
  onMoveDown: () => {},
};

export default ProductMediumCard;
