import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Accordion from "common/basicComponents/Accordion";
import Box from "common/basicComponents/Box";
import FormToggleField, {
  ToggleVariant,
} from "common/formFields/FormToggleField";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import FormSelectField from "common/formFields/FormSelectField";
import FormFileField from "common/formFields/FormFileField";
import FileExtension from "constants/FileExtension";
import ThumbnailMaker from "common/ThumbnailMaker";
import { useViewport, ViewportType } from "utils/ViewportContext";
import { tagGroupsToSelect } from "utils/TagUtils";
import ModelType from "constants/ModelType";
import { ButtonVariant } from "common/basicComponents/Button";
import AppTryOn from "../tryon/AppTryOn";
import BrowserTryOn from "../tryon/BrowserTryOn";
import { ExtendedUserRoles } from "constants/UserRoles";
import Product2DFilesForm from "./Product2DFilesForm";
import Product3DFilesForm from "./Product3DFilesForm";
import { IconName } from "common/basicComponents/Icon";

const VersionContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem 0.625rem;
`;

const FieldContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`;

const Thumb = styled.div`
  padding: 0 0.625rem;
  width: ${({ viewport }) => getFileWidth(viewport)};
  ${({ viewport }) => viewport > ViewportType.MOBILE && "max-width: 50%;"}
  ${({ viewport }) => viewport > ViewportType.TABLET && "max-width: 25%;"}

  flex-grow: 1;
`;

const getFileWidth = (viewportType) =>
  ({
    [ViewportType.MOBILE]: "100%",
    [ViewportType.TABLET]: "50%",
    [ViewportType.DESKTOP]: "25%",
  }[viewportType]);

const getTryOnWidth = (viewportType) =>
  ({
    [ViewportType.MOBILE]: "100%",
    [ViewportType.TABLET]: "50%",
    [ViewportType.DESKTOP]: "50%",
  }[viewportType]);

const ProductVersionForm = ({
  index,
  disabled,
  onAssign,
  onDelete,
  onThumbAssign,
  generate,
  conf
}) => {
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation("product");
  const { type: viewportType } = useViewport();
  const { partner, user } = useSelector((state) => state.auth);
  const { tagGroups } = useSelector((state) => state.tagGroups);
  const [accordionHasErrors, setAccordionHasErrors] = useState(false);

  useEffect(() => {
    if (accordionHasErrors) setAccordionHasErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const versionValues = values.versions[index];
  const isUnassigned =
    (values.fromMarketplace || values.fromOtherPartner) &&
    !versionValues.isAssigned;

  const [fileUrls, setFileUrls] = useState({
    modelFbx: null,
    albedo: null,
    metallic: null,
    normal: null,
    front: null,
    lenses: null,
    left: null,
    right: null,
  });

  useEffect(() => {
    setFileUrls({
      modelFbx: values.modelFileFbx?.file
        ? URL.createObjectURL(values.modelFileFbx?.file)
        : null,
      albedo: versionValues.albedoFile?.file
        ? URL.createObjectURL(versionValues.albedoFile?.file)
        : null,
      metallic: versionValues.metallicFile?.file
        ? URL.createObjectURL(versionValues.metallicFile?.file)
        : null,
      normal: versionValues.normalFile?.file
        ? URL.createObjectURL(versionValues.normalFile?.file)
        : null,
      front: versionValues.frontFile?.file
        ? URL.createObjectURL(versionValues.frontFile?.file)
        : null,
      lenses: versionValues.lensesFile?.file
        ? URL.createObjectURL(versionValues.lensesFile?.file)
        : null,
      left: versionValues.leftFile?.file
        ? URL.createObjectURL(versionValues.leftFile?.file)
        : null,
      right: versionValues.rightFile?.file
        ? URL.createObjectURL(versionValues.rightFile?.file)
        : null,
    });
  }, [
    values.modelFileFbx,
    versionValues.albedoFile,
    versionValues.metallicFile,
    versionValues.normalFile,
    versionValues.frontFile,
    versionValues.lensesFile,
    versionValues.leftFile,
    versionValues.rightFile,
  ]);

  useEffect(() => {
    return () => {
      if (fileUrls.modelFbx) URL.revokeObjectURL(fileUrls.modelFbx);
      if (fileUrls.albedo) URL.revokeObjectURL(fileUrls.albedo);
      if (fileUrls.metallic) URL.revokeObjectURL(fileUrls.metallic);
      if (fileUrls.normal) URL.revokeObjectURL(fileUrls.normal);
      if (fileUrls.front) URL.revokeObjectURL(fileUrls.front);
      if (fileUrls.lenses) URL.revokeObjectURL(fileUrls.lenses);
      if (fileUrls.left) URL.revokeObjectURL(fileUrls.left);
      if (fileUrls.right) URL.revokeObjectURL(fileUrls.right);
    };
  }, [fileUrls]);

  const getAccordionButton = () => {
    if (values.fromOtherPartner) return {};
    if (values.fromMarketplace && !versionValues.isAssigned)
      return {
        action: handleAssign,
        text: t("addVersion"),
        icon: IconName.PLUS,
        variant: ButtonVariant.GREEN,
      };

    return {
      action: onDelete,
      text: t("deleteVersion"),
      icon: IconName.TRASH_OUTLINED,
      variant: ButtonVariant.ERROR,
      disabled:
        disabled || (!values.fromMarketplace && values.versions.length === 1),
    };
  };

  const handleAssign = () => {
    onAssign(versionValues);
  };

  const handleThumbGenerated = (file) => {
    setFieldValue(`versions[${index}].autoGeneratedThumbFile`, file);
    onThumbAssign();
  };

  return (
    <Accordion
      text={t("version.header") + " " + (index + 1)}
      defaultOpen
      forceOpen={accordionHasErrors}
      buttonAction={getAccordionButton().action}
      buttonText={getAccordionButton().text}
      buttonIcon={getAccordionButton().icon}
      buttonVariant={getAccordionButton().variant}
      buttonDisabled={getAccordionButton().disabled}
    >
      <VersionContent>
        <FieldContainer>
          <Box
            padding="0 0.625rem"
            width="100%"
            show={!values.fromMarketplace && !values.fromOtherPartner}
          >
            <FormToggleField
              id={`versions[${index}].published`}
              name={`versions[${index}].published`}
              label={t("version.publishedApp")}
              disabled={disabled}
              variant={ToggleVariant.SWITCH_COLOR}
              onChange={(isVersionPublished) => {
                if (isVersionPublished && !values.published)
                  setFieldValue("published", true);
              }}
            />
          </Box>
          <Box padding="0 0.625rem" width="50%" show={!!versionValues.id}>
            <FormInputField
              id={`versions[${index}].partnersVersionId`}
              name={`versions[${index}].partnersVersionId`}
              label={t("version.id")}
              type={FormInputFieldType.COPY}
              disabled={true}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box padding="0 0.625rem" width="50%" show={!!versionValues.id}>
            <FormInputField
              id={`versions[${index}].modificationTime`}
              name={`versions[${index}].modificationTime`}
              label={t("version.modificationTime")}
              type={FormInputFieldType.TEXT}
              disabled={true}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box
            padding="0 0.625rem"
            width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
            grow={1}
          >
            <FormInputField
              id={`versions[${index}].name`}
              name={`versions[${index}].name`}
              label={t("version.name")}
              type={FormInputFieldType.TEXT}
              disabled={disabled || isUnassigned}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box
            padding="0 0.625rem"
            width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
            grow={1}
            show={partner?.mobile_active}
          >
            <FormInputField
              id={`versions[${index}].code`}
              name={`versions[${index}].code`}
              label={t("version.code")}
              type={FormInputFieldType.TEXT}
              disabled={
                disabled || values.fromMarketplace || values.fromOtherPartner
              }
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box
            padding="0 0.625rem"
            width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
            grow={1}
            show={partner?.mobile_active}
          >
            <FormInputField
              id={`versions[${index}].sku`}
              name={`versions[${index}].sku`}
              label={t("version.sku")}
              type={FormInputFieldType.TEXT}
              disabled={
                disabled || values.fromMarketplace || values.fromOtherPartner
              }
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box
            padding="0 0.625rem"
            width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
            grow={1}
            show={partner?.mobile_active}
          >
            <FormInputField
              id={`versions[${index}].pageLink`}
              name={`versions[${index}].pageLink`}
              label={t("version.pageLink")}
              type={FormInputFieldType.TEXT}
              disabled={disabled || isUnassigned}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box
            padding="0 0.625rem"
            width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
            show={partner?.mobile_active}
          >
            <FormInputField
              id={`versions[${index}].price`}
              name={`versions[${index}].price`}
              label={t("version.price")}
              type={FormInputFieldType.CURRENCY}
              currency={partner.partner_currency}
              disabled={disabled || isUnassigned}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box
            padding="0 0.625rem"
            width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
            grow={1}
            show={partner?.mobile_active}
          >
            <FormInputField
              id={`versions[${index}].specialPrice`}
              name={`versions[${index}].specialPrice`}
              label={t("version.specialPrice")}
              type={FormInputFieldType.CURRENCY}
              currency={partner.partner_currency}
              disabled={disabled || isUnassigned}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box
            padding="0 0.625rem"
            width="50%"
            grow={1}
            show={partner?.mobile_active}
          >
            <FormSelectField
              name={`versions[${index}].tags`}
              id={`versions[${index}].tags`}
              label={t("tags")}
              isMulti
              options={tagGroupsToSelect(tagGroups)}
              disabled={disabled || isUnassigned}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
        </FieldContainer>
        <FieldContainer>
          <Box
            padding="0 0.625rem"
            width={getTryOnWidth(viewportType)}
            show={!!versionValues.id}
            grow={1}
          >
            <BrowserTryOn
              label={t("tryOnBrowser")}
              versionKey={versionValues.key}
              globalSettings={{
                aspectRatioWeb: partner.aspect_ratio_web,
                positionYWeb: partner.position_y_web,
                positionZWeb: partner.position_z_web,
                rotationWeb: partner.rotation_web,
              }}
              data={{
                ...fileUrls,
                aspectRatioWeb: values.aspectRatioWeb,
                rotationWeb: values.rotationWeb,
                positionYWeb: values.positionYWeb,
                positionZWeb: values.positionZWeb,
                earRotationWeb: values.earRotationWeb,
                useMetallicGlass: values.useMetallicGlass,
                earpieceDistanceScale: values.earpieceDistanceScale,
                is2d: values.modelType.value === ModelType.MODEL2D,
              }}
            />
          </Box>
          <Box
            padding="0 0.625rem"
            width={getTryOnWidth(viewportType)}
            show={
              !!values.id &&
              !!versionValues.id &&
              partner?.mobile_active &&
              (user.role === ExtendedUserRoles.SUPERADMIN ||
                partner?.qrcodes_active)
            }
          >
            <AppTryOn
              versionId={versionValues.id || ""}
              qrDownloadName={`${versionValues.name}_${Date.parse(
                versionValues.modificationTime
              )}`}
              label={t("tryOnApp")}
            />
          </Box>
          <Thumb viewport={viewportType}>
            <FormFileField
              id={`versions[${index}].thumbFile`}
              name={`versions[${index}].thumbFile`}
              label={t("version.thumbFile", {
                x: partner.thumb_file_width,
                y: partner.thumb_file_height,
              })}
              disabled={
                disabled || values.fromMarketplace || values.fromOtherPartner
              }
              acceptedExtensions={[FileExtension.PNG]}
              preview={true}
              aspectRatio={1}
              onError={() => setAccordionHasErrors(true)}
            />
          </Thumb>
          {values.modelType?.value === ModelType.MODEL3D && (
            <Product3DFilesForm
              index={index}
              disabled={disabled}
              onError={() => setAccordionHasErrors(true)}
            />
          )}
        </FieldContainer>
        {values.modelType?.value === ModelType.MODEL2D && (
          <Product2DFilesForm
            index={index}
            disabled={disabled}
            onError={() => setAccordionHasErrors(true)}
            conf={conf}
          />
        )}
        <ThumbnailMaker
          size={{
            width: partner.thumb_file_width,
            height: partner.thumb_file_height,
          }}
          objectScale={partner.thumb_object_scale}
          fileUrls={fileUrls}
          index={index}
          onThumbReady={handleThumbGenerated}
          productType={values.type?.code}
          values={{
            ...versionValues,
            earRotationWeb: values.earRotationWeb,
          }}
          generate={generate}
        />
      </VersionContent>
    </Accordion>
  );
};

ProductVersionForm.propTypes = {
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onAssign: PropTypes.func,
  onDelete: PropTypes.func,
  onThumbAssign: PropTypes.func,
  generate: PropTypes.bool.isRequired,
  conf: PropTypes.object,
};

ProductVersionForm.defaultProps = {
  disabled: false,
  onAssign: () => {},
  onDelete: () => {},
  onThumbAssign: () => {},
  cong: {}
};

export default ProductVersionForm;
