export const mirrorImgBlob = async (imgBlobURL) => {

  const canvas = document.createElement("canvas");
  canvas.width = 1024;
  canvas.height = 1024;
  const ctx = canvas.getContext("2d");

  const img = new Image();
  img.src = imgBlobURL;
  await img.decode();

  ctx.scale(-1,1);
  ctx.drawImage(img,0,0,-1024, 1024);
  
  return canvas;
}


export const mirrorImgFile = (file) => {
  
  const mirroredFile = new File([file], 'mirror.png', {type: file.type})
  mirroredFile.width = 1024;
  mirroredFile.height = 1024;
  
  const canvas = document.createElement("canvas");
  canvas.width = 1024;
  canvas.height = 1024;
  const ctx = canvas.getContext("2d");

  const img = new Image();
  img.src = file.src;

  ctx.scale(-1,1);
  ctx.drawImage(img,0,0,-1024, 1024);

  mirroredFile.src = canvas.toDataURL();

  return mirroredFile;
}