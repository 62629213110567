import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Dialog from "common/basicComponents/Dialog";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Icon, { IconName } from "common/basicComponents/Icon";
import { useTranslation } from "react-i18next";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { ButtonWidth } from "common/basicComponents/Button";
import { useViewport, ViewportType } from "utils/ViewportContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  & svg {
    color: ${({ theme }) => theme.colors.secondary[700]};
  }
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: hidden;
  white-space: pre-line;
  text-align: center;
  p:first-child {
    margin-bottom: 0.5rem;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "row" : "column-reverse"};
  width: 100%;
  gap: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "2rem" : "1.25rem"};
`;

const WarningDialog = ({
  open,
  onAction,
  onClose,
  name,
  data,
  title,
  warningButtonText,
}) => {
  const { t } = useTranslation();
  const { type: viewportType } = useViewport();
  return (
    <Dialog open={open} onClose={onClose}>
      <Container>
        <Icon name={IconName.ERROR} size="4rem" />
        <TextContent>
          <Typography
            variant={TypographyVariant.BODY1}
            weight={TypographyWeight.REGULAR}
            text={title}
          />
          <Typography
            variant={TypographyVariant.H5}
            weight={TypographyWeight.BOLD}
            text={name || ""}
          />
        </TextContent>
        <Buttons viewport={viewportType}>
          <Button
            variant={ButtonVariant.OUTLINED}
            text={t("common:button.cancel")}
            width={ButtonWidth.GROW}
            onClick={onClose}
          />
          <Button
            variant={ButtonVariant.ERROR}
            text={warningButtonText}
            width={ButtonWidth.GROW}
            onClick={() => onAction(data)}
          />
        </Buttons>
      </Container>
    </Dialog>
  );
};

WarningDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  groupName: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.object,
  warningButtonText: PropTypes.string,
  title: PropTypes.string,
};

WarningDialog.defaultProps = {
  groupName: "",
  name: "",
  data: null,
  warningButtonText: "",
  title: "",
};

export default WarningDialog;
