import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Box from "common/basicComponents/Box";
import Table from "common/Table";
import { ViewportType } from "utils/ViewportContext";
import Accordion from "common/basicComponents/Accordion";
import LogRow from "./LogRow";
import LogCard from "./LogCard";
import LogDetailsDialog from "./LogDetailsDialog";
import { PageSizes } from "common/Table/Pagination";

const AccordionContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 2.5rem 1.25rem;
`;

const ProductLogs = ({ isLoading }) => {
  const { t } = useTranslation("product");
  const { colors } = useTheme();
  const { product } = useSelector((state) => state.products);
  const [meta, setMeta] = useState({ page: 1, pageSize: PageSizes[0] });
  const [detailsDialog, setDetailsDialog] = useState({
    open: false,
    id: null,
  });

  const headers = [
    {
      text: t("user"),
    },
    {
      text: t("timestamp"),
    },
    {
      text: t("details"),
      centered: true,
    },
  ];

  return (
    <Box padding="2rem 0 0 0" show={!isLoading}>
      <Accordion text={t("configurationHistory")}>
        <AccordionContent>
          <Table
            headers={headers}
            data={
              product?.logs?.slice(
                (meta.page - 1) * meta.pageSize,
                Math.min(product?.logs?.length, meta.page * meta.pageSize)
              ) || []
            }
            total={product?.logs?.length}
            tableRow={LogRow}
            tableCard={LogCard}
            cardViewportBreakpoint={ViewportType.MOBILE}
            keyPropertyName="log_id"
            meta={meta}
            onMetaChange={setMeta}
            itemProps={{
              onViewDetails: (item) => {
                setDetailsDialog({ open: true, id: item.log_id });
              },
            }}
            dividerColor={colors.grey[200]}
          />
        </AccordionContent>
      </Accordion>
      <LogDetailsDialog
        open={detailsDialog.open}
        logId={detailsDialog.id}
        onClose={() => setDetailsDialog({ open: false, id: null })}
      />
    </Box>
  );
};

ProductLogs.propTypes = {
  isLoading: PropTypes.bool,
};

ProductLogs.defaultProps = {
  isLoading: false,
};

export default ProductLogs;
