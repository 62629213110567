import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import { setBrands } from "./BrandSlice";
import { handleFormError } from "api/ApiClient";

export const getBrands = () => {
  return apiClient
    .get(ApiEndpoints.BRANDS)
    .then((res) => {
      const brands = res?.data?.body;
      if (!brands) return false;
      store.dispatch(setBrands(brands));
      return true;
    })
    .catch(() => false);
};

export const addBrand = (brand, form) => {
  return apiClient
    .post(ApiEndpoints.BRANDS, brand)
    .then(() => true)
    .catch((err) => handleFormError(err, form));
};

export const editBrand = (brand, form) => {
  return apiClient
    .put(`${ApiEndpoints.BRANDS}${brand.id}`, brand)
    .then(() => true)
    .catch((err) => handleFormError(err, form));
};

export const deleteBrand = (brand, form) => {
  return apiClient
    .delete(`${ApiEndpoints.BRANDS}${brand.id}`)
    .then(() => true)
    .catch((err) => handleFormError(err, form));
};
