import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";

import Box from "common/basicComponents/Box";
import { useViewport, ViewportType } from "utils/ViewportContext";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { NumberType } from "common/formFields/FormInputField";
import Accordion from "common/basicComponents/Accordion";
import FormToggleField, {
  ToggleVariant,
} from "common/formFields/FormToggleField";

const Content = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 1rem;
`;

const PushNotifications = () => {
  const { t } = useTranslation("pushNotifications");
  const { type: viewportType } = useViewport();
  const { values } = useFormikContext();
  const [accordionHasErrors, setAccordionHasErrors] = useState(false);

  useEffect(() => {
    if (accordionHasErrors) setAccordionHasErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Accordion text={t("pushNotifications")} forceOpen={accordionHasErrors}>
      <Content>
        <FormToggleField
          id="notificationsActive"
          name="notificationsActive"
          label={t("notificationsActive")}
          align="center"
          width="12.5rem"
          variant={ToggleVariant.SWITCH_COLOR}
          switchWidth="100%"
        />
        <Box
          grow={viewportType !== ViewportType.MOBILE ? 1 : null}
          width={viewportType === ViewportType.MOBILE ? "100%" : null}
        >
          <FormInputField
            type={FormInputFieldType.NUMBER}
            numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
            name="notificationsLimit"
            id="notificationsLimit"
            label={t("notificationsLimit")}
            disabled={!values.notificationsActive}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
      </Content>
    </Accordion>
  );
};

export default PushNotifications;
