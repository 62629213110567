export default {
  LOGIN: "/auth/",
  USER_PARTNERS: "/auth/partners/",
  PRODUCTS: "/product/",
  PRODUCT_LOG: "/product/log/",
  ALL_PRODUCTS: "/product/all/",
  ORDER_PRODUCTS: "/product/order/",
  MOVE_PRODUCT_TO_MAIN_PARTNER: "/product/toMainPartner/",
  PRODUCT_AUTOCUT: "/product/autocut/",
  BRANDS: "/brand/",
  EFFECTS: "/effects/",
  TYPES: "/type/",
  USERS: "/user/",
  PARTNERS: "/partner/",
  ACCEPT_TERMS: "/partner/terms",
  TAG_GROUPS: "/propertygroup/",
  LINKS: "/weblink/",
  NOTIFICATIONS: "/notification/",
  DASHBOARD: "/dashboard/",
  TOTEMS: "/totems/",
  TOTEM: "/totems/one/",
  TOTEM_COPY: "/totems/copy/",
  TOTEM_VERSIONS: "/totems/versions/",
  TOTEM_CONFIG: "/totems/config/",
  TOTEM_BANNERS: "/banners/",
  DOMAINS: "/domains/",
  APPLINKS: "/applinks/",
  MARKETPLACE_PRODUCTS: "/marketplace/",
  MARKETPLACE_PRODUCTS_ASSIGN: "/marketplace/assign",
  APPS: "/apps",
  APPS_MIN_VERSION: "/apps/minversion",
  IMPORT_REPORT: "/autocut/report",
  IMPORT_VISUAL_REPORT: "/autocut/visualreport",
  IMPORT_DATA: "/autocut",
  IMPORT_RUN: "/autocut/run",
  IMPORT_NOTIFY: "/autocut/notify",
  IMPORT_NOTIFY_COMPLETE: "/autocut/notifyclient",
  BILLING_SETUP: "/billing/setup",
  BILLING_ADD_PAYMENT_METHOD: "/billing/addPaymentMethod",
  BILLING_INFO:"/billing/payment",
  SETS: "/sets/"
};
