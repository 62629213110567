import Table from "common/Table";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ViewportType } from "utils/ViewportContext";
import MarketplaceRow from "./MarketplaceRow";
import MarketplaceCard from "./MarketplaceCard";

const MarketplaceTable = ({
  products,
  total,
  meta,
  onMetaChange,
  loading,
  onAssign,
}) => {
  const { t } = useTranslation("marketplace");

  const headers = [
    {
      text: t("thumbnail"),
      centered: true,
    },
    {
      text: t("productName"),
    },
    {
      text: t("versions"),
      centered: true,
      maxWidth: "4.5rem",
    },
    {
      text: t("actions"),
      centered: true,
      maxWidth: "4.5rem",
    },
  ];

  return (
    <>
      <Table
        headers={headers}
        data={products}
        total={total}
        tableRow={MarketplaceRow}
        tableCard={MarketplaceCard}
        cardViewportBreakpoint={ViewportType.TABLET}
        keyPropertyName="id"
        meta={meta}
        onMetaChange={onMetaChange}
        loading={loading}
        itemProps={{
          onAssign,
        }}
      />
    </>
  );
};

MarketplaceTable.propTypes = {
  products: PropTypes.array,
  total: PropTypes.number,
  onAssign: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  onMetaChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

MarketplaceTable.defaultProps = {
  products: [],
  total: 0,
  loading: false,
};

export default MarketplaceTable;
