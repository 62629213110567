import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { v4 as uuidv4 } from "uuid";

import Box from "common/basicComponents/Box";
import Table from "common/Table";
import { ViewportType } from "utils/ViewportContext";
import EditApplicationLinkDialog from "../applicationLinks/dialog/EditApplicationLinkDialog";
import WarningDialog from "common/basicComponents/WarningDialog";
import ApplicationLinkRow from "../applicationLinks/ApplicationLinkRow";
import ApplicationLinkCard from "../applicationLinks/ApplicationLinkCard";
import { ButtonVariant } from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import Accordion from "common/basicComponents/Accordion";
import theme from "theme/theme";

const Content = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 2.5rem 1.25rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`;

const ApplicationLinks = () => {
  const { t } = useTranslation("applicationLinks");
  const { values, setFieldValue, isSubmitting } = useFormikContext();

  const [editDialog, setEditDialog] = useState({
    open: false,
    link: null,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    link: null,
  });

  const handleSave = (appLink) => {
    if (appLink.id) {
      const newAppLinks = [...values.appLinks];
      const editedIndex = newAppLinks.findIndex((a) => a.id === appLink.id);
      newAppLinks[editedIndex] = appLink;
      setFieldValue("appLinks", newAppLinks);
    } else {
      appLink.id = uuidv4();
      setFieldValue("appLinks", [...values.appLinks, appLink]);
    }
    setEditDialog({ ...editDialog, open: false });
  };

  const handleDelete = () => {
    const newAppLinks = [...values.appLinks];
    const deletedIndex = newAppLinks.findIndex(
      (a) => a.id === deleteDialog.link.id
    );
    newAppLinks.splice(deletedIndex, 1);
    setFieldValue("appLinks", newAppLinks);
    setDeleteDialog({ ...deleteDialog, open: false });
  };

  const headers = [
    {
      text: t("link"),
      maxWidth: "45%",
    },
    {
      text: t("text"),
      maxWidth: "45%",
    },
    {
      text: t("actions"),
      centered: true,
      maxWidth: "1rem",
    },
  ];

  return (
    <Accordion
      text={t("applicationLinks")}
      buttonText={t("addLink")}
      buttonIcon={IconName.PLUS}
      buttonAction={() => setEditDialog({ link: null, open: true })}
      buttonVariant={ButtonVariant.CONTAINED}
      buttonWidth="11.25rem"
      buttonDisabled={isSubmitting}
    >
      <Content>
        <Box width="100%">
          <Table
            headers={headers}
            data={values.appLinks || []}
            keyPropertyName="id"
            total={values.appLinks ? values.appLinks?.length : 0}
            dividerColor={theme.colors.grey[200]}
            tableRow={ApplicationLinkRow}
            tableCard={ApplicationLinkCard}
            pagination={false}
            cardViewportBreakpoint={ViewportType.MOBILE}
            onMetaChange={() => {}}
            itemProps={{
              onEdit: (link) => setEditDialog({ open: true, link }),
              onDelete: (link) => setDeleteDialog({ open: true, link }),
              disabled: isSubmitting,
            }}
            noDataText={t("noData")}
          />
        </Box>
        <EditApplicationLinkDialog
          open={editDialog.open}
          link={editDialog.link}
          onClose={() => setEditDialog({ ...editDialog, open: false })}
          onSave={handleSave}
        />
        <WarningDialog
          open={deleteDialog.open}
          data={deleteDialog.link}
          name={deleteDialog.link?.url}
          onClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
          onAction={handleDelete}
          title={t("deleteAlertTitle")}
          warningButtonText={t("deleteButtonTitle")}
        />
      </Content>
    </Accordion>
  );
};

export default ApplicationLinks;
