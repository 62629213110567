import React, { useState, useEffect } from "react";
import styled, { css, useTheme } from "styled-components";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Dialog from "common/basicComponents/Dialog";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { ButtonWidth } from "common/basicComponents/Button";
import Box from "common/basicComponents/Box";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { ButtonType } from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";
import { FileState } from "common/formFields/FormFileField";
import FormFileField from "common/formFields/FormFileField";
import FileExtension from "constants/FileExtension";
import { NumberType } from "common/formFields/FormInputField";
import FormSelectField from "common/formFields/FormSelectField";
import { constantToSelect } from "utils/FormUtils";
import { Status, Types } from "./BannerOptions";
import validationSchema from "./EditBanner.schema";

const Buttons = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "row" : "column-reverse"};
  width: 100%;
  gap: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "2rem" : "1.25rem"};
  margin-top: 2.5rem;
`;

const Group = styled(Box)`
  display: flex;
  flex-wrap: ${({ viewportType }) =>
    viewportType === ViewportType.MOBILE ? "wrap" : "no-wrap"};
  gap: 1.25rem;
`;

const FieldsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: -1.25rem;
`;

const TimeFieldsBox = styled(Box)`
  display: flex;
  gap: 1.25rem;
  align-items: baseline;
`;

const PreviewContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Preview = styled(Box)`
  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
  box-sizing: content-box;
  margin-top: 0.25rem;
  ${({ aspectRatio }) => `aspect-ratio: ${aspectRatio};`}
`;

const PreviewBox = styled(Box)`
  background-color: ${({ background }) => background};
  color: ${({ theme }) => theme.colors.grey[400]};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ img, imgBackground }) =>
    img
      ? css`
          background-image: url(${img});
          background-color: ${imgBackground};
        `
      : null}
  background-size: cover;
  background-position: center;

  ${({ aspectRatio }) => `aspect-ratio: ${aspectRatio};`}
`;

const LargePreviewBox = styled(PreviewBox)`
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
`;

const getPreviewWidth = (type) =>
  ({
    [ViewportType.DESKTOP]: "16rem",
    [ViewportType.TABLET]: "16rem",
    [ViewportType.MOBILE]: "100%",
  }[type]);

export const BannerInitialValues = {
  bannerId: null,
  bannerName: "",
  bannerDuration: "",
  bannerDisplayedTime: "",
  bannerActive: { label: "", value: "" },
  bannerFile: {
    uploadState: FileState.NONE,
    url: null,
  },
  bannerImageLink: null,
  bannerType: { label: "", value: "" },
};
const EditBannerDialog = ({ open, banner, onSave, onClose, loading }) => {
  const { t } = useTranslation("totems");
  const { type: viewportType } = useViewport();
  const theme = useTheme();
  const [initialValues, setInitialValues] = useState(BannerInitialValues);

  useEffect(() => {
    if (banner) {
      setInitialValues({
        bannerId: banner.banner_id,
        bannerName: banner.banner_name,
        bannerDuration: banner.banner_duration,
        bannerDisplayedTime: banner.banner_displayed_time,
        bannerActive: {
          label: t(
            `banners.statuses.${banner.banner_active ? "active" : "inactive"}`
          ),
          value: banner.banner_active ? Status.ACTIVE : Status.INACTIVE,
        },
        bannerFile: banner.banner_image_link
          ? {
              uploadState: FileState.UPLOADED,
              url: banner.banner_image_link,
            }
          : {
              uploadState: FileState.NONE,
              url: null,
            },
        bannerImageLink: banner.banner_image_link,
        bannerType: {
          label: t(`banners.types.${banner.banner_type}`),
          value: banner.banner_type,
        },
      });
    } else {
      setInitialValues(BannerInitialValues);
    }
  }, [banner, t]);
  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding="0 0 2.5rem">
        <Typography
          variant={TypographyVariant.H4}
          weight={TypographyWeight.BOLD}
          text={
            banner?.banner_id ? t("banners.editTitle") : t("banners.addTitle")
          }
        />
      </Box>
      <Formik
        initialValues={initialValues}
        onSubmit={onSave}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ values }) => (
          <Form noValidate>
            <Group
              viewportType={viewportType}
              width="100%"
              padding="0 0 1.25rem 0"
            >
              <FieldsContainer grow={1}>
                <FormInputField
                  type={FormInputFieldType.TEXT}
                  id={"bannerName"}
                  name={"bannerName"}
                  label={t("banners.name")}
                  disabled={loading}
                />
                <FormSelectField
                  id={"bannerType"}
                  name={"bannerType"}
                  label={t("banners.type")}
                  options={constantToSelect(Types, "totems:banners.types")}
                  disabled={loading}
                />
                <TimeFieldsBox width="100%">
                  <Box grow={1}>
                    <FormInputField
                      type={FormInputFieldType.NUMBER}
                      numberCheck={NumberType.POSITIVE_INTEGER}
                      id={"bannerDuration"}
                      name={"bannerDuration"}
                      label={t("banners.duration")}
                      disabled={loading}
                    />
                  </Box>
                  <Box grow={1}>
                    <FormInputField
                      type={FormInputFieldType.NUMBER}
                      numberCheck={NumberType.POSITIVE_INTEGER}
                      id={"bannerDisplayedTime"}
                      name={"bannerDisplayedTime"}
                      label={t("banners.displayedTime")}
                      disabled={loading}
                    />
                  </Box>
                </TimeFieldsBox>
                <FormSelectField
                  id={"bannerActive"}
                  name={"bannerActive"}
                  label={t("banners.status")}
                  options={constantToSelect(Status, "totems:banners.statuses")}
                  disabled={loading}
                />
                <FormFileField
                  id={"bannerFile"}
                  name={"bannerFile"}
                  label={t("banners.file")}
                  acceptedExtensions={[FileExtension.PNG]}
                  disabled={loading}
                />
              </FieldsContainer>
              <PreviewContainer width={getPreviewWidth(viewportType)}>
                <Box
                  width={
                    viewportType === ViewportType.MOBILE ? "100%" : "16rem"
                  }
                >
                  <Typography
                    text={t("banners.preview")}
                    variant={TypographyVariant.BODY2}
                    weight={TypographyWeight.SEMIBOLD}
                  />
                </Box>
                <Preview
                  width={
                    viewportType === ViewportType.MOBILE ? "100%" : "16rem"
                  }
                  aspectRatio={1080 / 1920}
                >
                  <LargePreviewBox
                    aspectRatio={1080 / 1920}
                    width="100%"
                    background={theme.colors.grey[50]}
                    img={
                      values.bannerType.value === "large" &&
                      values.bannerFile?.url
                    }
                  >
                    <Box grow={1} basis={0} show={!values.bannerFile?.url}>
                      <Typography
                        text="1080x1920"
                        variant={TypographyVariant.BODY2}
                        weight={TypographyWeight.SEMIBOLD}
                      />
                    </Box>
                    <PreviewBox
                      aspectRatio={1080 / 110}
                      width="100%"
                      background={theme.colors.grey[100]}
                      img={values.bannerFile?.url}
                      show={
                        !values.bannerFile?.url ||
                        values.bannerType.value === "small"
                      }
                    >
                      {!values.bannerFile?.url && (
                        <Typography
                          text="1080x110"
                          variant={TypographyVariant.BODY2}
                          weight={TypographyWeight.SEMIBOLD}
                        />
                      )}
                    </PreviewBox>
                    <Box grow={1} basis={0} show={!values.bannerFile?.url} />
                  </LargePreviewBox>
                </Preview>
              </PreviewContainer>
            </Group>
            <Buttons viewport={viewportType}>
              <Button
                variant={ButtonVariant.OUTLINED}
                text={t("common:button.cancel")}
                width={ButtonWidth.GROW}
                onClick={onClose}
                disabled={loading}
              />
              <Button
                variant={ButtonVariant.GREEN}
                text={t("common:button.save")}
                width={ButtonWidth.GROW}
                type={ButtonType.SUBMIT}
                isLoading={loading}
              />
            </Buttons>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

EditBannerDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  banner: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

EditBannerDialog.defaultProps = {
  banner: null,
};

export default EditBannerDialog;
