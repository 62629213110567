import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import BasicNotification from "common/basicComponents/Notification";
import { hideNotification, showNotification } from "./NotificationSlice";

const Notification = () => {
  const [, setTimeoutId] = useState(null);
  const { show, notificationActive, notificationsQueue } = useSelector(
    (state) => state.notification
  );
  const dispatch = useDispatch();

  const close = useCallback(() => {
    setTimeoutId((prev) => {
      if (prev) clearTimeout(prev);
      return null;
    });
    dispatch(hideNotification());
  }, [dispatch]);

  useEffect(() => {
    if (notificationsQueue.length && !show) {
      setTimeout(() => {
        dispatch(showNotification());
      }, 750);
    } else if (
      notificationsQueue.length &&
      notificationActive.message &&
      show
    ) {
      close();
    } else if (
      !notificationsQueue.length &&
      notificationActive.message &&
      show
    ) {
      const id = setTimeout(
        () => dispatch(hideNotification()),
        notificationActive.displayTime * 1000
      );
      setTimeoutId((prev) => {
        if (prev) clearTimeout(prev);
        return id;
      });
    }
  }, [notificationsQueue, notificationActive, show, dispatch, close]);

  return (
    <BasicNotification
      open={show}
      variant={notificationActive.variant}
      text={notificationActive.message}
      fullWidth={notificationActive.fullWidth}
      height={notificationActive.height}
      onClose={close}
    />
  );
};

export default Notification;
