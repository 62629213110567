import Table from "common/Table";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ViewportType } from "utils/ViewportContext";
import LinkCard from "./LinkCard";
import LinkRow from "./LinkRow";

const LinkTable = ({
  links,
  total,
  meta,
  onMetaChange,
  loading,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation("links");

  const headers = [
    {
      text: t("thumbnail"),
      centered: true,
      maxWidth: "8rem",
    },
    {
      text: t("productName"),
    },
    {
      text: t("versionName"),
    },
    {
      text: t("webLinkUrl"),
    },
    {
      text: t("actions"),
      centered: true,
    },
  ];

  return (
    <Table
      headers={headers}
      data={links}
      total={total}
      tableRow={LinkRow}
      tableCard={LinkCard}
      cardViewportBreakpoint={ViewportType.TABLET}
      keyPropertyName="web_link_id"
      meta={meta}
      onMetaChange={onMetaChange}
      loading={loading}
      itemProps={{
        onEdit,
        onDelete,
      }}
      noDataText={t("noData")}
    />
  );
};

LinkTable.propTypes = {
  links: PropTypes.array,
  total: PropTypes.number,
  meta: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  onMetaChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

LinkTable.defaultProps = {
  products: [],
  total: 0,
  loading: false,
};

export default LinkTable;
