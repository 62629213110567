import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useDispatch } from "react-redux";

import { IconName } from "common/basicComponents/Icon";
import Button, {
  ButtonVariant,
  ButtonType,
  ButtonSize,
} from "common/basicComponents/Button";
import ImagePreview from "./ImagePreview";
import GradientBackground from "common/basicComponents/GradientBackground";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import {
  addNotification,
  NotificationVariant,
} from "components/notifications/NotificationSlice";
import { autocutProduct } from "../ProductActions";
import { ViewportType, useViewport } from "utils/ViewportContext";
import { mirrorImgFile } from "utils/ImageUtils";

const FullScreenContainer = styled.div`
  position: fixed;
  inset: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  ${({ viewport }) =>
    viewport !== ViewportType.DESKTOP && "overflow-y: scroll;"}
`;

const Header = styled.div`
  position: relative;
  padding: ${({ viewport }) => {
    switch (viewport) {
      case ViewportType.DESKTOP:
        return "1.25rem 5rem";
      case ViewportType.TABLET:
        return "1.5rem 2rem";
      case ViewportType.MOBILE:
      default:
        return "1.25rem";
    }
  }};

  border-bottom: 1px solid ${({ theme }) => theme.colors.black};

  display: flex;
  align-items: center;
  gap: 0.625rem;
  .title {
    flex-grow: 1;
  }
`;

const AutocutContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ImagesContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
  width: 100%;
  padding: ${({ viewport }) => {
    switch (viewport) {
      case ViewportType.DESKTOP:
        return "2.5rem 5rem";
      case ViewportType.TABLET:
        return "2rem 2rem";
      case ViewportType.MOBILE:
      default:
        return "1.25rem 1.25rem";
    }
  }};

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;
  flex-direction: ${({ viewport }) =>
    viewport === ViewportType.DESKTOP ? "row" : "column"};
`;

const Menu = styled.div`
  display: flex;
  ${({ viewport, theme }) => {
    switch (viewport) {
      case ViewportType.DESKTOP:
        return css`
          height: 11.5rem;
          padding: 2rem 5rem 0;
        `;
      case ViewportType.TABLET:
        return css`
          order: -1;
          background: ${theme.colors.white};
          padding: 2rem 2rem 0;
        `;
      case ViewportType.MOBILE:
      default:
        return css`
          order: -1;
          background: ${theme.colors.white};
          padding: 1.25rem 1.25rem 0;
        `;
    }
  }}
`;

const MenuText = styled(Typography)`
  background: ${({ theme }) => theme.colors.gradient[100]};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const TitleVariant = {
  [ViewportType.DESKTOP]: TypographyVariant.H3,
  [ViewportType.TABLET]: TypographyVariant.H4,
  [ViewportType.MOBILE]: TypographyVariant.H5,
};

const Autocut = ({ onSave, onBack }) => {
  const { t } = useTranslation("autocut");
  const dispatch = useDispatch();
  const { type: viewportType } = useViewport();

  const [error, setError] = useState(null);
  const [files, setFiles] = useState({ front: null, left: null, right: null });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (error?.message) {
      dispatch(
        addNotification({
          message: error?.message,
          variant: NotificationVariant.ERROR,
          fullWidth: true,
        })
      );
    }
  }, [error, dispatch]);

  const validate = () => {
    if (files.front && files.left && files.right) {
      setError(null);
      return true;
    }
    let fields = [];
    if (!files.left) fields.push("left");
    if (!files.front) fields.push("front");
    if (!files.right) fields.push("right");

    setError({
      fields,
      message: t("photosMissing"),
    });
    return false;
  };

  const handleSave = async () => {
    if (!validate()) return;
    setLoading(true);
    const preparedFileUrls = await autocutProduct(
      files.front,
      files.left,
      files.right
    );
    setLoading(false);
    onSave(preparedFileUrls);
  };

  return (
    <FullScreenContainer viewport={viewportType}>
      <GradientBackground />
      <Header viewport={viewportType}>
        <Typography
          className="title"
          variant={TitleVariant[viewportType]}
          weight={TypographyWeight.BOLD}
          text={t("title")}
        />
        <Button
          variant={ButtonVariant.GREEN}
          minWidth="11rem"
          text={viewportType > ViewportType.MOBILE && t("common:button.save")}
          icon={viewportType === ViewportType.MOBILE && IconName.SAVE}
          onClick={handleSave}
          type={ButtonType.BUTTON}
          size={ButtonSize.NORMAL}
          isLoading={loading}
        />
        <Button
          variant={ButtonVariant.ERROR}
          icon={IconName.TRASH_OUTLINED}
          onClick={onBack}
          type={ButtonType.BUTTON}
          size={ButtonSize.NORMAL}
          disabled={loading}
        />
      </Header>
      <AutocutContent>
        <ImagesContainer viewport={viewportType}>
          <ImagePreview
            icon={IconName.GLASSES_RIGHT_SIDE}
            title={t("rightEarpiece")}
            id="right"
            externalError={error?.fields.includes("right")}
            file={files.right}
            onFileUploaded={(rightFile) =>
              setFiles((files) => ({ ...files, right: rightFile }))
            }
            disabled={loading}
            actionName={"Mirror left"}
            showActionButton={files.left}
            onAction={()=>setFiles((files) => ({ ...files, right: mirrorImgFile(files.left) }))}
          />
          <ImagePreview
            icon={IconName.GLASSES_FRONT_WITH_LENSES}
            title={t("front")}
            id="front"
            externalError={error?.fields.includes("front")}
            file={files.front}
            onFileUploaded={(frontFile) =>
              setFiles((files) => ({ ...files, front: frontFile }))
            }
            disabled={loading}
          />
          <ImagePreview
            icon={IconName.GLASSES_LEFT_SIDE}
            title={t("leftEarpiece")}
            id="left"
            externalError={error?.fields.includes("left")}
            file={files.left}
            onFileUploaded={(leftFile) =>
              setFiles((files) => ({ ...files, left: leftFile }))
            }
            disabled={loading}
            actionName={"Mirror right"}
            showActionButton={files.right}
            onAction={()=>setFiles((files) => ({ ...files, left: mirrorImgFile(files.right) }))}
          />
        </ImagesContainer>
        <Menu viewport={viewportType}>
          <MenuText
            variant={TypographyVariant.BUTTON}
            weight={TypographyWeight.BOLD}
            text={t("info")}
          />
        </Menu>
      </AutocutContent>
    </FullScreenContainer>
  );
};

Autocut.propTypes = {
  onSave: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default Autocut;
