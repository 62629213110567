import { createSlice } from "@reduxjs/toolkit";
import { clearAuthData, setCurrentPartner } from "components/auth/AuthSlice";

const initialState = {
  types: [],
};

export const typeSlice = createSlice({
  name: "types",
  initialState,
  reducers: {
    setTypes: (state, action) => {
      state.types = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
    builder.addCase(setCurrentPartner, () => initialState);
  },
});

export const { setTypes } = typeSlice.actions;
export default typeSlice.reducer;
