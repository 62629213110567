import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Header from "common/page/Header";
import Content from "common/page/Content";
import Page from "common/page/Page";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import RouterPaths from "constants/RouterPaths";
import { prepareUrl } from "utils/UrlUtils";
import { setSearch, clearProducts } from "./ProductSlice";
import { getProducts, changeProductsOrder } from "./ProductActions";
import ProductTable from "./list/ProductTable";
import ProductSearch from "./list/ProductSearch";
import { PageSizes } from "common/Table/Pagination";

const ProductList = () => {
  const { t } = useTranslation("product");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const metaHistory = history.state?.productsMeta;

  const { partner } = useSelector((state) => state.auth);
  const { products, productsTotal, search } = useSelector(
    (state) => state.products
  );

  const [meta, setMeta] = useState({ page: 1, pageSize: PageSizes[0] });
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = (searchParams) => {
    dispatch(
      setSearch({
        ...searchParams,
      })
    );
    setMeta((prev) => ({ ...prev, page: 1 }));
  };

  const handleReorder = async (fromId, toId, direction) => {
    const newProducts = Array.from(visibleProducts);
    const fromIndex = newProducts.findIndex((p) => p.id === fromId);
    const toIndex = newProducts.findIndex((p) => p.id === toId);

    if (toIndex < 0) {
      // the replacement product is not yet retrieved from database
      let toItem = null;
      if (!direction && products.length === meta.page * meta.pageSize) {
        // moving down - we need missing rows
        const maximumVisible = Math.min(
          productsTotal || meta.pageSize,
          (meta.page + 1) * meta.pageSize
        );
        const offset = products.length;
        const limit = maximumVisible - offset;
        toItem = (await getProducts({ offset, limit, ...search }))[0];
      } else if (!direction) {
        //moving down but next rows are already downloaded
        toItem = products[meta.page * meta.pageSize];
      } else {
        // moving up - we use previous rows
        toItem = products[(meta.page - 1) * meta.pageSize - 1];
      }
      const insertIndex = direction ? 0 : newProducts.length - 1;
      newProducts.splice(insertIndex, 1, toItem);
      //send request and when succeeded - reorder products in redux store
      changeProductsOrder(fromId, toItem.id, direction);
    } else {
      const [removed] = newProducts.splice(fromIndex, 1);
      newProducts.splice(toIndex, 0, removed);
      //send request and when succeeded - reorder products in redux store
      changeProductsOrder(fromId, toId, direction);
    }

    //reorder local state so that the user sees the result immediately
    setVisibleProducts(newProducts);
  };

  useEffect(() => {
    dispatch(clearProducts());
    setMeta(metaHistory ? metaHistory : { page: 1, pageSize: PageSizes[0] });
    // eslint-disable-next-line
  }, [partner, dispatch]);

  useEffect(() => {
    const maximumVisible = Math.min(productsTotal, meta.page * meta.pageSize);

    if (maximumVisible - products.length > 0) return;

    setVisibleProducts(
      products.slice((meta.page - 1) * meta.pageSize, maximumVisible)
    );

    history.pushState({ productsMeta: meta }, "");
  }, [meta, products, productsTotal]);

  useEffect(() => {
    setLoading(true);
    const maximumVisible = Math.min(
      productsTotal || meta.pageSize,
      meta.page * meta.pageSize
    );
    const offset = products.length;
    const limit = maximumVisible - offset;

    if (productsTotal > 0 && limit <= 0) {
      setLoading(false);
      return;
    }

    getProducts({ offset, limit, ...search }).finally(() => {
      setLoading(false);
    });
  }, [meta, search, productsTotal, products.length]);

  return (
    <Page>
      <Header
        title={t("title")}
        subtitles={[[t("totalNumber"), (productsTotal || "").toString()]]}
        button={
          <Button
            minWidth={200}
            variant={ButtonVariant.GREEN}
            icon={IconName.PLUS}
            text={t("addProduct")}
            onClick={() => navigate(RouterPaths.ADD_PRODUCT)}
          />
        }
      >
        <ProductSearch onSearch={handleSearch} />
      </Header>
      <Content>
        <ProductTable
          products={visibleProducts}
          total={productsTotal}
          loading={loading}
          meta={meta}
          onMetaChange={setMeta}
          onEdit={(product) =>
            navigate(prepareUrl(RouterPaths.EDIT_PRODUCT, { id: product.id }))
          }
          onReorder={handleReorder}
        />
      </Content>
    </Page>
  );
};

export default ProductList;
