import { get } from "lodash";
import { ExtendedUserRoles } from "constants/UserRoles";
import { getPartnerGroup } from "utils/PartnerUtils";
import Pages, { TermsPage, ProductsPage } from "Pages";

const checkRequiredSettings = (settings, requiredSettings) => {
  return requiredSettings.every((v) =>
    v.some((x) => get(settings, x) === true)
  );
};

const checkDisabledSettings = (settings, disabledSettings) => {
  return disabledSettings.every((v) =>
    v.some((x) => get(settings, x) === false)
  );
};

const checkPermission = (userRole, permissionLevel) => {
  if (userRole === ExtendedUserRoles.SUPERADMIN) {
    return true;
  } else if (userRole === ExtendedUserRoles.ADMIN) {
    if (
      permissionLevel === ExtendedUserRoles.USER ||
      permissionLevel === ExtendedUserRoles.ADMIN
    ) {
      return true;
    }
  } else if (userRole === ExtendedUserRoles.USER) {
    if (permissionLevel === ExtendedUserRoles.USER) {
      return true;
    }
  }
  return false;
};

export const termsAccepted = (partner, user) => {
  return (
    partner?.terms_acceptance?.accepted ||
    user?.role === ExtendedUserRoles.SUPERADMIN
  );
};

export const getActivePages = (partner, user) => {
  if (!partner || !user) return [];
  if (!termsAccepted(partner, user)) {
    return [TermsPage];
  }

  return Pages.filter(
    (route) =>
      checkPermission(user.role, route.permission) &&
      checkRequiredSettings(partner, route.requiredSettings) &&
      checkDisabledSettings(partner, route.disabledSettings) &&
      route.visibleTo.includes(getPartnerGroup(partner))
  );
};

export const getDefaultPage = (partner, user) => {
  if (!partner || !user) return null;
  if (!termsAccepted(partner, user)) {
    return TermsPage;
  }
  return ProductsPage;
};

export const scrollToTop = () => {
  document.getElementById("content").scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
