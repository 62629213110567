import React from "react";
import RouterPaths from "constants/RouterPaths";
import { ExtendedUserRoles } from "constants/UserRoles";
import PartnersGroup from "constants/PartnersGroup";
import SettingsLabels from "constants/SettingsLabels";
import i18n from "i18n/i18n";
import { IconName } from "common/basicComponents/Icon";
import Example from "example/Example";
import EditPartner from "components/partners/EditPartner";
import ProductList from "components/products/Products";
import AddProduct from "components/products/AddProduct";
import EditProduct from "components/products/EditProduct";
import Links from "components/links/Links";
import Totems from "components/totems/Totems";
import EditTotem from "components/totems/EditTotem";
import Tags from "components/tagGroups/Tags";
import Users from "components/users/Users";
import Terms from "components/terms/Terms";
import Import from "components/import/Import";
import Report from "components/import/visualReport/VisualReport";
import Brands from "components/brands/Brands";
import AddPartner from "components/partners/AddPartner";
import Analytics from "components/analytics/Analytics";
import PushNotifications from "components/pushNotifications/PushNotifications";
import Marketplace from "components/marketplace/Marketplace";
import NotFound from "components/error/NotFound";
import Applications from "components/applications/Applications";
import Billing from "components/billing/Billing";
import Sets from "components/sets/Sets"

const Pages = [
  {
    path: RouterPaths.PRODUCTS,
    name: i18n.t("product:title"),
    icon: IconName.PRODUCTS,
    visibleTo: [PartnersGroup.MAIN_PARTNER, PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [],
    disabledSettings: [],
    element: <ProductList />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.ADD_PRODUCT,
    visibleTo: [PartnersGroup.MAIN_PARTNER, PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [],
    disabledSettings: [],
    element: <AddProduct />,
    showInNavigation: false,
  },
  {
    path: RouterPaths.EDIT_PRODUCT,
    visibleTo: [PartnersGroup.MAIN_PARTNER, PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [],
    disabledSettings: [],
    element: <EditProduct />,
    showInNavigation: false,
  },
  {
    path: RouterPaths.MARKETPLACE,
    name: i18n.t("marketplace:title"),
    icon: IconName.MARKETPLACE,
    permission: ExtendedUserRoles.USER,
    visibleTo: [PartnersGroup.OTHER_PARTNERS],
    requiredSettings: [[SettingsLabels.MARKETPLACE_VISIBLE]],
    disabledSettings: [],
    element: <Marketplace />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.TAG_GROUPS,
    name: i18n.t("tagGroups:title"),
    icon: IconName.TAGS,
    visibleTo: [PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.MOBILE_ACTIVE]],
    disabledSettings: [],
    element: <Tags />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.BRANDS,
    name: i18n.t("brands:title"),
    icon: IconName.BRANDS,
    visibleTo: [PartnersGroup.MAIN_PARTNER, PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.MOBILE_ACTIVE]],
    disabledSettings: [],
    element: <Brands />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.LINKS,
    name: i18n.t("links:sidebar"),
    icon: IconName.WEB,
    visibleTo: [PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [
      [SettingsLabels.WEB_ACTIVE, SettingsLabels.QRCODES_ACTIVE],
    ],
    disabledSettings: [],
    element: <Links />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.SETS,
    name: i18n.t("sets:title"),
    icon: IconName.WEB,
    visibleTo: [PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.SETS_ACTIVE]],
    disabledSettings: [],
    element: <Sets />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.TOTEMS,
    name: i18n.t("totems:title"),
    icon: IconName.TOTEMS,
    visibleTo: [PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.TOTEM_ACTIVE]],
    disabledSettings: [],
    element: <Totems />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.EDIT_TOTEM,
    visibleTo: [PartnersGroup.MAIN_PARTNER, PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.TOTEM_ACTIVE]],
    disabledSettings: [],
    element: <EditTotem />,
    showInNavigation: false,
  },
  {
    path: RouterPaths.NOTIFICATIONS,
    name: i18n.t("notifications:title"),
    icon: IconName.PUSH,
    visibleTo: [PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.NOTIFICATIONS_ACTIVE]],
    disabledSettings: [],
    element: <PushNotifications />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.ANALYTICS_DASHBOARD,
    name: i18n.t("analyticsDashboard:title"),
    icon: IconName.DASHBOARD,
    visibleTo: [PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [
      [SettingsLabels.MOBILE_ACTIVE, SettingsLabels.WEB_ACTIVE],
      [SettingsLabels.ANALYTICS_DASHBOARD_ACTIVE],
    ],
    disabledSettings: [],
    element: <Analytics />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.IMPORT,
    name: i18n.t("import:title"),
    icon: IconName.IMPORT,
    visibleTo: [PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.IMPORT_ACTIVE]],
    disabledSettings: [],
    element: <Import />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.IMPORT_REPORT,
    visibleTo: [PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.SUPERADMIN,
    requiredSettings: [[SettingsLabels.IMPORT_ACTIVE]],
    disabledSettings: [],
    element: <Report />,
    showInNavigation: false,
  },
  {
    path: RouterPaths.USERS,
    name: i18n.t("user:title"),
    icon: IconName.USER,
    visibleTo: [PartnersGroup.MAIN_PARTNER, PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.ADMIN,
    requiredSettings: [],
    disabledSettings: [],
    element: <Users />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.SETTINGS,
    name: i18n.t("partners:editTitle"),
    icon: IconName.SETTINGS,
    visibleTo: [PartnersGroup.MAIN_PARTNER, PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [],
    disabledSettings: [],
    element: <EditPartner />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.BILLING,
    name: i18n.t("billing:title"),
    icon: IconName.SHOPPING,
    visibleTo: [PartnersGroup.OTHER_PARTNERS],
    permissions: ExtendedUserRoles.USER,
    requiredSettings: [[SettingsLabels.BILLING_ACTIVE]],
    disabledSettings: [],
    element: <Billing />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.APPS,
    name: i18n.t("apps:sidebar"),
    icon: IconName.DASHBOARD,
    visibleTo: [PartnersGroup.MAIN_PARTNER],
    permission: ExtendedUserRoles.SUPERADMIN,
    requiredSettings: [],
    disabledSettings: [],
    element: <Applications />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.ADD_PARTNER,
    name: i18n.t("partners:addTitle"),
    icon: IconName.PLUS,
    visibleTo: [PartnersGroup.MAIN_PARTNER],
    permission: ExtendedUserRoles.SUPERADMIN,
    requiredSettings: [],
    disabledSettings: [],
    element: <AddPartner />,
    showInNavigation: true,
  },
  {
    path: RouterPaths.STYLE_GUIDE,
    visibleTo: [PartnersGroup.MAIN_PARTNER, PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.SUPERADMIN,
    requiredSettings: [],
    disabledSettings: [],
    element: <Example />,
    showInNavigation: false,
  },
  {
    path: RouterPaths.NOT_FOUND,
    visibleTo: [PartnersGroup.MAIN_PARTNER, PartnersGroup.OTHER_PARTNERS],
    permission: ExtendedUserRoles.USER,
    requiredSettings: [],
    disabledSettings: [],
    element: <NotFound />,
    showInNavigation: false,
  },
];

export const TermsPage = {
  path: RouterPaths.TERMS,
  visibleTo: [PartnersGroup.MAIN_PARTNER, PartnersGroup.OTHER_PARTNERS],
  permission: ExtendedUserRoles.USER,
  requiredSettings: [],
  disabledSettings: [],
  element: <Terms />,
  showInNavigation: false,
};

export const ProductsPage = Pages.find((p) => p.path === RouterPaths.PRODUCTS);

export default Pages;
