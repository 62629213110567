import React, { useState } from "react";
import { Formik, Form, FieldArray } from "formik";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Dialog from "common/basicComponents/Dialog";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonType,
  ButtonSize,
} from "common/basicComponents/Button";
import Box from "common/basicComponents/Box";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { useViewport, ViewportType } from "utils/ViewportContext";
import { IconName } from "common/basicComponents/Icon";
import ValidationSchema from "./TagGroupForm.schema";

const Buttons = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "row" : "column-reverse"};
  width: 100%;
`;

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
`;

const ErrorBox = styled(Box)`
  display: flex;
  align-items: center;
  min-height: 2.5rem;
`;

const EditTagsDialog = ({ open, tag, onSave, onClose }) => {
  const { t } = useTranslation("tags");
  const { type: viewportType } = useViewport();
  const [deletedTagIds, setDeletedTagIds] = useState([]); // TODO find deleted instead

  const tagDeleteHandler = (tag) => {
    if (!tag.id) return;
    setDeletedTagIds((deleted) => [...deleted, tag.id]);
  };

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    onSave({
      ...values,
      categoryId: values.category?.value,
      deletedTagIds,
    });
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding="0 0 2.5rem">
        <Typography
          variant={TypographyVariant.H4}
          weight={TypographyWeight.BOLD}
          text={tag ? t("editTagGroup") : t("addTagGroup")}
        />
      </Box>
      <Formik
        initialValues={{
          id: tag?.id,
          name: tag?.tag_group_name,
          tags:
            tag?.tags?.map((tag) => ({
              id: tag.id,
              name: tag.tag_name,
            })) || [],
        }}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
        enableReinitialize
      >
        {({ isSubmitting, values, errors, submitCount }) => (
          <Form>
            <Box width="100%">
              <FormInputField
                type={FormInputFieldType.TEXT}
                name="name"
                id="name"
                label={t("tagGroupName")}
                disabled={isSubmitting}
              />
            </Box>
            <FieldArray
              name="tags"
              render={(arrayHelpers) => (
                <div>
                  {values.tags &&
                    values.tags.length > 0 &&
                    values.tags.map((_, index) => (
                      <FieldContainer key={index}>
                        <Box grow={1}>
                          <FormInputField
                            id={`tags.${index}.name`}
                            name={`tags.${index}.name`}
                            label={t("tagName")}
                            type={FormInputFieldType.TEXT}
                          />
                        </Box>
                        <Button
                          icon={IconName.TRASH}
                          variant={ButtonVariant.TEXT}
                          size={ButtonSize.XSMALL}
                          onClick={() => {
                            tagDeleteHandler(values.tags[index]);
                            arrayHelpers.remove(index);
                          }}
                        />
                      </FieldContainer>
                    ))}
                  <Box
                    width={viewportType < ViewportType.TABLET ? "100%" : "50%"}
                    padding={
                      viewportType < ViewportType.TABLET
                        ? "0.5rem 0 0"
                        : "1.25rem 1rem 0 0"
                    }
                  >
                    <Button
                      minWidth="13rem"
                      width="100%"
                      variant={ButtonVariant.CONTAINED}
                      text={t("addNewTag")}
                      icon={IconName.PLUS}
                      onClick={() => {
                        arrayHelpers.push({
                          name: "",
                        });
                      }}
                      disabled={isSubmitting}
                    />
                  </Box>
                  <ErrorBox>
                    {submitCount > 0 &&
                      errors?.tags &&
                      typeof errors?.tags === "string" && (
                        <Typography
                          text={t(errors.tags)}
                          variant={TypographyVariant.CAPTION}
                          weight={TypographyWeight.LIGHT}
                          style={{ color: "gray" }}
                        />
                      )}
                  </ErrorBox>
                </div>
              )}
            />
            <Buttons viewport={viewportType}>
              <Box
                width={viewportType < ViewportType.TABLET ? "100%" : "50%"}
                padding={
                  viewportType < ViewportType.TABLET
                    ? "0.625rem 0"
                    : "0 1rem 0 0"
                }
              >
                <Button
                  variant={ButtonVariant.OUTLINED}
                  text={t("common:button.cancel")}
                  minWidth="13rem"
                  width="100%"
                  onClick={onClose}
                  disabled={isSubmitting}
                />
              </Box>
              <Box
                width={viewportType < ViewportType.TABLET ? "100%" : "50%"}
                padding={
                  viewportType < ViewportType.TABLET
                    ? "0.625rem 0"
                    : "0 0 0 1rem"
                }
              >
                <Button
                  variant={ButtonVariant.GREEN}
                  text={t("common:button.save")}
                  minWidth="13rem"
                  width="100%"
                  type={ButtonType.SUBMIT}
                  isLoading={isSubmitting}
                  disabled={values.tags.length === 0}
                />
              </Box>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

EditTagsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  tag: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

EditTagsDialog.defaultProps = {
  tag: null,
};

export default EditTagsDialog;
