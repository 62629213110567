import React from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { ViewportMinBreakpoints } from "utils/ViewportContext";
import Typography, { TypographyVariant, TypographyWeight } from "./Typography";

export const TabVariant = {
  MAIN: "main",
  WIDE: "wide",
  INLINE: "inline",
};

const getTabStyle = (theme, variant, pseudo = "default") =>
  ({
    [TabVariant.MAIN]: {
      default: css`
        padding: 0.825rem;
        flex-grow: 1;
        flex-basis: 0;
        background: ${theme.colors.white};
        border-bottom: 2px solid ${theme.colors.grey[200]};
        @media screen and (min-width: ${ViewportMinBreakpoints.DESKTOP}px) {
          min-width: 12.5rem;
          flex-grow: unset;
        }
      `,
      hover: css`
        border-bottom: 2px solid ${theme.colors.black};
      `,
      selected: css`
        border-bottom: 2px solid ${theme.colors.black};
        background: ${theme.colors.grey[200]};
      `,
    },
    [TabVariant.WIDE]: {
      default: css`
        padding: 0.825rem;
        flex-grow: 1;
        flex-basis: 0;
        background: ${theme.colors.white};
        border-bottom: 2px solid ${theme.colors.grey[200]};
      `,
      hover: css`
        border-bottom: 2px solid ${theme.colors.black};
      `,
      selected: css`
        border-bottom: 2px solid ${theme.colors.black};
        background: ${theme.colors.grey[200]};
      `,
    },
    [TabVariant.INLINE]: {
      default: css`
        padding: 0.625rem;
        flex-grow: 1;
        flex-basis: 0;
        background: transparent;
        border-bottom: 2px solid ${theme.colors.grey[200]};
        @media screen and (min-width: ${ViewportMinBreakpoints.DESKTOP}px) {
          min-width: 8.75rem;
          flex-grow: unset;
        }
      `,
      hover: css`
        border-image: ${theme.colors.gradient[60]} 1;
      `,
      selected: css`
        border-image: ${theme.colors.gradient[100]} 1;
        span {
          font-weight: bold;
        }
      `,
    },
  }[variant][pseudo]);

const StyledTabs = styled(Tabs)``;

const StyledTab = styled(Tab)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  border: none;
  list-style: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.black};

  ${({ theme, variant }) => getTabStyle(theme, variant, "default")};

  &:hover {
    ${({ theme, variant }) => getTabStyle(theme, variant, "hover")};
  }

  &.selected {
    ${({ theme, variant }) => getTabStyle(theme, variant, "selected")}
  }
`;

const StyledTabList = styled(TabList)`
  margin: 0 0 1.5rem;
  padding: 0;
  display: flex;
`;

const StyledTabPanel = styled(TabPanel)`
  display: none;

  &.selected {
    display: block;
  }
`;

const SingleTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  & p {
    margin-bottom: 1.5rem;
  }
`;

const TabNavigation = ({
  variant,
  tabs,
  tabsLongTitles,
  contents,
  onSelect,
  index,
  disabled,
}) => {
  if (
    tabs.length === 0 &&
    tabsLongTitles.length === 0 &&
    contents.length === 0
  ) {
    return <></>;
  }
  if (
    tabs.length === 1 &&
    tabsLongTitles.length === 1 &&
    contents.length === 1
  ) {
    return (
      <SingleTabContainer>
        <Typography
          text={tabsLongTitles[0]}
          variant={TypographyVariant.BODY2}
          weight={TypographyWeight.SEMIBOLD}
          tag="p"
        />
        {contents[0]}
      </SingleTabContainer>
    );
  }
  return (
    <StyledTabs
      selectedTabClassName="selected"
      selectedTabPanelClassName="selected"
      selectedIndex={index}
      onSelect={onSelect}
    >
      <StyledTabList>
        {tabs.map((tab) => (
          <StyledTab variant={variant} key={tab} disabled={disabled}>
            <Typography
              text={tab}
              variant={
                variant === TabVariant.INLINE
                  ? TypographyVariant.BODY1
                  : TypographyVariant.BUTTON
              }
              weight={
                variant === TabVariant.INLINE
                  ? TypographyWeight.REGULAR
                  : TypographyWeight.BOLD
              }
              tag="span"
            />
          </StyledTab>
        ))}
      </StyledTabList>
      {contents.map((content) => (
        <StyledTabPanel key={content.key}>{content}</StyledTabPanel>
      ))}
    </StyledTabs>
  );
};

const ItemPropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
});

TabNavigation.propTypes = {
  variant: PropTypes.oneOf(Object.values(TabVariant)),
  tabs: PropTypes.arrayOf(PropTypes.string),
  tabsLongTitles: PropTypes.arrayOf(PropTypes.string),
  contents: PropTypes.arrayOf(ItemPropType),
  index: PropTypes.number,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

TabNavigation.defaultProps = {
  variant: TabVariant.INLINE,
  tabs: [],
  tabsLongTitles: [],
  contents: [],
  index: null,
  onSelect: () => {},
  disabled: false,
};

export default TabNavigation;
