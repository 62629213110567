import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    name: Yup.string()
      .required("common:validator.required")
      .max(50, { code: "common:validator.maxChars", params: { max: 50 } }),
    tags: Yup.array()
      .min(1, "common:validator.minTagGroup")
      .of(
        Yup.object().shape({
          name: Yup.string()
            .required("common:validator.required")
            .max(50, {
              code: "common:validator.maxChars",
              params: { max: 50 },
            }),
        })
      ),
  });
};
