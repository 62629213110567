import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";

import Dialog from "common/basicComponents/Dialog";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { ButtonWidth } from "common/basicComponents/Button";
import Box from "common/basicComponents/Box";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { ButtonType } from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";
import FormFileField from "common/formFields/FormFileField";
import ValidationSchema from "./EffectForm.schema";

const Buttons = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "row" : "column-reverse"};
  width: 100%;
  gap: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "2rem" : "1.25rem"};
  margin-top: 2.5rem;
`;

const EditEffectDialog = ({ open, effect, onSave, onClose }) => {
  const { t } = useTranslation("effects");
  const { type: viewportType } = useViewport();

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    onSave({
      ...effect,
      name: values.name,
      fbxFile: values.fbxFile,
    });
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding="0 0 2.5rem">
        <Typography
          variant={TypographyVariant.H4}
          weight={TypographyWeight.BOLD}
          text={effect?.id ? t("editEffect") : t("addEffect")}
        />
      </Box>
      <Formik
        initialValues={{
          fbxFile: effect?.fbxFile,
          name: effect?.name,
          id: effect?.id,
        }}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={ValidationSchema}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <Box padding="0.5rem 0 0">
              <FormInputField
                type={FormInputFieldType.TEXT}
                name="name"
                id="name"
                label={t("name")}
                disabled={isSubmitting}
              />
            </Box>
            <Box padding="0.5rem 0 0">
              <FormFileField
                id="fbxFile"
                name="fbxFile"
                label={t("fbxFile")}
                acceptedExtensions={["fbx"]}
              />
            </Box>
            <Buttons viewport={viewportType}>
              <Button
                variant={ButtonVariant.OUTLINED}
                text={t("common:button.cancel")}
                width={ButtonWidth.GROW}
                onClick={onClose}
                disabled={isSubmitting}
              />
              <Button
                variant={ButtonVariant.GREEN}
                text={t("saveEffect")}
                width={ButtonWidth.GROW}
                type={ButtonType.SUBMIT}
                isLoading={isSubmitting}
              />
            </Buttons>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

EditEffectDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  effect: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

EditEffectDialog.defaultProps = {
  effects: null,
};

export default EditEffectDialog;
