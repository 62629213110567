import React from "react";
import styled, { useTheme } from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PropTypes from "prop-types";

import Pagination from "./Pagination";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Loader from "common/basicComponents/Loader";
import Box from "common/basicComponents/Box";
import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import theme from "theme/theme";
import i18n from "i18n/i18n";

const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  table-layout: auto;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
`;
const StyledHeaderCell = styled.th`
  border-bottom: 1px solid ${({ dividerColor }) => dividerColor};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
  max-width: ${({ maxWidth }) => maxWidth};
  width: ${({ maxWidth }) => maxWidth};
  padding: 0.75rem;
  top: 0;
  position: sticky;
  background: ${({ theme }) => theme.colors.white};
  z-index: 1;
`;

const StyledCardTable = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "2rem" : "1.25rem"};
`;

const getInfoHeight = (viewport) =>
  ({
    [ViewportType.DESKTOP]: "6rem",
    [ViewportType.TABLET]: "5.5rem",
    [ViewportType.MOBILE]: "7.5rem",
  }[viewport]);

const NoDataInfo = styled.div`
  width: 100%;
  height: ${({ viewport }) => getInfoHeight(viewport)};
  ${({ theme, transparent }) =>
    !transparent && `background: ${theme.colors.white};`}

  display: flex;
  align-items: center;
  justify-content: center;

  & > * {
    background: ${({ theme }) => theme.colors.gradient[100]};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: pre-line;
  }
`;

const Table = ({
  headers,
  data,
  total,
  tableRow,
  dividerColor,
  tableCard,
  cardViewportBreakpoint,
  keyPropertyName,
  meta,
  onMetaChange,
  loading,
  itemProps,
  enableReorder,
  onReorder,
  pagination,
  noDataText,
}) => {
  const RowComponent = tableRow;
  const CardComponent = tableCard;
  const { type: viewportType } = useViewport();
  const { colors } = useTheme();

  const handleDragEnd = (result) => {
    const startIndex = result.source?.index;
    const endIndex = result.destination?.index;
    if (startIndex == null || endIndex == null || startIndex === endIndex)
      return;
    onReorder(
      data[startIndex][keyPropertyName],
      data[endIndex][keyPropertyName],
      endIndex < startIndex
    );
  };

  const handleMoveUp = (fromIndex) => {
    if (!enableReorder) return;
    if (fromIndex === 0 && meta.page === 1) return;
    onReorder(
      data[fromIndex]?.[keyPropertyName],
      data[fromIndex - 1]?.[keyPropertyName],
      true
    );
  };

  const handleMoveDown = (fromIndex) => {
    if (!enableReorder) return;
    if (fromIndex === data.length - 1 && meta.page * meta.pageSize >= total)
      return;
    onReorder(
      data[fromIndex]?.[keyPropertyName],
      data[fromIndex + 1]?.[keyPropertyName],
      false
    );
  };

  const getTableRowStyle = (isDragging, draggableStyle) => {
    return {
      ...draggableStyle,
      display: isDragging ? "table" : "",
      tableLayout: isDragging ? "auto" : "",
    };
  };

  return (
    <>
      {(!cardViewportBreakpoint && cardViewportBreakpoint !== 0) ||
      !tableCard ||
      viewportType > cardViewportBreakpoint ? (
        <StyledTable>
          <thead>
            <tr>
              {headers.map((h) => (
                <StyledHeaderCell
                  centered={h.centered}
                  key={h.text}
                  dividerColor={dividerColor}
                  maxWidth={h.maxWidth}
                >
                  <Typography
                    variant={TypographyVariant.BODY2}
                    weight={TypographyWeight.SEMIBOLD}
                    text={h.text}
                  />
                </StyledHeaderCell>
              ))}
            </tr>
          </thead>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {!loading &&
                    data.map((row, index) => (
                      <Draggable
                        key={row[keyPropertyName]}
                        draggableId={row[keyPropertyName]}
                        index={index}
                        isDragDisabled={!enableReorder}
                      >
                        {(provided, snapshot) => (
                          <tr
                            key={row[keyPropertyName]}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getTableRowStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <RowComponent
                              data={row}
                              index={index}
                              number={
                                (meta ? (meta.page - 1) * meta.pageSize : 0) +
                                index +
                                1
                              }
                              moveUpEnabled={index > 0 || meta?.page > 1}
                              onMoveUp={() => handleMoveUp(index)}
                              moveDownEnabled={
                                index < data.length - 1 ||
                                meta?.page * meta?.pageSize < total
                              }
                              onMoveDown={() => handleMoveDown(index)}
                              {...itemProps}
                            />
                          </tr>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </StyledTable>
      ) : (
        <StyledCardTable viewport={viewportType}>
          {!loading &&
            data.map((row, index) => {
              return (
                <CardComponent
                  headers={headers}
                  data={row}
                  index={index}
                  key={row[keyPropertyName]}
                  enableReorder={enableReorder}
                  moveUpEnabled={index > 0 || meta?.page > 1}
                  onMoveUp={() => handleMoveUp(index)}
                  moveDownEnabled={
                    index < data.length - 1 ||
                    meta?.page * meta?.pageSize < total
                  }
                  onMoveDown={() => handleMoveDown(index)}
                  {...itemProps}
                />
              );
            })}
        </StyledCardTable>
      )}
      {loading && (
        <Box width="100%" height="6rem">
          <Loader show={true} color={colors.success[400]} height={20} />
        </Box>
      )}
      {!loading && data.length === 0 && (
        <NoDataInfo
          viewport={viewportType}
          transparent={viewportType <= cardViewportBreakpoint}
        >
          <Typography
            variant={TypographyVariant.BODY2}
            weight={TypographyWeight.REGULAR}
            text={noDataText}
          />
        </NoDataInfo>
      )}
      {pagination && meta && data.length > 0 && (
        <Pagination
          loading={loading}
          total={total}
          page={meta.page}
          onPageChange={(page) => onMetaChange({ ...meta, page })}
          pageSize={meta.pageSize}
          onPageSizeChange={(pageSize) => onMetaChange({ pageSize, page: 1 })}
        />
      )}
    </>
  );
};

Table.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  total: PropTypes.number,
  tableRow: PropTypes.oneOfType([PropTypes.func.isRequired, PropTypes.object])
    .isRequired,
  keyPropertyName: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }),
  onMetaChange: PropTypes.func,
  loading: PropTypes.bool,
  tableCard: PropTypes.func,
  dividerColor: PropTypes.string,
  cardViewportBreakpoint: PropTypes.oneOf(Object.values(ViewportType)),
  itemProps: PropTypes.object,
  enableReorder: PropTypes.bool,
  onReorder: PropTypes.func,
  pagination: PropTypes.bool,
  noDataText: PropTypes.string,
};

Table.defaultProps = {
  loading: false,
  total: 0,
  meta: null,
  onMetaChange: () => {},
  itemProps: {},
  enableReorder: false,
  onReorder: () => {},
  dividerColor: theme.colors.black,
  pagination: true,
  noDataText: i18n.t("common:noData"),
  cardViewportBreakpoint: null,
  tableCard: null,
};

export default Table;
