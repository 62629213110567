import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Box from "common/basicComponents/Box";
import { useViewport, ViewportType } from "utils/ViewportContext";
import FormToggleField, {
  ToggleVariant,
} from "common/formFields/FormToggleField";
import Accordion from "common/basicComponents/Accordion";
import { useFormikContext } from "formik";
import FormEditorField from "common/formFields/FormEditorField";
import FormInputField from "common/formFields/FormInputField";
import { FormInputFieldType } from "common/formFields/FormInputField";
import { useState, useEffect } from "react";

const PolicyContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem;
`;

const PrivacyPolicy = () => {
  const { type: viewportType } = useViewport();
  const [accordionHasErrors, setAccordionHasErrors] = useState(false);
  const { t } = useTranslation("privacyPolicy");
  const { values } = useFormikContext();

  useEffect(() => {
    if (accordionHasErrors) setAccordionHasErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Accordion text={t("privacyPolicy")} forceOpen={setAccordionHasErrors}>
      <PolicyContent>
        <Box width={viewportType > ViewportType.MOBILE ? "16rem" : "100%"}>
          <FormToggleField
            label={t("privacyPolicySource")}
            id="privacyPolicyFromEditor"
            name="privacyPolicyFromEditor"
            variant={ToggleVariant.SWITCH_BLACK}
            leftOption={t("editor")}
            rightOption={t("link")}
            switchWidth="100%"
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        {values.privacyPolicyFromEditor && (
          <FormEditorField
            id="privacyPolicyContents"
            name="privacyPolicyContents"
            placeholder={t("privacyPolicyPlaceholder")}
            onError={() => setAccordionHasErrors(true)}
          />
        )}
        {!values.privacyPolicyFromEditor && (
          <FormInputField
            id="privacyPolicyLink"
            name="privacyPolicyLink"
            label={t("privacyPolicyLink")}
            type={FormInputFieldType.TEXT}
            onError={() => setAccordionHasErrors(true)}
          />
        )}
      </PolicyContent>
    </Accordion>
  );
};
export default PrivacyPolicy;
