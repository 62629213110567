import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import FileInput from "common/basicComponents/FileInput";
import { useViewport, ViewportType } from "utils/ViewportContext";
import FileExtension from "constants/FileExtension";
import { translate } from "utils/FormUtils";

export const FileState = {
  UPLOADED: "uploaded",
  DELETED: "deleted",
  REPLACED: "replaced",
  NEW: "new",
  NONE: "none",
};

export const InitialFileState = {
  uploadState: FileState.NONE,
  file: null,
};

const FileActions = {
  ADD: "add",
  DELETE: "delete",
};

const FileStateTransitions = {
  [FileState.UPLOADED]: {
    [FileActions.ADD]: FileState.REPLACED,
    [FileActions.DELETE]: FileState.DELETED,
  },
  [FileState.DELETED]: {
    [FileActions.ADD]: FileState.REPLACED,
    [FileActions.DELETE]: FileState.DELETED,
  },
  [FileState.REPLACED]: {
    [FileActions.ADD]: FileState.REPLACED,
    [FileActions.DELETE]: FileState.DELETED,
  },
  [FileState.NEW]: {
    [FileActions.ADD]: FileState.NEW,
    [FileActions.DELETE]: FileState.NONE,
  },
  [FileState.NONE]: {
    [FileActions.ADD]: FileState.NEW,
    [FileActions.DELETE]: FileState.NONE,
  },
};

const getCaption = (files) => {
  const n = files.length;
  if (n === 0) return null;
  if (n === 1) return files[0].name;

  return `${files[0].name} i ${n - 1} inne`;
};

const validateExtenstions = (files, acceptedExtensions) => {
  let accept = true;
  files.forEach((f) => {
    const ext = f.name.split(".").pop();
    if (!acceptedExtensions.includes(ext.toLowerCase())) accept = false;
  });
  return accept;
};

const prepareAcceptedExtensions = (extensions) => {
  const extensionsWithDots = extensions.map((ext) => `.${ext}`);
  return extensionsWithDots.join();
};

const FormFileField = ({
  id,
  name,
  label,
  disabled,
  multiple,
  preview,
  acceptedExtensions,
  hintIcon,
  smallHintText,
  aspectRatio,
  enableDelete,
  enableAction,
  onAction,
  actionName,
  onError,
}) => {
  const { t } = useTranslation();
  const { type: viewportType } = useViewport();
  const [field, meta, helpers] = useField(name);
  const { isSubmitting, submitCount, initialValues, values } =
    useFormikContext();
  const [error, setError] = useState(null);
  const [caption, setCaption] = useState(null);

  const formError =
    submitCount > 0 && meta.touched && meta.error
      ? translate(meta.error.file)
      : "";

  useEffect(() => {
    if (submitCount > 0 && meta.touched && meta.error && onError) {
      onError();
    }
  }, [meta, submitCount, onError]);

  const getFileSrc = (file, prevUrl) => {
    let url;
    if (
      !file ||
      multiple ||
      acceptedExtensions.filter(
        (ext) => ext !== FileExtension.JPG && ext !== FileExtension.PNG
      ).length > 0
    ) {
      url = null;
    } else {
      try {
        url = URL.createObjectURL(file);
      } catch (err) {
        url = null;
      }
    }
    if (prevUrl) URL.revokeObjectURL(prevUrl);
    return url;
  };

  useEffect(() => {
    // Do not change to initial values => it is for deleting 2D files to work
    const fieldValue = _.get(values, field.name);
    if (!fieldValue) return;
    helpers.setValue({
      url: getFileSrc(fieldValue?.file),
      ...fieldValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    if (!field.value?.externalInput) return;
    handleChange(field.value.externalInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value?.externalInput]);

  useEffect(() => {
    if (!field.value?.externalUrlInput) return;

    helpers.setValue({
      uploadState:
        FileStateTransitions[field.value?.uploadState || FileState.NONE][
          FileActions.ADD
        ],
      file: null,
      url: field.value.externalUrlInput,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value?.externalUrlInput]);

  const handleChange = (files) => {
    const action = _.isEmpty(files) ? FileActions.DELETE : FileActions.ADD;

    if (!validateExtenstions(files, acceptedExtensions)) {
      setError(
        t("validator.unsupportedExtension", { ext: acceptedExtensions })
      );
      helpers.setValue({
        uploadState:
          FileStateTransitions[field.value?.uploadState || FileState.NONE][
            action
          ],
        file: null,
        url: getFileSrc(null, field.value?.url),
      });
      return;
    }

    setError(null);

    if (!multiple) {
      const file = _.isEmpty(files) ? null : files[0];
      setCaption(getCaption(files));
      helpers.setValue({
        uploadState:
          FileStateTransitions[field.value?.uploadState || FileState.NONE][
            action
          ],
        file,
        url: getFileSrc(file, field.value?.url),
      });
    } else {
      setCaption(getCaption(files));
      helpers.setValue({
        uploadState:
          FileStateTransitions[field.value?.uploadState || FileState.NONE][
            action
          ],
        files:
          action === FileActions.DELETE
            ? []
            : { ...field.value.files, ...files },
      });
    }
  };

  return (
    <FileInput
      id={id}
      name={name}
      label={label}
      caption={
        field.value?.uploadState === FileState.UPLOADED
          ? t("form.fileExists")
          : caption
      }
      showCheckmark={field.value?.uploadState === FileState.UPLOADED}
      showDeleteButton={
        [FileState.UPLOADED, FileState.REPLACED, FileState.NEW].includes(
          field.value?.uploadState
        ) && enableDelete
      }
      hintIcon={hintIcon}
      hintText={
        viewportType <= ViewportType.TABLET
          ? t("form.fileInputHint")
          : t("form.fileDragDropHint")
      }
      smallHintText={smallHintText}
      aspectRatio={aspectRatio}
      accept={prepareAcceptedExtensions(acceptedExtensions)}
      src={preview ? field.value?.url : null}
      disabled={isSubmitting || disabled}
      error={error ? error : formError}
      onChange={handleChange}
      showActionButton={enableAction}
      actionName={actionName}
      onAction={onAction}
    />
  );
};

FormFileField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  hintIcon: PropTypes.string,
  smallHintText: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  preview: PropTypes.bool,
  acceptedExtensions: PropTypes.arrayOf(
    PropTypes.oneOf(Object.values(FileExtension))
  ).isRequired,
  aspectRatio: PropTypes.number,
  enableDelete: PropTypes.bool,
  enableAction: PropTypes.bool,
  onAction: PropTypes.func,
  actionName: PropTypes.string,
  onError: PropTypes.func,
};

FormFileField.defaultProps = {
  label: null,
  hintIcon: null,
  smallHintText: null,
  disabled: false,
  multiple: false,
  preview: false,
  aspectRatio: null,
  enableDelete: true,
  enableAction: false,
  actionName: null,
  onAction: () => {},
  onError: () => {},
};

export default FormFileField;
