import React from "react";
import { useFormikContext } from "formik";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

import Box from "common/basicComponents/Box";
import FormFileField from "common/formFields/FormFileField";
import FileExtension from "constants/FileExtension";
import { useViewport, ViewportType } from "utils/ViewportContext";
import ModelType from "constants/ModelType";

const getFileWidth = (viewportType) =>
  ({
    [ViewportType.MOBILE]: "100%",
    [ViewportType.TABLET]: "50%",
    [ViewportType.DESKTOP]: "25%",
  }[viewportType]);

const Product3DFilesForm = ({ index, disabled, onError }) => {
  const { values } = useFormikContext();
  const { t } = useTranslation("product");
  const { type: viewportType } = useViewport();

  return (
    <>
      <Box
        padding="0 0.625rem"
        width={getFileWidth(viewportType)}
        show={
          !values.fromMarketplace &&
          !values.fromOtherPartner &&
          values.modelType?.value === ModelType.MODEL3D
        }
      >
        <FormFileField
          id={`versions[${index}].albedoFile`}
          name={`versions[${index}].albedoFile`}
          label={t("version.albedoFile")}
          disabled={disabled}
          acceptedExtensions={[FileExtension.PNG]}
          preview={true}
          aspectRatio={1}
          onError={onError}
        />
      </Box>

      <Box
        padding="0 0.625rem"
        width={getFileWidth(viewportType)}
        show={
          !values.fromMarketplace &&
          !values.fromOtherPartner &&
          values.modelType?.value === ModelType.MODEL3D
        }
      >
        <FormFileField
          id={`versions[${index}].metallicFile`}
          name={`versions[${index}].metallicFile`}
          label={t("version.metallicFile")}
          disabled={disabled}
          acceptedExtensions={[FileExtension.PNG]}
          preview={true}
          aspectRatio={1}
          onError={onError}
        />
      </Box>
      <Box
        padding="0 0.625rem"
        width={getFileWidth(viewportType)}
        show={
          !values.fromMarketplace &&
          !values.fromOtherPartner &&
          values.modelType?.value === ModelType.MODEL3D
        }
      >
        <FormFileField
          id={`versions[${index}].normalFile`}
          name={`versions[${index}].normalFile`}
          label={t("version.normalFile")}
          disabled={disabled}
          acceptedExtensions={[FileExtension.PNG]}
          preview={true}
          aspectRatio={1}
          onError={onError}
        />
      </Box>
    </>
  );
};

Product3DFilesForm.propTypes = {
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onError: PropTypes.func,
};

Product3DFilesForm.defaultProps = {
  disabled: false,
  onError: () => {},
};

export default Product3DFilesForm;
