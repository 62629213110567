import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "common/page/Header";
import Content from "common/page/Content";
import Page from "common/page/Page";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import Box from "common/basicComponents/Box";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";
import { acceptTerms } from "./TermsActions";
import { getUserPartners, setPartnerById } from "components/auth/AuthActions";
import { ExtendedUserRoles } from "constants/UserRoles";
import TermsStructure from "./TermsStructure";
import { TypographyWeight } from "common/basicComponents/Typography";
import RouterPaths from "constants/RouterPaths";

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > button {
    align-self: flex-end;
    margin-top: 1.25rem;
  }
`;

const TermsItem = styled.div`
  display: flex;
  gap: 1rem;
  padding-bottom: 0.5rem;
`;

const Terms = () => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("terms");
  const { user, partner } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const acceptHandler = () => {
    acceptTerms()
      .then(() => getUserPartners(user.id))
      .then(() => {
        setPartnerById(partner.id);
        navigate(RouterPaths.ROOT);
      });
  };

  const renderTermsItem = (item, number, isTitle) => {
    return (
      <Box padding={!isTitle ? "0 0 0 2rem" : "0"} key={number}>
        <TermsItem>
          <Typography
            variant={TypographyVariant.BODY1}
            weight={
              isTitle ? TypographyWeight.SEMIBOLD : TypographyWeight.REGULAR
            }
            text={number}
          />
          <Typography
            variant={TypographyVariant.BODY1}
            weight={
              isTitle ? TypographyWeight.SEMIBOLD : TypographyWeight.REGULAR
            }
            text={item.text}
          />
        </TermsItem>
        {item.children &&
          item.children.map((child, index) =>
            renderTermsItem(child, `${number}${index + 1}.`)
          )}
      </Box>
    );
  };

  const renderTerms = (structure) => {
    return structure.map((item, index) =>
      renderTermsItem(item, `${index + 1}.`, true)
    );
  };

  return (
    <Page>
      <Header title={t("title")}></Header>
      <Content>
        <TermsContainer>
          {user.role !== ExtendedUserRoles.ADMIN && (
            <Typography
              variant={TypographyVariant.BODY1}
              text={t("userInfo")}
            />
          )}
          {user.role === ExtendedUserRoles.ADMIN && (
            <>
              {renderTerms(TermsStructure)}
              <Button
                variant={ButtonVariant.GREEN}
                text={t("accept")}
                width={viewportType === ViewportType.DESKTOP ? "10rem" : "100%"}
                onClick={acceptHandler}
              />
            </>
          )}
        </TermsContainer>
      </Content>
    </Page>
  );
};

export default Terms;
