import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import { IconName } from "common/basicComponents/Icon";
import Button, {
  ButtonVariant,
  ButtonType,
  ButtonSize,
} from "common/basicComponents/Button";
import Menu from "./Menu";
import ImagePreview from "./ImagePreview";
import GradientBackground from "common/basicComponents/GradientBackground";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import {
  addNotification,
  NotificationVariant,
} from "components/notifications/NotificationSlice";
import { mirrorImgFile } from "utils/ImageUtils";

const FullScreenContainer = styled.div`
  position: fixed;
  inset: 0;
  background: ${({ theme }) => theme.colors.white};
  z-index: 10;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  position: relative;
  min-height: 5rem;
  height: 5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HeaderContent = styled.div`
  max-width: min(calc(100% - 12.5rem), calc((100vh - 25rem) * 3) + 2.5rem);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5rem;
`;
const HeaderButtons = styled.div`
  display: flex;
  gap: 0.625rem;
`;

const WizardContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
  padding: 2.5rem 0 2rem;
  width: 100%;

  display: flex;
  align-items: center;
  position: relative;
`;

const FittingLine = styled.div`
  position: absolute;
  border-top: 0.5px dashed ${({ theme }) => theme.colors.success[700]};
  left: 0;
  right: 0;
  bottom: 50%;
`;

const FittingLineText = styled.div`
  color: ${({ theme }) => theme.colors.success[700]};
  text-transform: uppercase;
  text-align: center;
  padding: 0.5rem;
  font-size: 0.6rem;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 50%;
  width: 5rem;
  white-space: pre-line;
  align-self: flex-start;
`;

const Images = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 1.25rem;
  justify-content: center;
  max-height: 100%;
  height: 100%;
`;

const initialPhotoState = {
  crop: null,
  cropPercentage: null,
  scale: 1,
  position: {
    x: 0,
    y: 0,
  },
  rotation: 0,
};

export const PhotoIDs = {
  LEFT: "left",
  FRONT: "front",
  RIGHT: "right",
};

const Wizard = ({ onSave, onBack }) => {
  const { t } = useTranslation("wizard");
  const dispatch = useDispatch();

  const [active, setActive] = useState(null);
  const [cropModeActive, setCropModeActive] = useState(false);
  const [photos, setPhotos] = useState({
    front: initialPhotoState,
    left: initialPhotoState,
    right: initialPhotoState,
  });
  const [error, setError] = useState(null);
  const leftCanvasRef = useRef(null);
  const frontCanvasRef = useRef(null);
  const rightCanvasRef = useRef(null);
  const frontWithLensesCanvasRef = useRef(null);

  useEffect(() => {
    if (error?.message) {
      dispatch(
        addNotification({
          message: error?.message,
          variant: NotificationVariant.ERROR,
          height: "9rem",
          fullWidth: true,
        })
      );
    }
  }, [error, dispatch]);

  const handleCropClick = () => {
    setCropModeActive((c) => {
      if (c) handleCropSave();
      return !c;
    });
  };

  const handleCropSave = () => {
    const { cropPercentage, scale } = photos[active];

    setPhotos((photos) => {
      const newPhotos = { ...photos };
      newPhotos[active].crop = null;
      newPhotos[active].cropPercentage = null;
      return newPhotos;
    });

    if (
      !cropPercentage ||
      cropPercentage.width === 0 ||
      cropPercentage.height === 0
    )
      return;

    const width = 1024 / scale;
    const diffX =
      ((50 - cropPercentage.x - cropPercentage.width / 2) * width) / 100;
    const diffY =
      ((50 - cropPercentage.y - cropPercentage.height / 2) * width) / 100;
    const diffScale = (scale * 100) / cropPercentage.width - scale;

    handleMove(diffX, diffY);
    handleResize(diffScale);
  };

  const handleCropFinished = (crop, cropPercentage) => {
    setPhotos((photos) => {
      const newPhotos = { ...photos };
      newPhotos[active] = { ...newPhotos[active], crop, cropPercentage };
      return newPhotos;
    });
  };

  const handleResize = (diffScale, photoId) => {
    let activeId = photoId ? photoId : active;
    setPhotos((photos) => {
      const newPhotos = { ...photos };
      newPhotos[activeId] = {
        ...newPhotos[activeId],
        scale: newPhotos[activeId].scale + diffScale,
      };
      return newPhotos;
    });
  };

  const handleMove = (diffPositionX, diffPositionY, photoId) => {
    let activeId = photoId ? photoId : active;
    setPhotos((photos) => {
      const newPhotos = { ...photos };
      // clockwise rotation
      const radius = (newPhotos[activeId].rotation * Math.PI) / 180;
      const newY =
        newPhotos[activeId].position.y -
        Math.sin(radius) * diffPositionX +
        Math.cos(radius) * diffPositionY;
      const newX =
        newPhotos[activeId].position.x +
        Math.cos(radius) * diffPositionX +
        Math.sin(radius) * diffPositionY;
      newPhotos[activeId] = {
        ...newPhotos[activeId],
        position: {
          x: Math.floor(newX),
          y: Math.floor(newY),
        },
      };
      return newPhotos;
    });
  };

  const handleRotate = (diffRotation) => {
    setPhotos((photos) => {
      const newPhotos = { ...photos };
      newPhotos[active] = {
        ...newPhotos[active],
        rotation: newPhotos[active].rotation + diffRotation,
      };
      return newPhotos;
    });
  };

  const handleActiveChange = (id) => {
    setCropModeActive(false);
    setActive(id);
  };

  const resetPhoto = (id) => {
    setPhotos((photos) => {
      const newPhotos = { ...photos };
      newPhotos[id] = initialPhotoState;
      return newPhotos;
    });

    setActive((act) => {
      if (act === id) {
        setCropModeActive(false);
        return null;
      }
      return act;
    });
  };

  const validate = () => {
    if (
      leftCanvasRef.current &&
      frontCanvasRef.current &&
      rightCanvasRef.current &&
      frontWithLensesCanvasRef.current
    ) {
      setError(null);
      return true;
    }
    let fields = [];

    if (!leftCanvasRef.current) fields.push(PhotoIDs.LEFT);
    if (!frontCanvasRef.current || !frontWithLensesCanvasRef.current)
      fields.push(PhotoIDs.FRONT);
    if (!rightCanvasRef.current) fields.push(PhotoIDs.RIGHT);

    setError({
      fields,
      message:
        fields.length === 1 && !frontWithLensesCanvasRef.current
          ? t("frontNotEdited")
          : t("photosMissing"),
    });
    return false;
  };

  const handleSave = async () => {
    if (!validate()) return;
    const left = await fetch(leftCanvasRef.current?.toDataURL()).then((res) =>
      res.blob()
    );
    const front = await fetch(frontCanvasRef.current?.toDataURL()).then((res) =>
      res.blob()
    );
    const right = await fetch(rightCanvasRef.current?.toDataURL()).then((res) =>
      res.blob()
    );
    const frontFull = await fetch(
      frontWithLensesCanvasRef.current?.toDataURL()
    ).then((res) => res.blob());
    onSave(front, frontFull, left, right);
  };


  // const mirrorCanvas = (source, destination) => {
  //   const canvas = document.createElement("canvas");
  //   const ctx = canvas.getContext("2d");
  //   canvas.width = 1024;
  //   canvas.height = 1024;

  //   ctx.drawImage(source.current, 0, 0, 1024, 1024);
  //   destination.current = canvas;

  // }

  // const logCavs = () => {
  // console.log('refs')
  // console.log(rightCanvasRef.current)
  // console.log(leftCanvasRef.current)
  // }

  return (
    <FullScreenContainer>
      <GradientBackground />
      <Header>
        <HeaderContent>
          <Typography
            className="title"
            variant={TypographyVariant.H3}
            weight={TypographyWeight.BOLD}
            text={t("title")}
          />
          <HeaderButtons style={{ display: "flex" }}>
            <Button
              variant={ButtonVariant.GREEN}
              minWidth="11rem"
              text={t("common:button.save")}
              onClick={handleSave}
              type={ButtonType.BUTTON}
              size={ButtonSize.NORMAL}
            />
            <Button
              variant={ButtonVariant.ERROR}
              icon={IconName.TRASH_OUTLINED}
              onClick={onBack}
              type={ButtonType.BUTTON}
              size={ButtonSize.NORMAL}
            />
          </HeaderButtons>
        </HeaderContent>
      </Header>
      <WizardContent>
        <FittingLine />
        <FittingLineText>{t("fittingLine")}</FittingLineText>
        <Images>
          <ImagePreview
            icon={IconName.GLASSES_RIGHT_SIDE}
            title={t("rightEarpiece")}
            id={PhotoIDs.RIGHT}
            active={active}
            changeActive={handleActiveChange}
            modifications={photos.right}
            cropModeActive={cropModeActive && active === PhotoIDs.RIGHT}
            setCrop={handleCropFinished}
            resetPhoto={resetPhoto}
            mainCanvasRef={rightCanvasRef}
            externalError={error?.fields.includes(PhotoIDs.RIGHT)}
            onWheelZoom={(diffScale) => handleResize(diffScale, PhotoIDs.RIGHT)}
            onMouseDrag={(diffX, diffY) =>
              handleMove(diffX, diffY, PhotoIDs.RIGHT)
            }
            // showActionButton={leftCanvasRef}
            // actionName={"Mirror left"}
            // onAction={() => {logCavs(); mirrorCanvas(leftCanvasRef, rightCanvasRef)}}
          />
          <ImagePreview
            icon={IconName.GLASSES_FRONT_WITHOUT_LENSES}
            title={t("front")}
            id={PhotoIDs.FRONT}
            active={active}
            changeActive={handleActiveChange}
            modifications={photos.front}
            cropModeActive={cropModeActive && active === PhotoIDs.FRONT}
            setCrop={handleCropFinished}
            resetPhoto={resetPhoto}
            mainCanvasRef={frontCanvasRef}
            frontWithLensesCanvasRef={frontWithLensesCanvasRef}
            externalError={error?.fields.includes(PhotoIDs.FRONT)}
            onWheelZoom={(diffScale) => handleResize(diffScale, PhotoIDs.FRONT)}
            onMouseDrag={(diffX, diffY) =>
              handleMove(diffX, diffY, PhotoIDs.FRONT)
            }
          />
          <ImagePreview
            icon={IconName.GLASSES_LEFT_SIDE}
            title={t("leftEarpiece")}
            id={PhotoIDs.LEFT}
            active={active}
            changeActive={handleActiveChange}
            modifications={photos.left}
            cropModeActive={cropModeActive && active === PhotoIDs.LEFT}
            setCrop={handleCropFinished}
            resetPhoto={resetPhoto}
            mainCanvasRef={leftCanvasRef}
            externalError={error?.fields.includes(PhotoIDs.LEFT)}
            onWheelZoom={(diffScale) => handleResize(diffScale, PhotoIDs.LEFT)}
            onMouseDrag={(diffX, diffY) =>
              handleMove(diffX, diffY, PhotoIDs.LEFT)
            }
            // showActionButton={rightCanvasRef}
            // actionName={"Mirror right"}
            // onAction={() => logCavs()}
          />
        </Images>
        <FittingLineText>{t("fittingLine")}</FittingLineText>
      </WizardContent>
      <Menu
        disabled={!active}
        cropActive={cropModeActive}
        moveValue={photos[active]?.position?.y || 0}
        resizeValue={photos[active]?.scale || 1}
        rotateValue={photos[active]?.rotation || 0}
        onCrop={handleCropClick}
        onMove={handleMove}
        onResize={handleResize}
        onRotate={handleRotate}
      />
    </FullScreenContainer>
  );
};

Wizard.propTypes = {
  onSave: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default Wizard;
