import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Icon from "common/basicComponents/Icon";

const StyledButton = styled.button`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;

  width: 2rem;
  height: 2rem;

  background: ${({ theme, selected }) =>
    selected ? theme.colors.black : theme.colors.white};
  color: ${({ theme, selected }) =>
    selected ? theme.colors.white : theme.colors.black};

  ${({ theme, selected }) =>
    selected
      ? `border: none`
      : `
          border-image: ${theme.colors.gradient[20]} 1;
          background-image: ${theme.colors.gradient[20]};
          background-clip: text;
          -webkit-background-clip: text;
          &::before {
            content: "";
            background-color: ${theme.colors.white};
            position: absolute;
            inset: 0;
            z-index: -1;
          }
  `}

  ${({ theme, selected }) =>
    !selected &&
    `
      &:disabled:not(.dots) {
        border: 1px solid ${theme.colors.grey[200]};
      }
      &:disabled.dots {
        border-image: ${theme.colors.gradient[20]} 1;
        background-image: ${theme.colors.gradient[20]};
        background-clip: text;
        -webkit-background-clip: text;
      }
      &:hover {
        background: ${theme.colors.white};
        border-image: ${theme.colors.gradient[60]} 1;
        background-image: ${theme.colors.gradient[60]};
        background-clip: text;
        -webkit-background-clip: text;
        color: ${theme.colors.black};
      }
      &:active {
        background: ${theme.colors.white};
        border-image: ${theme.colors.gradient[100]} 1;
        background-image: ${theme.colors.gradient[100]};
        background-clip: text;
        -webkit-background-clip: text;
        color: ${theme.colors.black};
      }
  `}
`;

const PaginationButton = ({
  icon,
  text,
  onClick,
  disabled,
  className,
  selected,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      disabled={disabled}
      className={className}
      type="button"
      selected={selected}
    >
      {icon && <Icon name={icon} size="1.5rem" />}
      {text && (
        <Typography
          variant={TypographyVariant.BUTTON}
          weight={
            selected ? TypographyWeight.SEMIBOLD : TypographyWeight.REGULAR
          }
          text={text}
        />
      )}
    </StyledButton>
  );
};

const checkIfTextOrIcon = (props, _propName, componentName) => {
  if (!props.text && !props.icon) {
    return new Error(
      `One of 'text' or 'icon' is required by '${componentName}' component.`
    );
  }
  return null;
};

PaginationButton.propTypes = {
  icon: checkIfTextOrIcon,
  text: checkIfTextOrIcon,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  selected: PropTypes.bool,
};

PaginationButton.defaultProps = {
  icon: null,
  text: null,
  onClick: () => {},
  disabled: false,
  className: null,
  selected: false,
};

export default PaginationButton;
