import React from "react";
import PropTypes from "prop-types";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  BarElement,
  defaults,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import styled, { css, useTheme } from "styled-components";

import Box from "common/basicComponents/Box";
import theme from "theme/theme";
import { chartTypes } from "utils/AnalyticsUtils";
import { ViewportMinBreakpoints } from "utils/ViewportContext";
import { TypographyVariant } from "common/basicComponents/Typography";
import { TypographyWeight } from "common/basicComponents/Typography";
import Typography from "common/basicComponents/Typography";
import PieChart from "./PieChart";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";
import Table from "common/Table";

defaults.font.family = "Open Sans";
defaults.font.weight = 300;
defaults.font.size = 11;
defaults.color = theme.colors.black;
defaults.scale.beginAtZero = true;
defaults.scale.border.display = false;
defaults.scale.grid.color = theme.colors.grey[100];

const CustomColors = [theme.colors.primary[200], theme.colors.success[400]];

const ChartContainer = styled(Box)`
  background: white;
  min-height: 23rem;
  min-width: 100%;
  @media screen and (min-width: ${ViewportMinBreakpoints.DESKTOP}px) {
    min-width: 40%;
  }
  flex-grow: 1;
  flex-basis: 0;
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;
`;

const ChartBox = styled(Box)`
  ${({ type }) =>
    type === chartTypes.LINE || type === chartTypes.BAR
      ? css`
          letter-spacing: 0.05rem;
          padding: 0 0.5rem 0 0;
        `
      : css`
          padding: 0 0.25rem;
        `}
`;
const AnalyticsChart = ({ labels, data, title, type }) => {
  const theme = useTheme();
  const { type: viewportType } = useViewport();

  Chart.register(
    Colors,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        backgroundColor: theme.colors.primary[500],
        cornerRadius: 0,
        padding: 8,
        caretSize: 0,
        boxPadding: 4,
        bodyFont: {
          weight: 600,
        },
      },
      legend: {
        labels: {
          boxHeight: 16,
          boxWidth: 32,
          boxPadding: 50,
          textAlign: "left",
          font: {
            weight: 600,
          },
          padding: 30,
        },
        position: "bottom",
      },
    },
    elements: {
      point: {
        pointRadius: viewportType === ViewportType.MOBILE ? 2 : 3,
      },
      line: {
        borderWidth: viewportType === ViewportType.MOBILE ? 2 : 3,
      },
    },
    scales: {
      x: {
        ticks: {
          minRotation: 45,
          maxRotation: 45,
          maxTicksLimit: 15,
          align: "inner",
          autoSkipPadding: 10,
          font: {
            weight: 600,
          },
        },
      },
      y: {
        grid: {
          tickLength: 20,
        },
        ticks: {
          precision: 0,
          beginAtZero: true,
          maxTicksLimit: 6,
        },
      },
    },
  };
  return (
    <ChartContainer>
      <Typography
        variant={TypographyVariant.BODY1}
        weight={TypographyWeight.SEMIBOLD}
        text={title}
        className="chart-title"
      />
      <ChartBox grow={1} type={type}>
        {type === chartTypes.LINE && (
          <Line
            options={options}
            data={{
              labels: labels,
              datasets: data.map((data, idx) => ({
                backgroundColor: CustomColors[idx],
                borderColor: CustomColors[idx],
                ...data,
              })),
            }}
          />
        )}
        {type === chartTypes.BAR && (
          <Bar
            options={options}
            data={{
              labels: labels,
              datasets: data.map((data, idx) => ({
                backgroundColor: CustomColors[idx],
                borderColor: CustomColors[idx],
                ...data,
              })),
            }}
          />
        )}
        {type === chartTypes.LIST && (
          <Table
            headers={data[0].headers}
            data={data[0].rowsData}
            tableRow={data[0].rowComponent}
            keyPropertyName={data[0].key}
          />
        )}
        {type === chartTypes.PIE && (
          <PieChart
            data={data.map((data, idx) => ({
              backgroundColor: CustomColors[idx],
              borderColor: CustomColors[idx],
              ...data,
            }))}
          />
        )}
      </ChartBox>
    </ChartContainer>
  );
};

AnalyticsChart.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  labels: PropTypes.array.isRequired,
  type: PropTypes.oneOf(Object.values(chartTypes)).isRequired,
};

export default AnalyticsChart;
