import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Header from "common/page/Header";
import Content from "common/page/Content";
import Page from "common/page/Page";
import ProductSearch from "./list/MarketplaceSearch";
import { PageSizes } from "common/Table/Pagination";
import {
  setMarketplaceSearch,
  clearMarketplaceProducts,
} from "./MarketplaceSlice";
import {
  assignProductFromMarketplace,
  getMarketplaceProducts,
} from "./MarketplaceActions";
import MarketplaceTable from "./list/MarketplaceTable";

const Marketplace = () => {
  const { t } = useTranslation("marketplace");
  const dispatch = useDispatch();

  const { partner } = useSelector((state) => state.auth);
  const { marketplaceProducts, marketplaceProductsTotal, marketplaceSearch } =
    useSelector((state) => state.marketplace);

  const [meta, setMeta] = useState({ page: 1, pageSize: PageSizes[0] });
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleSearch = (searchParams) => {
    dispatch(
      setMarketplaceSearch({
        ...searchParams,
      })
    );
    setMeta((prev) => ({ ...prev, page: 1 }));
  };

  const assignHandler = (data) => {
    assignProductFromMarketplace(data);
  };

  useEffect(() => {
    dispatch(clearMarketplaceProducts());
    setMeta({ page: 1, pageSize: PageSizes[0] });
  }, [partner, dispatch]);

  useEffect(() => {
    const maximumVisible = Math.min(
      marketplaceProductsTotal,
      meta.page * meta.pageSize
    );

    if (maximumVisible - marketplaceProducts.length > 0) return;

    setVisibleProducts(
      marketplaceProducts.slice((meta.page - 1) * meta.pageSize, maximumVisible)
    );
  }, [meta, marketplaceProducts, marketplaceProductsTotal]);

  useEffect(() => {
    setLoading(true);
    const maximumVisible = Math.min(
      marketplaceProductsTotal || meta.pageSize,
      meta.page * meta.pageSize
    );
    const offset = marketplaceProducts.length;
    const limit = maximumVisible - offset;

    if (marketplaceProductsTotal > 0 && limit <= 0) {
      setLoading(false);
      return;
    }

    getMarketplaceProducts({ offset, limit, ...marketplaceSearch }).finally(
      () => {
        setLoading(false);
      }
    );
  }, [
    meta,
    marketplaceSearch,
    marketplaceProductsTotal,
    marketplaceProducts.length,
  ]);

  return (
    <Page>
      <Header
        title={t("title")}
        subtitles={[
          [t("totalNumber"), (marketplaceProductsTotal || "").toString()],
        ]}
      >
        <ProductSearch onSearch={handleSearch} />
      </Header>
      <Content>
        <MarketplaceTable
          products={visibleProducts}
          total={marketplaceProductsTotal}
          loading={loading}
          meta={meta}
          onMetaChange={setMeta}
          onAssign={(id) => assignHandler(id)}
        />
      </Content>
    </Page>
  );
};

export default Marketplace;
