const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "";
const REACT_APP_API_URL = process.env.REACT_APP_API_URL || "";
const REACT_APP_REDUX_DEV_TOOLS_ENABLED =
  !!process.env.REACT_APP_REDUX_DEV_TOOLS_ENABLED || false;
const REACT_APP_DEV_MODE =
  process.env.NODE_ENV === "development" ? true : false;
const REACT_APP_GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "";
const REACT_APP_TOTEM_INSTALLER_LINK =
  process.env.REACT_APP_TOTEM_INSTALLER_LINK || "";
const REACT_APP_EDITOR_BACKEND_WS_URL =
  process.env.REACT_APP_EDITOR_BACKEND_WS_URL || "";
const REACT_APP_DEV_AUTH_TOKEN = process.env.REACT_APP_DEV_AUTH_TOKEN || "";

export {
  REACT_APP_API_URL,
  REACT_APP_BACKEND_URL,
  REACT_APP_REDUX_DEV_TOOLS_ENABLED,
  REACT_APP_DEV_MODE,
  REACT_APP_GOOGLE_CLIENT_ID,
  REACT_APP_TOTEM_INSTALLER_LINK,
  REACT_APP_EDITOR_BACKEND_WS_URL,
  REACT_APP_DEV_AUTH_TOKEN,
};
