import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { useSelector } from "react-redux";

import { getTotems } from "../../../TotemActions";
import Dialog from "common/basicComponents/Dialog";
import { totemsToSelect } from "utils/TotemUtils";
import FormSelectField from "common/formFields/FormSelectField";
import { useViewport } from "utils/ViewportContext";
import styled from "styled-components";
import { ViewportType } from "utils/ViewportContext";
import Button, {
  ButtonVariant,
  ButtonWidth,
  ButtonType,
} from "common/basicComponents/Button";
import Box from "common/basicComponents/Box";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";

const Buttons = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "row" : "column-reverse"};
  width: 100%;
  gap: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "2rem" : "1.25rem"};
  margin-top: 2.5rem;
`;

const WarningBox = styled(Box)`
  color: ${({ theme }) => theme.colors.secondary[700]};
`;

const CopyTotemDialog = ({ open, onClose, onCopy, saving }) => {
  const { t } = useTranslation("totems");
  const { type: viewportType } = useViewport();

  useSelector;
  const { totems } = useSelector((state) => state.totems);
  const { partner } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!totems || totems.length === 0) {
      getTotems();
    }
  }, [partner, totems]);

  const onSubmit = (values) => {
    onCopy(values.totem.value);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding="0 0 1.5rem">
        <Typography
          variant={TypographyVariant.H4}
          weight={TypographyWeight.BOLD}
          text={t("copy.title")}
        />
      </Box>
      <Box>
        <Typography
          variant={TypographyVariant.BODY1}
          weight={TypographyWeight.REGULAR}
          text={t("copy.info")}
        />
      </Box>
      <WarningBox padding="0 0 1.5rem">
        <Typography
          variant={TypographyVariant.BODY1}
          weight={TypographyWeight.SEMIBOLD}
          text={t("copy.warning")}
        />
      </WarningBox>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={{
          totem: { label: "", value: "" },
        }}
      >
        <Form noValidate>
          <FormSelectField
            id="totem"
            name="totem"
            label={t("copy.totem")}
            options={totemsToSelect(totems)}
          />
          <Buttons viewport={viewportType}>
            <Button
              variant={ButtonVariant.OUTLINED}
              text={t("common:button.cancel")}
              width={ButtonWidth.GROW}
              onClick={onClose}
              disabled={saving}
            />
            <Button
              type={ButtonType.SUBMIT}
              variant={ButtonVariant.GREEN}
              text={t("common:button.copy")}
              width={ButtonWidth.GROW}
              isLoading={saving}
            />
          </Buttons>
        </Form>
      </Formik>
    </Dialog>
  );
};

CopyTotemDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default CopyTotemDialog;
