import React from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDispatch } from "react-redux";

import Button, {
  ButtonType,
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { updateMinVersion } from "../ApplicationsActions";
import { addNotification } from "components/notifications/NotificationSlice";
import { NotificationVariant } from "common/basicComponents/Notification";
import { useViewport, ViewportType } from "utils/ViewportContext";
import { IconName } from "common/basicComponents/Icon";

const Container = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  align-self: stretch;

  & > *:not(button) {
    flex-grow: ${({ viewport }) => viewport !== ViewportType.DESKTOP && "1"};
    max-height: 4rem;
    white-space: nowrap;
  }
`;

const AppsMinVersionForm = (version) => {
  const { t } = useTranslation("apps");
  const dispatch = useDispatch();
  const { type: viewportType } = useViewport();

  const onSubmit = (values, form) => {
    updateMinVersion(values.version).then((ok) => {
      if (ok) {
        dispatch(
          addNotification({
            variant: NotificationVariant.SUCCESS,
            message: t("common:form.savedSuccessfully"),
          })
        );
      }
      form.setSubmitting(false);
    });
  };

  return (
    <Formik
      initialValues={{
        version: version.version,
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {() => (
        <Form noValidate>
          <Container viewport={viewportType}>
            <FormInputField
              type={FormInputFieldType.TEXT}
              name="version"
              id="version"
              label={t("minVersion")}
            />
            {viewportType !== ViewportType.MOBILE ? (
              <Button
                type={ButtonType.SUBMIT}
                variant={ButtonVariant.GREEN}
                size={ButtonSize.NORMAL}
                minWidth="200px"
                text={t("common:button.save")}
              />
            ) : (
              <Button
                type={ButtonType.SUBMIT}
                variant={ButtonVariant.OUTLINED}
                icon={IconName.FILTERS}
              />
            )}
          </Container>
        </Form>
      )}
    </Formik>
  );
};

AppsMinVersionForm.propTypes = {
  version: PropTypes.string,
};

AppsMinVersionForm.defaultProps = {
  version: "",
};

export default AppsMinVersionForm;
