import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import { appendUsers } from "./UserSlice";
import { handleFormError } from "api/ApiClient";

export const getUsers = (params) => {
  return apiClient
    .get(ApiEndpoints.USERS, { params })
    .then((res) => {
      const users = res?.data?.body;
      const meta = res?.data?.meta;
      if (!users || !meta) return false;
      store.dispatch(
        appendUsers({
          users,
          meta,
        })
      );
      return true;
    })
    .catch(() => false);
};

export const addUser = (user, form) => {
  return apiClient
    .post(ApiEndpoints.USERS, user)
    .then((res) => res?.data)
    .catch((err) => handleFormError(err, form));
};

export const editUser = (user, form) => {
  return apiClient
    .put(`${ApiEndpoints.USERS}${user.id}`, user)
    .then(() => true)
    .catch((err) => handleFormError(err, form));
};

export const deleteUser = (user, form) => {
  return apiClient
    .delete(`${ApiEndpoints.USERS}${user.id}`)
    .then(() => true)
    .catch((err) => handleFormError(err, form));
};
