import zip from "jszip";

import {
  apiClient,
  handleFormError,
  setPartnerHeader,
  externalApiClient,
} from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import { setPartnerById } from "components/auth/AuthActions";
import { PARTNER } from "constants/StorageKeys";
import { prepareProductFormData, loadProductFiles } from "utils/ProductUtils";
import { productPropertiesToTags } from "utils/TagUtils";
import {
  setAllProducts,
  appendProducts,
  setProducts,
  setProduct,
  setProductLog,
} from "./ProductSlice";
import { loadThumbnails } from "utils/ProductUtils";
import { prepareAutocutFormData } from "utils/ProductUtils";

export const getProducts = (params) => {
  return apiClient
    .get(ApiEndpoints.PRODUCTS, { params })
    .then((res) => {
      const products = res?.data?.body;
      const meta = res?.data?.meta;
      if (!products || !meta) return false;
      store.dispatch(
        appendProducts({
          products,
          meta,
        })
      );
      return products;
    })
    .catch(() => false);
};

export const getAllProductsWithVersions = () => {
  return apiClient
    .get(ApiEndpoints.ALL_PRODUCTS)
    .then((res) => {
      const products = res?.data?.body;
      if (!products) return false;
      store.dispatch(setAllProducts(products));
      return true;
    })
    .catch(() => false);
};

export const getProduct = (id) => {
  setPartnerHeader(sessionStorage.getItem(PARTNER));
  return apiClient
    .get(`${ApiEndpoints.PRODUCTS}${id}`)
    .then((res) => {
      const product = productPropertiesToTags(res?.data?.body);
      if (!product) return false;
      if (product.partner_id) setPartnerById(product.partner_id);
      if (!product.product_files_zip_link) {
        loadThumbnails(product);
        store.dispatch(setProduct(product));
        return product;
      }

      const linkSuffix = product.product_files_zip_link?.split("/").pop();
      return new Promise((resolve, reject) => {
        externalApiClient
          .get(`https://files.ar-labs.io/${linkSuffix}`, {
            responseType: "blob",
          })
          .then((res) => res.data)
          .then(zip.loadAsync)
          .then((zipData) => loadProductFiles(zipData, product))
          .then((productWithFiles) => {
            store.dispatch(setProduct(product));
            return resolve(productWithFiles);
          })
          .catch(reject);
      });
    })
    .catch(() => false);
};

export const getProductLog = (id) => {
  return apiClient
    .get(ApiEndpoints.PRODUCT_LOG, { params: { log_id: id } })
    .then((res) => {
      const productLog = res?.data?.body;
      if (!productLog) return false;
      store.dispatch(setProductLog(productLog));
      return true;
    })
    .catch(() => false);
};

export const addProduct = (product, form) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const formData = prepareProductFormData(product);
  return apiClient
    .post(ApiEndpoints.PRODUCTS, formData, config)
    .then((res) => res?.data)
    .catch((err) => handleFormError(err, form));
};

export const changeProductsOrder = (fromId, toId, direction) => {
  return apiClient
    .put(`${ApiEndpoints.ORDER_PRODUCTS}`, {
      from_id: fromId,
      to_id: toId,
      direction,
    })
    .then(() => {
      const newProducts = Array.from(store.getState().products.products);
      const fromIndex = newProducts.findIndex((p) => p.id === fromId);
      const toIndex = newProducts.findIndex((p) => p.id === toId);
      const [removed] = newProducts.splice(fromIndex, 1);
      newProducts.splice(toIndex, 0, removed);
      store.dispatch(setProducts(newProducts));
      return true;
    })
    .catch(() => false);
};

export const editProduct = (product, form) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const formData = prepareProductFormData(product);
  return apiClient
    .put(`${ApiEndpoints.PRODUCTS}${product.id}`, formData, config)
    .then(() => true)
    .catch((err) => handleFormError(err, form));
};

export const moveProductToMarketplace = (id) => {
  return apiClient
    .put(`${ApiEndpoints.MOVE_PRODUCT_TO_MAIN_PARTNER}${id}`)
    .then(() => true)
    .catch(() => false);
};

export const deleteProduct = (id) => {
  return apiClient
    .delete(`${ApiEndpoints.PRODUCTS}${id}`)
    .then(() => true)
    .catch(() => false);
};

export const autocutProduct = (frontFile, leftFile, rightFile) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const formData = prepareAutocutFormData(frontFile, leftFile, rightFile);
  return apiClient
    .post(ApiEndpoints.PRODUCT_AUTOCUT, formData, config)
    .then((res) => res?.data)
    .catch(() => false);
};
