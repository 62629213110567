import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import { setAnalytics } from "./AnalyticsSlice";

export const getAnalytics = () => {
  return apiClient
    .get(ApiEndpoints.DASHBOARD)
    .then((res) => {
      const analytics = res?.data?.body;
      if (!analytics) return false;
      store.dispatch(
        setAnalytics({
          data: analytics.chart_data,
          date: analytics.gathered_at,
          status: analytics.status_progress,
        })
      );
      return true;
    })
    .catch(() => false);
};
