import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";
import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";

import Box from "common/basicComponents/Box";
import FormFileField from "common/formFields/FormFileField";
import FileExtension from "constants/FileExtension";
import { useViewport, ViewportType } from "utils/ViewportContext";
import ModelType from "constants/ModelType";
import Typography from "common/basicComponents/Typography";
import { TypographyVariant } from "common/basicComponents/Typography";
import { TypographyWeight } from "common/basicComponents/Typography";
import Icon, { IconName } from "common/basicComponents/Icon";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import Wizard from "../wizard/Wizard";
import { FileState } from "common/formFields/FormFileField";
import Autocut from "../autocut/Autocut";
import { mirrorImgBlob } from "utils/ImageUtils";

const Method = {
  FILES: "files",
  WIZARD: "wizard",
  AUTOCUT: "autocut",
};

const Sides = {
  LEFT: "left",
  RIGHT: "right"
};

const SubsectionHeader = styled.div`
  height: 3.75rem;
  line-height: 3.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[400]};
  color: ${({ theme }) => theme.colors.grey[400]};
  margin: 0 0.625rem 3rem;
`;

const FileMethods = styled.div`
  display: grid;
  ${({ viewport }) =>
    viewport === ViewportType.DESKTOP &&
    "grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));"}
  gap: 1.25rem;

  padding: 0 0.625rem;
  margin: 0 0 2.5rem;
`;

const FileMethodButton = styled.button`
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
  background-image: ${({ theme }) => theme.colors.gradient[20]};
  background-clip: text;
  -webkit-background-clip: text;
  color: black;
  flex-basis: 0;
  flex-grow: 1;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 2.5rem;
  text-transform: uppercase;
  text-align: left;
`;

const FileMethodIcon = styled.div`
  width: 5rem;
  min-width: 5rem;
  height: 5rem;
  background-image: ${({ theme }) => theme.colors.gradient[20]};
  display: flex;
  align-items: center;
  justify-content: center;
  & svg {
    fill: url(#gradient);
  }
`;

const WizardInfo = styled.div`
  padding: 0.5rem 0.625rem 0 0.625rem;
  & > * {
    background: ${({ theme }) => theme.colors.gradient[100]};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: pre-line;
  }
`;

const Files2D = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  width: 100%;
  row-gap: 0.5rem;
`;

const Files2DButtons = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  padding: 3rem 0 1.25rem;
`;

const getFileWidth = (viewportType) =>
  ({
    [ViewportType.MOBILE]: "100%",
    [ViewportType.TABLET]: "50%",
    [ViewportType.DESKTOP]: "25%",
  }[viewportType]);

const Product2DFilesForm = ({ index, disabled, onError, conf }) => {
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation("product");
  const { type: viewportType } = useViewport();
  const [method, setMethod] = useState(null);

  const versionValues = values.versions[index];

  useEffect(() => {
    setMethod(conf.method ? conf.method : null)
  },[])

  const clear2DFiles = () => {
    setFieldValue(`versions[${index}].frontFile`, {
      ...versionValues.frontFile,
      externalInput: [],
      externalUrlInput: null,
    });
    setFieldValue(`versions[${index}].lensesFile`, {
      ...versionValues.lensesFile,
      externalInput: [],
      externalUrlInput: null,
    });
    setFieldValue(`versions[${index}].frontFullFile`, {
      ...versionValues.frontFullFile,
      externalInput: [],
      externalUrlInput: null,
    });
    setFieldValue(`versions[${index}].leftFile`, {
      ...versionValues.leftFile,
      externalInput: [],
      externalUrlInput: null,
    });
    setFieldValue(`versions[${index}].rightFile`, {
      ...versionValues.rightFile,
      externalInput: [],
      externalUrlInput: null,
    });
  };

  const checkFiles2DUploaded = () =>
    [
      versionValues.frontFile,
      versionValues.leftFile,
      versionValues.rightFile,
    ].every((file) =>
      [FileState.NEW, FileState.REPLACED, FileState.UPLOADED].includes(
        file?.uploadState
      )
    );

  const handleWizardSave = (front, frontWithLenses, left, right) => {
    if (front?.type === "image/png") {
      front.path = "front.png";
      front.name = "front.png";
      setFieldValue(`versions[${index}].frontFile`, {
        ...versionValues.frontFile,
        externalInput: [front],
      });
    }

    if (frontWithLenses?.type === "image/png") {
      frontWithLenses.path = "frontWithLenses.png";
      frontWithLenses.name = "frontWithLenses.png";
      setFieldValue(`versions[${index}].frontFullFile`, {
        ...versionValues.frontFullFile,
        externalInput: [frontWithLenses],
      });
      setFieldValue(`versions[${index}].lensesFile`, {
        ...versionValues.lensesFile,
        externalInput: [],
        externalUrlInput: null,
      });
    }
    if (left?.type === "image/png") {
      left.path = "left.png";
      left.name = "left.png";
      setFieldValue(`versions[${index}].leftFile`, {
        ...versionValues.leftFile,
        externalInput: [left],
      });
    }
    if (right?.type === "image/png") {
      right.path = "right.png";
      right.name = "right.png";
      setFieldValue(`versions[${index}].rightFile`, {
        ...versionValues.rightFile,
        externalInput: [right],
      });
    }

    setMethod(Method.FILES);
  };

  const handleMirror = async (side) => {
    let fileValue = null;
    let urlValue = null;

    switch(side) {
      case Sides.LEFT:
        fileValue = `versions[${index}].rightFile`;
        urlValue = versionValues.leftFile.url;
        break;
      case Sides.RIGHT:
        fileValue = `versions[${index}].leftFile`;
        urlValue = versionValues.rightFile.url;
        break;
    }

    if(!urlValue || !fileValue) return;

    const mirrored = await mirrorImgBlob(urlValue);
    mirrored.toBlob(b => {
      const url =  URL.createObjectURL(b);
      const file = b;

      setFieldValue(fileValue, {
        url: url,
        file: file,
        uploadState: "replaced"
      });
    });
  }

  const handleAutocutSave = (urls) => {
    if (!urls) return;

    setFieldValue(`versions[${index}].frontFile`, {
      ...versionValues.frontFile,
      externalUrlInput: urls.front,
      externalInput: null,
    });
    setFieldValue(`versions[${index}].lensesFile`, {
      ...versionValues.lensesFile,
      externalUrlInput: urls.lenses,
      externalInput: null,
    });
    setFieldValue(`versions[${index}].frontFullFile`, {
      ...versionValues.frontFullFile,
      externalInput: [],
      externalUrlInput: null,
    });
    setFieldValue(`versions[${index}].leftFile`, {
      ...versionValues.leftFile,
      externalUrlInput: urls.left,
      externalInput: null,
    });
    setFieldValue(`versions[${index}].rightFile`, {
      ...versionValues.rightFile,
      externalUrlInput: urls.right,
      externalInput: null,
    });

    setMethod(Method.FILES);
  };

  return (
    <Box
      width="100%"
      show={
        values.modelType?.value === ModelType.MODEL2D &&
        !values.fromMarketplace &&
        !values.fromOtherPartner
      }
    >
      <SubsectionHeader>
        <Typography
          variant={TypographyVariant.BUTTON}
          weight={TypographyWeight.SEMIBOLD}
          text={t("photos")}
        />
      </SubsectionHeader>
      {!method && !checkFiles2DUploaded() && (
        <>
          <FileMethods viewport={viewportType}>
            <FileMethodButton
              type="button"
              onClick={() => setMethod(Method.FILES)}
            >
              <FileMethodIcon>
                <Icon name={IconName.FILE} size="3rem" />
              </FileMethodIcon>
              <Typography
                variant={TypographyVariant.BUTTON}
                weight={TypographyWeight.SEMIBOLD}
                text={t("addPreparedPhotos")}
              />
            </FileMethodButton>
            {viewportType === ViewportType.DESKTOP && (
              <FileMethodButton
                type="button"
                onClick={() => setMethod(Method.WIZARD)}
              >
                <FileMethodIcon>
                  <Icon name={IconName.MAGIC_WAND} size="3rem" />
                </FileMethodIcon>
                <Typography
                  variant={TypographyVariant.BUTTON}
                  weight={TypographyWeight.SEMIBOLD}
                  text={t("useWizard")}
                />
              </FileMethodButton>
            )}
            <FileMethodButton
              type="button"
              onClick={() => setMethod(Method.AUTOCUT)}
            >
              <FileMethodIcon>
                <Icon name={IconName.ROCKET} size="3rem" />
              </FileMethodIcon>
              <Typography
                variant={TypographyVariant.BUTTON}
                weight={TypographyWeight.SEMIBOLD}
                text={t("useAutocut")}
              />
            </FileMethodButton>
          </FileMethods>
          {viewportType !== ViewportType.DESKTOP && (
            <WizardInfo>
              <Typography
                variant={TypographyVariant.BODY2}
                weight={TypographyWeight.SEMIBOLD}
                text={t("important")}
              />
              <Typography
                variant={TypographyVariant.BODY2}
                weight={TypographyWeight.LIGHT}
                text={t("wizardUnavailable")}
              />
            </WizardInfo>
          )}
        </>
      )}
      {viewportType === ViewportType.DESKTOP && method === Method.WIZARD && (
        <Wizard onSave={handleWizardSave} onBack={() => setMethod(null)} />
      )}
      {method === Method.AUTOCUT && (
        <Autocut onSave={handleAutocutSave} onBack={() => setMethod(null)} />
      )}
      {(method === Method.FILES || (!method && checkFiles2DUploaded())) &&
        !values.fromMarketplace && (
          <Files2D>
            <Box padding="0 0.625rem" width={getFileWidth(viewportType)}>
              <FormFileField
                id={`versions[${index}].frontFile`}
                name={`versions[${index}].frontFile`}
                label={t("version.frontFile")}
                hintIcon={IconName.GLASSES_FRONT_WITHOUT_LENSES}
                disabled={disabled}
                acceptedExtensions={[FileExtension.PNG]}
                preview={true}
                aspectRatio={1}
                enableDelete={true}
                onError={onError}
              />
            </Box>
            <Box
              padding="0 0.625rem"
              width={getFileWidth(viewportType)}
              hidden={
                ![
                  FileState.NEW,
                  FileState.REPLACED,
                  FileState.UPLOADED,
                ].includes(versionValues.lensesFile?.uploadState)
              }
            >
              <FormFileField
                id={`versions[${index}].lensesFile`}
                name={`versions[${index}].lensesFile`}
                label={t("version.lensesFile")}
                disabled={disabled}
                acceptedExtensions={[FileExtension.PNG]}
                preview={true}
                aspectRatio={1}
                enableDelete={true}
                onError={onError}
              />
            </Box>
            <Box
              padding="0 0.625rem"
              width={getFileWidth(viewportType)}
              show={
                ![
                  FileState.NEW,
                  FileState.REPLACED,
                  FileState.UPLOADED,
                ].includes(versionValues.lensesFile?.uploadState)
              }
            >
              <FormFileField
                id={`versions[${index}].frontFullFile`}
                name={`versions[${index}].frontFullFile`}
                label={t("version.frontFullFile")}
                hintIcon={IconName.GLASSES_FRONT_WITH_LENSES}
                disabled={disabled}
                acceptedExtensions={[FileExtension.PNG]}
                preview={true}
                aspectRatio={1}
                enableDelete={true}
                onError={onError}
              />
            </Box>
            <Box padding="0 0.625rem" width={getFileWidth(viewportType)}>
              <FormFileField
                id={`versions[${index}].leftFile`}
                name={`versions[${index}].leftFile`}
                label={t("version.leftFile")}
                hintIcon={IconName.GLASSES_LEFT_SIDE}
                disabled={disabled}
                acceptedExtensions={[FileExtension.PNG]}
                preview={true}
                aspectRatio={1}
                enableDelete={true}
                onError={onError}
                enableAction={versionValues.rightFile.url}
                actionName={"Mirror right"}
                onAction={() => handleMirror(Sides.RIGHT)}
              />
            </Box>
            <Box padding="0 0.625rem" width={getFileWidth(viewportType)}>
              <FormFileField
                id={`versions[${index}].rightFile`}
                name={`versions[${index}].rightFile`}
                label={t("version.rightFile")}
                hintIcon={IconName.GLASSES_RIGHT_SIDE}
                disabled={disabled}
                acceptedExtensions={[FileExtension.PNG]}
                preview={true}
                aspectRatio={1}
                enableDelete={true}
                onError={onError}
                enableAction={versionValues.leftFile.url}
                actionName={"Mirror left"}
                onAction={() => handleMirror(Sides.LEFT)}
              />
            </Box>
            <Files2DButtons>
              <Box
                padding="0 0.625rem"
                width={viewportType === ViewportType.DESKTOP ? "50%" : "100%"}
              >
                <Button
                  minWidth="100%"
                  variant={ButtonVariant.ERROR}
                  text={t("deleteFiles")}
                  onClick={() => {
                    clear2DFiles();
                    setMethod(null);
                  }}
                />
              </Box>
            </Files2DButtons>
          </Files2D>
        )}
    </Box>
  );
};

Product2DFilesForm.propTypes = {
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onError: PropTypes.func,
  conf: PropTypes.object
};

Product2DFilesForm.defaultProps = {
  disabled: false,
  onError: () => {},
  conf: {}
};

export default Product2DFilesForm;
