import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import { useTranslation } from "react-i18next";

const Cell = styled.td`
  height: 4.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
`;

const ReportRow = ({ data }) => {
  const { t } = useTranslation("import");

  return (
    <>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={t("importStatus." + data.status)}
          tag="span"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.count}
          tag="span"
        />
      </Cell>
    </>
  );
};

ReportRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReportRow;
