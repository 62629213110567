import React from "react";
import styled from "styled-components";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import PropTypes from "prop-types";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import { useTranslation } from "react-i18next";

const Cell = styled.td`
  height: 4.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const ButtonsCell = styled(Cell)`
  min-width: 5.5rem;
  button {
    display: inline-flex;
    margin: 0 0.25rem;
  }
`;

const Thumb = styled.img`
  height: 3.5rem;
  width: 7rem;
  object-fit: contain;
`;

const ThumbPlaceholder = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[400]};
`;

const LinkRow = ({ data, onEdit, onDelete }) => {
  const thumbLink = data.version_thumb_small_link || data.version_thumb_link;
  const { t } = useTranslation("common");
  return (
    <>
      <Cell centered={true}>
        {thumbLink ? (
          <Thumb src={thumbLink} />
        ) : (
          <ThumbPlaceholder>{t("notAdded")}</ThumbPlaceholder>
        )}
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.product_name}
          tag="span"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.version_name}
          tag="span"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.domain_name + data.web_link_url}
          tag="span"
        />
      </Cell>
      <ButtonsCell centered={true}>
        <Button
          icon={IconName.EDIT}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onEdit(data)}
        />
        <Button
          icon={IconName.TRASH}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onDelete(data)}
        />
      </ButtonsCell>
    </>
  );
};

LinkRow.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default LinkRow;
