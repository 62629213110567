import React from "react";
import { ViewportType, useViewport } from "utils/ViewportContext";
import ProductMediumCard from "./ProductMediumCard";
import ProductSmallCard from "./ProductSmallCard";

const ProductCard = (props) => {
  const { type } = useViewport();
  if (type > ViewportType.MOBILE) return <ProductMediumCard {...props} />;
  else return <ProductSmallCard {...props} />;
};

export default ProductCard;
