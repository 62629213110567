import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import { handleFormError } from "api/ApiClient";
import { preparePartnerFormData } from "utils/PartnerUtils";

export const addPartner = (values, form) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const data = preparePartnerFormData(values);
  return apiClient
    .post(ApiEndpoints.PARTNERS, data, config)
    .then((res) => res?.data?.body)
    .catch((err) => handleFormError(err, form));
};

export const updatePartner = (id, values, form) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };

  const data = preparePartnerFormData(values);

  return apiClient
    .put(`${ApiEndpoints.PARTNERS}${id}`, data, config)
    .then(
      Promise.all([
        ...values.deletedDomains.map((d) => deleteDomain(d.domainId)),
        ...values.deletedEffects.map((e) => deleteEffect(e.id)),
        ...values.deletedAppLinks.map((a) => deleteAppLink(a.id)),
      ])
    )
    .catch((err) => handleFormError(err, form));
};

export const deleteDomain = (id) => {
  return apiClient
    .delete(`${ApiEndpoints.DOMAINS}${id}`)
    .then(() => true)
    .catch(() => false);
};

export const deleteEffect = (id) => {
  return apiClient
    .delete(`${ApiEndpoints.EFFECTS}${id}`)
    .then(() => true)
    .catch(() => false);
};

export const deleteAppLink = (id) => {
  return apiClient
    .delete(`${ApiEndpoints.APPLINKS}${id}`)
    .then(() => true)
    .catch(() => false);
};
