import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  appsList: [],
  minVersion: null,
};

export const applicationsSlice = createSlice({
  name: "applications",
  initialState,
  reducers: {
    setApplications: (state, action) => {
      state.appsList = action.payload.appsList;
      state.minVersion = action.payload.minVersion;
    },
  },
});

export const { setApplications } = applicationsSlice.actions;
export default applicationsSlice.reducer;
