import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import {
  setBanners,
  setTotem,
  setTotems,
  setVersionActive,
  setVersions,
  clearTotem,
} from "./TotemSlice";
import { setPartnerById } from "components/auth/AuthActions";
import { prepareTotemFormData } from "utils/TotemUtils";
import { prepareBannerFormData } from "utils/TotemUtils";

export const getTotems = () => {
  return apiClient
    .get(ApiEndpoints.TOTEMS)
    .then((res) => {
      const totems = res?.data?.body;
      if (!totems) return false;
      store.dispatch(setTotems(totems));
      return true;
    })
    .catch(() => false);
};

export const getTotem = (totem_id) => {
  const params = { totem_id };
  return apiClient
    .get(ApiEndpoints.TOTEM, { params })
    .then((res) => {
      const totem = res?.data?.body;
      const partnerId = totem?.partner_id;
      if (!partnerId || !totem) return false;
      setPartnerById(partnerId);
      store.dispatch(setTotem(totem));
      return true;
    })
    .catch(() => false);
};

export const getTotemVersions = (totem_id) => {
  const params = { totem_id };
  return apiClient
    .get(ApiEndpoints.TOTEM_VERSIONS, { params })
    .then((res) => {
      const versions = res?.data?.body;
      if (!versions) return false;
      store.dispatch(setVersions(versions));
    })
    .catch(() => false);
};

export const getTotemBanners = (totem_id) => {
  const params = { totem_id };
  return apiClient
    .get(ApiEndpoints.TOTEM_BANNERS, { params })
    .then((res) => {
      const banners = res?.data?.body;
      if (!banners) return false;
      store.dispatch(setBanners(banners));
    })
    .catch(() => false);
};

export const updateTotem = (totem) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const totemData = prepareTotemFormData(totem);
  return apiClient
    .put(ApiEndpoints.TOTEMS, totemData, config)
    .then(() => true)
    .catch(() => false);
};

export const copyTotem = (fromTotemId, toTotemId) => {
  return apiClient
    .post(ApiEndpoints.TOTEM_COPY, { from: fromTotemId, to: toTotemId })
    .then(() => true)
    .catch(() => false);
};

export const deleteTotem = (totemId) => {
  const params = { totemId };
  return apiClient
    .delete(ApiEndpoints.TOTEMS, { params })
    .then(() => {
      store.dispatch(setTotem(null));
      return true;
    })
    .catch(() => false);
};

export const deleteBanner = (bannerId, bannerImageLink) => {
  const params = { banner_image_link: bannerImageLink };
  return apiClient
    .delete(`${ApiEndpoints.TOTEM_BANNERS}${bannerId}`, { params })
    .then(() => true)
    .catch(() => false);
};

export const addBanner = (banner) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const bannerData = prepareBannerFormData(banner);
  return apiClient
    .post(ApiEndpoints.TOTEM_BANNERS, bannerData, config)
    .then(() => true)
    .catch(() => false);
};

export const editBanner = (banner) => {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const bannerData = prepareBannerFormData(banner);
  return apiClient
    .put(`${ApiEndpoints.TOTEM_BANNERS}${banner.banner_id}`, bannerData, config)
    .then(() => true)
    .catch(() => false);
};

export const updateVersion = (totem_id, version_id, version_active) => {
  return apiClient
    .put(`${ApiEndpoints.TOTEM_VERSIONS}`, {
      totem_id,
      version_id,
      version_active,
    })
    .then(() => {
      store.dispatch(
        setVersionActive({
          id: version_id,
          active: version_active,
        })
      );
      return true;
    })
    .catch(() => false);
};

export const changeVersionsOrder = (fromId, toId, direction, totemId) => {
  return apiClient
    .put(`${ApiEndpoints.TOTEMS}/order/`, {
      totem_id: totemId,
      from_id: fromId,
      to_id: toId,
      direction,
    })
    .then(() => {
      const newVersions = Array.from(store.getState().totems.versions);
      const fromIndex = newVersions.findIndex((p) => p.id === fromId);
      const toIndex = newVersions.findIndex((p) => p.id === toId);
      const [removed] = newVersions.splice(fromIndex, 1);
      newVersions.splice(toIndex, 0, removed);
      store.dispatch(setVersions(newVersions));
      return true;
    })
    .catch(() => false);
};

export const deleteVersion = (totemId, partnersVersionId) => {
  const params = {
    partners_version_id: partnersVersionId,
    totem_id: totemId,
  };
  return apiClient
    .delete(ApiEndpoints.TOTEM_VERSIONS, { params })
    .then(() => true)
    .catch(() => false);
};

export const addVersion = (totemId, versionId) => {
  const params = {
    version_id: versionId,
    totem_id: totemId,
  };
  return apiClient
    .post(ApiEndpoints.TOTEM_VERSIONS, params)
    .then(() => true)
    .catch(() => false);
};

export const clearTotemData = () => {
  store.dispatch(clearTotem());
};
