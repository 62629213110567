import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Accordion from "common/basicComponents/Accordion";
import Box from "common/basicComponents/Box";
import FormIconSelectField from "common/formFields/FormIconSelectField";
import { IconName } from "common/basicComponents/Icon";
import FormFileField from "common/formFields/FormFileField";
import FileExtension from "constants/FileExtension";
import FormRangeField from "common/formFields/FormRangeField";
import TabNavigation from "common/basicComponents/Tabs";
import FormToggleField, {
  ToggleVariant,
} from "common/formFields/FormToggleField";
import { ExtendedUserRoles } from "constants/UserRoles";
import ProductTypes from "constants/ProductTypes";
import { useViewport, ViewportType } from "utils/ViewportContext";
import ModelType from "constants/ModelType";
import FormInputField from "common/formFields/FormInputField";
import { FormInputFieldType } from "common/formFields/FormInputField";
import { NumberType } from "common/formFields/FormInputField";

const Content = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem 0.625rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  align-items: baseline;
`;

const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const GlassesDimensionsBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`;

const getSwitchBoxWidth = (viewportType) =>
  ({
    [ViewportType.MOBILE]: "100%",
    [ViewportType.TABLET]: "50%",
    [ViewportType.DESKTOP]: "25%",
  }[viewportType]);

const ProductModelForm = ({ disabled }) => {
  const { values, setFieldValue } = useFormikContext();
  const { t } = useTranslation("product");
  const { type: viewportType } = useViewport();
  const { partner, user } = useSelector((state) => state.auth);
  const [accordionHasErrors, setAccordionHasErrors] = useState(false);

  useEffect(() => {
    if (accordionHasErrors) setAccordionHasErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const options = [
    {
      value: ModelType.MODEL3D,
      label: t("model3d"),
      icon: IconName.MODEL3D,
    },
    {
      value: ModelType.MODEL2D,
      label: t("model2d"),
      icon: IconName.MODEL2D,
    },
  ];

  const ModelSettingsTabs = [
    {
      title: t("iOS"),
      longTitle: t("modelSettingsIos"),
      show:
        partner?.mobile_active &&
        !values.fromMarketplace &&
        !values.fromOtherPartner,
      content: (
        <RangeContainer key="iOS">
          <FormRangeField
            id="aspectRatioIos"
            name="aspectRatioIos"
            label={t("aspectRatio")}
            min={0.5}
            max={2}
            step={0.05}
            markStep={0.1}
            disabled={disabled}
          />
          <FormRangeField
            id="positionYIos"
            name="positionYIos"
            label={t("positionY")}
            min={-1}
            max={1}
            step={0.05}
            markStep={0.1}
            disabled={disabled}
          />
          <FormRangeField
            id="positionZIos"
            name="positionZIos"
            label={t("positionZ")}
            min={-1}
            max={1}
            step={0.05}
            markStep={0.1}
            disabled={disabled}
          />
          <FormRangeField
            id="rotationIos"
            name="rotationIos"
            label={t("rotation")}
            min={-10}
            max={10}
            step={0.5}
            markStep={1}
            disabled={disabled}
          />
        </RangeContainer>
      ),
    },
    {
      title: t("android"),
      longTitle: t("modelSettingsAndroid"),
      show:
        partner?.mobile_active &&
        !values.fromMarketplace &&
        !values.fromOtherPartner,
      content: (
        <RangeContainer key="Android">
          <FormRangeField
            id="aspectRatioAndroid"
            name="aspectRatioAndroid"
            label={t("aspectRatio")}
            min={0.5}
            max={2}
            step={0.05}
            markStep={0.1}
            disabled={disabled}
          />
          <FormRangeField
            id="positionYAndroid"
            name="positionYAndroid"
            label={t("positionY")}
            min={-1}
            max={1}
            step={0.05}
            markStep={0.1}
            disabled={disabled}
          />
          <FormRangeField
            id="positionZAndroid"
            name="positionZAndroid"
            label={t("positionZ")}
            min={-1}
            max={1}
            step={0.05}
            markStep={0.1}
            disabled={disabled}
          />
          <FormRangeField
            id="rotationAndroid"
            name="rotationAndroid"
            label={t("rotation")}
            min={-10}
            max={10}
            step={0.5}
            markStep={1}
            disabled={disabled}
          />
        </RangeContainer>
      ),
    },
    {
      title: t("browser"),
      longTitle: t("modelSettingsWeb"),
      show:
        partner?.web_active &&
        !values.fromMarketplace &&
        !values.fromOtherPartner,
      content: (
        <RangeContainer key="web">
          <FormRangeField
            id="aspectRatioWeb"
            name="aspectRatioWeb"
            label={t("aspectRatio")}
            min={0.5}
            max={2}
            step={0.05}
            markStep={0.1}
            disabled={disabled}
          />
          <FormRangeField
            id="positionYWeb"
            name="positionYWeb"
            label={t("positionY")}
            min={-1}
            max={1}
            step={0.05}
            markStep={0.1}
            disabled={disabled}
          />
          <FormRangeField
            id="positionZWeb"
            name="positionZWeb"
            label={t("positionZ")}
            min={-1}
            max={1}
            step={0.05}
            markStep={0.1}
            disabled={disabled}
          />
          <FormRangeField
            id="rotationWeb"
            name="rotationWeb"
            label={t("rotation")}
            min={-10}
            max={10}
            step={0.5}
            markStep={1}
            disabled={disabled}
          />
          {values.modelType?.value === ModelType.MODEL3D && (
            <FormRangeField
              id="earRotationWeb"
              name="earRotationWeb"
              label={t("earRotation")}
              min={-5}
              max={5}
              step={0.5}
              markStep={1}
              disabled={disabled}
            />
          )}
        </RangeContainer>
      ),
    },
  ];

  const unpublishAllVersions = () => {
    for (let idx = 0; idx < values.versions.length; idx++)
      setFieldValue(`versions[${idx}].published`, false);
  };

  return (
    <Accordion text={t("model")} defaultOpen forceOpen={accordionHasErrors}>
      <Content>
        <Box
          padding={
            viewportType === ViewportType.DESKTOP
              ? "0 calc(50% + 0.625rem) 0 0.625rem"
              : "0 0.625rem"
          }
          width="100%"
          show={values.type?.code === ProductTypes.GLASSES}
        >
          <FormIconSelectField
            id="modelType"
            name="modelType"
            label={t("modelType")}
            options={options}
            readOnly={!!values.id}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width="100%"
          height="9rem"
          show={
            values.modelType?.value === ModelType.MODEL3D &&
            !values.fromMarketplace &&
            !values.fromOtherPartner
          }
        >
          <FormFileField
            id="modelFileFbx"
            name="modelFileFbx"
            label={t("modelFileFbx")}
            disabled={disabled}
            acceptedExtensions={[FileExtension.FBX]}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem 0.5rem 0.625rem"
          width={getSwitchBoxWidth(viewportType)}
          show={!values.fromMarketplace && !values.fromOtherPartner}
        >
          <FormToggleField
            id="published"
            name="published"
            label={t("publishedApp")}
            disabled={disabled}
            variant={ToggleVariant.SWITCH_COLOR}
            onChange={(value) => {
              if (!value) unpublishAllVersions();
            }}
          />
        </Box>
        <Box
          padding="0 0.625rem 0.5rem 0.625rem"
          width={getSwitchBoxWidth(viewportType)}
          show={
            user.role === ExtendedUserRoles.SUPERADMIN &&
            values.type?.code === ProductTypes.GLASSES &&
            values.modelType?.value === ModelType.MODEL3D &&
            !values.fromMarketplace &&
            !values.fromOtherPartner
          }
        >
          <FormToggleField
            id="useMetallicGlass"
            name="useMetallicGlass"
            label={t("useMetallicGlass")}
            disabled={disabled}
            variant={ToggleVariant.SWITCH_COLOR}
          />
        </Box>
        <GlassesDimensionsBox
          width="100%"
          show={
            values.modelType?.value === ModelType.MODEL2D &&
            !values.fromMarketplace &&
            !values.fromOtherPartner
          }
        >
          <Box padding="0 0.625rem 1rem" width="100%">
            <FormRangeField
              id="earpieceDistanceScale"
              name="earpieceDistanceScale"
              label={t("earpieceDistanceScale")}
              min={0.5}
              max={1}
              step={0.05}
              markStep={0.1}
              disabled={disabled}
            />
          </Box>
          <Box
            padding="0 0.625rem"
            width={viewportType === ViewportType.MOBILE ? "100%" : "50%"}
          >
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.POSITIVE_INTEGER}
              id="glassesWidth"
              name="glassesWidth"
              label={t("glassesWidth")}
              disabled={disabled}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box
            padding="0 0.625rem"
            width={viewportType === ViewportType.MOBILE ? "100%" : "50%"}
          >
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.POSITIVE_INTEGER}
              id="lensHeight"
              name="lensHeight"
              label={t("lensHeight")}
              disabled={disabled}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
        </GlassesDimensionsBox>
        <Box
          padding="0 0.625rem 1.875rem"
          width="100%"
          show={ModelSettingsTabs.filter((tab) => tab.show).length > 0}
        >
          <TabNavigation
            tabs={ModelSettingsTabs.filter((tab) => tab.show).map(
              (tab) => tab.title
            )}
            tabsLongTitles={ModelSettingsTabs.filter((tab) => tab.show).map(
              (tab) => tab.longTitle
            )}
            contents={ModelSettingsTabs.filter((tab) => tab.show).map(
              (tab) => tab.content
            )}
          />
        </Box>
      </Content>
    </Accordion>
  );
};

ProductModelForm.propTypes = {
  disabled: PropTypes.bool,
};

ProductModelForm.defaultProps = {
  disabled: false,
};

export default ProductModelForm;
