import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { getTotemStatusTooltip, getTotemStatus } from "utils/TotemUtils";
import validationSchema from "./form/GeneralInfoForm.schema";
import Box from "common/basicComponents/Box";
import Loader from "common/basicComponents/Loader";
import DetailsForm from "./form/DetailsForm";
import FormObserver from "common/FormObserver";
import WelcomeScreenForm from "./form/WelcomeScreenForm";
import { ViewportType } from "utils/ViewportContext";
import ColorsForm from "./form/ColorsForm";
import TryOnScreenForm from "./form/TryOnScreenForm";
import { FileState } from "common/formFields/FormFileField";
import { getTotem, updateTotem } from "../../TotemActions";
import {
  addNotification,
  NotificationVariant,
} from "components/notifications/NotificationSlice";
import { scrollToTop } from "utils/PageUtils";
import WarningDialog from "common/basicComponents/WarningDialog";
import { scrollToError } from "utils/FormUtils";
import { valuesEqual } from "utils/CommonUtils";

const FormsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ viewport }) =>
    viewport === ViewportType.TABLET ? "2rem" : "1.25rem"};
`;

const GeneralInfo = ({
  loading,
  onDirtyChanged,
  formLoading,
  setFormLoading,
  unsavedEditsDialog,
  hideUnsavedEditsDialog,
}) => {
  const { totem } = useSelector((state) => state.totems);
  const { partner } = useSelector((state) => state.auth);
  const [initialValues, setInitialValues] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation("totems");
  useEffect(() => {
    if (totem) {
      const totemData = {
        totemId: totem.totem_id,
        totemName: totem.totem_name || "",
        licenseKey: partner.totems_license_key,
        totemActive: totem.totem_active,
        totemStatus: getTotemStatus(totem),
        totemStatusTooltip: getTotemStatusTooltip(totem),
        topImageLink: totem.top_image_link || "",
        topImageFile: totem.top_image_link
          ? {
              uploadState: FileState.UPLOADED,
              url: totem.top_image_link,
            }
          : {
              uploadState: FileState.NONE,
              url: null,
            },
        middleImageLink: totem.middle_image_link || "",
        middleImageFile: totem.middle_image_link
          ? {
              uploadState: FileState.UPLOADED,
              url: totem.middle_image_link,
            }
          : {
              uploadState: FileState.NONE,
              url: null,
            },
        bottomImageLink: totem.bottom_image_link || "",
        bottomImageFile: totem.bottom_image_link
          ? {
              uploadState: FileState.UPLOADED,
              url: totem.bottom_image_link,
            }
          : {
              uploadState: FileState.NONE,
              url: null,
            },
        fontColor: totem.font_color,
        backgroundColor: totem.background_color,
        progressBarBackground: totem.progressbar_background,
        progressBarFront: totem.progressbar_front,
        logoLink: totem.logo_link || "",
        logoFile: totem.logo_link
          ? {
              uploadState: FileState.UPLOADED,
              url: totem.logo_link,
            }
          : {
              uploadState: FileState.NONE,
              url: null,
            },
        productNameFontReversed: totem.product_name_font_reversed,
        qrCodes: totem.qr_codes,
        qrCodesTextLine1: totem.qr_codes_text?.split("\n")?.[0] || "",
        qrCodesTextLine2: totem.qr_codes_text?.split("\n")?.[1] || "",
        showWelcomeScreen: totem.show_welcome_screen,
        changeProductSeconds: totem.change_product_seconds.toString(),
        faceDistanceLimit: totem.face_distance_limit.toString(),
        hideTryonSeconds: totem.hide_tryon_seconds.toString(),
      };
      setInitialValues(totemData);
    }
  }, [totem, partner]);

  const handleSubmit = (values, form) => {
    setFormLoading(true);
    const editedTotem = {
      ...values,
      qrCodesText:
        values.qrCodesTextLine1 +
        (values.qrCodesTextLine2 ? "\n" + values.qrCodesTextLine2 : ""),
    };
    updateTotem(editedTotem).then((formCorrect) => {
      if (formCorrect) {
        dispatch(
          addNotification({
            variant: NotificationVariant.SUCCESS,
            message: t("common:form.savedSuccessfully"),
          })
        );
        getTotem(editedTotem.totemId).then(scrollToTop);
      }
      form.setSubmitting(false);
      setFormLoading(false);
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ resetForm, values }) => (
        <Form id="totem-general-info-form" noValidate>
          <WarningDialog
            open={unsavedEditsDialog.show}
            onClose={() => hideUnsavedEditsDialog()}
            onAction={() => {
              resetForm(initialValues);
              setTimeout(() => {
                unsavedEditsDialog?.action();
              }, [300]);
              hideUnsavedEditsDialog();
            }}
            name={t("unsavedEditsDialogTitle")}
            title={t("unsavedEditsDialogInfo")}
            warningButtonText={t("unsavedEditsDialogButton")}
          />
          <FormObserver
            onDirtyChanged={() =>
              onDirtyChanged(!valuesEqual(initialValues, values))
            }
            onValidationErrors={(errors) =>
              setTimeout(() => scrollToError(errors), 500)
            }
          />
          {loading ? (
            <Box width="100%" height="6rem">
              <Loader show={true} height={20} />
            </Box>
          ) : (
            <FormsContainer>
              <DetailsForm disabled={formLoading} />
              <WelcomeScreenForm disabled={formLoading} />
              <ColorsForm disabled={formLoading} />
              <TryOnScreenForm disabled={formLoading} />
            </FormsContainer>
          )}
        </Form>
      )}
    </Formik>
  );
};

GeneralInfo.propTypes = {
  loading: PropTypes.bool.isRequired,
  onDirtyChanged: PropTypes.func,
  formLoading: PropTypes.bool.isRequired,
  setFormLoading: PropTypes.func.isRequired,
  unsavedEditsDialog: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    action: PropTypes.func.isRequired,
  }).isRequired,
  hideUnsavedEditsDialog: PropTypes.func.isRequired,
};

GeneralInfo.defaultProps = {
  onDirtyChanged: () => {},
};

export default GeneralInfo;
