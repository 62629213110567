import React, { useMemo } from "react";
import Navigation from "common/Navigation";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { getActivePages } from "utils/PageUtils";
import { setPartner } from "components/auth/AuthActions";
import { useNavigate } from "react-router-dom";
import { logout } from "components/auth/AuthActions";
import RouterPaths from "constants/RouterPaths";
import GradientBackground from "common/basicComponents/GradientBackground";

const getNavigationPages = (partner, user) =>
  getActivePages(partner, user).filter((p) => p.showInNavigation);

const Main = () => {
  const navigate = useNavigate();
  const { partner, partners, user } = useSelector((state) => state.auth);
  const navigationPages = useMemo(
    () => getNavigationPages(partner, user),
    [partner, user]
  );

  const handlePartnerChanged = (partner) => {
    setPartner(partner);
    const defaultPage = getActivePages(partner, user).filter(
      (p) => p.isDefault
    );
    if (defaultPage) navigate(defaultPage.path);
  };

  const handlePageChanged = (page) => {
    navigate(page.path);
  };

  const handleLogout = () => {
    logout();
    navigate(RouterPaths.ROOT);
  };

  return (
    <Navigation
      pages={navigationPages}
      partners={partners}
      partner={partner}
      onPartnerChanged={handlePartnerChanged}
      onPageChanged={handlePageChanged}
      onLogout={handleLogout}
    >
      <GradientBackground />
      <Outlet />
    </Navigation>
  );
};

export default Main;
