import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { useFormikContext, useField } from "formik";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Icon from "common/basicComponents/Icon";
import { ButtonType } from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";
import { translate } from "utils/FormUtils";

export const OptionWidth = {
  GROW: "grow",
};

const getOptionsContainerStyle = (optionWidth) => {
  if (optionWidth === OptionWidth.GROW)
    return css`
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1.25rem;
    `;
  if (typeof optionWidth == "number")
    return css`
      display: grid;
      grid-gap: 1.25rem;
      grid-template-columns: repeat(auto-fill, minmax(${optionWidth}px, 1fr));
    `;
  return css`
    display: grid;
    grid-gap: 1.25rem;
    grid-template-columns: repeat(auto-fill, minmax(${optionWidth}, 1fr));
  `;
};

const getButtonColors = (theme, pseudo = "default") =>
  ({
    default: css`
      &:not(.selected) {
        border-width: 1px;
        border-image: ${theme.colors.gradient[20]} 1;
        background-image: ${theme.colors.gradient[20]};
        background-clip: text;
        -webkit-background-clip: text;
        color: black;
        & svg {
          fill: url(#gradient);
          opacity: 100%;
        }
      }

      &.selected {
        border-width: 1px;
        border-image: ${theme.colors.gradient[100]} 1;
        background-image: ${theme.colors.gradient[100]};
        & svg {
          color: ${theme.colors.white};
        }
      }
    `,
    disabled: css`
      &:not(.selected) {
        border: 1px solid ${theme.colors.grey[400]};
        background: ${theme.colors.grey[100]};
        color: ${theme.colors.grey[400]};
      }

      &.selected {
        border: none;
        background: ${theme.colors.grey[400]};
        color: ${theme.colors.white};
      }
    `,
    hover: css`
      &:not(.selected) {
        border-width: 1px;
        border-image: ${theme.colors.gradient[100]} 1;
        background-image: ${theme.colors.gradient[100]};
        background-clip: text;
        -webkit-background-clip: text;
        color: black;
      }
    `,
    active: css``,
  }[pseudo]);

const getOptionButtonSize = (viewport) =>
  ({
    [ViewportType.DESKTOP]: css`
      width: 3rem;
      min-width: 3rem;
      height: 3rem;
    `,
    [ViewportType.TABLET]: css`
      width: 3rem;
      min-width: 3rem;
      height: 3rem;
    `,
    [ViewportType.MOBILE]: css`
      width: 2.5rem;
      min-width: 2.5rem;
      height: 2.5rem;
    `,
  }[viewport]);

const getOptionWidthStyle = (width) => {
  if (width === OptionWidth.GROW)
    return css`
      flex-grow: 1;
      flex-basis: 0;
    `;
  if (typeof width == "number")
    return css`
      width: ${width}px;
    `;
  return css`
    width: ${width};
  `;
};

const CaptionContainer = styled.div`
  min-height: 1rem;
`;

const StyledLabel = styled.label`
  display: inline-block;
  width: 100%;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey[400] : theme.colors.black};
`;

const OptionsContainer = styled.div`
  width: 100%;
  ${({ optionWidth }) => getOptionsContainerStyle(optionWidth)};
`;

const StyledOption = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ viewport }) =>
    viewport > ViewportType.TABLET ? "1.25rem" : "0.625rem"};
  text-transform: capitalize;
  padding: 0.5rem 0;
  ${({ width }) => getOptionWidthStyle(width)};

  & h6 {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.grey[400] : theme.colors.black};
  }
`;

const OptionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ viewport }) => getOptionButtonSize(viewport)}
  ${({ theme }) => getButtonColors(theme, "default")}

  &:hover {
    ${({ theme }) => getButtonColors(theme, "hover")}
  }

  &:disabled {
    ${({ theme }) => getButtonColors(theme, "disabled")};
  }
`;

const Option = ({
  label,
  icon,
  selected,
  onSelect,
  disabled,
  viewport,
  width,
}) => (
  <StyledOption viewport={viewport} width={width} disabled={disabled}>
    <OptionButton
      disabled={disabled}
      onClick={onSelect}
      type={ButtonType.BUTTON}
      className={selected && "selected"}
      viewport={viewport}
    >
      {icon && <Icon name={icon} size="1.5rem" />}
    </OptionButton>
    <Typography
      variant={TypographyVariant.SUBTITLE2}
      weight={selected ? TypographyWeight.BOLD : TypographyWeight.REGULAR}
      text={label}
    />
  </StyledOption>
);

Option.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  viewport: PropTypes.oneOf(Object.values(ViewportType)),
  width: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(OptionWidth)),
    PropTypes.number,
    PropTypes.string,
  ]),
};

Option.defaultProps = {
  label: "",
  icon: null,
  disabled: false,
  selected: false,
  onSelect: () => {},
  viewport: ViewportType.DESKTOP,
  width: OptionWidth.GROW,
};

const FormIconSelectField = ({
  name,
  label,
  caption,
  options,
  optionWidth,
  onChange,
  disabled,
  className,
  readOnly,
}) => {
  const { type: viewportType } = useViewport();
  const { isSubmitting, submitCount } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const error =
    submitCount > 0 && meta.touched && meta.error ? translate(meta.error) : "";

  const handleSelect = (option) => {
    helpers.setValue(option);
    onChange(option);
  };

  useEffect(() => {
    if (!field.value && options.length > 0) helpers.setValue(options[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <div className={className}>
      <StyledLabel disabled={isSubmitting || disabled}>
        {label && (
          <Typography
            variant={TypographyVariant.BODY2}
            weight={TypographyWeight.SEMIBOLD}
            text={label}
            tag="span"
          />
        )}
      </StyledLabel>
      <OptionsContainer optionWidth={optionWidth}>
        {options
          .filter((o) => (readOnly ? field.value?.value === o.value : true))
          .map((o) => (
            <Option
              key={o.value}
              label={o.label}
              icon={o.icon}
              selected={field.value?.value === o.value}
              onSelect={() => handleSelect(o)}
              disabled={isSubmitting || readOnly || disabled}
              viewport={viewportType}
              width={optionWidth}
            />
          ))}
      </OptionsContainer>

      <CaptionContainer>
        {(caption || error) && (
          <Typography
            variant={TypographyVariant.CAPTION}
            weight={TypographyWeight.LIGHT}
            text={error || caption}
          />
        )}
      </CaptionContainer>
    </div>
  );
};

FormIconSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  caption: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
    })
  ),
  optionWidth: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(OptionWidth)),
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
};

FormIconSelectField.defaultProps = {
  label: "",
  caption: "",
  options: [],
  optionWidth: OptionWidth.GROW,
  onChange: () => {},
  disabled: false,
  className: "",
  readOnly: false,
};

export default FormIconSelectField;
