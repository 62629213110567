import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
  ButtonWidth,
} from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import { getProductTags } from "utils/ProductUtils";
import Chips from "common/basicComponents/Chips";
import Box from "common/basicComponents/Box";
import { IconName } from "common/basicComponents/Icon";

const Card = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1.25rem;
`;

const Thumb = styled.img`
  height: 4.5rem;
  width: 9rem;
  object-fit: contain;
  align-self: center;
`;

const ThumbPlaceholder = styled.div`
  height: 4.5rem;
  width: 9rem;
  align-self: center;
  text-transform: uppercase;
  text-align: center;
  line-height: 3.25rem;
  color: ${({ theme }) => theme.colors.grey[400]};
`;

const DataCell = styled.div`
  padding: 1.25rem 0;
  border-top: ${({ theme }) => "1px solid " + theme.colors.grey[100]};

  display: flex;
  justify-content: space-between;
  ${({ column }) => column && "flex-direction: column;"}
`;

const FieldName = styled(Typography)`
  padding-right: 1rem;
`;

const Tags = styled.div`
  min-height: 1.5rem;
  margin-top: 0.25rem;
  & > span {
    margin-right: 0.25rem;
  }
`;

const Title = styled(Typography)`
  text-align: center;
  padding: 1.25rem 0;
  word-wrap: break-word;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
`;

const ProductSmallCard = ({
  data,
  user,
  partner,
  onEdit,
  enableReorder,
  moveUpEnabled,
  onMoveUp,
  moveDownEnabled,
  onMoveDown,
}) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("product");

  const externalProduct =
    (!partner.main_partner && data.from_main_partner) ||
    data.from_other_partner;

  return (
    <Card viewport={viewportType}>
      {data.product_thumb_link ? (
        <Thumb src={data.product_thumb_link} />
      ) : (
        <ThumbPlaceholder>{t("common:notAdded")}</ThumbPlaceholder>
      )}

      <Title
        variant={TypographyVariant.H5}
        weight={TypographyWeight.BOLD}
        text={data.product_name}
      />
      <DataCell>
        <FieldName
          variant={TypographyVariant.BODY2}
          weight={TypographyWeight.BOLD}
          text={t("publishedVersions")}
        />
        <div>
          <Typography
            variant={TypographyVariant.BODY2}
            text={
              externalProduct ? data.assigned_versions : data.published_versions
            }
            tag="span"
          />
          <Typography
            variant={TypographyVariant.BODY2}
            weight={TypographyWeight.BOLD}
            text={` / ${
              externalProduct ? data.published_versions : data.all_versions
            }`}
            tag="span"
          />
        </div>
      </DataCell>
      <DataCell column>
        <FieldName
          variant={TypographyVariant.BODY2}
          weight={TypographyWeight.BOLD}
          text={t("info")}
        />
        <Tags>
          {getProductTags(user, partner, data).map(
            (tag) =>
              tag.show && (
                <Chips key={tag.text} variant={tag.variant} text={tag.text} />
              )
          )}
        </Tags>
      </DataCell>
      <Buttons>
        {enableReorder && (
          <>
            <Box padding="0 0.625rem 0 0">
              <Button
                icon={IconName.UP_ARROW}
                variant={ButtonVariant.OUTLINED}
                size={ButtonSize.SMALL}
                onClick={onMoveUp}
                disabled={!moveUpEnabled}
              />
            </Box>
            <Box padding="0 1.25rem 0 0">
              <Button
                icon={IconName.DOWN_ARROW}
                variant={ButtonVariant.OUTLINED}
                size={ButtonSize.SMALL}
                onClick={onMoveDown}
                disabled={!moveDownEnabled}
              />
            </Box>
            <Button
              text={t("common:button.edit")}
              variant={ButtonVariant.CONTAINED}
              size={ButtonSize.SMALL}
              onClick={() => onEdit(data)}
              width={ButtonWidth.GROW}
            />
          </>
        )}
      </Buttons>
    </Card>
  );
};

ProductSmallCard.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  enableReorder: PropTypes.bool,
  moveUpEnabled: PropTypes.bool,
  onMoveUp: PropTypes.func,
  moveDownEnabled: PropTypes.bool,
  onMoveDown: PropTypes.func,
};

ProductSmallCard.defaultProps = {
  enableReorder: false,
  moveUpEnabled: false,
  onMoveUp: () => {},
  moveDownEnabled: false,
  onMoveDown: () => {},
};

export default ProductSmallCard;
