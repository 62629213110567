import * as Yup from "yup";

export default () =>
  Yup.object().shape({
    text: Yup.string()
      .required("common:validator.required")
      .max(50, { code: "common:validator.maxChars", params: { max: 50 } }),
    price: Yup.string().required("common:validator.required"),
    link: Yup.string().required("common:validator.required"),
    number: Yup.number().required("common:validator.required"),
    multiline: Yup.string().required("common:validator.required"),
    limit: Yup.string().required("common:validator.required"),
    color: Yup.object()
      .shape({
        r: Yup.number().min(0).max(255).required("common:validator.required"),
        g: Yup.number().min(0).max(255).required("common:validator.required"),
        b: Yup.number().min(0).max(255).required("common:validator.required"),
      })
      .nullable(),
    select: Yup.object()
      .shape({
        value: Yup.string(),
      })
      .nullable(),
    select_multi: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string(),
        })
      )
      .min(1, "common:validator.required")
      .required("common:validator.required"),
  });
