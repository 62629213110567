import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import backgroundTablet from "assets/background/background_tablet.jpg";
import backgroundMobile from "assets/background/background_mobile.jpg";
import backgroundDesktop from "assets/background/background_desktop.jpg";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";

export const BackgroundSize = {
  DESKTOP: ViewportType.DESKTOP,
  TABLET: ViewportType.TABLET,
  MOBILE: ViewportType.MOBILE,
};

const getBackground = (type) =>
  ({
    [ViewportType.DESKTOP]: backgroundDesktop,
    [ViewportType.TABLET]: backgroundTablet,
    [ViewportType.MOBILE]: backgroundMobile,
  }[type]);

const StyledBackground = styled.div`
  background-image: url(${({ type }) => getBackground(type)});
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  position: absolute;
  inset: 0;
  z-index: -1;
`;

const GradientBackground = ({ size, className }) => {
  const { type } = useViewport();
  return <StyledBackground type={size || type} className={className} />;
};

GradientBackground.propTypes = {
  size: PropTypes.oneOf(Object.values(BackgroundSize)),
  className: PropTypes.string,
};

GradientBackground.defaultProps = {
  size: null,
  className: null,
};

export default GradientBackground;
