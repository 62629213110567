import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { REACT_APP_DEV_MODE } from "constants/env";
import pl from "./pl.json";
import en from "./en.json";

const options = {
  debug: REACT_APP_DEV_MODE,
  defaultNS: "common",
  fallbackLng: "en",
  resources: {
    pl,
    en,
  },
  supportedLngs: ["pl", "en"],
  nonExplicitSupportedLngs: true,
  detection: {
    order: ["navigator", "cookie", "localStorage", "sessionStorage"],
  },
  interpolation: {
    escapeValue: false,
  },
  returnObjects: true,
};

export function getCurrentLocale() {
  return i18n.language || window.localStorage.i18nextLng;
}

i18n.use(LanguageDetector).use(initReactI18next).init(options);

export default i18n;
