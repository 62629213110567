import store from "redux/store";

import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import { setPushNotifications } from "./PushNotificationsSlice";

export const getPushNotifications = (params) => {
  return apiClient
    .get(ApiEndpoints.NOTIFICATIONS, { params })
    .then((res) => {
      const pushNotifications = res?.data?.body;
      if (!pushNotifications) return false;
      store.dispatch(setPushNotifications(pushNotifications));
      return true;
    })
    .catch(() => false);
};

export const addPushNotifications = (pushNotifications) => {
  return apiClient
    .post(ApiEndpoints.NOTIFICATIONS, pushNotifications)
    .then(() => true)
    .catch(() => false);
};

export const deletePushNotifications = (pushNotifications) => {
  return apiClient
    .delete(`${ApiEndpoints.NOTIFICATIONS}${pushNotifications.id}`)
    .then(() => true)
    .catch(() => false);
};
