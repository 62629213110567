import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import { TextField, MenuItem } from "@mui/material";

import Icon, { IconName } from "../Icon";
import Button, { ButtonSize, ButtonType, ButtonVariant } from "../Button";

export const DialogVariant = {
  MOBILE: "mobile",
  DESKTOP: "desktop",
};

export const DateInputSize = {
  NORMAL: "normal",
  LARGE: "large",
};

const getInputColors = (theme, error, pseudo = "default") => {
  if (error) {
    return css`
      border: 1px solid ${theme.colors.secondary[600]};
      background: ${theme.colors.white};
      color: ${pseudo === "active"
        ? theme.colors.secondary[200]
        : theme.colors.black};
      outline: none;
      caret-color: ${theme.colors.secondary[200]};
    `;
  }
  return {
    default: css`
      background: ${theme.colors.white};
      border: 1px solid;
      border-image: ${theme.colors.gradient[20]} 1;
      color: ${theme.colors.black};
      outline: none;
      caret-color: ${theme.colors.black};
    `,
    disabled: css`
      border: 1px solid ${theme.colors.grey[300]};
      background: ${theme.colors.grey[50]};
      color: ${theme.colors.grey[500]};
      border-image: none;
    `,
    hover: css`
      background: ${theme.colors.white};
      border-image: ${theme.colors.gradient[60]} 1;
      color: ${theme.colors.black};
    `,
    active: css`
      background: ${theme.colors.white};
      border-image: ${theme.colors.gradient[100]} 1;
      color: ${theme.colors.black};
    `,
  }[pseudo];
};

const getInputStyle = (size) =>
  ({
    [DateInputSize.NORMAL]: css`
      line-height: 1.25rem;
      font-size: 0.875rem;
      padding: 0.5rem 0.75rem;
      height: 2.5rem;
    `,
    [DateInputSize.LARGE]: css`
      line-height: 1.5rem;
      font-size: 1rem;
      padding: 0.625rem 0.75rem;
      height: 3rem;
    `,
  }[size]);

export const StyledPicker = styled(DateTimePicker)`
  width: 100%;

  .MuiInputBase-root {
    ${({ theme, error }) => getInputColors(theme, error, "default")};
    ${({ size }) => getInputStyle(size)}

    &::placeholder {
      color: ${({ theme }) => theme.colors.secondary[200]};
      opacity: 1;
    }

    display: block;
    width: 100%;
    margin: 0.25rem 0;
    resize: none;
    font-family: "Open Sans", sans-serif;
    border-radius: 0;

    &:hover {
      ${({ theme, error }) => getInputColors(theme, error, "hover")};
    }

    &:focus,
    &:focus-visible {
      ${({ theme, error }) => getInputColors(theme, error, "active")}
    }
  }
  .MuiInputBase-root.Mui-disabled {
    ${({ theme, error }) => getInputColors(theme, error, "disabled")};
  }
  .MuiInputBase-root fieldset {
    border: 0;
  }

  .MuiInputBase-input {
    padding: 0;
    &::placeholder {
      color: transparent;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const StyledLabel = styled.label`
  width: 100%;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey[500] : theme.colors.black};
  .label-text {
    display: inline-block;
  }
`;

export const ErrorContainer = styled.div`
  min-height: 1rem;
`;

export const InputContainer = styled.div`
  position: relative;
  & button {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto 0.75rem auto 0.5rem;
  }
`;

export const StyledDay = styled(PickersDay)`
  &.MuiPickersDay-root {
    font-family: "Open Sans", sans-serif;
    border-radius: 0;
    background: ${({ theme }) => theme.colors.white};
    :hover {
      background: ${({ theme }) => theme.colors.grey[200]};
    }
    :active {
      background: ${({ theme }) => theme.colors.grey[300]};
    }
  }
  &.MuiPickersDay-root.Mui-selected {
    background: black;
    :hover {
      background: black;
    }
    :focus {
      background: black;
    }
  }
  & .MuiTouchRipple-root {
    display: none;
  }
`;

export const StyledClockItem = styled(MenuItem)`
  &.MuiMenuItem-root {
    :hover {
      background: ${({ theme }) => theme.colors.grey[200]};
    }
    :active {
      background: ${({ theme }) => theme.colors.grey[300]};
    }
  }
  &.MuiMenuItem-root.Mui-selected {
    color: white;
    background: ${({ theme }) => theme.colors.black};
    :hover {
      background: ${({ theme }) => theme.colors.black};
    }
    :focus {
      background: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const StyledPopper = styled(Popper)`
  z-index: 3;
  .MuiPaper-root {
    border-radius: 0;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.black};
    box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
  }
  .MuiTypography-root {
    font-family: "Open Sans", sans-serif;
  }

  .MuiPickersCalendarHeader-root {
    margin-top: 2rem;
  }

  .MuiPickersCalendarHeader-label {
    font-family: "Open Sans", sans-serif;
  }

  .MuiPickersYear-yearButton {
    font-family: "Open Sans", sans-serif;
    border-radius: 0;
    background: ${({ theme }) => theme.colors.white};
    :hover {
      background: ${({ theme }) => theme.colors.grey[200]};
    }
    :active {
      background: ${({ theme }) => theme.colors.grey[300]};
    }
  }
  .MuiPickersYear-yearButton.Mui-selected {
    background: black;
    :hover {
      background: black;
    }
    :focus {
      background: black;
    }
  }
`;

export const StyledPaper = styled(Paper)`
  &.MuiPaper-root {
    border-radius: 0;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.black};
    box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
  }

  .MuiButtonBase-root:hover {
    background-color: ${({ theme }) => theme.colors.grey[100]};
    border-radius: 0;
  }

  .MuiTypography-root {
    font-family: "Open Sans", sans-serif;
  }

  .MuiPickersLayout-toolbar {
    padding-top: 2rem;
  }

  .MuiPickersCalendarHeader-label {
    font-family: "Open Sans", sans-serif;
  }

  .MuiPickersYear-yearButton {
    font-family: "Open Sans", sans-serif;
    border-radius: 0;
    background: ${({ theme }) => theme.colors.white};
    :hover {
      background: ${({ theme }) => theme.colors.grey[200]};
    }
    :active {
      background: ${({ theme }) => theme.colors.grey[300]};
    }
  }
  .MuiPickersYear-yearButton.Mui-selected {
    background: black;
    :hover {
      background: black;
    }
    :focus {
      background: black;
    }
  }

  .MuiTabs-root {
    box-shadow: none;
  }

  .MuiTab-root {
    color: ${({ theme }) => theme.colors.black};
    border-bottom: 2px solid ${({ theme }) => theme.colors.grey[200]};
  }

  .MuiTab-root.Mui-selected {
    color: ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.grey[200]};
    border-bottom: 2px solid ${({ theme }) => theme.colors.black};
  }

  .MuiClock-pin,
  .MuiClockPointer-root {
    background-color: ${({ theme }) => theme.colors.primary[700]};
  }

  .MuiClockPointer-thumb {
    border: 16px solid ${({ theme }) => theme.colors.primary[700]};
  }

  .MuiTabs-indicator {
    display: none;
  }

  .MuiTouchRipple-root {
    display: none;
  }
`;

const StyledActionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 1.25rem 2.5rem;
  gap: 1.25rem;

  button {
    flex-grow: 1;
    flex-basis: 0;
  }
`;

export const CustomActionList = ({
  onAccept,
  onCancel,
  className,
  variant,
}) => {
  const { t } = useTranslation();
  if (variant === DialogVariant.DESKTOP) return <></>;
  return (
    <StyledActionContainer className={className}>
      <Button
        type={ButtonType.BUTTON}
        variant={ButtonVariant.GREEN}
        size={ButtonSize.SMALL}
        text={t("button.ok")}
        onClick={onAccept}
      />
      <Button
        type={ButtonType.BUTTON}
        variant={ButtonVariant.OUTLINED}
        size={ButtonSize.SMALL}
        text={t("button.cancel")}
        onClick={onCancel}
      />
    </StyledActionContainer>
  );
};

CustomActionList.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(Object.values(DialogVariant)),
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
CustomActionList.defaultProps = {
  className: null,
  variant: DialogVariant.DESKTOP,
};

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 1rem;
  margin-top: 0.75rem;
`;

export const CustomTextField = (params) => {
  const inputProps =
    params.ownerState.InputProps || params.ownerState.inputProps;
  return (
    <div style={{ position: "relative" }}>
      <TextField {...params} />
      {!inputProps.endAdornment && (
        <StyledIcon name={IconName.CALENDAR} size="1.5rem" />
      )}
    </div>
  );
};
