import { createSlice } from "@reduxjs/toolkit";
import { clearAuthData, setCurrentPartner } from "components/auth/AuthSlice";

const initialState = {
  allProducts: [],
  products: [],
  productsTotal: null,
  search: {},
  product: null,
  productLog: null,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    appendProducts: (state, action) => {
      if (state.products.length != action.payload.meta?.offset) return;
      state.products = state.products.concat(action.payload.products);
      state.productsTotal = Number(action.payload.meta?.count);
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setSearch: (state, action) => {
      state.products = [];
      state.productsTotal = null;
      state.search = action.payload;
    },
    setAllProducts: (state, action) => {
      state.allProducts = action.payload;
    },
    clearProducts: () => initialState,
    setProduct: (state, action) => {
      state.product = action.payload;
    },
    setProductLog: (state, action) => {
      state.productLog = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
    builder.addCase(setCurrentPartner, () => initialState);
  },
});

export const {
  appendProducts,
  setSearch,
  setProducts,
  setAllProducts,
  clearProducts,
  setProduct,
  setProductLog,
} = productSlice.actions;
export default productSlice.reducer;
