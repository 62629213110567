import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

export const CheckboxSize = {
  SMALL: "small",
  NORMAL: "normal",
};

export const CheckboxType = {
  CONTAINED: "contained",
  OUTLINED: "outlined",
};

const getCheckboxColors = (theme, type, pseudo, checked) =>
  ({
    [CheckboxType.OUTLINED]: {
      default: checked
        ? css`
            border-image: ${theme.colors.gradient[100]} 1;
            background: ${theme.colors.white};
            &:after {
              border-color: ${theme.colors.black};
            }
          `
        : css`
            background: linear-gradient(${theme.colors.white} 0 0) padding-box,
              ${theme.colors.gradient[20]} border-box;
            border: 1px solid transparent;
          `,
      hover: checked
        ? css`
            border-image: ${theme.colors.gradient[60]} 1;
          `
        : css`
            background: linear-gradient(${theme.colors.white} 0 0) padding-box,
              ${theme.colors.gradient[60]} border-box;
          `,
      disabled: checked
        ? css`
            border: 1px solid ${theme.colors.grey[300]};
          `
        : css`
            background: ${theme.colors.white};
            border: 1px solid ${theme.colors.grey[300]};
          `,
    },

    [CheckboxType.CONTAINED]: {
      default: checked
        ? css`
            border-image: ${theme.colors.gradient[100]} 1;
            background-image: ${theme.colors.gradient[100]};
            &:after {
              border-color: ${theme.colors.white};
            }
          `
        : css`
            background: linear-gradient(${theme.colors.white} 0 0) padding-box,
              ${theme.colors.gradient[20]} border-box;
            border: 1px solid transparent;
          `,
      hover: checked
        ? css`
            border-image: ${theme.colors.gradient[80]} 1;
            background-image: ${theme.colors.gradient[80]};
          `
        : css`
            background: linear-gradient(${theme.colors.white} 0 0) padding-box,
              ${theme.colors.gradient[60]} border-box;
          `,
      disabled: checked
        ? css`
            border: 1px solid ${theme.colors.grey[300]};
            background: ${theme.colors.grey[300]};
          `
        : css`
            background: ${theme.colors.white};
            border: 1px solid ${theme.colors.grey[300]};
          `,
    },
  }[type][pseudo]);

const StyledCheckbox = styled.input`
  appearance: none;
  cursor: pointer;
  position: relative;
  width: ${({ size }) => (size === CheckboxSize.SMALL ? "1rem" : "1.25rem")};
  min-width: ${({ size }) =>
    size === CheckboxSize.SMALL ? "1rem" : "1.25rem"};
  height: ${({ size }) => (size === CheckboxSize.SMALL ? "1rem" : "1.25rem")};

  ${({ theme, UIType }) => getCheckboxColors(theme, UIType, "default", false)}

  &:checked {
    ${({ theme, UIType }) => getCheckboxColors(theme, UIType, "default", true)}
    &:after {
      display: block;
      position: absolute;
      top: ${({ size }) =>
        size === CheckboxSize.SMALL ? "0.0625rem" : "0.1875rem"};
      left: ${({ size }) =>
        size === CheckboxSize.SMALL ? "0.25rem" : "0.375rem"};
      content: "";
      width: 0.25rem;
      height: 0.4375rem;
      border-width: 0 0.15rem 0.15rem 0;
      border-style: solid;
      transform: rotate(45deg);
    }
  }

  &:hover:checked {
    ${({ theme, UIType }) => getCheckboxColors(theme, UIType, "hover", true)}
  }

  &:hover:not(:checked) {
    ${({ theme, UIType }) => getCheckboxColors(theme, UIType, "hover", false)}
  }

  &:disabled:checked {
    ${({ theme, UIType, readOnly }) =>
      getCheckboxColors(theme, UIType, readOnly ? "default" : "disabled", true)}
  }

  &:disabled:not(:checked) {
    ${({ theme, UIType, readOnly }) =>
      getCheckboxColors(
        theme,
        UIType,
        readOnly ? "default" : "disabled",
        false
      )}
  }
`;

const Checkbox = ({ id, disabled, readOnly, type, size, value, onChange }) => {
  const handleClick = (value) => {
    onChange(value);
  };

  return (
    <StyledCheckbox
      type="checkbox"
      checked={value}
      id={id}
      readOnly={readOnly}
      disabled={disabled || readOnly}
      onChange={() => handleClick(!value)}
      UIType={type}
      size={size}
    />
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(CheckboxType)),
  size: PropTypes.oneOf(Object.values(CheckboxSize)),
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  value: true,
  readOnly: false,
  disabled: false,
  type: CheckboxType.CONTAINED,
  size: CheckboxType.NORMAL,
  onChange: () => {},
};

export default Checkbox;
