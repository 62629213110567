import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";

import ReportRow from "./ReportRow";
import Table from "common/Table";

const ImportReportTable = ({ report, loading }) => {
  const { t } = useTranslation("import");
  const { colors } = useTheme();

  const headers = [
    {
      text: t("status"),
    },
    {
      text: t("productCount"),
    },
  ];

  return (
    <Table
      headers={headers}
      data={report}
      total={report.length}
      tableRow={ReportRow}
      keyPropertyName="status"
      loading={loading}
      dividerColor={colors.grey[200]}
    />
  );
};

ImportReportTable.propTypes = {
  report: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ImportReportTable;
