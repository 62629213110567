import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";

const Card = styled.div`
  min-width: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "40%" : "100%"};
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const FieldLabel = styled(Typography)`
  text-align: left;
  word-wrap: break-word;
  padding-top: 1rem;
  ${({ theme, showDivider }) =>
    showDivider && "border-top: 1px solid" + theme.colors.grey[200]};
`;

const FieldValue = styled(Typography)`
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
  word-wrap: break-word;
  padding-bottom: 1rem;
`;

const ButtonsContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ApplicationLinkCard = ({ data, disabled, onEdit, onDelete }) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("applicationLinks");
  return (
    <Card viewport={viewportType}>
      <FieldLabel
        text={t("link")}
        variant={TypographyVariant.BODY2}
        weight={TypographyWeight.BOLD}
        tag="span"
        showDivider={false}
      />
      <FieldValue
        text={data.url}
        weight={TypographyWeight.BOLD}
        variant={TypographyVariant.SUBTITLE2}
      />
      <FieldLabel
        text={t("text")}
        variant={TypographyVariant.BODY2}
        weight={TypographyWeight.BOLD}
        tag="span"
        showDivider={true}
      />
      <FieldValue text={data.text} variant={TypographyVariant.SUBTITLE2} />
      <ButtonsContainer>
        <Button
          text={t("common:button.edit")}
          variant={ButtonVariant.CONTAINED}
          size={ButtonSize.SMALL}
          onClick={() => onEdit(data)}
          disabled={disabled}
        />
        <Button
          text={t("common:button.delete")}
          variant={ButtonVariant.ERROR}
          size={ButtonSize.SMALL}
          onClick={() => onDelete(data)}
          disabled={disabled}
        />
      </ButtonsContainer>
    </Card>
  );
};

ApplicationLinkCard.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ApplicationLinkCard.defaultProps = {
  disabled: false,
};

export default ApplicationLinkCard;
