import { FileState } from "common/formFields/FormFileField";
import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    name: Yup.string()
      .required("common:validator.required")
      .max(50, { code: "common:validator.maxChars", params: { max: 50 } }),
    partnerCurrency: Yup.object()
      .shape({
        value: Yup.string().required("common:validator.required"),
      })
      .required("common:validator.required"),
    partnerAppStoreLink: Yup.string()
      .max(200, {
        code: "common:validator.maxChars",
        params: { max: 200 },
      })
      .nullable(),
    partnerPlayStoreLink: Yup.string()
      .max(200, {
        code: "common:validator.maxChars",
        params: { max: 200 },
      })
      .nullable(),
    partnerDefaultUrl: Yup.string()
      .max(200, {
        code: "common:validator.maxChars",
        params: { max: 200 },
      })
      .nullable(),
    partnerAppPrefix: Yup.string()
      .max(50, {
        code: "common:validator.maxChars",
        params: { max: 50 },
      })
      .required("common:validator.required"),
    bundleId: Yup.string()
      .max(50, {
        code: "common:validator.maxChars",
        params: { max: 50 },
      })
      .nullable(),
    packageName: Yup.string()
      .max(50, {
        code: "common:validator.maxChars",
        params: { max: 50 },
      })
      .nullable(),
    rotationIOS: Yup.number().required("common:validator.required"),
    rotationAndroid: Yup.number().required("common:validator.required"),
    rotationWeb: Yup.number().required("common:validator.required"),
    aspectRatioIOS: Yup.number().required("common:validator.required"),
    aspectRatioAndroid: Yup.number().required("common:validator.required"),
    aspectRatioWeb: Yup.number().required("common:validator.required"),
    positionYIOS: Yup.number().required("common:validator.required"),
    positionYAndroid: Yup.number().required("common:validator.required"),
    positionYWeb: Yup.number().required("common:validator.required"),
    positionZIOS: Yup.number().required("common:validator.required"),
    positionZAndroid: Yup.number().required("common:validator.required"),
    positionZWeb: Yup.number().required("common:validator.required"),
    thumbObjectScale: Yup.number().required("common:validator.required"),
    notificationsActive: Yup.bool().required("common:validator.required"),
    notificationsLimit: Yup.number()
      .nullable()
      .test(
        "push_limit_required_when_active",
        "common:validator.required",
        (value, context) => !context.parent.notificationsActive || value != null
      ),
    webActive: Yup.bool().required("common:validator.required"),
    qrcodesActive: Yup.bool().required("common:validator.required"),
    mobileActive: Yup.bool().required("common:validator.required"),
    totemActive: Yup.bool().required("common:validator.required"),
    importActive: Yup.bool().required("common:validator.required"),
    organizingProducts: Yup.bool().required("common:validator.required"),
    versionsGrouped: Yup.bool().required("common:validator.required"),
    pdMeasurementActive: Yup.bool().required("common:validator.required"),
    googleAnalyticsActive: Yup.bool().required("common:validator.required"),
    googleAnalyticsKey: Yup.string()
      .max(50, {
        code: "common:validator.maxChars",
        params: { max: 50 },
      })
      .nullable()
      .test(
        "ga_key_required_when_active",
        "common:validator.required",
        (value, context) =>
          !context.parent.googleAnalyticsActive || value != null
      ),
    analyticsDashboardActive: Yup.bool().required("common:validator.required"),
    marketplaceVisible: Yup.bool().required("common:validator.required"),
    billingActive: Yup.bool().required("common:validator.required"),
    privacyPolicyLink: Yup.string()
      .max(200, {
        code: "common:validator.maxChars",
        params: { max: 200 },
      })
      .nullable()
      .test(
        "oneOfManyRequired",
        "common:validator.oneOfManyRequired",
        (value, context) =>
          context.parent.privacyPolicyLink ||
          context.parent.privacyPolicyContents
      ),
    privacyPolicyContents: Yup.string()
      .nullable()
      .test(
        "oneOfManyRequired",
        "common:validator.oneOfManyRequired",
        (value, context) =>
          context.parent.privacyPolicyLink ||
          context.parent.privacyPolicyContents
      ),
    privacyPolicyFromEditor: Yup.bool().required("common:validator.required"),
    numberOfTotems: Yup.number()
      .nullable()
      .test(
        "totem_count_required_when_active",
        "common:validator.required",
        (value, context) => !context.parent.totemActive || value != null
      ),
    totemsLicenseExpirationDate: Yup.date()
      .nullable()
      .test(
        "totem_exp_date_required_when_active",
        "common:validator.required",
        (value, context) => !context.parent.totemActive || value != null
      ),
    webUsageRemaindersActive: Yup.bool().required("common:validator.required"),
    webRequestsLimit: Yup.number()
      .nullable()
      .test(
        "web_limit_required_when_active",
        "common:validator.required",
        (value, context) =>
          !context.parent.webUsageRemaindersActive || value != null
      ),
    types: Yup.array()
      .of(
        Yup.object()
          .shape({
            value: Yup.string().required("common:validator.required"),
          })
          .required("common:validator.required")
      )
      .min(1, "common:validator.required"),
    domains: Yup.array().of(
      Yup.object().shape({
        domainUrl: Yup.string()
          .required("common:validator.required")
          .max(200, {
            code: "common:validator.maxChars",
            params: { max: 200 },
          }),
      })
    ),
    appLinks: Yup.array().of(
      Yup.object().shape({
        url: Yup.string()
          .required("common:validator.required")
          .max(250, {
            code: "common:validator.maxChars",
            params: { max: 250 },
          }),
        text: Yup.string()
          .required("common:validator.required")
          .max(250, {
            code: "common:validator.maxChars",
            params: { max: 250 },
          }),
      })
    ),
    effects: Yup.array().of(
      Yup.object().shape({
        name: Yup.string()
          .required("common:validator.required")
          .max(50, { code: "common:validator.maxChars", params: { max: 50 } }),
        active: Yup.bool().required("common:validator.required"),
        fbxFile: Yup.object({
          uploadState: Yup.string().test(
            "is_uploaded",
            "common:validator.required",
            (value) =>
              [FileState.UPLOADED, FileState.REPLACED, FileState.NEW].includes(
                value
              )
          ),
        }).required("common:validator.required"),
      })
    ),
  });
};
