import { cloneDeep, isEmpty } from "lodash";

import PartnersGroup from "constants/PartnersGroup";
import { InitialFileState } from "common/formFields/FormFileField";
import { FileState } from "common/formFields/FormFileField";
import { typesToSelect } from "utils/TypeUtils";
import { currencyIsPrefix } from "constants/CurrencyOptions";
import { REACT_APP_API_URL } from "constants/env";

const PRIVACY_LINK = "/privacy/";

export const currenciesToSelect = (currencies) =>
  Object.keys(currencies).map((key) => ({
    label: currencies[key],
    value: currencies[key],
  }));

const findChangedDomains = (oldList, newList) => {
  return {
    new: newList.filter(
      (newDomain) =>
        !oldList.find((domain) => domain.domainId === newDomain.domainId)
    ),
    updated: newList.filter((newDomain) =>
      oldList.some(
        (oldDomain) =>
          oldDomain.domainId === newDomain.domainId &&
          oldDomain.domainUrl !== newDomain.domainUrl
      )
    ),
    deleted: oldList.filter(
      (oldDomain) =>
        !newList.some((newDomain) => newDomain.domainId === oldDomain.domainId)
    ),
  };
};

const effectChanged = (oldEffect, newEffect) => {
  return (
    newEffect.name !== oldEffect.name ||
    newEffect.active !== oldEffect.active ||
    [FileState.NEW, FileState.REPLACED, FileState.DELETED].includes(
      newEffect.fbxFile.uploadState
    )
  );
};

const findChangedEffects = (oldList, newList) => {
  return {
    new: newList.filter((newEffect) => !newEffect.id),
    updated: newList.filter((newEffect) =>
      oldList.some(
        (oldEffect) =>
          oldEffect.id === newEffect.id && effectChanged(oldEffect, newEffect)
      )
    ),
    deleted: oldList.filter(
      (oldEffect) => !newList.some((newEffect) => oldEffect.id === newEffect.id)
    ),
  };
};

const linkChanged = (oldLink, newLink) => {
  return oldLink.url !== newLink.url || oldLink.text !== newLink.text;
};

const findChangedAppLinks = (oldList, newList) => {
  return {
    new: newList.filter((newLink) =>
      oldList.every((oldLink) => newLink.id !== oldLink.id)
    ),
    updated: newList.filter((newLink) =>
      oldList.some(
        (oldLink) => oldLink.id === newLink.id && linkChanged(oldLink, newLink)
      )
    ),
    deleted: oldList.filter(
      (oldLink) => !newList.some((newLink) => oldLink.id === newLink.id)
    ),
  };
};

const domainsToValues = (domains) =>
  domains?.map((d) => ({
    domainId: d.domain_id,
    domainUrl: d.domain_url,
  }));

const domainsToPayload = (domains) =>
  domains.map((d) => ({
    domain_id: d.domainId,
    domain_url: d.domainUrl,
  }));

const appLinksToValues = (appLinks) =>
  appLinks?.map((a) => ({
    id: a.app_link_id,
    url: a.app_link_url,
    text: a.app_link_text,
  }));

const effectsToValues = (effects) =>
  effects?.map((e) => ({
    id: e.id,
    name: e.effect_name,
    active: e.effect_active,
    fbxFile: {
      ...InitialFileState,
      uploadState: e.effect_link
        ? FileState.UPLOADED
        : InitialFileState.uploadState,
      url: e.effect_link || InitialFileState.url,
    },
    fbxLink: e.effect_link,
  }));

const getAssignedTypes = (types) =>
  types.filter((type) => type.type_show === true);

const findChangedTypes = (oldList, newList) => {
  return {
    new: newList.filter(
      (newType) => !oldList.find((oldType) => oldType.value === newType.value)
    ),
    deleted: oldList.filter(
      (oldType) => !newList.find((newType) => newType.value === oldType.value)
    ),
  };
};

export const partnerDataToValues = (data) => {
  return {
    id: data?.id || null,
    name: data?.partner_name || null,
    partnerAPIKey: data?.partner_api_key || null,
    partnerWebAppKey: data?.webapp_api_key || null,
    partnerAppKey: data?.app_api_key || null,
    totemsApiKey: data?.totems_api_key || null,
    notificationsAppId: data?.notifications_app_id || null,
    notificationsKey: data?.notifications_key || null,
    googleAnalyticsKey: data?.google_analytics_key || null,
    partnerCurrency: data?.partner_currency
      ? {
          label: data.partner_currency,
          value: data.partner_currency,
        }
      : null,
    partnerAppStoreLink: data?.partner_app_store_link || null,
    partnerPlayStoreLink: data?.partner_play_store_link || null,
    partnerDefaultUrl: data?.partner_default_url || null,
    partnerAppPrefix: data?.partner_app_prefix || null,
    bundleId: data?.app_bundle_id || null,
    packageName: data?.app_package_name || null,
    rotationIOS: data?.rotation_ios?.toString() || "0",
    rotationAndroid: data?.rotation_android?.toString() || "0",
    rotationWeb: data?.rotation_web?.toString() || "0",
    aspectRatioIOS: data?.aspect_ratio_ios?.toString() || "1",
    aspectRatioAndroid: data?.aspect_ratio_android?.toString() || "1",
    aspectRatioWeb: data?.aspect_ratio_web?.toString() || "1",
    positionYIOS: data?.position_y_ios?.toString() || "0",
    positionYAndroid: data?.position_y_android?.toString() || "0",
    positionYWeb: data?.position_y_web?.toString() || "0",
    positionZIOS: data?.position_z_ios?.toString() || "0",
    positionZAndroid: data?.position_z_android?.toString() || "0",
    positionZWeb: data?.position_z_web?.toString() || "0",
    thumbObjectScale: data?.thumb_object_scale?.toString() || "1",
    notificationsActive: data?.notifications_active || false,
    notificationsLimit: data?.notifications_limit?.toString() || null,
    totemsLicenseKey: data?.totems_license_key?.toString() || null,
    numberOfTotems: data?.number_of_totems?.toString() || null,
    numberOfTotemsActive: data?.number_of_totems_active?.toString() || null,
    webActive: data?.web_active || false,
    qrcodesActive: data?.qrcodes_active || false,
    mobileActive: data?.mobile_active || false,
    totemActive: data?.totem_active || false,
    importActive: data?.autocut_active || false,
    organizingProducts: data?.organizing_products || false,
    versionsGrouped: data?.versions_grouped || false,
    pdMeasurementActive: data?.pd_measurement_active || false,
    googleAnalyticsActive: data?.google_analytics_active || false,
    analyticsDashboardActive: data?.analytics_dashboard_active || false,
    marketplaceVisible: data?.marketplace_visible || false,
    billingActive: data?.billing_active || false,
    setsActive: data?.sets_active || false,

    types: typesToSelect(getAssignedTypes(data?.types || [])),
    allTypes: typesToSelect(data?.types || []),

    domains: domainsToValues(data?.domains || []),
    appLinks: appLinksToValues(data?.app_links || []),
    effects: effectsToValues(data?.effects || []),

    privacyPolicyLink: data?.partner_privacy_policy_link || null,
    privacyPolicyContents: data?.partner_privacy_policy_contents || "",
    privacyPolicyFromEditor:
      isEmpty(data.partner_privacy_policy_link) ||
      data.partner_privacy_policy_link ===
        createPrivacyLink(data.partner_app_prefix),

    totemsLicenseExpirationDate: data?.totems_license_expiration_date
      ? new Date(data.totems_license_expiration_date).toISOString()
      : null,

    webUsageRemaindersActive:
      data?.web_requests_usage?.web_usage_remainders_active || false,
    webRequestsLimit:
      data?.web_requests_usage?.web_requests_limit?.toString() || null,
  };
};

export const partnerValuesToPayload = (partner, values) => {
  const changedDomains = findChangedDomains(
    domainsToValues(partner.domains || []),
    values.domains
  );
  const changedEffects = findChangedEffects(
    effectsToValues(partner.effects || []),
    values.effects
  );
  const changedAppLinks = findChangedAppLinks(
    appLinksToValues(partner.app_links || []),
    values.appLinks
  );

  const changedTypes = findChangedTypes(
    typesToSelect(getAssignedTypes(partner.types || [])),
    values.types
  );

  return {
    id: values.id,
    name: values.name,
    notificationsAppId: values.notificationsAppId,
    notificationsKey: values.notificationsKey,
    googleAnalyticsKey: values.googleAnalyticsKey,

    panelLanguage: partner.panel_language || "en", // Deprecated
    partnerCurrency: values.partnerCurrency.value,
    currencyAsPrefix: currencyIsPrefix(values.partnerCurrency.value) || false,

    partnerAppKey: partner.app_api_key, // TODO - this field is never edited, should not be sent to backend
    partnerAppStoreLink: values.partnerAppStoreLink,
    partnerPlayStoreLink: values.partnerPlayStoreLink,
    partnerDefaultUrl: values.partnerDefaultUrl,
    partnerAppPrefix: values.partnerAppPrefix,
    bundleId: values.bundleId,
    packageName: values.packageName,

    partnerTypes: changedTypes.new.map((t) => t.value), // creating new partner
    partnerTypesNew: changedTypes.new.map((t) => t.value), // editing
    partnerTypesDeleted: changedTypes.deleted.map((t) => t.value), //editing

    rotationIOS: Number(values.rotationIOS),
    rotationAndroid: Number(values.rotationAndroid),
    rotationWeb: Number(values.rotationWeb),
    aspectRatioIOS: Number(values.aspectRatioIOS),
    aspectRatioAndroid: Number(values.aspectRatioAndroid),
    aspectRatioWeb: Number(values.aspectRatioWeb),
    positionYIOS: Number(values.positionYIOS),
    positionYAndroid: Number(values.positionYAndroid),
    positionYWeb: Number(values.positionYWeb),
    positionZIOS: Number(values.positionZIOS),
    positionZAndroid: Number(values.positionZAndroid),
    positionZWeb: Number(values.positionZWeb),
    thumbObjectScale: Number(values.thumbObjectScale),
    notificationsActive: values.notificationsActive,
    notificationsLimit: Number(values.notificationsLimit),
    numberOfTotems: Number(values.numberOfTotems),
    webActive: values.webActive,
    qrcodesActive: values.qrcodesActive,
    mobileActive: values.mobileActive,
    totemActive: values.totemActive,
    autocutActive: values.importActive,
    organizingProducts: values.organizingProducts,
    versionsGrouped: values.versionsGrouped,
    categoriesVisible: false, // Deprecated
    pdMeasurementActive: values.pdMeasurementActive,
    googleAnalyticsActive: values.googleAnalyticsActive,
    analyticsDashboardActive: values.analyticsDashboardActive,
    marketplaceVisible: values.marketplaceVisible,
    billingActive: values.billingActive,
    setsActive: values.setsActive,
    webRequestsUsage: {
      web_requests_limit: values.webRequestsLimit,
      web_usage_remainders_active: values.webUsageRemaindersActive,
    },

    privacyPolicyLink: values.privacyPolicyFromEditor
      ? createPrivacyLink(values.partnerAppPrefix)
      : values.privacyPolicyLink,
    privacyPolicyContents: values.privacyPolicyContents,

    totemsLicenseExpirationDate: values.totemsLicenseExpirationDate
      ? new Date(values.totemsLicenseExpirationDate)
          .toISOString()
          .replace("T", " ")
      : null,

    newEffects: changedEffects.new,
    updateEffects: changedEffects.updated,
    deletedEffects: changedEffects.deleted,

    newDomains: domainsToPayload(changedDomains.new),
    updateDomains: domainsToPayload(changedDomains.updated),
    deletedDomains: changedDomains.deleted,

    newAppLinks: changedAppLinks.new,
    updateAppLinks: changedAppLinks.updated,
    deletedAppLinks: changedAppLinks.deleted,
  };
};

export const preparePartnerFormData = (partner) => {
  const formData = new FormData();
  const partnerData = cloneDeep(partner);

  partnerData.effects?.forEach((effect, index) => {
    if (effect.fbxFile.file) {
      formData.append(`effects[${index}].fbxFile`, effect.fbxFile.file);
      delete effect.fbxFile;
    }
  });

  partnerData.newEffects?.forEach((effect, index) => {
    if (effect.fbxFile.file) {
      formData.append(`newEffects[${index}].fbxFile`, effect.fbxFile.file);
      delete effect.fbxFile;
    }
  });

  partnerData.updateEffects?.forEach((effect, index) => {
    if (effect.fbxFile.file) {
      formData.append(`updateEffects[${index}].fbxFile`, effect.fbxFile.file);
      if (
        [FileState.DELETED, FileState.REPLACED].includes(
          effect.fbxFile.uploadState
        )
      )
        effect.fbxFileDeleted = true;
      delete effect.fbxFile;
    }
  });

  formData.append("partnerData", JSON.stringify(partnerData));
  return formData;
};

export const parsePartners = (partners) => {
  const mainPartnerIdx = partners.findIndex((p) => p.main_partner);
  if (mainPartnerIdx > 0)
    partners.unshift(partners.splice(mainPartnerIdx, 1)[0]);
  return partners;
};

export const getPartnerGroup = (partner) => {
  return partner.main_partner
    ? PartnersGroup.MAIN_PARTNER
    : PartnersGroup.OTHER_PARTNERS;
};

const createPrivacyLink = (prefix) => {
  return `${REACT_APP_API_URL}${PRIVACY_LINK}${prefix}`;
};
