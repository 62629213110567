import Table from "common/Table";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ProductRow from "./ProductRow";
import { ViewportType } from "utils/ViewportContext";
import ProductCard from "./ProductCard";
import Tooltip from "common/basicComponents/Tooltip";

const ProductTable = ({
  products,
  total,
  meta,
  onMetaChange,
  onReorder,
  loading,
  onEdit,
}) => {
  const { t } = useTranslation("product");
  const { partner, user } = useSelector((state) => state.auth);

  const headers = [
    {
      text: t("thumbnail"),
      centered: true,
    },
    {
      text: t("productName"),
    },
    {
      text: t("info"),
    },
    {
      text: t("publishedVersions"),
      centered: true,
    },
    {
      text: t("actions"),
      centered: true,
    },
  ];

  return (
    <>
      <Table
        headers={headers}
        data={products}
        total={total}
        tableRow={ProductRow}
        tableCard={ProductCard}
        cardViewportBreakpoint={ViewportType.TABLET}
        keyPropertyName="id"
        meta={meta}
        onMetaChange={onMetaChange}
        loading={loading}
        itemProps={{
          onEdit,
          user,
          partner,
        }}
        enableReorder={partner?.organizing_products}
        onReorder={onReorder}
        noDataText={t("noData")}
      />
      {partner?.organizing_products && (
        <Tooltip anchorSelect=".show-tooltip" text={t("dragDropInfo")} />
      )}
    </>
  );
};

ProductTable.propTypes = {
  products: PropTypes.array,
  total: PropTypes.number,
  meta: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  onMetaChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onReorder: PropTypes.func,
};

ProductTable.defaultProps = {
  products: [],
  total: 0,
  loading: false,
  onReorder: () => {},
};

export default ProductTable;
