import React, { useEffect, useState, useRef } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import PropTypes from "prop-types";
import styled from "styled-components";
import Typography, { TypographyVariant } from "./Typography";

const StyledTooltip = styled(ReactTooltip)`
  background: transparent !important;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0;
  padding: 0;
  z-index: 1;
  max-width: 15rem;
`;

const TooltipTypography = styled(Typography)`
  background: ${({ theme }) => theme.colors.primary[500]};
  padding: 0.25rem 0.5rem;
  margin-left: 50%;
  margin-right: -50%;
`;

const Tooltip = ({ anchorSelect, text }) => {
  const [, setInactivityTimeout] = useState(null);
  const [hiddenTooltip, setHiddenTooltip] = useState(false);
  const position = useRef({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setHiddenTooltip(false);
    setInactivityTimeout((prevTimeout) => {
      if (prevTimeout) clearTimeout(prevTimeout);

      const newTimeout = setTimeout(() => {
        setHiddenTooltip(true);
      }, 2000);

      return newTimeout;
    });
    position.current = { x: Math.min(e.x, window.innerWidth - 200), y: e.y }; //prevents tooltip from overflowing the window
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <StyledTooltip
      anchorSelect={anchorSelect}
      noArrow
      place="bottom"
      hidden={hiddenTooltip}
      position={position.current}
      positionStrategy="fixed"
    >
      <TooltipTypography text={text} variant={TypographyVariant.CAPTION} />
    </StyledTooltip>
  );
};

Tooltip.propTypes = {
  anchorSelect: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

Tooltip.defaultProps = {
  anchorSelect: PropTypes.string,
  content: PropTypes.string,
};

export default Tooltip;
