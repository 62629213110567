import React from "react";
import QRCode from "qrcode.react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { REACT_APP_API_URL } from "constants/env";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Button, { ButtonVariant } from "common/basicComponents/Button";

const TryOn = styled.div`
  height: 100%;
  padding-bottom: 1.25rem;
  .label-text {
    display: inline-block;
    padding-bottom: 0.25rem;
  }
`;

const Container = styled.div`
  height: 100%;
  min-height: 12.5rem;
  width: 100%;

  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
  padding: 1.25rem 2.5rem;

  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: center;
  justify-content: center;

  & canvas {
    display: none;
  }
`;

const TryOnText = styled.div`
  text-align: center;
  white-space: pre-line;
  height: 2rem;
  max-height: 2rem;
`;

const AppTryOn = ({ label, versionId, qrDownloadName }) => {
  const { partner } = useSelector((state) => state.auth);
  const { t } = useTranslation("product");

  const downloadQRCode = () => {
    const canvas = document.getElementById(versionId + "-qr");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = qrDownloadName + ".png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <TryOn>
      <Typography
        variant={TypographyVariant.BODY2}
        weight={TypographyWeight.SEMIBOLD}
        text={label}
        tag="span"
        className="label-text"
      />
      <Container>
        <QRCode
          id={versionId + "-qr"}
          value={`${REACT_APP_API_URL}/qr/${versionId}/${partner.id}`}
          renderAs="canvas"
          size={300}
        />
        <Button
          variant={ButtonVariant.CONTAINED}
          text={t("downloadQR")}
          onClick={downloadQRCode}
          width="100%"
        />
        <TryOnText>
          <Typography variant={TypographyVariant.CAPTION} text={t("qrInfo")} />
        </TryOnText>
      </Container>
    </TryOn>
  );
};

AppTryOn.propTypes = {
  label: PropTypes.string.isRequired,
  versionId: PropTypes.string.isRequired,
  qrDownloadName: PropTypes.string.isRequired,
};

export default AppTryOn;
