import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Content from "common/page/Content";
import Page from "common/page/Page";
import Header from "common/page/Header";
import { PageSizes } from "common/Table/Pagination";
import { getApplications } from "./ApplicationsActions";
import ApplicationsTable from "./applicationsList/ApplicationsTable";
import AppVersion from "./applicationsList/AppVersion";

const Applications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("apps");
  const { appsList, minVersion } = useSelector((state) => state.applications);
  const [meta, setMeta] = useState({ page: 1, pageSize: PageSizes[0] });
  const [visibleApps, setVisibleApps] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getApplications();
  }, []);

  useEffect(() => {
    setMeta({ page: 1, pageSize: PageSizes[0] });
  }, [appsList, dispatch]);

  useEffect(() => {
    const maximumVisible = Math.min(
      appsList?.length,
      meta.page * meta.pageSize
    );

    if (maximumVisible - appsList?.length > 0) return;

    setVisibleApps(
      appsList.slice((meta.page - 1) * meta.pageSize, maximumVisible)
    );
  }, [meta, appsList]);

  useEffect(() => {
    setLoading(true);
    const maximumVisible = Math.min(
      appsList.length || meta.pageSize,
      meta.page * meta.pageSize
    );
    const offset = appsList.length;
    const limit = maximumVisible - offset;

    if (appsList.length > 0 && limit <= 0) {
      setLoading(false);
      return;
    }
  }, [meta, appsList?.length]);
  return (
    <Page>
      <Header
        title={t("title")}
        subtitles={[[t("totalNumber"), (appsList?.length || "").toString()]]}
      >
        <AppVersion version={minVersion} />
      </Header>
      <Content>
        <ApplicationsTable
          appList={visibleApps}
          total={appsList?.length}
          loading={loading}
          meta={meta}
          onMetaChange={setMeta}
        />
      </Content>
    </Page>
  );
};

export default Applications;
