import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Header from "common/page/Header";
import Content from "common/page/Content";
import Page from "common/page/Page";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import WarningDialog from "common/basicComponents/WarningDialog";
import { addBrand, deleteBrand, editBrand, getBrands } from "./BrandActions";
import BrandTable from "./list/BrandTable";
import EditBrandDialog from "./dialogs/EditBrandDialog";

const BrandList = () => {
  const { t } = useTranslation("brands");
  const { brands } = useSelector((state) => state.brands);
  const { partner } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getBrands().finally(() => {
      setLoading(false);
    });
  }, []);

  const [editDialog, setEditDialog] = useState({
    open: false,
    brand: null,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    brand: null,
  });

  const handleSave = (brand, form) => {
    if (brand.id) {
      editBrand(brand, form).then((ok) => {
        if (!ok) return;
        getBrands();
        setEditDialog({ brand: null, open: false });
      });
    } else {
      addBrand(brand, form).then((ok) => {
        if (!ok) return;
        getBrands();
        setEditDialog({ brand: null, open: false });
      });
    }
  };

  const handleDelete = () => {
    deleteBrand(deleteDialog.brand).then((ok) => {
      if (!ok) return;
      getBrands();
      setDeleteDialog({ ...deleteDialog, open: false });
    });
  };

  return (
    <Page>
      <Header
        title={t("title")}
        subtitles={[
          [t("totalNumber") + ":", (brands.length || 0).toString()],
          [
            t("globalBrands") + ":",
            (partner.main_partner
              ? 0
              : brands.filter((key) => key.from_main_partner).length || 0
            ).toString(),
          ],
          [
            t("ownBrands") + ":",
            (partner.main_partner
              ? brands.length || 0
              : brands.filter((key) => !key.from_main_partner).length || 0
            ).toString(),
          ],
        ]}
        button={
          <Button
            minWidth={200}
            variant={ButtonVariant.GREEN}
            icon={IconName.PLUS}
            text={t("addBrand")}
            onClick={() => setEditDialog({ open: true, brand: null })}
          />
        }
      />
      <Content>
        <BrandTable
          brands={brands}
          total={brands?.length}
          loading={loading}
          onEdit={(brand) => setEditDialog({ open: true, brand })}
          onDelete={(brand) => setDeleteDialog({ open: true, brand })}
        />
        <EditBrandDialog
          open={editDialog.open}
          brand={editDialog.brand}
          onClose={() => setEditDialog({ ...editDialog, open: false })}
          onSave={handleSave}
        />
        <WarningDialog
          open={deleteDialog.open}
          data={deleteDialog.brand}
          name={deleteDialog.brand?.brand_name}
          onClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
          onAction={handleDelete}
          title={t("deleteAlertTitle")}
          warningButtonText={t("deleteButtonTitle")}
        />
      </Content>
    </Page>
  );
};

export default BrandList;
