import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import { useTranslation } from "react-i18next";
import { ViewportType } from "utils/ViewportContext";

const Card = styled.div`
  min-width: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "40%" : "100%"};
  background: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  overflow: hidden;
`;

const Thumb = styled.img`
  height: 4rem;
  width: 8rem;
  object-fit: contain;
  align-self: center;
  margin-bottom: 1.25rem;
`;

const ThumbPlaceholder = styled.div`
  height: 4rem;
  width: 8rem;
  align-self: center;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  text-align: center;
  line-height: 4rem;
  color: ${({ theme }) => theme.colors.grey[400]};
`;

const ProductTypography = styled(Typography)`
  text-align: center;
  word-wrap: break-word;
`;

const VersionTypography = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.colors.grey[400]};
  margin-bottom: 2.25rem;
  word-wrap: break-word;
`;

const UrlTypography = styled(Typography)`
  word-wrap: break-word;
`;

const ButtonsContainer = styled.div`
  margin-top: 2.25rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LinkCard = ({ data, onEdit, onDelete }) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("links");

  const thumbLink = data.version_thumb_small_link || data.version_thumb_link;

  return (
    <Card viewport={viewportType}>
      {thumbLink ? (
        <Thumb src={thumbLink} />
      ) : (
        <ThumbPlaceholder>{t("common:notAdded")}</ThumbPlaceholder>
      )}
      <ProductTypography
        text={data.product_name}
        variant={TypographyVariant.H5}
        weight={TypographyWeight.BOLD}
        tag="span"
      />
      <VersionTypography
        text={data.version_name}
        variant={TypographyVariant.SUBTITLE2}
      />
      <Typography
        variant={TypographyVariant.SUBTITLE2}
        weight={TypographyWeight.BOLD}
        text={t("webLinkUrl")}
      />
      <UrlTypography
        variant={TypographyVariant.SUBTITLE2}
        text={data.domain_name + data.web_link_url}
      />
      <ButtonsContainer>
        <Button
          text={t("common:button.edit")}
          variant={ButtonVariant.CONTAINED}
          size={ButtonSize.SMALL}
          onClick={() => onEdit(data)}
        />
        <Button
          text={t("common:button.delete")}
          variant={ButtonVariant.ERROR}
          size={ButtonSize.SMALL}
          onClick={() => onDelete(data)}
        />
      </ButtonsContainer>
    </Card>
  );
};

LinkCard.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default LinkCard;
