import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Content from "common/page/Content";
import Header from "common/page/Header";
import Page from "common/page/Page";
import Box from "common/basicComponents/Box";
import Button, {
  ButtonVariant,
  ButtonType,
} from "common/basicComponents/Button";
import Accordion from "common/basicComponents/Accordion";
import {
  getImportData,
  getReport,
  notifyAboutImport,
  notifyAboutImportComplete,
  startImport,
  updateImportData,
} from "./ImportActions";
import {
  addNotification,
  NotificationVariant,
} from "components/notifications/NotificationSlice";
import ValidationSchema from "./form/ImportForm.schema";
import WarningDialog from "common/basicComponents/WarningDialog";
import { ExtendedUserRoles } from "constants/UserRoles";
import RouterPaths from "constants/RouterPaths";
import ImportForm from "./form/ImportForm";
import ImportReportTable from "./tableReport/ImportReportTable";
import { useViewport, ViewportType } from "utils/ViewportContext";
import { formatTimestamp } from "utils/DateTimeUtils";

const Buttons = styled.div`
  display: flex;

  ${({ viewport }) => viewport < ViewportType.DESKTOP && "flex-wrap: wrap;"}
  gap: ${({ viewport }) =>
    viewport === ViewportType.TABLET ? "1rem" : "1.25rem"};
  padding-top: ${({ viewport }) =>
    viewport === ViewportType.TABLET ? "1rem" : "1.25rem"};
`;

const AccordionContent = styled.div`
  padding: 1.25rem;
  padding-bottom: 2.5rem;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`;

const parseReport = (report) => {
  if (!report) return [];
  return Object.keys(report).map((key) => ({
    status: key,
    count: report[key].length,
  }));
};

const Import = () => {
  const { t } = useTranslation("import");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type: viewportType } = useViewport();
  const { importData, report } = useSelector((state) => state.import);
  const { partner, user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [showNotifyClientAlert, setShowNotifyClientAlert] = useState(false);

  useEffect(() => {
    Promise.all([getImportData(), getReport()]).then(() => {
      setLoading(false);
    });

    const refreshInterval = setInterval(getReport, 10 * 1000);
    return () => clearInterval(refreshInterval);
  }, []);

  const onSubmit = (values, form) => {
    updateImportData(values?.csvUrl).then((ok) => {
      if (!ok) return;
      if (user.role === ExtendedUserRoles.SUPERADMIN) {
        setLoading(true);
        startImport(importData?.url)
          .then((startOk) => {
            if (!startOk) return false;
            return getReport();
          })
          .then((getOk) => {
            form.setSubmitting(false);
            setLoading(false);
            if (!getOk) return;
            dispatch(
              addNotification({
                variant: NotificationVariant.SUCCESS,
                message: t("importStarted"),
              })
            );
          });
      } else {
        notifyAboutImport(partner.partner_name);
        form.setSubmitting(false);
        dispatch(
          addNotification({
            variant: NotificationVariant.SUCCESS,
            message: t("importStartsSoon"),
          })
        );
      }
    });
  };

  const onNotifyClientConfirmed = () => {
    notifyAboutImportComplete(t("emailSubject"), t("emailText")).then(() =>
      setShowNotifyClientAlert(false)
    );
  };

  return (
    <Page>
      <Header
        title={t("title")}
        subtitles={[
          [
            user.role === ExtendedUserRoles.SUPERADMIN &&
              t("lastImportDate") + ":",
            (importData && formatTimestamp(importData.date)) || "-",
          ],
        ]}
      >
        <Buttons viewport={viewportType}>
          <Box
            show={user.role === ExtendedUserRoles.SUPERADMIN}
            width={viewportType === ViewportType.DESKTOP ? "26rem" : "100%"}
            shrink={1}
          >
            <Button
              variant={ButtonVariant.OUTLINED}
              text={t("notifyClient")}
              onClick={() => setShowNotifyClientAlert(true)}
              disabled={!importData}
              isLoading={loading}
              width="100%"
            />
          </Box>
          <Box
            show={user.role === ExtendedUserRoles.SUPERADMIN}
            grow={viewportType === ViewportType.DESKTOP ? 0 : 1}
            width="12rem"
            shrink={1}
            basis={viewportType === ViewportType.DESKTOP ? "auto" : 0}
          >
            <Button
              variant={ButtonVariant.CONTAINED}
              text={t("openReport")}
              disabled={!importData || !report || !report["SUCCESS"]}
              isLoading={loading}
              onClick={() => navigate(RouterPaths.IMPORT_REPORT)}
              width="100%"
            />
          </Box>
          <Box
            grow={viewportType === ViewportType.DESKTOP ? 0 : 1}
            width="12rem"
            shrink={1}
            basis={viewportType === ViewportType.DESKTOP ? "auto" : 0}
          >
            <Button
              variant={ButtonVariant.GREEN}
              type={ButtonType.SUBMIT}
              text={t("importFile")}
              disabled={!importData}
              isLoading={loading}
              form="import-form"
              width="100%"
            />
          </Box>
        </Buttons>
      </Header>
      <Content>
        <Accordion text={t("importedFile")} lockedOpen>
          <AccordionContent>
            <Formik
              initialValues={{
                csvUrl: importData?.url,
              }}
              validationSchema={ValidationSchema}
              onSubmit={onSubmit}
              enableReinitialize
            >
              <ImportForm />
            </Formik>
            <ImportReportTable report={parseReport(report)} loading={loading} />
          </AccordionContent>
        </Accordion>
        <WarningDialog
          open={showNotifyClientAlert}
          title={t("notifyClientTitle")}
          onClose={() => setShowNotifyClientAlert(false)}
          onAction={onNotifyClientConfirmed}
          warningButtonText={t("common:button.send")}
        />
      </Content>
    </Page>
  );
};

export default Import;
