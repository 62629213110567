import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import moment from "moment/moment";
import PropTypes from "prop-types";

import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import Chips, { ChipsVariant } from "common/basicComponents/Chips";
import { TypographyWeight } from "common/basicComponents/Typography";
import Box from "common/basicComponents/Box";

const Cell = styled.td`
  height: 4.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const TextCell = styled(Cell)`
  padding: 0.75rem;
  & > span {
    overflow-wrap: anywhere;
  }
`;

const ButtonsCell = styled(Cell)`
  min-width: 5.5rem;
  button {
    display: inline-flex;
    margin: 0 0.25rem;
  }
`;

const PushNotificationRow = ({ data, onDelete }) => {
  const { t } = useTranslation("notifications");

  return (
    <>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={
            data.notification_timestamp
              ? moment(data.notification_timestamp).format("L, LT")
              : "-"
          }
          tag="span"
        />
      </Cell>
      <TextCell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.notification_title}
          tag="span"
        />
      </TextCell>
      <TextCell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.notification_message}
          tag="span"
        />
      </TextCell>
      <Cell centered={true}>
        <Chips
          variant={data.planned ? ChipsVariant.VIOLET : ChipsVariant.GREY}
          text={data.planned ? t("search.planned") : t("search.sent")}
        />
      </Cell>
      <Cell centered={true}>
        <Box show={data.planned || !!data?.onesignal_data}>
          <Typography
            variant={TypographyVariant.BODY2}
            text={data.onesignal_data?.successful || 0}
            tag="span"
          />
          <Typography
            variant={TypographyVariant.BODY2}
            weight={TypographyWeight.BOLD}
            text={` / ${data.receivers || 0}`}
            tag="span"
          />
        </Box>
      </Cell>
      <ButtonsCell centered={true}>
        {data.planned && (
          <Button
            icon={IconName.TRASH}
            variant={ButtonVariant.TEXT}
            size={ButtonSize.XSMALL}
            onClick={() => onDelete(data)}
          />
        )}
      </ButtonsCell>
    </>
  );
};

PushNotificationRow.propTypes = {
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PushNotificationRow;
