import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Typography, { TypographyVariant, TypographyWeight } from "../Typography";
import Button, { ButtonSize, ButtonType, ButtonVariant } from "../Button";
import { IconName } from "../Icon";
import {
  StyledLabel,
  DateInputSize,
  StyledPicker,
  InputContainer,
  StyledPaper,
  StyledPopper,
  StyledDay,
  CustomActionList,
  ErrorContainer,
  CustomTextField,
  StyledClockItem,
} from "./DateInputComponents";
import { useTranslation } from "react-i18next";
import { ClickAwayListener } from "@mui/material";

const DateInput = ({
  id,
  name,
  error,
  label,
  disabled,
  size,
  disablePast,
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <StyledLabel disabled={disabled} name={name}>
      {label && (
        <Typography
          variant={
            size === DateInputSize.LARGE
              ? TypographyVariant.BODY1
              : TypographyVariant.BODY2
          }
          weight={TypographyWeight.SEMIBOLD}
          text={label}
          tag="span"
          className="label-text"
        />
      )}
      <ClickAwayListener
        onClickAway={() => setOpen(false)}
        mouseEvent="onMouseDown"
      >
        <InputContainer>
          <StyledPicker
            localeText={{
              dateTimePickerToolbarTitle: t("datetime.title").toUpperCase(),
            }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            id={id}
            disabled={disabled}
            error={error}
            size={size}
            minDate={disablePast ? moment() : null}
            minTime={
              disablePast && moment(value).date() === moment().date()
                ? moment()
                : null
            }
            helperText=""
            value={moment(value)}
            onChange={(v) => onChange(v?.toISOString())}
            slotProps={{
              actionBar: ({ wrapperVariant }) => ({
                variant: wrapperVariant,
              }),
            }}
            slots={{
              actionBar: CustomActionList,
              textField: CustomTextField,
              day: StyledDay,
              digitalClockSectionItem: StyledClockItem,
              popper: StyledPopper, // only on desktop
              mobilePaper: StyledPaper, // only mobile
              openPickerButton: (params) => (
                <Button
                  type={ButtonType.BUTTON}
                  icon={IconName.CALENDAR}
                  variant={ButtonVariant.TEXT}
                  size={ButtonSize.SMALL}
                  disabled={disabled}
                  {...params}
                />
              ),
              nextIconButton: (params) => (
                <Button
                  {...params}
                  type={ButtonType.BUTTON}
                  icon={IconName.RIGHT_ARROW}
                  variant={ButtonVariant.TEXT}
                  size={ButtonSize.SMALL}
                  disabled={disabled}
                />
              ),
              previousIconButton: (params) => (
                <Button
                  {...params}
                  type={ButtonType.BUTTON}
                  icon={IconName.LEFT_ARROW}
                  variant={ButtonVariant.TEXT}
                  size={ButtonSize.SMALL}
                  disabled={disabled}
                />
              ),
              switchViewButton: (params) => (
                <Button
                  {...params}
                  type={ButtonType.BUTTON}
                  icon={IconName.DOWN_ARROW}
                  variant={ButtonVariant.TEXT}
                  size={ButtonSize.SMALL}
                  disabled={disabled}
                />
              ),
            }}
          />
        </InputContainer>
      </ClickAwayListener>
      <ErrorContainer error={error}>
        {error && (
          <Typography
            variant={TypographyVariant.CAPTION}
            weight={TypographyWeight.LIGHT}
            text={error}
          />
        )}
      </ErrorContainer>
    </StyledLabel>
  );
};

DateInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  caption: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  size: PropTypes.oneOf(Object.values(DateInputSize)),
  onChange: PropTypes.func,
  disablePast: PropTypes.bool,
};

DateInput.defaultProps = {
  error: "",
  label: "",
  caption: "",
  disabled: false,
  value: undefined,
  size: DateInputSize.NORMAL,
  onChange: () => {},
  disablePast: false,
};

export default DateInput;
export { DateInputSize };
