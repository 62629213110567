import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Chips, { ChipsVariant } from "common/basicComponents/Chips";

const Card = styled.div`
  min-width: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "40%" : "100%"};
  background: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1.25rem;
`;

const FieldLabel = styled(Typography)`
  text-align: left;
  padding-bottom: 0.25rem;
  word-wrap: break-word;
  padding-top: 1rem;
  ${({ theme, showDivider }) =>
    showDivider && "border-top: 1px solid" + theme.colors.grey[200]};
`;

const FieldValue = styled(Typography)`
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
  word-wrap: break-word;
  padding-bottom: 1rem;
`;

const ChipsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.25rem;
  padding-bottom: 1rem;
`;

const ButtonsContainer = styled.div`
  padding-top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TagsCard = ({ data, onEdit, onDelete }) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("tags");
  return (
    <Card viewport={viewportType}>
      <FieldLabel
        text={t("tagGroupName")}
        variant={TypographyVariant.BODY2}
        weight={TypographyWeight.BOLD}
        tag="span"
        showDivider={false}
      />
      <FieldValue
        text={data.tag_group_name}
        variant={TypographyVariant.SUBTITLE2}
      />
      <FieldLabel
        text={t("title")}
        variant={TypographyVariant.BODY2}
        weight={TypographyWeight.BOLD}
        tag="span"
        showDivider={true}
      />
      <ChipsContainer>
        {data?.tags.map((element) => (
          <Chips
            key={element.id}
            variant={ChipsVariant.VIOLET}
            text={element.tag_name}
          />
        ))}
      </ChipsContainer>
      <ButtonsContainer>
        <Button
          text={t("common:button.edit")}
          variant={ButtonVariant.CONTAINED}
          size={ButtonSize.SMALL}
          onClick={() => onEdit(data)}
        />
        <Button
          text={t("common:button.delete")}
          variant={ButtonVariant.ERROR}
          size={ButtonSize.SMALL}
          onClick={() => onDelete(data)}
        />
      </ButtonsContainer>
    </Card>
  );
};

TagsCard.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TagsCard;
