const PublishingOptions = {
  ALL: "all",
  PUBLISHED: "published",
  NOTPUBLISHED: "notPublished",
};

const AssignedOptions = {
  ALL: "all",
  ASSIGNED: "assigned",
  NOTASSIGNED: "notAssigned",
};

const OwnershipOptions = {
  ALL: "all",
  MY: "myProducts",
  OTHER_PARTNER: "fromOtherPartner",
};

export { PublishingOptions, AssignedOptions, OwnershipOptions };
