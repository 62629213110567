import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import BannerTable from "./list/BannerTable";
import WarningDialog from "common/basicComponents/WarningDialog";
import { useTranslation } from "react-i18next";
import { deleteBanner } from "components/totems/TotemActions";
import { useDispatch } from "react-redux";
import {
  addNotification,
  NotificationVariant,
} from "components/notifications/NotificationSlice";
import { getTotemBanners } from "components/totems/TotemActions";
import { useParams } from "react-router-dom";
import EditBannerDialog from "./EditBannerDialog";
import { LoaderType } from "components/totems/EditTotem";
import { Status } from "./BannerOptions";
import { editBanner } from "components/totems/TotemActions";
import { addBanner } from "components/totems/TotemActions";

const Banners = ({
  loading,
  editBannerDialog,
  setEditBannerDialog,
  setFormLoading,
  formLoading,
}) => {
  const { t } = useTranslation("totems");
  const { id: totemId } = useParams();
  const dispatch = useDispatch();
  const { banners } = useSelector((state) => state.totems);
  const [deleteDialog, setDeleteDialog] = useState({
    show: false,
    banner: null,
  });

  const [dataReloading, setDataReloading] = useState(false);

  const deleteBannerHandler = (banner) => {
    deleteBanner(banner?.banner_id, banner?.banner_image_link).then((ok) => {
      if (ok) {
        dispatch(
          addNotification({
            variant: NotificationVariant.SUCCESS,
            message: t("common:form.deletedSuccessfully"),
          })
        );
        setDeleteDialog({ ...deleteDialog, show: false });
        setDataReloading(true);
        getTotemBanners(totemId).finally(() => setDataReloading(false));
      }
    });
  };

  const editBannerHandler = (values) => {
    const data = {
      totem_id: totemId,
      banner_id: values.bannerId,
      banner_name: values.bannerName,
      active: values.bannerActive.value === Status.ACTIVE,
      duration: values.bannerDuration,
      displayed_time: values.bannerDisplayedTime,
      banner_file: values.bannerFile,
      banner_image_link: values.bannerImageLink,
      banner_type: values.bannerType.value,
    };

    const saveBannerResultHandler = (res) => {
      if (res) {
        dispatch(
          addNotification({
            variant: NotificationVariant.SUCCESS,
            message: t("common:form.savedSuccessfully"),
          })
        );
        setEditBannerDialog({
          ...editBannerDialog,
          show: false,
        });
        setDataReloading(true);
        getTotemBanners(totemId).finally(() => setDataReloading(false));
      }
      setFormLoading(LoaderType.INITIAL);
    };

    if (data.banner_id) {
      setFormLoading(LoaderType.BANNER_EDIT);
      editBanner(data).then((ok) => {
        saveBannerResultHandler(ok);
      });
    } else {
      setFormLoading(LoaderType.BANNER_ADD);
      addBanner(data).then((ok) => {
        saveBannerResultHandler(ok);
      });
    }
  };

  return (
    <>
      <WarningDialog
        open={deleteDialog.show}
        onClose={() => setDeleteDialog({ ...deleteDialog, show: false })}
        name={deleteDialog?.banner?.banner_name || ""}
        onAction={() => deleteBannerHandler(deleteDialog?.banner)}
        title={t("banners.deleteDialogTitle")}
        warningButtonText={t("common:button.delete")}
      />
      <EditBannerDialog
        open={editBannerDialog.show}
        banner={editBannerDialog.banner}
        loading={formLoading}
        onClose={() =>
          setEditBannerDialog({
            ...editBannerDialog,
            show: false,
          })
        }
        onSave={(values) => editBannerHandler(values)}
      />
      <BannerTable
        loading={loading || dataReloading || formLoading}
        onEdit={(data) => {
          setEditBannerDialog({
            show: true,
            banner: data,
          });
        }}
        onDelete={(data) => {
          setDeleteDialog({
            show: true,
            banner: data,
          });
        }}
        banners={banners}
      />
    </>
  );
};

Banners.propTypes = {
  loading: PropTypes.bool.isRequired,
  editBannerDialog: PropTypes.object.isRequired,
  setEditBannerDialog: PropTypes.func.isRequired,
  setFormLoading: PropTypes.func.isRequired,
  formLoading: PropTypes.bool.isRequired,
};

export default Banners;
