import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Box from "common/basicComponents/Box";
import Button, {
  ButtonType,
  ButtonVariant,
  ButtonWidth,
  ButtonSize,
} from "common/basicComponents/Button";
import Range from "common/basicComponents/Range";
import { RangeDisplayMode } from "common/basicComponents/Range";
import { IconName } from "common/basicComponents/Icon";

const MenuContainer = styled.div`
  height: 12.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MenuContent = styled.div`
  max-width: min(calc(100% - 12.5rem), calc((100vh - 25rem) * 3) + 2.5rem);
  width: 100%;
  display: flex;
  align-items: flex-end;
`;

const MenuTitle = styled(Typography)`
  background: ${({ theme }) => theme.colors.gradient[100]};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1.25rem;
  white-space: pre-line;
`;

const Controls = styled.div`
  display: flex;
  gap: 2.5rem;
`;

const MoveButtons = styled.div`
  height: 5rem;

  display: flex;
  gap: 1rem;
  align-items: flex-end;
  justify-content: center;

  position: relative;
  & button:first-child {
    position: absolute;
    bottom: 3rem;
  }
`;

const GradientFrame = styled.div`
  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
  padding: 0.5rem;
  display: flex;
  background: ${({ theme }) => theme.colors.white};
`;

const LabelTypography = styled(Typography)`
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey[500] : theme.colors.black};
`;

const Menu = ({
  disabled,
  onCrop,
  onResize,
  onMove,
  onRotate,
  cropActive,
  resizeValue,
  rotateValue,
}) => {
  const { t } = useTranslation("wizard");

  return (
    <MenuContainer>
      <MenuContent>
        <Box grow={1}>
          <MenuTitle
            variant={TypographyVariant.BUTTON}
            weight={TypographyWeight.BOLD}
            text={t("selectInfo")}
            tag="p"
          />
          <Controls>
            <Box grow={1} basis={0}>
              <LabelTypography
                variant={TypographyVariant.OVERLINE}
                text={t("manualCrop")}
                tag="span"
                disabled={disabled}
              />
              <GradientFrame>
                <Button
                  disabled={disabled}
                  type={ButtonType.BUTTON}
                  variant={ButtonVariant.CONTAINED}
                  width={ButtonWidth.GROW}
                  size={ButtonSize.SMALL}
                  onClick={onCrop}
                  text={cropActive ? t("saveCrop") : t("crop")}
                />
              </GradientFrame>
            </Box>
            <Box grow={1} basis={0}>
              <Range
                label={t("resizeInfo")}
                displayMode={RangeDisplayMode.BUTTONS}
                min={20}
                max={300}
                step={1}
                longStep={5}
                disabled={disabled || cropActive}
                value={Math.round(resizeValue * 100)}
                suffix="%"
                onChange={(newValue) => onResize(newValue / 100 - resizeValue)}
              />
            </Box>
            <Box grow={1} basis={0}>
              <Range
                label={t("rotateInfo")}
                displayMode={RangeDisplayMode.BUTTONS}
                min={-360}
                max={360}
                step={0.5}
                longStep={3}
                disabled={disabled || cropActive}
                value={Math.round(rotateValue * 10) / 10}
                onChange={(newValue) => onRotate(newValue - rotateValue)}
              />
            </Box>
          </Controls>
        </Box>
        <Box padding="0 0 0 2.5rem">
          <LabelTypography
            variant={TypographyVariant.OVERLINE}
            text={t("moveInfo")}
            tag="span"
            disabled={disabled}
          />
          <GradientFrame>
            <MoveButtons>
              <Button
                disabled={disabled || cropActive}
                type={ButtonType.BUTTON}
                variant={ButtonVariant.CONTAINED}
                width={ButtonWidth.GROW}
                size={ButtonSize.SMALL}
                onClick={() => onMove(0, -2)}
                onPress={() => onMove(0, -10)}
                icon={IconName.UP_ARROW}
              />
              <Button
                disabled={disabled || cropActive}
                type={ButtonType.BUTTON}
                variant={ButtonVariant.CONTAINED}
                width={ButtonWidth.GROW}
                size={ButtonSize.SMALL}
                onClick={() => onMove(-2, 0)}
                onPress={() => onMove(-10, 0)}
                icon={IconName.LEFT_ARROW}
              />
              <Button
                disabled={disabled || cropActive}
                type={ButtonType.BUTTON}
                variant={ButtonVariant.CONTAINED}
                width={ButtonWidth.GROW}
                size={ButtonSize.SMALL}
                onClick={() => onMove(0, 2)}
                onPress={() => onMove(0, 10)}
                icon={IconName.DOWN_ARROW}
              />
              <Button
                disabled={disabled || cropActive}
                type={ButtonType.BUTTON}
                variant={ButtonVariant.CONTAINED}
                width={ButtonWidth.GROW}
                size={ButtonSize.SMALL}
                onClick={() => onMove(2, 0)}
                onPress={() => onMove(10, 0)}
                icon={IconName.RIGHT_ARROW}
              />
            </MoveButtons>
          </GradientFrame>
        </Box>
      </MenuContent>
    </MenuContainer>
  );
};

Menu.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onCrop: PropTypes.func.isRequired,
  onResize: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onRotate: PropTypes.func.isRequired,
  cropActive: PropTypes.bool.isRequired,
  resizeValue: PropTypes.number.isRequired,
  rotateValue: PropTypes.number.isRequired,
};

export default Menu;
