import i18n from "i18n/i18n";

const brandsToSelect = (brandList) => {
  return brandList
    .map((brand) => ({
      label: brand.brand_name,
      value: brand.id,
      additionalLabel: brand.from_main_partner ? i18n.t("brands:global") : null,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export { brandsToSelect };
