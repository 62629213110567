import React from "react";
import styled, { css } from "styled-components";
import Typography, { TypographyVariant } from "./Typography";
import PropTypes from "prop-types";
import i18n from "../../i18n/i18n";

const getToggleColors = (theme, disabled, checked) => {
  if (!disabled && checked)
    return css`
      background-color: ${theme.colors.success[600]};
      .label-yes {
        color: ${theme.colors.white};
      }
      .label-no {
        color: ${theme.colors.grey[500]};
      }
      &:before {
        background-color: ${theme.colors.white};
      }
    `;
  else if (!disabled && !checked)
    return css`
      background-color: ${theme.colors.grey[200]};
      .label-yes {
        color: ${theme.colors.white};
      }
      .label-no {
        color: ${theme.colors.grey[500]};
      }
      &:before {
        background-color: ${theme.colors.white};
      }
    `;
  else if (disabled && checked)
    return css`
      background-color: ${theme.colors.success[500]};
      .label-yes {
        color: ${theme.colors.grey[300]};
      }
      .label-no {
        color: ${theme.colors.grey[400]};
      }
      &:before {
        background-color: ${theme.colors.grey[300]};
      }
    `;
  else
    return css`
      background-color: ${theme.colors.grey[200]};
      .label-yes {
        color: ${theme.colors.grey[300]};
      }
      .label-no {
        color: ${theme.colors.grey[400]};
      }
      &:before {
        background-color: ${theme.colors.grey[100]};
      }
    `;
};

const StyledToggle = styled.span`
  position: relative;
  width: 4rem;
  height: 1.75rem;
  padding: 0.25rem 0.375rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  cursor: pointer;
  user-select: none;
  transition: 0.4s;

  .label-yes {
    visibility: ${({ checked }) => (checked ? "visible" : "hidden")};
  }
  .label-no {
    visibility: ${({ checked }) => (checked ? "hidden" : "visible")};
  }

  &:before {
    position: absolute;
    content: "";
    height: 1.25rem;
    width: 1.25rem;
    left: 0.25rem;
    bottom: 0.25rem;
    transition: 0.4s;
    ${({ checked }) => checked && "transform: translateX(2.25rem)"};
  }

  &:hover {
    &:before {
      ${({ disabled }) => !disabled && "width: 1.5rem;"}
      ${({ checked, disabled }) =>
        checked && !disabled && "transform: translateX(2rem);"}
    }
  }

  ${({ theme, disabled, checked }) => getToggleColors(theme, disabled, checked)}
`;

const Toggle = ({ disabled, value, onChange, leftOption, rightOption }) => {
  const handleClick = () => {
    if (disabled) return;
    onChange(!value);
  };

  return (
    <StyledToggle checked={value} onClick={handleClick} disabled={disabled}>
      <Typography
        variant={TypographyVariant.BUTTON}
        text={leftOption}
        className="label-yes"
      />
      <Typography
        variant={TypographyVariant.BUTTON}
        text={rightOption}
        className="label-no"
      />
    </StyledToggle>
  );
};

Toggle.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  leftOption: PropTypes.string,
  rightOption: PropTypes.string,
};

Toggle.defaultProps = {
  disabled: false,
  value: false,
  onChange: () => {},
  leftOption: i18n.t("yes"),
  rightOption: i18n.t("no"),
};

export default Toggle;
