import React, { useState, useEffect } from "react";
import { Form, useFormikContext, FieldArray } from "formik";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { getBrands } from "components/brands/BrandActions";
import { getTagGroups } from "components/tagGroups/TagGroupActions";
import { getTypes } from "components/types/TypeActions";
import { scrollToError } from "utils/FormUtils";
import ProductVersionForm from "./ProductVersionForm";
import ProductInfoForm from "./ProductInfoForm";
import ProductModelForm from "./ProductModelForm";
import { useViewport, ViewportType } from "utils/ViewportContext";
import ModelType from "constants/ModelType";
import {
  addNotification,
  NotificationVariant,
} from "components/notifications/NotificationSlice";
import { InitialFileState } from "common/formFields/FormFileField";
import FormObserver from "./FormObserver";
import WarningDialog from "common/basicComponents/WarningDialog";
import { valuesEqual } from "utils/CommonUtils";

const FormsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ viewport }) =>
    viewport === ViewportType.MOBILE ? "1.25rem" : "2rem"};
`;

export const initialProductValues = {
  fromMarketplace: false,
  fromOtherPartner: false,
  id: null,
  published: false,
  name: "",
  brand: null,
  type: null,
  modelType: {
    value: ModelType.MODEL3D,
  },
  glassesWidth: null,
  lensHeight: null,
  aspectRatioIos: 1,
  aspectRatioAndroid: 1,
  aspectRatioWeb: 1,
  positionYIos: 0,
  positionYAndroid: 0,
  positionYWeb: 0,
  positionZIos: 0,
  positionZAndroid: 0,
  positionZWeb: 0,
  rotationIos: 0,
  rotationAndroid: 0,
  rotationWeb: 0,
  earRotationWeb: 0,
  earpieceDistanceScale: 1,
  useMetallicGlass: false,
  versions: [],
  tagGroups: null,
};

export const initialVersionValues = {
  published: false,
  name: "",
  pageLink: "",
  thumbFile: InitialFileState,
  albedoFile: InitialFileState,
  metallicFile: InitialFileState,
  normalFile: InitialFileState,
  frontFile: InitialFileState,
  lensesFile: InitialFileState,
  leftFile: InitialFileState,
  rightFile: InitialFileState,
  sku: "",
  code: "",
  price: null,
  specialPrice: null,
};

const AddVersionManager = ({ addVersionBtnRef, arrayHelpers }) => {
  useEffect(() => {
    if (addVersionBtnRef?.current && arrayHelpers) {
      addVersionBtnRef.current.onclick = () => {
        arrayHelpers.push({ ...initialVersionValues, key: uuidv4() });
      };
    }
  }, [addVersionBtnRef, arrayHelpers]);
  return <></>;
};

AddVersionManager.propTypes = {
  addVersionBtnRef: PropTypes.object.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
};

const ProductForm = ({
  saveBtnRef,
  addVersionBtnRef,
  isLoading,
  onDirtyChanged,
  setLoading,
  initialValues,
  unsavedEditsDialog,
  hideUnsavedEditsDialog,
  conf,
}) => {
  const {
    values,
    submitForm,
    resetForm,
    validateForm,
    isSubmitting,
    setFieldValue,
  } = useFormikContext();
  const { partner } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { t } = useTranslation("product");

  const { type: viewportType } = useViewport();
  const [generate, setGenerate] = useState({
    generate: false,
    done: 0,
  });

  useEffect(() => {
    if (generate.generate === false || generate.done > 0) return;
    setGenerate({
      generate: false,
      done: 0,
    });
    submitForm();
  }, [generate, submitForm]);

  useEffect(() => {
    return () => {
      const loadingScript = document.getElementById("load-arlab");
      const arlabScript = document.getElementById("arlab");
      if (arlabScript) arlabScript.parentElement.removeChild(arlabScript);
      if (loadingScript) loadingScript.parentElement.removeChild(loadingScript);
      if (window["arlab"]) window["arlab"].q = [];
    };
  }, []);

  useEffect(() => {
    if (!partner) return;
    getBrands();
    getTagGroups();
    getTypes();
  }, [partner]);

  const onVersionDelete = (version) => {
    if (!version) return;

    if (values.fromMarketplace || values.fromOtherPartner) {
      const newVersions = [...values.versions];
      const editedIndex = newVersions.findIndex((e) => e.id === version.id);
      newVersions[editedIndex] = {
        ...newVersions[editedIndex],
        price: null,
        specialPrice: null,
        pageLink: null,
        isAssigned: false,
        isNewAssign: false,
        isNewUnassign: !!version.partnersVersionId,
      };
      setFieldValue("versions", newVersions);
    }
  };

  const onVersionAssign = (version) => {
    if (!version) return;

    if (values.fromMarketplace || values.fromOtherPartner) {
      const newVersions = [...values.versions];
      const editedIndex = newVersions.findIndex((e) => e.id === version.id);
      newVersions[editedIndex] = {
        ...newVersions[editedIndex],
        isAssigned: true,
        isNewAssign: !version.partnersVersionId,
        isNewUnassign: false,
      };
      setFieldValue("versions", newVersions);
    }
  };

  useEffect(() => {
    const onProductSave = async () => {
      setLoading(true);
      const errors = await validateForm();
      if (!_.isEmpty(errors)) {
        setLoading(false);

        if (errors.published)
          dispatch(
            addNotification({
              message: t(errors.published),
              variant: NotificationVariant.ERROR,
            })
          );
        setTimeout(() => scrollToError(errors), 500);
        submitForm();
        return;
      }
      setGenerate({
        generate: true,
        done: values.versions.length,
      });
    };
    if (saveBtnRef.current) saveBtnRef.current.onclick = onProductSave;
  }, [
    saveBtnRef,
    dispatch,
    setLoading,
    submitForm,
    values?.versions?.length,
    t,
    validateForm,
  ]);

  return (
    <Form noValidate>
      <WarningDialog
        open={unsavedEditsDialog?.show || false}
        onClose={() => hideUnsavedEditsDialog()}
        onAction={() => {
          resetForm(initialValues);
          setTimeout(() => {
            unsavedEditsDialog?.action();
          }, [300]);
          hideUnsavedEditsDialog();
        }}
        name={t("unsavedEditsDialogTitle")}
        title={t("unsavedEditsDialogInfo")}
        warningButtonText={t("unsavedEditsDialogButton")}
      />
      <FormObserver
        onDirtyChanged={() =>
          onDirtyChanged(!valuesEqual(initialValues, values))
        }
      />
      <FormsContainer viewport={viewportType}>
        <ProductInfoForm disabled={isLoading || isSubmitting} />
        <ProductModelForm disabled={isLoading || isSubmitting} />
        <FieldArray
          name="versions"
          render={(arrayHelpers) => (
            <>
              <AddVersionManager
                addVersionBtnRef={addVersionBtnRef}
                arrayHelpers={arrayHelpers}
              />
              {values.versions &&
                values.versions.map((v, index) => (
                  <ProductVersionForm
                    key={v.key}
                    index={index}
                    disabled={isLoading || isSubmitting}
                    onDelete={() => {
                      if (!values.fromMarketplace && !values.fromOtherPartner)
                        arrayHelpers.remove(index);
                      if (v.id && onVersionDelete) onVersionDelete(v);
                    }}
                    onAssign={onVersionAssign}
                    generate={generate.generate}
                    onThumbAssign={() =>
                      setGenerate((old) => {
                        return { ...old, done: old.done - 1 };
                      })
                    }
                    conf={conf}
                  />
                ))}
            </>
          )}
        />
      </FormsContainer>
    </Form>
  );
};

ProductForm.propTypes = {
  saveBtnRef: PropTypes.object.isRequired,
  addVersionBtnRef: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDirtyChanged: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
  initialValues: PropTypes.any,
  unsavedEditsDialog: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    action: PropTypes.func.isRequired,
  }),
  hideUnsavedEditsDialog: PropTypes.func,
  conf: PropTypes.object
};

ProductForm.defaultProps = {
  initialValues: null,
  unsavedEditsDialog: null,
  onDirtyChanged: () => {},
  hideUnsavedEditsDialog: () => {},
  conf: {}
};

export default ProductForm;
