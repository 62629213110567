export const CurrencyOptions = {
  PLN: "ZŁ",
  USD: "$",
  EUR: "€",
  GBP: "£",
};

export const currencyIsPrefix = (currency) =>
  ({
    [CurrencyOptions.PLN]: false,
    [CurrencyOptions.USD]: true,
    [CurrencyOptions.EUR]: false,
    [CurrencyOptions.GBP]: true,
  }[currency]);
