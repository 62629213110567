import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Typography, { TypographyVariant, TypographyWeight } from "./Typography";
import Icon, { IconName } from "./Icon";
import Button, { ButtonVariant, ButtonSize } from "./Button";

const getFileInputColors = (theme, error, pseudo = "default") => {
  if (error)
    return css`
      background: linear-gradient(${theme.colors.white} 0 0) padding-box,
        ${theme.colors.secondary[700]} border-box;
      .text {
        color: ${theme.colors.grey[300]};
      }
    `;

  return {
    default: css`
      background: linear-gradient(${theme.colors.white} 0 0) padding-box,
        ${theme.colors.gradient[20]} border-box;
      .text {
        color: ${theme.colors.grey[300]};
      }
    `,
    disabled: css`
      background: linear-gradient(${theme.colors.white} 0 0) padding-box,
        ${theme.colors.grey[300]} border-box;
      .text {
        color: ${theme.colors.grey[300]};
      }
    `,
    hover: css`
      background: linear-gradient(${theme.colors.white} 0 0) padding-box,
        ${theme.colors.gradient[100]} border-box;
      .text {
        color: ${theme.colors.grey[400]};
      }
    `,
    active: css`
      background: linear-gradient(${theme.colors.white} 0 0) padding-box,
        ${theme.colors.gradient[100]} border-box;
      .text {
        color: ${theme.colors.grey[400]};
      }
    `,
  }[pseudo];
};

const StyledButton = styled.button`
  width: 100%;
  padding: 1.25rem;

  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: center;
  justify-content: center;
  border: 2px dashed #fff;

  & p {
    white-space: pre-line;
  }

  ${({ theme, error }) => getFileInputColors(theme, error)};

  &:hover {
    ${({ theme, error }) => getFileInputColors(theme, error, "hover")};
  }
  &:active {
    ${({ theme, error }) => getFileInputColors(theme, error, "active")}
  }
  &:disabled {
    ${({ theme, error }) => getFileInputColors(theme, error, "disabled")};
  }

  &.drag-active {
    ${({ theme, error }) => getFileInputColors(theme, error, "active")}
  }
`;

const StyledLabel = styled.div`
  width: 100%;
  min-height: 1.25rem;
  margin-bottom: 0.25rem;
  ${({ theme, disabled }) => disabled && `color: ${theme.colors.grey[500]};`}
  .label-text {
    display: inline-block;
  }
`;

const StyledCaption = styled.div`
  min-height: 1rem;
  margin-top: 0.25rem;

  display: flex;
  flex-direction: row;
  gap: 0.25rem;

  color: ${({ theme, error }) => (error ? theme.colors.secondary[700] : null)};
  & .caption-check {
    color: ${({ theme }) => theme.colors.success[500]};
  }
`;

const StyledFrame = styled.div`
  width: 100%;
  height: 100%;
  min-height: 5rem;
  width: 100%;
  display: flex;
  flex-grow: 1;
  aspect-ratio: ${({ aspectRatio }) => aspectRatio};
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledImg = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
`;

const FileInput = ({
  id,
  name,
  label,
  caption,
  showCheckmark,
  showDeleteButton,
  showActionButton,
  actionName,
  onAction,
  hintIcon,
  hintText,
  smallHintText,
  aspectRatio,
  accept,
  src,
  disabled,
  onChange,
  error,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled && (e.type === "dragenter" || e.type === "dragover")) {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleFileDropped = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (!disabled && e.dataTransfer.files && e.dataTransfer.files[0]) {
      onChange(Array.from(e.dataTransfer.files));
    }
  };

  const handleFileSelected = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      onChange(Array.from(e.target.files));
    }
    e.target.value = null;
  };

  const handleFileDeleted = () => {
    onChange([]);
  };

  return (
    <StyledContainer>
      <StyledLabel disabled={disabled}>
        {label && (
          <Typography
            text={label}
            variant={TypographyVariant.BODY2}
            weight={TypographyWeight.SEMIBOLD}
            className="label-text"
          />
        )}
      </StyledLabel>
      <StyledFrame
        onDragEnter={src ? null : handleDrag}
        onDragLeave={src ? null : handleDrag}
        onDragOver={src ? null : handleDrag}
        onDrop={src ? null : handleFileDropped}
        aspectRatio={aspectRatio}
      >
        <input
          id={id}
          name={name}
          ref={inputRef}
          type="file"
          multiple
          style={{ display: "none" }}
          accept={accept}
          onChange={handleFileSelected}
        />
        {src ? (
          <StyledImg src={src} />
        ) : (
          <StyledButton
            className={dragActive ? "drag-active" : null}
            type="button"
            onClick={() => inputRef.current.click()}
            disabled={disabled}
            error={error}
          >
            {hintIcon && (
              <Icon name={hintIcon} size="5rem" className="hint-icon" />
            )}
            {hintText && (
              <Typography
                variant={TypographyVariant.BUTTON}
                className="text"
                tag="p"
                text={hintText}
              />
            )}
            {smallHintText && (
              <Typography
                variant={TypographyVariant.OVERLINE}
                className="text"
                text={smallHintText}
              />
            )}
          </StyledButton>
        )}
      </StyledFrame>
      <StyledCaption error={error}>
        {showCheckmark && !error && (
          <Icon
            name={IconName.CHECKMARK}
            size="1rem"
            className="caption-check"
          />
        )}
        {typeof error === "string" && error?.length > 0 && (
          <Icon name={IconName.ERROR} size="1rem" />
        )}
        {(caption || (typeof error === "string" && error?.length > 0)) && (
          <Typography
            text={error || caption}
            variant={TypographyVariant.CAPTION}
            weight={TypographyWeight.LIGHT}
          />
        )}
        {showDeleteButton && !error && !disabled && (
          <Button
            variant={ButtonVariant.TEXT}
            size={ButtonSize.XXSMALL}
            icon={IconName.TRASH}
            onClick={handleFileDeleted}
          />
        )}
        { !showDeleteButton && showActionButton && (
          <Button
            variant={ButtonVariant.TEXT}
            size={TypographyVariant.CAPTION}
            weight={TypographyWeight.LIGHT}
            onClick={onAction}
            text={actionName}
          />
        )}
      </StyledCaption>
    </StyledContainer>
  );
};

FileInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  caption: PropTypes.string,
  showCheckmark: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showActionButton: PropTypes.bool,
  actionName: PropTypes.string,
  onAction: PropTypes.func,
  hintIcon: PropTypes.string,
  hintText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  smallHintText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  aspectRatio: PropTypes.number,
  accept: PropTypes.string,
  src: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

FileInput.defaultProps = {
  label: null,
  caption: null,
  showCheckmark: false,
  showDeleteButton: false,
  showActionButton: false,
  actionIcon: null,
  onAction: () => {},
  actionName: null,
  hintIcon: null,
  hintText: null,
  smallHintText: null,
  aspectRatio: null,
  accept: null,
  src: null,
  onChange: () => {},
  disabled: false,
  error: null,
};

export default FileInput;
