import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";

import Box from "common/basicComponents/Box";
import FormInputField, {
  FormInputFieldType,
  InputSize,
} from "common/formFields/FormInputField";
import FormSelectField from "common/formFields/FormSelectField";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Accordion from "common/basicComponents/Accordion";
import { currenciesToSelect } from "utils/PartnerUtils";
import { CurrencyOptions } from "constants/CurrencyOptions";
import { ExtendedUserRoles } from "constants/UserRoles";

const Content = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem 0.625rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  align-items: baseline;
`;

const BasicData = () => {
  const { type: viewportType } = useViewport();
  const { user } = useSelector((state) => state.auth);
  const { t } = useTranslation("data");
  const { values } = useFormikContext();
  const [accordionHasErrors, setAccordionHasErrors] = useState(false);

  useEffect(() => {
    if (accordionHasErrors) setAccordionHasErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Accordion text={t("data")} defaultOpen forceOpen={accordionHasErrors}>
      <Content>
        <Box padding="0 0.625rem" width="100%">
          <FormInputField
            type={FormInputFieldType.TEXT}
            name="name"
            id="name"
            disabled={!!values.id}
            label={t("partner")}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.TABLET ? "50%" : "100%"}
          grow={1}
          show={values.id && user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormInputField
            type={FormInputFieldType.COPY}
            name="partnerAPIKey"
            id="partnerAPIKey"
            label={t("partnerAPIKey")}
            disabled
            size={InputSize.NORMAL}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.TABLET ? "50%" : "100%"}
          grow={1}
          show={
            values.id &&
            (values.webActive || user.role === ExtendedUserRoles.SUPERADMIN)
          }
        >
          <FormInputField
            type={FormInputFieldType.COPY}
            name="partnerWebAppKey"
            id="partnerWebAppKey"
            label={t("internalWebApplicationKey")}
            disabled
            size={InputSize.NORMAL}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.TABLET ? "50%" : "100%"}
          grow={1}
          show={values.id && user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormInputField
            type={FormInputFieldType.COPY}
            name="partnerAppKey"
            id="partnerAppKey"
            label={t("internalMobileApplicationKey")}
            disabled
            size={InputSize.NORMAL}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.TABLET ? "50%" : "100%"}
          grow={1}
          show={values.id && user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormInputField
            type={FormInputFieldType.COPY}
            name="totemsApiKey"
            id="totemsApiKey"
            label={t("internalTotemApplicationKey")}
            disabled
            size={InputSize.NORMAL}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.TABLET ? "50%" : "100%"}
          grow={1}
          show={values.id && user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormInputField
            type={FormInputFieldType.TEXT}
            name="notificationsAppId"
            id="notificationsAppId"
            label={t("applicationIDForOneSignal")}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.TABLET ? "50%" : "100%"}
          grow={1}
          show={values.id && user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormInputField
            type={FormInputFieldType.TEXT}
            name="notificationsKey"
            id="notificationsKey"
            label={t("applicationKeyForOneSignal")}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
          show={values.googleAnalyticsActive}
        >
          <FormInputField
            type={FormInputFieldType.TEXT}
            name="googleAnalyticsKey"
            id="googleAnalyticsKey"
            label={t("googleUniversalAnalyticsKey")}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={
            viewportType > ViewportType.MOBILE && values.googleAnalyticsActive
              ? "50%"
              : "100%"
          }
        >
          <FormInputField
            type={FormInputFieldType.TEXT}
            name="partnerDefaultUrl"
            id="partnerDefaultUrl"
            label={t("partnerDefaultUrl")}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
          show={user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormInputField
            type={FormInputFieldType.TEXT}
            id="partnerAppPrefix"
            name="partnerAppPrefix"
            label={t("partnerAppPrefix")}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
          grow={1}
          show={user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormSelectField
            name="partnerCurrency"
            id="partnerCurrency"
            label={t("currency")}
            options={currenciesToSelect(CurrencyOptions)}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
          grow={1}
          show={user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormInputField
            type={FormInputFieldType.TEXT}
            id="bundleId"
            name="bundleId"
            label={t("bundleId")}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
          grow={1}
          show={user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormInputField
            type={FormInputFieldType.TEXT}
            id="packageName"
            name="packageName"
            label={t("packageName")}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
          grow={1}
          show={user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormInputField
            type={FormInputFieldType.LINK}
            name="partnerAppStoreLink"
            id="partnerAppStoreLink"
            label={t("partnerAppStoreLink")}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.MOBILE ? "50%" : "100%"}
          grow={1}
          show={user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormInputField
            type={FormInputFieldType.LINK}
            name="partnerPlayStoreLink"
            id="partnerPlayStoreLink"
            label={t("googlePlayStoreLink")}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width="100%"
          grow={1}
          show={user.role === ExtendedUserRoles.SUPERADMIN}
        >
          <FormSelectField
            name="types"
            id="types"
            label={t("productType")}
            isMulti
            options={values.allTypes}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
      </Content>
    </Accordion>
  );
};

export default BasicData;
