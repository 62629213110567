import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useViewport, ViewportType } from "utils/ViewportContext";
import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";

const Card = styled.div`
  min-width: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "40%" : "100%"};
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  flex-basis: 40%;
  padding: 1.25rem;
`;

const Thumb = styled.img`
  height: 4.5rem;
  width: 9rem;
  object-fit: contain;
  align-self: center;
`;

const ThumbPlaceholder = styled.div`
  height: 4.5rem;
  width: 9rem;
  align-self: center;
  text-transform: uppercase;
  text-align: center;
  line-height: 3.25rem;
  color: ${({ theme }) => theme.colors.grey[400]};
`;

const DataCell = styled.div`
  padding: 1.25rem 0;
  border-top: ${({ theme }) => "1px solid " + theme.colors.grey[100]};

  display: flex;
  justify-content: space-between;
  ${({ column }) => column && "flex-direction: column;"}
`;

const FieldName = styled(Typography)`
  padding-right: 1rem;
`;

const Title = styled(Typography)`
  text-align: center;
  padding: 1.25rem 0;
  word-wrap: break-word;
`;

const AsignInformationContainer = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  padding: 1.25rem 0 0 0;
  align-items: center;
  justify-content: center;
`;

const MarketplaceCard = ({ data, onAssign }) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("marketplace");

  return (
    <Card viewport={viewportType}>
      {data.product_thumb_link ? (
        <Thumb src={data.product_thumb_link} />
      ) : (
        <ThumbPlaceholder>{t("common:notAdded")}</ThumbPlaceholder>
      )}
      <Title
        variant={TypographyVariant.H5}
        weight={TypographyWeight.BOLD}
        text={data.product_name}
      />
      <DataCell>
        <FieldName
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("publishedVersions")}
        />
        <div>
          <Typography
            variant={TypographyVariant.BODY2}
            weight={TypographyWeight.BOLD}
            text={data.published_versions}
            tag="span"
          />
        </div>
      </DataCell>
      <AsignInformationContainer>
        {data.assigned ? (
          <Typography
            variant={TypographyVariant.BUTTON}
            text={t("search.assigned")}
            weight={TypographyWeight.SEMIBOLD}
            tag="span"
          />
        ) : (
          <Button
            variant={ButtonVariant.CONTAINED}
            size={ButtonSize.SMALL}
            onClick={() => onAssign(data.id)}
            minWidth="100%"
            text={t("assign")}
          />
        )}
      </AsignInformationContainer>
    </Card>
  );
};

MarketplaceCard.propTypes = {
  data: PropTypes.object.isRequired,
  onAssign: PropTypes.func.isRequired,
};

export default MarketplaceCard;
