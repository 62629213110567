import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonType,
  ButtonVariant,
} from "common/basicComponents/Button";
import FormInputField, {
  FormInputFieldType,
  NumberType,
  InputSize,
} from "common/formFields/FormInputField";
import FormFileField from "common/formFields/FormFileField";
import FormRangeField from "common/formFields/FormRangeField";
import FormToggleField, {
  ToggleVariant,
} from "common/formFields/FormToggleField";
import ValidationSchema from "./ExampleForm.schema";
import FormSelectField from "common/formFields/FormSelectField";
import FormDateField, { DateInputSize } from "common/formFields/FormDateField";
import { IconName } from "common/basicComponents/Icon";
import FormColorField from "common/formFields/FormColorField";
import FileExtension from "constants/FileExtension";
import Box from "common/basicComponents/Box";
import Tooltip from "common/basicComponents/Tooltip";

const options = [
  {
    value: "chocolate",
    label: "Chocolate",
    group: "Group 1",
    additionalLabel: "additional",
  },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const Example = () => {
  const { t } = useTranslation();
  const [initVal, setInitVal] = useState({});

  useEffect(() => {
    setInitVal({
      text: "initial text",
      price: null,
      uuid: "20938-203948-209-20987",
      date: "2023-05-14T10:00:00.000Z",
      regex: "",
      link: "",
      search: "",
      copy: "",
      color: {
        r: "255",
        g: "255",
        b: "255",
      },
      number: "",
      multiline: "",
      limit: "",
      file: null,
      range: 2,
      toggle: true,
      switch_color: true,
      switch_black: true,
      select: null,
      select_multi: [
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
      ],
    });
  }, []);

  return (
    <div style={{ width: "80%", padding: 30 }}>
      <Typography variant={TypographyVariant.H3} text="Example form" />
      <Formik
        validationSchema={ValidationSchema}
        initialValues={initVal}
        onSubmit={(_, actions) => {
          setTimeout(() => {
            actions.setSubmitting(false);
          }, 1000);
        }}
        enableReinitialize
      >
        {() => (
          <Form>
            <FormToggleField
              id="toggle"
              name="toggle"
              variant={ToggleVariant.CLASSIC}
            />
            <FormToggleField
              label="Switch 1"
              id="switch_color"
              name="switch_color"
              variant={ToggleVariant.SWITCH_COLOR}
            />
            <FormToggleField
              label="Switch 2"
              id="switch_black"
              name="switch_black"
              variant={ToggleVariant.SWITCH_BLACK}
              leftOption="opcja A"
              rightOption="opcja B"
            />
            <Box width="300px">
              <FormFileField
                id="file"
                name="file"
                label="Label"
                caption="Caption"
                acceptedExtensions={[FileExtension.PNG, FileExtension.JPG]}
                smallHintText="image size 1024 x 1024 px"
                aspectRatio={2}
                preview={true}
              />
            </Box>
            <FormSelectField
              name="select"
              id="select"
              label="Select"
              options={options}
            />
            <FormSelectField
              name="select_multi"
              id="select_multi"
              label="Select"
              isMulti
              options={options}
            />
            <FormInputField
              type={FormInputFieldType.TEXT}
              name="text"
              id="text"
              label="Text"
            />
            <Tooltip
              anchorSelect="#text"
              text="Drag and drop to change the orderDrag and drop to change the orderDrag and drop to change the orderDrag and drop to change the orderDrag and drop to change the order"
            />

            <FormInputField
              type={FormInputFieldType.CURRENCY}
              name="price"
              id="price"
              label="Price"
              currency="$"
            />
            <FormColorField id="color" name="color" label="Color" />

            <FormInputField
              type={FormInputFieldType.TEXT}
              name="custom_icon"
              id="custom_icon"
              label="Custom Icon"
              icon={IconName.CLOCK}
            />
            <FormInputField
              type={FormInputFieldType.COPY}
              name="uuid"
              id="uuid"
              label="UUID"
              disabled
              size={InputSize.NORMAL}
            />
            <FormDateField
              name="date"
              id="date"
              label="Date"
              size={DateInputSize.NORMAL}
              disablePastDates
            />
            <FormInputField
              type={FormInputFieldType.LINK}
              name="link"
              id="link"
              label="Link"
            />
            <FormInputField
              type={FormInputFieldType.SEARCH}
              name="search"
              id="search"
              label="Search"
              placeholder="Search"
            />
            <FormInputField
              type={FormInputFieldType.NUMBER}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              name="number"
              id="number"
              label="Number"
              size="large"
            />
            <FormInputField
              type={FormInputFieldType.TEXT}
              size="large"
              name="multiline"
              id="multiline"
              label="Multiline"
              multiline
              rows={2}
            />
            <FormRangeField
              id="range"
              name="range"
              label="Range label"
              min={1}
              max={5}
              step={0.5}
              markStep={1}
            />
            <FormInputField
              type={FormInputFieldType.TEXT}
              name="limit"
              id="limit"
              label="Limit"
              limit={30}
            />
            <FormInputField
              type={FormInputFieldType.REGEX}
              name="regex"
              id="regex"
              label="Regex - A-Z"
              regex={/^[A-Z]*$/}
            />
            <Button
              type={ButtonType.SUBMIT}
              variant={ButtonVariant.CONTAINED}
              text={t("button.save")}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Example;
