import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { v4 as uuidv4 } from "uuid";

import Table from "common/Table";
import { ViewportType } from "utils/ViewportContext";
import EditEffectDialog from "../effects/dialog/EditEffectDialog";
import EffectRow from "../effects/EffectRow";
import EffectCard from "../effects/EffectCard";
import Accordion from "common/basicComponents/Accordion";
import { IconName } from "common/basicComponents/Icon";
import { ButtonSize, ButtonVariant } from "common/basicComponents/Button";
import WarningDialog from "common/basicComponents/WarningDialog";

const EffectsContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 2.5rem 1.25rem;
  row-gap: 0.5rem;
  width: 100%;
  overflow: hidden;
`;

const Effects = () => {
  const { values, setFieldValue, isSubmitting } = useFormikContext();
  const { t } = useTranslation("effects");
  const { colors } = useTheme();

  const [editDialog, setEditDialog] = useState({
    open: false,
    effect: null,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    effect: null,
  });

  const handleSave = (effect) => {
    if (effect.id) {
      const newEffects = [...values.effects];
      const editedIndex = newEffects.findIndex((e) => e.id === effect.id);
      newEffects[editedIndex] = effect;
      setFieldValue("effects", newEffects);
    } else {
      effect.id = uuidv4();
      setFieldValue("effects", [
        ...values.effects,
        { ...effect, active: false },
      ]);
    }
    setEditDialog({ ...editDialog, open: false });
  };

  const handleDelete = () => {
    const newEffects = [...values.effects];
    const deletedIndex = newEffects.findIndex(
      (e) => e.id === deleteDialog.effect.id
    );
    newEffects.splice(deletedIndex, 1);
    setFieldValue("effects", newEffects);
    setDeleteDialog({ ...deleteDialog, open: false });
  };

  const headers = [
    {
      text: t("name"),
      maxWidth: "25%",
    },
    {
      text: t("file"),
      maxWidth: "55%",
    },
    {
      text: t("isActive"),
      maxWidth: "10%",
    },
    {
      text: t("actions"),
      centered: true,
      maxWidth: "2.5rem",
    },
  ];

  return (
    <Accordion
      text={t("effects")}
      buttonText={t("addEffect")}
      buttonIcon={IconName.PLUS}
      buttonAction={() => setEditDialog({ effect: null, open: true })}
      buttonVariant={ButtonVariant.CONTAINED}
      buttonSize={ButtonSize.NORMAL}
      buttonWidth="11.25rem"
      buttonDisabled={isSubmitting}
    >
      <EffectsContent>
        <Table
          headers={headers}
          data={values.effects || []}
          total={values.effects?.length}
          dividerColor={colors.grey[200]}
          keyPropertyName="name"
          tableRow={EffectRow}
          tableCard={EffectCard}
          pagination={false}
          cardViewportBreakpoint={ViewportType.MOBILE}
          onMetaChange={() => {}}
          itemProps={{
            onEdit: (effect) => setEditDialog({ open: true, effect }),
            onDelete: (effect) => setDeleteDialog({ open: true, effect }),
            disabled: isSubmitting,
          }}
          noDataText={t("noData")}
        />
        <EditEffectDialog
          open={editDialog.open}
          effect={editDialog.effect}
          onClose={() => setEditDialog({ ...editDialog, open: false })}
          onSave={handleSave}
        />
        <WarningDialog
          open={deleteDialog.open}
          data={deleteDialog.effect}
          name={deleteDialog.effect?.name}
          onClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
          onAction={handleDelete}
          title={t("deleteAlertTitle")}
          warningButtonText={t("deleteButtonTitle")}
        />
      </EffectsContent>
    </Accordion>
  );
};

export default Effects;
