import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";

import Dialog from "common/basicComponents/Dialog";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { ButtonWidth } from "common/basicComponents/Button";
import Box from "common/basicComponents/Box";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { ButtonType } from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";
import ValidationSchema from "./ApplicationLinkForm.schema";

const Buttons = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "row" : "column-reverse"};
  width: 100%;
  gap: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "2rem" : "1.25rem"};
  margin-top: 2.5rem;
`;

const EditApplicationLinkDialog = ({ open, link, onSave, onClose }) => {
  const { t } = useTranslation("applicationLinks");
  const { type: viewportType } = useViewport();

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    onSave({
      ...link,
      text: values?.text,
      url: values?.url,
    });
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding="0 0 2.5rem">
        <Typography
          variant={TypographyVariant.H4}
          weight={TypographyWeight.BOLD}
          text={link?.id ? t("editLinkTitle") : t("addLinkTitle")}
        />
      </Box>
      <Formik
        initialValues={{
          id: link?.id,
          text: link?.text,
          url: link?.url,
        }}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={ValidationSchema}
      >
        {({ isSubmitting }) => (
          <Form noValidate>
            <Box padding="0.5rem 0 0" width="100%">
              <FormInputField
                type={FormInputFieldType.TEXT}
                name="url"
                id="url"
                label={t("link")}
                disabled={isSubmitting}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormInputField
                type={FormInputFieldType.TEXT}
                name="text"
                id="text"
                label={t("text")}
                disabled={isSubmitting}
              />
            </Box>
            <Buttons viewport={viewportType}>
              <Button
                variant={ButtonVariant.OUTLINED}
                text={t("common:button.cancel")}
                width={ButtonWidth.GROW}
                onClick={onClose}
                disabled={isSubmitting}
              />
              <Button
                variant={ButtonVariant.GREEN}
                text={t("common:button.save")}
                width={ButtonWidth.GROW}
                type={ButtonType.SUBMIT}
                isLoading={isSubmitting}
              />
            </Buttons>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

EditApplicationLinkDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  link: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  domainOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  productOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

EditApplicationLinkDialog.defaultProps = {
  link: null,
};

export default EditApplicationLinkDialog;
