import Button, {
  ButtonVariant,
  ButtonSize,
  ButtonWidth,
} from "common/basicComponents/Button";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Icon, { IconName } from "common/basicComponents/Icon";
import ExampleForm from "example/ExampleForm";
import { addNotification } from "components/notifications/NotificationSlice";
import { NotificationVariant } from "common/basicComponents/Notification";
import Chips, { ChipsVariant } from "common/basicComponents/Chips";
import Table from "common/Table";
import TableRow from "example/TableRow";
import TableCard from "example/TableCard";
import Dialog from "common/basicComponents/Dialog";
import Accordion from "common/basicComponents/Accordion";
import Loader from "common/basicComponents/Loader";
import Header from "common/page/Header";
import Content from "common/page/Content";
import Page from "common/page/Page";
import TabNavigation from "common/basicComponents/Tabs";
import { TabVariant } from "common/basicComponents/Tabs";
import { ViewportType } from "utils/ViewportContext";
import { ButtonIconAlign } from "common/basicComponents/Button";
import { PageSizes } from "common/Table/Pagination";

const headers = [
  {
    text: "Company",
  },
  {
    text: "Contact",
  },
  {
    text: "Country",
    centered: true,
  },
];

const data = [
  {
    one: "Alfreds Futterkiste 1",
    two: "Maria Anders",
    three: "Germany",
  },
  {
    one: "Centro comercial Moctezuma 2",
    two: "Francisco Chang",
    three: "Mexico",
  },
  {
    one: "Alfreds Futterkiste 3",
    two: "Maria Anders",
    three: "Germany",
  },
  {
    one: "Centro comercial Moctezuma 4",
    two: "Francisco Chang",
    three: "Mexico",
  },
  {
    one: "Alfreds Futterkiste  5",
    two: "Maria Anders",
    three: "Germany",
  },
  {
    one: "Centro comercial Moctezuma 6",
    two: "Francisco Chang",
    three: "Mexico",
  },
  {
    one: "Alfreds Futterkiste 7",
    two: "Maria Anders",
    three: "Germany",
  },
  {
    one: "Centro comercial Moctezuma 8",
    two: "Francisco Chang",
    three: "Mexico",
  },
  {
    one: "Alfreds Futterkiste 9",
    two: "Maria Anders",
    three: "Germany",
  },
  {
    one: "Centro comercial Moctezuma 10",
    two: "Francisco Chang",
    three: "Mexico",
  },
  {
    one: "Alfreds Futterkiste 11",
    two: "Maria Anders",
    three: "Germany",
  },
  {
    one: "Centro comercial Moctezuma 12",
    two: "Francisco Chang",
    three: "Mexico",
  },
  {
    one: "Alfreds Futterkiste 13",
    two: "Maria Anders",
    three: "Germany",
  },
  {
    one: "Centro comercial Moctezuma 14",
    two: "Francisco Chang",
    three: "Mexico",
  },
  {
    one: "Alfreds Futterkiste 15",
    two: "Maria Anders",
    three: "Germany",
  },
  {
    one: "Centro comercial Moctezuma 16",
    two: "Francisco Chang",
    three: "Mexico",
  },
  {
    one: "Alfreds Futterkiste 17",
    two: "Maria Anders",
    three: "Germany",
  },
  {
    one: "Centro comercial Moctezuma 18",
    two: "Francisco Chang",
    three: "Mexico",
  },
  {
    one: "Alfreds Futterkiste 19",
    two: "Maria Anders",
    three: "Germany",
  },
  {
    one: "Centro comercial Moctezuma 20",
    two: "Francisco Chang",
    three: "Mexico",
  },
  {
    one: "Alfreds Futterkiste 21",
    two: "Maria Anders",
    three: "Germany",
  },
  {
    one: "Centro comercial Moctezuma 22",
    two: "Francisco Chang",
    three: "Mexico",
  },
  {
    one: "Alfreds Futterkiste 23",
    two: "Maria Anders",
    three: "Germany",
  },
  {
    one: "Centro comercial Moctezuma 24",
    two: "Francisco Chang",
    three: "Mexico",
  },
];

const Example = () => {
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [meta, setMeta] = useState({ page: 1, pageSize: PageSizes[0] });
  const total = data.length;

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        addNotification({
          variant: NotificationVariant.SUCCESS,
          message: "Great notification!",
        })
      );
    }, 5000);
  }, [dispatch]);

  return (
    <Page>
      <Header
        title="Header"
        subtitles={[
          ["Total number of receivers:", "Last 30 days"],
          ["Number of application users:", "156"],
        ]}
        button={
          <Button
            width="15%"
            variant={ButtonVariant.GREEN}
            text="Click"
            onClick={() => {}}
          />
        }
      >
        treść treść
      </Header>
      <Content>
        <TabNavigation
          tabs={["Tab1", "Tab2", "Tab3"]}
          contents={[
            <div key="1">content1</div>,
            <div key="2">content2</div>,
            <div key="3">content3</div>,
          ]}
        />
        <TabNavigation
          variant={TabVariant.MAIN}
          tabs={["General", "T", "Tab3"]}
          contents={[
            <div key="1">content1</div>,
            <div key="2">content2</div>,
            <div key="3">content3</div>,
          ]}
        />
        <div
          style={{
            display: "flex",
            gap: "10px",
            padding: 10,
            flexWrap: "wrap",
            background: "white",
          }}
        >
          <Button
            variant={ButtonVariant.OUTLINED}
            size={ButtonSize.LARGE}
            text="outlined"
          />
          <Button
            variant={ButtonVariant.CONTAINED}
            size={ButtonSize.SMALL}
            text="contained"
          />
          <Button variant={ButtonVariant.GREEN} text="green" disabled />
          <Button
            variant={ButtonVariant.GREEN}
            text="green"
            size={ButtonSize.SMALL}
          />
          <Button variant={ButtonVariant.TEXT} text="text" />

          <Typography
            variant={TypographyVariant.BUTTON}
            weight={TypographyWeight.SEMIBOLD}
            text="HEJ"
          />
          <Icon size="2.25rem" name={IconName.ZOOM_IN} />
          <Button
            variant={ButtonVariant.CONTAINED}
            size={ButtonSize.SMALL}
            icon={IconName.TRASH_OUTLINED}
            isLoading
            text="button"
          />
          <Button
            variant={ButtonVariant.TEXT}
            size={ButtonSize.NORMAL}
            icon={IconName.TRASH_OUTLINED}
            text="button"
          />
          <Button
            variant={ButtonVariant.OUTLINED}
            size={ButtonSize.LARGE}
            icon={IconName.ZOOM_IN}
            text="button"
          />
          <Button
            variant={ButtonVariant.OUTLINED}
            size={ButtonSize.LARGE}
            icon={IconName.TRASH}
            text="button"
            width="200px"
            iconAlign={ButtonIconAlign.LEFT}
          />
          <Button
            variant={ButtonVariant.OUTLINED}
            size={ButtonSize.XSMALL}
            icon={IconName.TRASH_OUTLINED}
          />
          <Button
            variant={ButtonVariant.OUTLINED_GRADIENT}
            size={ButtonSize.SMALL}
            icon={IconName.TRASH_OUTLINED}
          />
          <Button
            variant={ButtonVariant.TEXT}
            size={ButtonSize.SMALL}
            icon={IconName.TRASH}
          />
        </div>
        <Button text="dialog" onClick={() => setDialogOpen(true)} />
        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
          <Typography variant={TypographyVariant.H3} text="Dialog" />
        </Dialog>
        <div style={{ width: "80%" }}>
          <Loader show={true} />
        </div>
        <div style={{ width: "100%" }}>
          <div style={{ display: "flex", gap: 15, marginBottom: 20 }}>
            <Button text="auto" width={ButtonWidth.AUTO} />
            <Button text="grow" width={ButtonWidth.GROW} />
            <Button
              variant={ButtonVariant.CONTAINED}
              size={ButtonSize.NORMAL}
              icon={IconName.TRASH}
            />
          </div>
          <div style={{ display: "flex", gap: 15, flexWrap: "wrap" }}>
            <Button text="40%" width="40%" />
            <Button text="grow" width={ButtonWidth.GROW} />
            <Button text="200" width={200} />
          </div>
        </div>
        <div
          style={{
            width: "100%",
            gap: 10,
            display: "flex",
            flexDirection: "column",
            background: "white",
          }}
        >
          <Accordion text="Chips">
            <Chips variant={ChipsVariant.YELLOW} text="Yellow" />
            <Chips variant={ChipsVariant.GREY} text="Grey" />
            <Chips variant={ChipsVariant.VIOLET} text="Violet" />
            <Chips variant={ChipsVariant.GREEN} text="Green" />
            <Chips variant={ChipsVariant.ROSE} text="Rose" />
            <Chips variant={ChipsVariant.BLACK} text="Black" />
            <Chips variant={ChipsVariant.DARK_GREY} text="Dark grey" />
            <Chips variant={ChipsVariant.LIGHT_GREY} text="Light grey" />
          </Accordion>
          <Accordion
            text="Form"
            buttonText="Hello"
            buttonIcon={IconName.PLUS}
            buttonAction={() => {}}
            buttonVariant={ButtonVariant.ERROR}
          >
            <ExampleForm />
          </Accordion>
          <Accordion text="Table">
            <Table
              headers={headers}
              data={data.slice(
                (meta.page - 1) * meta.pageSize,
                Math.min(total, meta.page * meta.pageSize)
              )}
              total={total}
              tableRow={TableRow}
              tableCard={TableCard}
              cardViewportBreakpoint={ViewportType.TABLET}
              keyPropertyName="one"
              meta={meta}
              onMetaChange={setMeta}
              noDataText="No rows to display"
            />
          </Accordion>
        </div>
      </Content>
    </Page>
  );
};

export default Example;
