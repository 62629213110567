import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "formik";
import PropTypes from "prop-types";

import { AssignedOptions } from "./MarketplaceSearchOptions";
import { useViewport, ViewportType } from "utils/ViewportContext";
import DesktopSearchForm from "./DesktopSearchForm";
import MobileSearchForm from "./MobileSearchForm";
import CustomFormik from "common/basicComponents/CustomFormik";

const ProductSearch = ({ onSearch }) => {
  const { t } = useTranslation("marketplace");
  const { type: viewportType } = useViewport();

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    onSearch({
      productNameSearch: values.productName,
      assignedSearch:
        values.status.value === AssignedOptions.ALL
          ? null
          : values.status.value === AssignedOptions.ASSIGNED,
    });
    setSubmitting(false);
  };

  return (
    <CustomFormik
      initialValues={{
        productName: "",
        status: { label: t("search.all"), value: AssignedOptions.ALL },
      }}
      onSubmit={onSubmit}
      countChangedFields
    >
      <Form>
        {viewportType === ViewportType.DESKTOP ? (
          <DesktopSearchForm />
        ) : (
          <MobileSearchForm />
        )}
      </Form>
    </CustomFormik>
  );
};

ProductSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default ProductSearch;
