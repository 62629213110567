import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import io from "socket.io-client";
import styled from "styled-components";
import { REACT_APP_EDITOR_BACKEND_WS_URL } from "constants/env";
import EditorCanvas from "./EditorCanvas";
import GradientBackground from "common/basicComponents/GradientBackground";
import Button, { ButtonType } from "common/basicComponents/Button";
import { ButtonVariant } from "common/basicComponents/Button";

const FullScreenContainer = styled.div`
  position: fixed;
  inset: 0;
  background: ${({ theme }) => theme.colors.white};
  z-index: 10;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  position: relative;
  min-height: 5rem;
  overflow: hidden;
  padding: 1.25rem 5rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};

  display: flex;
  gap: 0.625rem;
  justify-content: space-between;

  & button {
    min-width: 12.5rem;
  }
`;

const Editor = ({
  inputFile,
  maskFile,
  onBack,
  onNext,
  nextText,
  hintText,
  photoTitle,
}) => {
  const { t } = useTranslation("wizard");

  const [isFirstConnection, setIsFirstConnection] = useState(true);
  const [mask, setMask] = useState(maskFile);
  const [maskHistory, setMaskHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const maskRef = React.useRef(mask);
  const socketRef = useRef();

  useEffect(() => {
    maskRef.current = mask;
  });

  // Socket functions
  const onInitFinished = useCallback(
    (response) => {
      if (response?.error) {
        onBack();
        return;
      }
      if (response?.data) {
        setMask({ src: response.data });
        setMaskHistory([{ src: response.data }]);
        setIsFirstConnection(false);
      }
      setLoading(false);
    },
    [onBack]
  );

  const onEditFinished = (response, save = false) => {
    if (response?.error) return;
    if (response?.data) {
      setMask({ src: response.data });
      if (save) {
        setMaskHistory((masks) => {
          masks.push({ src: response.data });
          return masks.slice(-5);
        });
      }
    }
  };

  const init = useCallback(() => {
    setLoading(true);
    if (!maskRef.current?.src) {
      socketRef.current.emit("init", inputFile.src, onInitFinished);
    } else {
      socketRef.current.emit(
        "reconnect",
        inputFile.src,
        maskRef.current.src,
        isFirstConnection,
        onInitFinished
      );
    }
  }, [inputFile.src, onInitFinished, isFirstConnection]);

  const edit = useCallback((x, y, brushRadius, tool, save = false) => {
    socketRef.current.emit("edit", x, y, brushRadius, tool, (res) =>
      onEditFinished(res, save)
    );
  }, []);

  const undo = useCallback(() => {
    setMaskHistory((masks) => {
      if (masks.length > 1) {
        masks.pop();
        const previous = masks.slice(-1)[0].src;
        socketRef.current.emit("undo", previous, onEditFinished);
      }
      return masks;
    });
  }, []);

  const onSocketConnected = useCallback(() => {
    if (!inputFile?.src) return;
    init();
  }, [init, inputFile]);

  const onSocketDisconnected = () => {
    setLoading(true);
  };

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = io(REACT_APP_EDITOR_BACKEND_WS_URL);
    }
    socketRef.current.on("connect", onSocketConnected);
    socketRef.current.on("disconnect", onSocketDisconnected);
    return () => {
      socketRef.current.off("connect");
      socketRef.current.off("disconnect");
    };
  }, [onSocketConnected]);

  useEffect(() => {
    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  // End of socket functions

  return (
    <FullScreenContainer>
      <Header>
        <GradientBackground />
        <Button
          type={ButtonType.BUTTON}
          variant={ButtonVariant.OUTLINED}
          onClick={onBack}
          text={t("common:button.back")}
        />
        <Button
          type={ButtonType.BUTTON}
          variant={ButtonVariant.GREEN}
          onClick={() => onNext(maskRef.current.src)}
          text={nextText}
          disabled={loading}
        />
      </Header>
      <EditorCanvas
        onEdit={edit}
        onUndo={undo}
        isUndoPossible={maskHistory.length > 1}
        backgroundImg={inputFile?.src}
        maskImg={mask?.src}
        loading={loading}
        hintText={hintText}
        photoTitle={photoTitle}
      />
    </FullScreenContainer>
  );
};

Editor.propTypes = {
  inputFile: PropTypes.object.isRequired,
  maskFile: PropTypes.object,
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  nextText: PropTypes.string.isRequired,
  hintText: PropTypes.string.isRequired,
  photoTitle: PropTypes.string.isRequired,
};

Editor.defaultProps = {
  maskFile: null,
};

export default Editor;
