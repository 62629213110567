import React from "react";
import { createGlobalStyle } from "styled-components";
import { ViewportMinBreakpoints } from "utils/ViewportContext";

const BaseGlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    border-radius: 0;
    -webkit-tap-highlight-color: transparent;
    scrollbar-color: ${({ theme }) => theme.colors.black} ${({ theme }) =>
  theme.colors.grey[200]};
  }
  *:disabled {
    pointer-events: none;
  }
  *:focus-visible {
    outline: none;
  }
  button, [role="button"] {
    user-select: none;
    cursor: pointer;
  }
  body {
    overflow-y: hidden;
  }
  #root {
    overflow-x: hidden;
  }
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .MuiBackdrop-root.MuiModal-backdrop {
    background-color: rgba(255, 255, 255, 0.75);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    opacity: 0;
    transition: opacity 400ms ease-in-out !important;
  }
  .MuiPickersPopper-root {
    padding: 0.25rem 0rem;
  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  @media screen and (min-width: ${ViewportMinBreakpoints.DESKTOP}px) {
    ::-webkit-scrollbar {
      width: 0.5rem;
      height: 0.5rem;
    }
  }
  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.grey[200]};
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.black};
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const GlobalSvgDefinitions = () => (
  <svg
    width="0"
    height="0"
    viewBox="0 0 0 0"
    style={{ visibility: "hidden", position: "absolute" }}
  >
    <title>SVG resources</title>
    <defs>
      <linearGradient id="gradient">
        <stop offset="0%" stopColor="#BA1176" />
        <stop offset="100%" stopColor="#6B2BEC" />
      </linearGradient>
    </defs>
  </svg>
);

const GlobalStyle = () => (
  <>
    <BaseGlobalStyle />
    <GlobalSvgDefinitions />
  </>
);

export default GlobalStyle;
