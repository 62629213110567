import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import { appendLinks } from "./LinkSlice";
import { handleFormError } from "api/ApiClient";

export const getLinks = (searchParams) => {
  if (searchParams) searchParams.limit = null;
  return apiClient
    .get(ApiEndpoints.LINKS, { params: searchParams })
    .then((res) => {
      const links = res?.data?.body;
      const meta = res?.data?.meta;
      if (!links || !meta) return false;
      store.dispatch(appendLinks({ links, meta }));
      return true;
    })
    .catch(() => false);
};

export const addLink = (link, form) => {
  return apiClient
    .post(ApiEndpoints.LINKS, link)
    .then((res) => res?.data?.id)
    .catch((err) => handleFormError(err, form));
};

export const editLink = (id, link, form) => {
  return apiClient
    .put(`${ApiEndpoints.LINKS}${id}`, link)
    .then(() => true)
    .catch((err) => handleFormError(err, form));
};

export const deleteLink = (id) => {
  return apiClient
    .delete(`${ApiEndpoints.LINKS}${id}`)
    .then(() => true)
    .catch(() => false);
};
