import Table from "common/Table";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ViewportType } from "utils/ViewportContext";
import SetCard from "./SetCard";
import SetRow from "./SetRow";

const SetTable = ({
  sets,
  total,
  meta,
  onMetaChange,
  loading,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation("sets");

  const headers = [
    {
      text:  "",//t("primaryThumbnail"),
      centered: true,
      maxWidth: "8rem",
    },
    {
      text: "",//t("secondaryThumbnail"),
      centered: true,
      maxWidth: "9rem",
    },
    {
      text: t("setName"),
    },
    {
      text: t("Products"),
    },
    {
      text: t("webLinkUrl"),
    },
    {
      text: t("actions"),
      centered: true,
    },
  ];

  return (
    <Table
      headers={headers}
      data={sets}
      total={total}
      tableRow={SetRow}
      tableCard={SetCard}
      cardViewportBreakpoint={ViewportType.TABLET}
      keyPropertyName="set_id"
      meta={meta}
      onMetaChange={onMetaChange}
      loading={loading}
      itemProps={{
        onEdit,
        onDelete,
      }}
      noDataText={t("noData")}
    />
  );
};

SetTable.propTypes = {
  sets: PropTypes.array,
  total: PropTypes.number,
  meta: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  onMetaChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

SetTable.defaultProps = {
  products: [],
  total: 0,
  loading: false,
};

export default SetTable;
