import React from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useViewport } from "utils/ViewportContext";
import Box from "./Box";
import { ViewportType } from "utils/ViewportContext";
import Typography, { TypographyVariant, TypographyWeight } from "./Typography";

const Badge = styled(Box)`
  background: ${({ theme }) => theme.colors.secondary[600]};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterBadge = ({ className }) => {
  const { status } = useFormikContext();
  const { type: viewportType } = useViewport();

  if (!status?.changedFields) {
    return null;
  }

  return (
    <Badge
      className={className}
      width={viewportType === ViewportType.MOBILE ? "1.125rem" : "1.25rem"}
      height={viewportType === ViewportType.MOBILE ? "1.125rem" : "1.25rem"}
    >
      <Typography
        variant={TypographyVariant.OVERLINE}
        weight={TypographyWeight.BOLD}
        text={status.changedFields}
      />
    </Badge>
  );
};

FilterBadge.propTypes = {
  className: PropTypes.string,
};

FilterBadge.defaultProps = {
  className: "",
};

export default FilterBadge;
