import { createSlice } from "@reduxjs/toolkit";
import { clearAuthData, setCurrentPartner } from "components/auth/AuthSlice";

const initialState = {
  tagGroups: [],
};

export const tagGroupsSlice = createSlice({
  name: "tagGroups",
  initialState,
  reducers: {
    setTagGroups: (state, action) => {
      state.tagGroups = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
    builder.addCase(setCurrentPartner, () => initialState);
  },
});

export const { setTagGroups } = tagGroupsSlice.actions;
export default tagGroupsSlice.reducer;
