import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Dialog from "common/basicComponents/Dialog";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import { useTranslation } from "react-i18next";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { ButtonWidth } from "common/basicComponents/Button";
import { Formik, Form } from "formik";
import Box from "common/basicComponents/Box";
import FormSelectField from "common/formFields/FormSelectField";
import { ButtonType } from "common/basicComponents/Button";
import ValidationSchema from "./AddVersion.schema";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";

const Buttons = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "row" : "column-reverse"};
  width: 100%;
  gap: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "2rem" : "1.25rem"};
  margin-top: 2.5rem;
`;

const AddVersionDialog = ({
  open,
  onSave,
  onClose,
  productOptions,
  loading,
}) => {
  const { t } = useTranslation("totems");
  const { type: viewportType } = useViewport();
  const [versionOptions, setVersionOptions] = useState([]);

  const onSubmit = (values, { setSubmitting }) => {
    onSave(
      {
        versionId: values.version.value,
      },
      setSubmitting
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding="0 0 2.5rem">
        <Typography
          variant={TypographyVariant.H4}
          weight={TypographyWeight.BOLD}
          text={t("versions.addDialogTitle")}
        />
      </Box>
      <Formik
        initialValues={{
          version: {
            label: "",
            value: "",
          },
          product: {
            label: "",
            value: "",
          },
        }}
        validationSchema={ValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ setFieldValue }) => (
          <Form noValidate>
            <Box width="100%">
              <FormSelectField
                name="product"
                id="product"
                label={t("versions.product")}
                options={productOptions}
                disabled={loading}
                onChange={(product) => {
                  setFieldValue("version", { label: "", value: "" });
                  setVersionOptions(product.versions);
                }}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormSelectField
                name="version"
                id="version"
                label={t("versions.version")}
                options={versionOptions}
                disabled={loading}
              />
            </Box>
            <Buttons viewport={viewportType}>
              <Button
                variant={ButtonVariant.OUTLINED}
                text={t("common:button.cancel")}
                width={ButtonWidth.GROW}
                onClick={onClose}
                disabled={loading}
              />
              <Button
                variant={ButtonVariant.GREEN}
                text={t("common:button.save")}
                width={ButtonWidth.GROW}
                type={ButtonType.SUBMIT}
                isLoading={loading}
              />
            </Buttons>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

AddVersionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  productOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default AddVersionDialog;
