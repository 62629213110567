import React from "react";
import styled from "styled-components";
import LoginButton from "components/auth/google/LoginButton";
import CreateAccountButton from "components/auth/google/CreateAccountButton";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Logo from "common/basicComponents/Logo";
import { LogoVariant } from "common/basicComponents/Logo";
import Typography from "common/basicComponents/Typography";
import { TypographyVariant } from "common/basicComponents/Typography";
import { TypographyWeight } from "common/basicComponents/Typography";
import GradientBackground, {
  BackgroundSize,
} from "common/basicComponents/GradientBackground";
import { useTranslation } from "react-i18next";
import AppStoreBadge from "../../assets/login/app-store-badge.svg";
import PlayStoreBadge from "../../assets/login/google-play-badge.svg";
import { APP_STORE_URL, PLAY_STORE_URL } from "constants/StoreLinks";

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const LoginContainer = styled.div`
  height: 100vh;
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
`;
const LogoContainer = styled.div`
  width: 40%;
  max-width: 12.5rem;
  aspect-ratio: 1;
  margin-top: 12.5vh;
  & svg {
    width: 100%;
    height: 100%;
  }
`;
const ButtonsContainer = styled.div`
  padding-top: 12.5vh;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
const Footer = styled.div`
  margin: 2.5rem;
`;
const PromoContainer = styled.div`
  height: 100vh;
  flex: 3;
  ${({ viewportType }) =>
    viewportType < ViewportType.DESKTOP && "display: none;"}
  border-left: 1px solid ${({ theme }) => theme.colors.black};
`;

const PromoContent = styled.div`
  padding: 2rem 10%;
  text-align: right;
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: right;
  justify-content: space-around;
`;

const MainText = styled.div`
  max-width: 54rem;
  .subtitle {
    margin-top: 2rem;
    white-space: pre-line;
  }
  .gradient-text {
    background: ${({ theme }) => theme.colors.gradient[100]};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const BadgeImg = styled.img`
  width: 189px;
  height: 56px;
  cursor: pointer;
`;

const StoreBadges = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 32px;
  justify-content: flex-end;
`;

const Auth = () => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("auth");

  return (
    <MainContainer>
      <LoginContainer>
        <LogoContainer>
          <Logo variant={LogoVariant.VERTICAL} />
        </LogoContainer>

        <ButtonsContainer>
          <LoginButton />
          <CreateAccountButton />
        </ButtonsContainer>
        <Footer>
          <Typography
            variant={TypographyVariant.OVERLINE}
            weight={TypographyWeight.SEMIBOLD}
            text={<>&copy; 2023 BY AR-LABS.IO</>}
          />
        </Footer>
      </LoginContainer>
      <PromoContainer viewportType={viewportType}>
        <GradientBackground size={BackgroundSize.TABLET} />
        <PromoContent>
          <MainText>
            <Typography
              variant={TypographyVariant.H1}
              weight={TypographyWeight.LIGHT}
              tag="span"
              text={t("title")[0]}
            />
            <Typography
              variant={TypographyVariant.H1}
              weight={TypographyWeight.BOLD}
              tag="span"
              text={t("title")[1]}
            />
            <Typography
              variant={TypographyVariant.H1}
              weight={TypographyWeight.LIGHT}
              tag="span"
              text={t("title")[2]}
            />
            <Typography
              variant={TypographyVariant.H1}
              weight={TypographyWeight.BOLD}
              tag="span"
              text={t("title")[3]}
              className="gradient-text"
            />
            <Typography
              className="subtitle"
              variant={TypographyVariant.H4}
              weight={TypographyWeight.LIGHT}
              text={t("subtitle")}
            />
          </MainText>
          <StoreBadges>
            <BadgeImg
              src={AppStoreBadge}
              alt={"app-store-badge"}
              onClick={() => window.open(APP_STORE_URL)}
            />
            <BadgeImg
              src={PlayStoreBadge}
              alt={"play-store-badge"}
              onClick={() => window.open(PLAY_STORE_URL)}
            />
          </StoreBadges>
        </PromoContent>
      </PromoContainer>
    </MainContainer>
  );
};

export default Auth;
