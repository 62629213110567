const getAllReceivers = (partner) => {
  const day = new Date().toISOString().substring(0, 10);
  const yesterday = new Date(new Date().getDate() - 1)
    .toISOString()
    .substring(0, 10);
  return (
    partner?.onesignal_data?.[day] ||
    partner?.onesignal_data?.[yesterday] ||
    null
  );
};

export { getAllReceivers };
