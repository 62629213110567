import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    version: Yup.object().shape({
      value: Yup.string().required("common:validator.required"),
    }),
    product: Yup.object().shape({
      value: Yup.string().required("common:validator.required"),
    }),
    domain: Yup.object().shape({
      value: Yup.string().required("common:validator.required"),
    }),
    webLinkUrl: Yup.string().required("common:validator.required"),
  });
};
