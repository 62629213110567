import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LogoHorizontal } from "assets/logo/Logo_Horizontal.svg";
import { ReactComponent as LogoVertical } from "assets/logo/Logo_Vertical.svg";

export const LogoVariant = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

const getLogoConfig = (variant) =>
  ({
    [LogoVariant.HORIZONTAL]: {
      element: LogoHorizontal,
      width: "7.5rem",
      height: "3rem",
    },
    [LogoVariant.VERTICAL]: {
      element: LogoVertical,
      width: "6.25rem",
      height: "6.25rem",
    },
  }[variant]);

const Logo = ({ variant, className }) => {
  const config = getLogoConfig(variant);
  const LogoElement = config.element;

  return (
    <LogoElement
      width={config.width}
      height={config.height}
      className={className}
    />
  );
};

Logo.propTypes = {
  variant: PropTypes.oneOf(Object.values(LogoVariant)),
  className: PropTypes.string,
};

Logo.defaultProps = {
  variant: LogoVariant.HORIZONTAL,
  className: null,
};

export default Logo;
