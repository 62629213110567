import React, { useState, useEffect, useRef, useCallback } from "react";
import styled, { css, useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import { v4 as uuidv4 } from "uuid";

import Page from "common/page/Page";
import Header from "common/page/Header";
import Content from "common/page/Content";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import { addNotification } from "components/notifications/NotificationSlice";
import { NotificationVariant } from "common/basicComponents/Notification";
import RouterPaths from "constants/RouterPaths";
import {
  tagSelectToList,
  tagListToSelect,
  findChangedTags,
} from "utils/TagUtils";
import ProductForm, { initialVersionValues } from "./form/ProductForm";
import ValidationSchema from "./form/ProductForm.schema";
import {
  getProduct,
  deleteProduct,
  moveProductToMarketplace,
  editProduct,
} from "./ProductActions";
import { FileState } from "common/formFields/FormFileField";
import { useViewport, ViewportType } from "utils/ViewportContext";
import { ButtonWidth } from "common/basicComponents/Button";
import ModelType from "constants/ModelType";
import Loader from "common/basicComponents/Loader";
import Box from "common/basicComponents/Box";
import { formatTimestamp } from "utils/DateTimeUtils";
import { findDeletedVersions } from "utils/ProductUtils";
import ProductLogs from "./logs/ProductLogs";
import WarningDialog from "common/basicComponents/WarningDialog";
import { ExtendedUserRoles } from "constants/UserRoles";
import { scrollToTop } from "utils/PageUtils";
import Method from "constants/Product2DMethods";

const getHeaderStyle = (viewport) =>
  ({
    [ViewportType.DESKTOP]: css`
      column-gap: 1.25rem;
      row-gap: 1rem;
      margin-top: 1.5rem;
    `,
    [ViewportType.TABLET]: css`
      column-gap: 1rem;
      row-gap: 1rem;
      margin-top: 1.875rem;
    `,
    [ViewportType.MOBILE]: css`
      column-gap: 0.625rem;
      row-gap: 1rem;
      margin-top: 1.25rem;
    `,
  }[viewport]);

const HeaderButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ viewport }) => getHeaderStyle(viewport)}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-grow: 3;
  ${({ viewport }) => getHeaderStyle(viewport)}
  margin: 0;
`;

const ButtonsMenu = styled.div`
  width: 100%;
  margin-top: 1.25rem;
  padding: 1.25rem;
  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[100]} 1;
  background: ${({ theme }) => theme.colors.white};
  display: ${({ show }) => (show ? "flex" : "none")};

  flex-direction: column;
  gap: 0.625rem;
`;

const Placeholder = styled.div`
  display: none;
  @media screen and (min-width: 1500px) {
    display: block;
    flex-grow: 10;
  }
`;

const LoaderType = {
  INITIAL: "initial",
  SAVE: "save",
  MOVE: "move",
  DELETE: "delete",
};

const EditProduct = () => {
  const { t } = useTranslation("product");
  const { id: partnerProductId } = useParams();
  const { type: viewportType } = useViewport();
  const navigate = useNavigate();
  const { colors } = useTheme();
  const { user, partners, partner } = useSelector((state) => state.auth);
  const { product } = useSelector((state) => state.products);

  const [initialValues, setInitialValues] = useState({});
  const [loader, setLoader] = useState(LoaderType.INITIAL);
  const [showMarketplaceAlert, setShowMarketplaceAlert] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [buttonMenuOpen, setButtonMenuOpen] = useState(false);
  const [isDirtyForm, setIsDirtyForm] = useState(false);
  const [unsavedEditsDialog, setUnsavedEditsDialog] = useState({
    show: false,
    action: () => {},
  });

  const addVersionBtnRef = useRef();
  const saveBtnRef = useRef();
  const dispatch = useDispatch();

  const getProductData = useCallback(() => {
    return getProduct(partnerProductId)
      .then((product) => {
        if (product) {
          const productValues = {
            fromMarketplace: product.from_main_partner && !partner.main_partner,
            fromOtherPartner: product.from_other_partner,
            id: product.id,
            partnerProductId: product.partners_product_id,
            modelType: {
              value: product.is_2d ? ModelType.MODEL2D : ModelType.MODEL3D,
            },
            glassesWidth: product.product_dimensions?.glassesWidth || null,
            lensHeight: product.product_dimensions?.lensHeight || null,
            published: product.product_published,
            name: product.product_name,
            brand: product.brand_id
              ? {
                  label: product.brand_name,
                  value: product.brand_id,
                }
              : null,
            type: {
              code: product.type_name,
              label: t(`types:${product.type_name}`),
              value: product.type_id,
            },
            filesZipLink: product.product_files_zip_link,
            modelFile: product.modelFile,
            modelFileFbx: product.modelFileFbx,
            modelFileUploaded: product.product_model_file_uploaded, // All "uploaded" fields are necessary until the refactor of a productContoller
            modelFileFbxUploaded: product.product_model_file_fbx_uploaded,
            aspectRatioIos: product.product_aspect_ratio_ios,
            aspectRatioAndroid: product.product_aspect_ratio_android,
            aspectRatioWeb: product.product_aspect_ratio_web,
            positionYIos: product.product_position_y_ios,
            positionYAndroid: product.product_position_y_android,
            positionYWeb: product.product_position_y_web,
            positionZIos: product.product_position_z_ios,
            positionZAndroid: product.product_position_z_android,
            positionZWeb: product.product_position_z_web,
            rotationIos: product.product_rotation_ios,
            rotationAndroid: product.product_rotation_android,
            rotationWeb: product.product_rotation_web,
            earRotationWeb: product.product_ear_rotation_web,
            earpieceDistanceScale: product.product_earpiece_distance_scale,
            useMetallicGlass: product.product_use_metallic_glass,
            tags: tagListToSelect(product.tags) || [],
            versions: product.versions?.map((v) => {
              return {
                key: uuidv4(),
                id: v.id,
                isAssigned: !!v.partners_version_id,
                partnersVersionId: v.partners_version_id,
                isNewAssign: false,
                published: v.version_published,
                modificationTime: v.updated_at
                  ? formatTimestamp(v.updated_at)
                  : null,
                name: v.version_name,
                code: v.version_code,
                sku: v.version_sku,
                pageLink: v.version_shop_url,
                price: v.version_price ? v.version_price.toString() : null,
                specialPrice: v.version_special_price
                  ? v.version_special_price.toString()
                  : null,
                thumbLink: v.version_thumb_link,
                smallThumbLink: v.version_thumb_small_link,
                albedoFile: v.albedoFile,
                metallicFile: v.metallicFile,
                normalFile: v.normalFile,
                frontFile: v.frontFile,
                lensesFile: v.lensesFile,
                rightFile: v.rightFile,
                leftFile: v.leftFile,
                albedoFileUploaded: v.version_texture_albedo_uploaded, // All "uploaded" fields are necessary until the refactor of a productContoller
                metallicFileUploaded: v.version_texture_metallic_uploaded,
                normalFileUploaded: v.version_texture_normal_uploaded,
                frontFileUploaded: v.version_front_uploaded,
                lensesFileUploaded: v.version_lenses_uploaded,
                leftFileUploaded: v.version_left_uploaded,
                rightFileUploaded: v.version_right_uploaded,
                tags: tagListToSelect(v.tags) || [],
                thumbFile: v.thumbFile,
              };
            }) || [{ ...initialVersionValues, key: uuidv4() }],
          };
          setInitialValues(productValues);
        }
      })
      .finally(() => setLoader(null));
  }, [partner, partnerProductId, t]);

  useEffect(() => {
    if (partnerProductId && partners?.length > 0) {
      getProductData();
    }
  }, [partnerProductId, partners, user, getProductData]);

  const handleDelete = () => {
    setLoader(LoaderType.DELETE);
    deleteProduct(partnerProductId).then((deleted) => {
      setLoader(null);
      if (deleted) {
        dispatch(
          addNotification({
            message: t("common:form.deletedSuccessfully"),
            variant: NotificationVariant.SUCCESS,
          })
        );
        navigate(RouterPaths.PRODUCTS);
      }
    });
  };

  const handleMove = () => {
    setLoader(LoaderType.MOVE);
    setShowMarketplaceAlert(false);
    moveProductToMarketplace(product.id).then((productMoved) => {
      setLoader(null);
      if (productMoved) {
        dispatch(
          addNotification({
            message: t("moveToMarketPlaceSuccess"),
            variant: NotificationVariant.SUCCESS,
          })
        );
        navigate(RouterPaths.PRODUCTS);
      }
    });
  };

  const handleSubmit = (values, form) => {
    form.setSubmitting(false);
    if (!product) return;
    if (!loader === LoaderType.SAVE) return;

    const changedTags = findChangedTags(product.tags, values.tags);

    const editedProduct = {
      ...values,
      brandId: values.brand?.value,
      typeId: values.type?.value,
      is2d: values.modelType?.value === ModelType.MODEL2D,
      newTags: changedTags.new,
      deletedTags: changedTags.deleted,
      deletedVersions: findDeletedVersions(product.versions, values.versions),
      productLastChangedBy: user.id,
      ownerId: product.owner_id,
      productDimensions: {
        ...(product.product_dimensions || {}),
        glassesWidth: values.glassesWidth,
        lensHeight: values.lensHeight,
      },
    };

    delete editedProduct.brand;
    delete editedProduct.type;
    delete editedProduct.tags;

    if (editedProduct.versions) {
      editedProduct.versions.map((v) => {
        v.pageLink = v.pageLink?.trim();
        if (v.id) {
          const changedVersionTags = findChangedTags(
            product.versions.find((oldV) => oldV.id === v.id)?.tags,
            v.tags
          );
          v.newTags = changedVersionTags.new;
          v.deletedTags = changedVersionTags.deleted;
          delete v.tags;
        } else {
          v.tags = tagSelectToList(v.tags);
        }
        if (
          v.thumbFile?.uploadState === FileState.DELETED ||
          v.thumbFile?.uploadState === FileState.NONE
        ) {
          v.thumbFile.uploadState =
            v.thumbFile?.uploadState === FileState.DELETED
              ? FileState.REPLACED
              : FileState.NEW;
          v.thumbFile.file = v.autoGeneratedThumbFile;
        }
        return v;
      });
    }

    editProduct(editedProduct, form).then((formCorrect) => {
      if (formCorrect) {
        dispatch(
          addNotification({
            variant: NotificationVariant.SUCCESS,
            message: t("common:form.savedSuccessfully"),
          })
        );
        getProductData().finally(scrollToTop);
      } else {
        setLoader(null);
      }
    });
  };

  return (
    <Page>
      <Header title={t("editProductTitle")}>
        <HeaderButtons viewport={viewportType}>
          <Button
            variant={ButtonVariant.OUTLINED}
            icon={IconName.LEFT_ARROW}
            text={viewportType > ViewportType.MOBILE && t("common:button.back")}
            onClick={() => {
              if (!isDirtyForm) {
                navigate(-1);
              } else {
                setUnsavedEditsDialog({
                  show: true,
                  action: () => {
                    navigate(-1);
                  },
                });
              }
            }}
            width={
              viewportType === ViewportType.TABLET
                ? ButtonWidth.GROW
                : ButtonWidth.AUTO
            }
          />
          <Placeholder />
          <Box
            show={
              loader !== LoaderType.INITIAL &&
              user.role === ExtendedUserRoles.SUPERADMIN &&
              !initialValues.fromMarketplace &&
              !initialValues.fromOtherPartner &&
              viewportType !== ViewportType.MOBILE
            }
            grow={viewportType !== ViewportType.MOBILE ? 1 : null}
          >
            <Button
              variant={ButtonVariant.OUTLINED}
              text={t("download")}
              icon={IconName.DOWNLOAD}
              disabled={!product?.product_files_zip_link || !!loader}
              onClick={() => (location.href = product?.product_files_zip_link)}
              width="100%"
            />
          </Box>
          <Box
            show={
              loader !== LoaderType.INITIAL &&
              user.role === ExtendedUserRoles.SUPERADMIN &&
              !initialValues.fromMarketplace &&
              !initialValues.fromOtherPartner &&
              initialValues.modelType?.value === ModelType.MODEL2D &&
              viewportType !== ViewportType.MOBILE
            }
            grow={1}
          >
            <Button
              variant={ButtonVariant.OUTLINED}
              text={t("downloadInitial")}
              icon={IconName.DOWNLOAD}
              disabled={!product?.product_initial_files_zip_link || !!loader}
              onClick={() =>
                (location.href = product?.product_initial_files_zip_link)
              }
              width="100%"
            />
          </Box>
          <ButtonsContainer viewport={viewportType}>
            <Box
              show={
                loader !== LoaderType.INITIAL &&
                user.role === ExtendedUserRoles.SUPERADMIN &&
                !partner.main_partner &&
                !initialValues.fromMarketplace &&
                !initialValues.fromOtherPartner &&
                initialValues?.published &&
                viewportType !== ViewportType.MOBILE
              }
              grow={1}
            >
              <Button
                variant={ButtonVariant.OUTLINED}
                text={t("moveToMarketPlace")}
                icon={IconName.PLUS}
                onClick={() => setShowMarketplaceAlert(true)}
                disabled={!!loader}
                isLoading={loader === LoaderType.MOVE}
                width="100%"
              />
            </Box>
            <Box
              show={
                loader !== LoaderType.INITIAL &&
                !initialValues.fromMarketplace &&
                !initialValues.fromOtherPartner
              }
              grow={viewportType !== ViewportType.MOBILE ? 1 : null}
            >
              <Button
                variant={ButtonVariant.CONTAINED}
                icon={IconName.PLUS}
                text={viewportType > ViewportType.MOBILE && t("addVersion")}
                ref={addVersionBtnRef}
                disabled={!!loader}
                width="100%"
              />
            </Box>
            <Box show={loader !== LoaderType.INITIAL}>
              <Button
                variant={ButtonVariant.ERROR}
                icon={IconName.TRASH_OUTLINED}
                onClick={() => setShowDeleteAlert(true)}
                disabled={!!loader}
                isLoading={loader === LoaderType.DELETE}
              />
            </Box>
            <Box grow={2} show={loader !== LoaderType.INITIAL}>
              <Button
                variant={ButtonVariant.GREEN}
                text={t("common:button.save")}
                ref={saveBtnRef}
                disabled={!!loader}
                isLoading={loader === LoaderType.SAVE}
                width="100%"
              />
            </Box>
            <Box
              show={
                !loader &&
                viewportType === ViewportType.MOBILE &&
                user.role === ExtendedUserRoles.SUPERADMIN &&
                !initialValues.fromMarketplace &&
                !initialValues.fromOtherPartner
              }
            >
              <Button
                variant={ButtonVariant.OUTLINED}
                icon={buttonMenuOpen ? IconName.CLOSE : IconName.DOT_MENU}
                disabled={!!loader}
                isActive={buttonMenuOpen}
                onClick={() => setButtonMenuOpen(!buttonMenuOpen)}
              />
            </Box>
          </ButtonsContainer>
        </HeaderButtons>
        <ButtonsMenu
          show={buttonMenuOpen && viewportType === ViewportType.MOBILE}
        >
          <Box
            show={
              user.role === ExtendedUserRoles.SUPERADMIN &&
              !initialValues.fromMarketplace &&
              !initialValues.fromOtherPartner
            }
          >
            <Button
              variant={ButtonVariant.OUTLINED}
              text={t("download")}
              icon={IconName.DOWNLOAD}
              disabled={!product?.product_files_zip_link || !!loader}
              onClick={() => (location.href = product?.product_files_zip_link)}
              width="100%"
            />
          </Box>
          <Box
            show={
              user.role === ExtendedUserRoles.SUPERADMIN &&
              !initialValues.fromMarketplace &&
              !initialValues.fromOtherPartner &&
              initialValues.modelType?.value === ModelType.MODEL2D
            }
          >
            <Button
              variant={ButtonVariant.OUTLINED}
              text={t("downloadInitial")}
              icon={IconName.DOWNLOAD}
              disabled={!product?.product_initial_files_zip_link || !!loader}
              onClick={() =>
                (location.href = product?.product_initial_files_zip_link)
              }
              width="100%"
            />
          </Box>
          <Box
            show={
              user.role === ExtendedUserRoles.SUPERADMIN &&
              !partner.main_partner &&
              !initialValues.fromMarketplace &&
              !initialValues.fromOtherPartner &&
              initialValues?.published
            }
          >
            <Button
              variant={ButtonVariant.OUTLINED}
              text={t("moveToMarketPlace")}
              icon={IconName.PLUS}
              onClick={() => setShowMarketplaceAlert(true)}
              disabled={!!loader}
              isLoading={loader === LoaderType.MOVE}
              width="100%"
            />
          </Box>
        </ButtonsMenu>
      </Header>
      <Content>
        {loader === LoaderType.INITIAL && (
          <Box width="100%" height="6rem">
            <Loader show={true} color={colors.grey[500]} height={20} />
          </Box>
        )}
        {loader !== LoaderType.INITIAL && (
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={ValidationSchema}
            enableReinitialize
          >
            <ProductForm
              saveBtnRef={saveBtnRef}
              addVersionBtnRef={addVersionBtnRef}
              unsavedEditsDialog={unsavedEditsDialog}
              onDirtyChanged={setIsDirtyForm}
              initialValues={initialValues}
              isLoading={!!loader}
              setLoading={(value) => setLoader(value ? LoaderType.SAVE : null)}
              hideUnsavedEditsDialog={() =>
                setUnsavedEditsDialog({
                  show: false,
                  action: () => {},
                })
              }
              conf={{method: Method.FILES}}
            />  
          </Formik>
        )}
        <ProductLogs isLoading={!!loader} />
      </Content>
      <WarningDialog
        open={showMarketplaceAlert}
        onClose={() => setShowMarketplaceAlert(false)}
        onAction={handleMove}
        warningButtonText={t("common:button.move")}
        title={t("moveToMarketPlaceAlertTitle")}
      />
      <WarningDialog
        open={showDeleteAlert}
        name={initialValues.name}
        onClose={() => setShowDeleteAlert(false)}
        onAction={handleDelete}
        title={t("deleteAlertTitle")}
        warningButtonText={t("deleteButtonTitle")}
      />
    </Page>
  );
};

export default EditProduct;
