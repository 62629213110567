import { createSlice } from "@reduxjs/toolkit";
import { NotificationVariant } from "common/basicComponents/Notification";
import { clearAuthData } from "components/auth/AuthSlice";

const initialState = {
  show: false,
  notificationsQueue: [],
  notificationActive: {
    variant: NotificationVariant.INFO,
    message: "",
    displayTime: 15,
    height: null,
    fullWidth: false,
  },
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notificationsQueue.push({
        variant: action.payload.variant,
        message: action.payload.message,
        displayTime: action.payload.displayTime || 15,
        height: action.payload.height || null,
        fullWidth: action.payload.fullWidth || false,
      });
    },
    showNotification: (state) => {
      if (state.notificationsQueue.length) {
        state.notificationActive = state.notificationsQueue[0];
        state.notificationsQueue.shift();
        state.show = true;
      }
    },
    hideNotification: (state) => {
      state.show = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
  },
});
export { NotificationVariant };
export const { addNotification, showNotification, hideNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
