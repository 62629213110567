import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";

import Header from "common/page/Header";
import Content from "common/page/Content";
import Page from "common/page/Page";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { ViewportType } from "utils/ViewportContext";
import { useViewport } from "utils/ViewportContext";
import { IconName } from "common/basicComponents/Icon";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonWidth } from "common/basicComponents/Button";
import TabNavigation, { TabVariant } from "common/basicComponents/Tabs";
import EditHeaderButtons from "./edit/EditHeaderButtons";
import GeneralInfo from "./edit/generalInfo/GeneralInfo";
import Versions from "./edit/versions/Versions";
import {
  copyTotem,
  deleteTotem,
  getTotem,
  getTotemBanners,
  getTotemVersions,
  clearTotemData,
} from "./TotemActions";
import CopyTotemDialog from "./edit/generalInfo/dialogs/CopyTotemDialog";
import {
  addNotification,
  NotificationVariant,
} from "components/notifications/NotificationSlice";
import WarningDialog from "common/basicComponents/WarningDialog";
import RouterPaths from "constants/RouterPaths";
import Banners from "./edit/banners/Banners";
import { getAllProductsWithVersions } from "components/products/ProductActions";

const getHeaderStyle = (viewport) =>
  ({
    [ViewportType.DESKTOP]: css`
      gap: 1.25rem;
      margin-top: 0.875rem;
    `,
    [ViewportType.TABLET]: css`
      gap: 1.25rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    `,
    [ViewportType.MOBILE]: css`
      gap: 0.625rem;
      margin-top: 1.25rem;
    `,
  }[viewport]);

const HeaderButtons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${({ viewport }) => getHeaderStyle(viewport)}
`;

export const LoaderType = {
  INITIAL: "initial",
  SAVE: "save",
  COPY: "copy",
  VERSION: "version",
  BANNER_ADD: "bannerAdd",
  BANNER_EDIT: "bannerEdit",
  DELETE: "delete",
};

const EditTotem = () => {
  const { t } = useTranslation("totems");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: totemId } = useParams();
  const { type: viewportType } = useViewport();

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState({
    general: true,
    versions: true,
    banners: true,
  });
  const [formLoader, setFormLoader] = useState(LoaderType.INITIAL);
  const [copyDialog, setCopyDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [unsavedEditsDialog, setUnsavedEditsDialog] = useState({
    show: false,
    action: () => {},
  });

  const [editBannerDialog, setEditBannerDialog] = useState({
    show: false,
    banner: null,
  });

  const [addVersionDialog, setAddVersionDialog] = useState(false);

  const [isDirtyForm, setIsDirtyForm] = useState(false);

  const loadAllTotemData = useCallback(() => {
    setLoading({
      general: true,
      versions: true,
      banners: true,
    });
    clearTotemData();
    getTotem(totemId).then(() =>
      setLoading((prev) => ({ ...prev, general: false }))
    );
    getTotemVersions(totemId).then(() =>
      setLoading((prev) => ({ ...prev, versions: false }))
    );
    getTotemBanners(totemId).then(() =>
      setLoading((prev) => ({ ...prev, banners: false }))
    );
    getAllProductsWithVersions();
  }, [totemId]);

  useEffect(() => {
    loadAllTotemData();
  }, [loadAllTotemData]);

  useEffect(() => {
    return clearTotemData;
  }, []);

  const handleCopy = (totemToCopyId) => {
    setFormLoader(LoaderType.COPY);
    copyTotem(totemToCopyId, totemId).then((ok) => {
      if (ok) {
        dispatch(
          addNotification({
            variant: NotificationVariant.SUCCESS,
            message: t("common:form.copiedSuccessfully"),
          })
        );
        clearTotemData();
        loadAllTotemData();
        setCopyDialog(false);
      }
      setFormLoader(LoaderType.INITIAL);
    });
  };

  const handleDelete = () => {
    setFormLoader(LoaderType.DELETE);
    deleteTotem(totemId).then((ok) => {
      if (ok) {
        dispatch(
          addNotification({
            variant: NotificationVariant.SUCCESS,
            message: t("common:form.deletedSuccessfully"),
          })
        );
        setDeleteDialog(false);
        navigate(RouterPaths.TOTEMS);
      }
      setFormLoader(LoaderType.INITIAL);
    });
  };

  return (
    <Page>
      <Header title={t("editTitle")}>
        <HeaderButtons viewport={viewportType}>
          <Button
            variant={ButtonVariant.OUTLINED}
            icon={IconName.LEFT_ARROW}
            text={viewportType > ViewportType.MOBILE && t("common:button.back")}
            onClick={() => {
              if (!isDirtyForm) {
                navigate(-1);
              } else {
                setUnsavedEditsDialog({
                  show: true,
                  action: () => {
                    navigate(-1);
                  },
                });
              }
            }}
            width={
              viewportType === ViewportType.TABLET ? null : ButtonWidth.AUTO
            }
            minWidth={viewportType === ViewportType.TABLET ? "9rem" : null}
          />
          <EditHeaderButtons
            formLoader={formLoader}
            activeTab={activeTab}
            onCopy={() => setCopyDialog(true)}
            onDelete={() => setDeleteDialog(true)}
            onBannerAdd={() =>
              setEditBannerDialog({
                show: true,
                banner: null,
              })
            }
            onVersionAdd={() => setAddVersionDialog(true)}
          />
        </HeaderButtons>
      </Header>
      <Content>
        <TabNavigation
          disabled={formLoader !== LoaderType.INITIAL}
          variant={TabVariant.MAIN}
          index={activeTab}
          onSelect={(tab) => {
            if (!isDirtyForm) {
              setActiveTab(tab);
            } else {
              setUnsavedEditsDialog({
                show: true,
                action: () => setActiveTab(tab),
              });
            }
          }}
          tabs={[
            viewportType > ViewportType.MOBILE
              ? t("editTabs.info")
              : t("editTabs.infoMobile"),
            t("editTabs.banners"),
            t("editTabs.versions"),
          ]}
          contents={[
            <GeneralInfo
              key={0}
              loading={loading.general}
              unsavedEditsDialog={unsavedEditsDialog}
              hideUnsavedEditsDialog={() =>
                setUnsavedEditsDialog({
                  show: false,
                  action: () => {},
                })
              }
              onDirtyChanged={setIsDirtyForm}
              setFormLoading={(value) => {
                if (value) {
                  setFormLoader(LoaderType.SAVE);
                } else {
                  setFormLoader(LoaderType.INITIAL);
                }
              }}
              formLoading={
                formLoader === LoaderType.SAVE ||
                formLoader === LoaderType.COPY ||
                formLoader === LoaderType.DELETE
              }
            />,
            <Banners
              key={1}
              loading={loading.banners}
              editBannerDialog={editBannerDialog}
              setEditBannerDialog={setEditBannerDialog}
              setFormLoading={setFormLoader}
              formLoading={
                formLoader === LoaderType.BANNER_ADD ||
                formLoader === LoaderType.BANNER_EDIT
              }
            />,
            <Versions
              key={2}
              loading={loading.versions}
              addVersionDialog={addVersionDialog}
              setAddVersionDialog={setAddVersionDialog}
              formLoading={formLoader === LoaderType.VERSION}
              setFormLoading={(value) => {
                if (value) {
                  setFormLoader(LoaderType.VERSION);
                } else {
                  setFormLoader(LoaderType.INITIAL);
                }
              }}
            />,
          ]}
        />
        <CopyTotemDialog
          open={copyDialog}
          onClose={() => setCopyDialog(false)}
          onCopy={handleCopy}
          saving={formLoader === LoaderType.COPY}
        />
        <WarningDialog
          open={deleteDialog}
          onClose={() => setDeleteDialog(false)}
          onAction={handleDelete}
          title={t("deleteDialogTitle")}
          warningButtonText={t("deleteDialogButton")}
        />
      </Content>
    </Page>
  );
};

export default EditTotem;
