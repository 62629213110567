import React from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import styled from "styled-components";

import FormSelectField from "common/formFields/FormSelectField";
import Button, { ButtonType } from "common/basicComponents/Button";
import { constantToSelect } from "utils/FormUtils";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Box from "common/basicComponents/Box";
import { IconName } from "common/basicComponents/Icon";
import { StatusOptions } from "constants/NotificationOptions";
const MiniContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;
  gap: 1rem;

  & > *:not(button) {
    max-height: 4.125rem;
  }
`;

const MobileSearchForm = () => {
  const { t } = useTranslation("notifications");
  const { isSubmitting } = useFormikContext();
  const { type: viewportType } = useViewport();

  return (
    <>
      <MiniContainer viewport={viewportType}>
        <Box grow={1}>
          <FormSelectField
            id="statusVal"
            name="statusVal"
            label={t("status")}
            disabled={isSubmitting}
            options={constantToSelect(StatusOptions, "notifications:search")}
          />
        </Box>
        <Box>
          <Button
            type={ButtonType.SUBMIT}
            minWidth="100px"
            disabled={isSubmitting}
            text={
              viewportType > ViewportType.MOBILE && t("common:button.search")
            }
            icon={viewportType < ViewportType.TABLET && IconName.SEARCH}
          />
        </Box>
        <input style={{ display: "none" }} type="submit" />
      </MiniContainer>
    </>
  );
};

export default MobileSearchForm;
