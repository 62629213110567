import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import Box from "common/basicComponents/Box";
import FormCheckboxField, {
  CheckboxType,
} from "common/formFields/FormCheckboxField";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Accordion from "common/basicComponents/Accordion";

const Content = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 2rem 0.625rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1.25rem;
  align-items: center;
`;

const getWidth = (viewport) =>
  ({
    [ViewportType.DESKTOP]: "25%",
    [ViewportType.TABLET]: "50%",
    [ViewportType.MOBILE]: "100%",
  }[viewport]);

const AvailableFunctionalities = () => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("availableFunctionalities");

  return (
    <Accordion text={t("availableFunctionalities")}>
      <Content>
        <Box width={getWidth(viewportType)} padding="0 0.625rem">
          <FormCheckboxField
            type={CheckboxType.CONTAINED}
            id="webActive"
            label={t("tryOnWeb")}
          />
        </Box>
        <Box width={getWidth(viewportType)} padding="0 0.625rem">
          <FormCheckboxField
            type={CheckboxType.CONTAINED}
            id="qrcodesActive"
            label={t("qrCodes")}
          />
        </Box>
        <Box width={getWidth(viewportType)} padding="0 0.625rem">
          <FormCheckboxField
            type={CheckboxType.CONTAINED}
            id="versionsGrouped"
            label={t("productVersionsAdjacent")}
          />
        </Box>
        <Box width={getWidth(viewportType)} padding="0 0.625rem">
          <FormCheckboxField
            type={CheckboxType.CONTAINED}
            id="pdMeasurementActive"
            label={t("pdMeasurement")}
          />
        </Box>
        <Box width={getWidth(viewportType)} padding="0 0.625rem">
          <FormCheckboxField
            type={CheckboxType.CONTAINED}
            id="totemActive"
            label={t("tryOnTotem")}
          />
        </Box>
        <Box width={getWidth(viewportType)} padding="0 0.625rem">
          <FormCheckboxField
            type={CheckboxType.CONTAINED}
            id="importActive"
            label={t("import")}
          />
        </Box>
        <Box width={getWidth(viewportType)} padding="0 0.625rem">
          <FormCheckboxField
            type={CheckboxType.CONTAINED}
            id="organizingProducts"
            label={t("changingProductOrder")}
          />
        </Box>
        <Box width={getWidth(viewportType)} padding="0 0.625rem">
          <FormCheckboxField
            type={CheckboxType.CONTAINED}
            id="mobileActive"
            label={t("tryOnMobile")}
          />
        </Box>
        <Box width={getWidth(viewportType)} padding="0 0.625rem">
          <FormCheckboxField
            type={CheckboxType.CONTAINED}
            id="googleAnalyticsActive"
            label={t("googleAnalytics")}
          />
        </Box>
        <Box width={getWidth(viewportType)} padding="0 0.625rem">
          <FormCheckboxField
            type={CheckboxType.CONTAINED}
            id="analyticsDashboardActive"
            label={t("analyticsDashboardActive")}
          />
        </Box>
        <Box width={getWidth(viewportType)} padding="0 0.625rem">
          <FormCheckboxField
            type={CheckboxType.CONTAINED}
            id="marketplaceVisible"
            label={t("marketplace")}
          />
        </Box>
        <Box width={getWidth(viewportType)} padding="0 0.625rem">
          <FormCheckboxField
            type={CheckboxType.CONTAINED}
            id="billingActive"
            label={t("billing")}
          />
        </Box>
        <Box width={getWidth(viewportType)} padding="0 0.625rem">
          <FormCheckboxField
            type={CheckboxType.CONTAINED}
            id="setsActive"
            label={t("sets")}
          />
        </Box>
      </Content>
    </Accordion>
  );
};

export default AvailableFunctionalities;
