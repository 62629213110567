import { useEffect } from "react";
import PropTypes from "prop-types";

import { useFormikContext } from "formik";

const FormObserver = ({ onDirtyChanged }) => {
  const { dirty } = useFormikContext();

  useEffect(() => {
    onDirtyChanged(dirty);
  }, [dirty, onDirtyChanged]);

  return null;
};

FormObserver.propTypes = {
  onDirtyChanged: PropTypes.func.isRequired,
};

export default FormObserver;
