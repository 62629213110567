import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import store from "redux/store";
import i18n from "i18n/i18n";
import _ from "lodash";
import Input, {
  InputSize,
  InputType,
  InputAlign,
} from "common/basicComponents/Input";
import { IconName } from "common/basicComponents/Icon";
import { addNotification } from "components/notifications/NotificationSlice";
import { NotificationVariant } from "common/basicComponents/Notification";
import CurrencyInput from "common/basicComponents/CurrencyInput";
import { translate } from "utils/FormUtils";

export const FormInputFieldType = {
  TEXT: "text",
  NUMBER: "number",
  COPY: "copy",
  REGEX: "regex",
  LINK: "link",
  SEARCH: "search",
  CURRENCY: "currency",
};

export const NumberType = {
  NON_NEGATIVE_INTEGER: /^(0|[1-9]\d*)$/,
  POSITIVE_INTEGER: /^[1-9]\d*$/,
  INTEGER: /^([-]?([1-9][0-9]*)*|0)$/,
  NON_NEGATIVE_DECIMAL: /^[0-9]*[.]?[0-9]*$/,
  DECIMAL: /^[-]?[0-9]*[.]?[0-9]*$/,
};

const getInputMeta = (type, field) =>
  ({
    [FormInputFieldType.COPY]: {
      type: InputType.TEXT,
      icon: IconName.COPY,
      iconAction: () => {
        navigator.clipboard.writeText(field.value);
        store.dispatch(
          addNotification({
            variant: NotificationVariant.SUCCESS,
            message: i18n.t("common:copyToClipboard"),
          })
        );
      },
      iconAlign: InputAlign.RIGHT,
    },
    [FormInputFieldType.LINK]: {
      type: InputType.TEXT,
      icon: IconName.LINK,
      iconAction: null,
      iconAlign: InputAlign.RIGHT,
    },
    [FormInputFieldType.NUMBER]: {
      type: InputType.TEXT,
      icon: null,
      iconAction: null,
      iconAlign: InputAlign.RIGHT,
    },
    [FormInputFieldType.REGEX]: {
      type: InputType.TEXT,
      icon: null,
      iconAction: null,
      iconAlign: InputAlign.RIGHT,
    },
    [FormInputFieldType.TEXT]: {
      type: InputType.TEXT,
      icon: null,
      iconAction: null,
      iconAlign: InputAlign.RIGHT,
    },
    [FormInputFieldType.SEARCH]: {
      type: InputType.TEXT,
      icon: IconName.SEARCH,
      iconAction: null,
      iconAlign: InputAlign.LEFT,
    },
  }[type]);

const FormInputField = ({
  label,
  type,
  regex,
  numberCheck,
  disabled,
  id,
  name,
  placeholder,
  size,
  multiline,
  rows,
  limit,
  caption,
  icon,
  currency,
  onError,
}) => {
  const [field, meta, helpers] = useField(name);
  const { isSubmitting, submitCount } = useFormikContext();

  const inputMeta = getInputMeta(type, field);

  useEffect(() => {
    if (submitCount > 0 && meta.touched && meta.error && onError) {
      onError();
    }
  }, [meta, submitCount, onError]);

  return type === FormInputFieldType.CURRENCY ? (
    <CurrencyInput
      label={label}
      name={name}
      value={field.value}
      id={id}
      disabled={isSubmitting || disabled}
      size={size}
      onChange={(value) => {
        helpers.setValue(value);
      }}
      error={
        submitCount > 0 && meta.touched && meta.error
          ? translate(meta.error)
          : ""
      }
      placeholder={placeholder}
      currency={currency}
    />
  ) : (
    <Input
      label={label}
      name={name}
      value={field.value}
      id={id}
      disabled={isSubmitting || disabled}
      size={size}
      onChange={({ target }) => {
        if (limit && target.value?.length > limit) return;
        if (
          !target.value ||
          ((type !== FormInputFieldType.REGEX || regex?.test(target.value)) &&
            (type !== FormInputFieldType.NUMBER ||
              numberCheck?.test(target.value)))
        )
          if (type === FormInputFieldType.NUMBER && _.isEmpty(target.value))
            helpers.setValue(null);
          else helpers.setValue(target.value);
      }}
      error={
        submitCount > 0 && meta.touched && meta.error
          ? translate(meta.error)
          : ""
      }
      placeholder={placeholder}
      type={inputMeta.type}
      icon={icon || inputMeta.icon}
      iconAction={inputMeta.iconAction}
      iconActionAlwaysEnabled={type === FormInputFieldType.COPY}
      iconAlign={inputMeta.iconAlign}
      multiline={multiline}
      rows={rows}
      caption={limit ? `${field.value?.length || 0}/${limit}` : caption}
      captionAlign={limit ? InputAlign.RIGHT : InputAlign.LEFT}
    />
  );
};

FormInputField.defaultProps = {
  label: "",
  disabled: false,
  regex: null,
  numberCheck: null,
  placeholder: "",
  size: InputSize.NORMAL,
  multiline: false,
  rows: null,
  limit: null,
  caption: null,
  icon: null,
  currency: "",
  onError: () => {},
};

FormInputField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(Object.values(FormInputFieldType)).isRequired,
  regex: PropTypes.instanceOf(RegExp),
  numberCheck: PropTypes.oneOf(Object.values(NumberType)),
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(Object.values(InputSize)),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  limit: PropTypes.number,
  caption: PropTypes.string,
  icon: PropTypes.string,
  currency: PropTypes.string,
  onError: PropTypes.func,
};

export default FormInputField;
export { InputSize };
