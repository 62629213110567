import { decodeJwt } from "jose";
import { AUTH_TOKEN, PARTNER } from "constants/StorageKeys";
import {
  setAuthData,
  clearAuthData,
  setPartners,
  setCurrentPartner,
} from "./AuthSlice";
import {
  apiClient,
  setAuthorizationHeader,
  setPartnerHeader,
} from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import { REACT_APP_DEV_MODE } from "constants/env";
import { parsePartners } from "utils/PartnerUtils";
import { isEqual } from "lodash";

export const login = (token) => {
  if (REACT_APP_DEV_MODE) {
    if (token) {
      localStorage.setItem(AUTH_TOKEN, token);
      setUserInSession();
      return decodeJwt(token);
    } else {
      clearToken();
      store.dispatch(clearAuthData());
      return false;
    }
  }

  return apiClient
    .post(ApiEndpoints.LOGIN, { token })
    .then((res) => {
      const { token } = res.data.body;
      if (res?.status === 200 && token) {
        localStorage.setItem(AUTH_TOKEN, token);
        setUserInSession();
        return decodeJwt(token);
      } else {
        clearToken();
        store.dispatch(clearAuthData());
        return false;
      }
    })
    .catch(() => {
      clearToken();
      return false;
    });
};

export const setUserInSession = async () => {
  const authToken = localStorage.getItem(AUTH_TOKEN);
  if (!authToken) {
    logout();
    return;
  }
  const decoded = decodeJwt(authToken);
  if (!decoded) {
    logout();
    return;
  }

  setAuthorizationHeader(authToken);

  store.dispatch(
    setAuthData({
      id: decoded.id,
      email: decoded.email,
    })
  );

  const success = await getUserPartners(decoded.id);
  if (!success) {
    logout();
    return;
  }

  const lastPartnerId = sessionStorage.getItem(PARTNER);
  setPartnerById(lastPartnerId);
};

const clearToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(PARTNER);
  setAuthorizationHeader(null);
  setPartnerHeader(null);
};

export const logout = () => {
  clearToken();
  store.dispatch(clearAuthData());
};

export const getUserPartners = (userId) => {
  return apiClient
    .get(`${ApiEndpoints.USER_PARTNERS}${userId}`)
    .then((res) => {
      if (!res?.data?.body) return false;
      const partners = parsePartners(res?.data?.body);
      store.dispatch(setPartners(partners));
      return true;
    })
    .catch(() => false);
};

export const setPartner = (newPartner) => {
  const { partner } = store.getState().auth;
  if (isEqual(newPartner, partner)) return;

  sessionStorage.setItem(PARTNER, newPartner?.id);
  store.dispatch(setCurrentPartner(newPartner));
  setPartnerHeader(newPartner?.id);
};

export const setPartnerById = (partnerId) => {
  const { partners } = store.getState().auth;
  const selectedPartner = partners.find((p) => p.id === partnerId);

  if (selectedPartner) setPartner(selectedPartner);
  else setPartner(partners[0]);
};
