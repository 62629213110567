import Table from "common/Table";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import TotemRow from "./TotemRow";
import { ViewportType } from "utils/ViewportContext";
import TotemCard from "./TotemCard";

const TotemTable = ({ totems, loading, onEdit }) => {
  const { t } = useTranslation("totems");

  const headers = [
    {
      text: t("number"),
      centered: true,
      maxWidth: "3rem",
    },
    {
      text: t("status"),
      centered: true,
      maxWidth: "4.25rem",
    },
    {
      text: t("totemName"),
    },
    {
      text: t("totemDeviceName"),
    },
    {
      text: t("totemExpirationDate"),
    },
    {
      text: t("totemLastActivity"),
    },
    {
      text: t("actions"),
      centered: true,
      maxWidth: "5rem",
    },
  ];

  return (
    <Table
      headers={headers}
      data={totems}
      tableRow={TotemRow}
      tableCard={TotemCard}
      cardViewportBreakpoint={ViewportType.TABLET}
      keyPropertyName="totem_id"
      loading={loading}
      itemProps={{
        onEdit,
      }}
      pagination={false}
      noDataText={t("noData")}
    />
  );
};

TotemTable.propTypes = {
  totems: PropTypes.array,
  total: PropTypes.number,
  loading: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
};

TotemTable.defaultProps = {
  totems: [],
  total: 0,
  loading: false,
};

export default TotemTable;
