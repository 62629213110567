import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Dialog from "common/basicComponents/Dialog";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import { useTranslation } from "react-i18next";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { ButtonWidth } from "common/basicComponents/Button";
import { Formik, Form } from "formik";
import Box from "common/basicComponents/Box";
import FormSelectField from "common/formFields/FormSelectField";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { ButtonType } from "common/basicComponents/Button";
import ValidationSchema from "./LinkValidationSchema";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";
import FormToggleField, {
  ToggleVariant,
} from "common/formFields/FormToggleField";
import { productsWithVersionsToSelect } from "utils/ProductUtils";

const Buttons = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "row" : "column-reverse"};
  width: 100%;
  gap: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "2rem" : "1.25rem"};
  margin-top: 2.5rem;
`;

const EditLinkDialog = ({
  open,
  link,
  links,
  onSave,
  onClose,
  allProducts,
  domainOptions,
}) => {
  const { t } = useTranslation("links");
  const { type: viewportType } = useViewport();
  const [versionOptions, setVersionOptions] = useState([]);
  const [hideUsed, setHideUsed] = useState(false);
  const [unassignedProductsWithVersions, setUnassignedProductsWithVersions] =
    useState([]);
  const [visibleOptions, setVisibleOptions] = useState([]);

  useEffect(() => {
    setVisibleOptions(hideUsed ? unassignedProductsWithVersions : allProducts);
  }, [allProducts, hideUsed, unassignedProductsWithVersions]);

  useEffect(() => {
    if (!link?.product_id) return;
    const product = visibleOptions.find((p) => p.value === link.product_id);
    if (product) {
      setVersionOptions(product.versions);
    }
  }, [link, visibleOptions, allProducts, hideUsed]);

  useEffect(() => {
    if (!allProducts) return;
    if (!links) return;
    setUnassignedProductsWithVersions(
      allProducts
        .map((product) => ({
          ...product,
          versions: product.versions.filter(
            (version) =>
              !links
                .map((item) => item.partners_version_id)
                .includes(version.partners_version_id)
          ),
        }))
        .filter((product) => product.versions.length > 0)
    );
  }, [allProducts, links]);

  const onSubmit = (values, form) => {
    form.setSubmitting(true);
    onSave(
      {
        id: link?.web_link_id,
        versionId: values.version.value,
        domainId: values.domain.value,
        webLinkUrl: values.webLinkUrl?.trim(),
      },
      form
    );
    form.setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding="0 0 2.5rem">
        <Typography
          variant={TypographyVariant.H4}
          weight={TypographyWeight.BOLD}
          text={link?.web_link_id ? t("editLinkTitle") : t("addLinkTitle")}
        />
      </Box>
      <Formik
        initialValues={{
          version: {
            label: link?.version_name || "",
            value: link?.partners_version_id || "",
          },
          product: {
            label: link?.product_name || "",
            value: link?.product_id || "",
          },
          domain: {
            label: link?.domain_name || "",
            value: link?.domain_id || "",
          },
          webLinkUrl: link?.web_link_url,
          hideUsed: hideUsed,
        }}
        validationSchema={ValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form noValidate>
            <FormToggleField
              id="hideUsed"
              name="hideUsed"
              label={t("hideUsed")}
              align="center"
              variant={ToggleVariant.SWITCH_COLOR}
              switchWidth="47%"
              onChange={(hideUsed) => setHideUsed(hideUsed)}
            />
            <Box width="100%">
              <FormSelectField
                name="product"
                id="product"
                label={t("product")}
                options={productsWithVersionsToSelect(visibleOptions)}
                disabled={isSubmitting}
                onChange={(product) => {
                  setFieldValue("version", { label: "", value: "" });
                  setVersionOptions(product.versions);
                }}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormSelectField
                name="version"
                id="version"
                label={t("version")}
                options={versionOptions}
                disabled={isSubmitting}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormSelectField
                name="domain"
                id="domain"
                label={t("domain")}
                options={domainOptions}
                disabled={isSubmitting}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormInputField
                type={FormInputFieldType.TEXT}
                name="webLinkUrl"
                id="webLinkUrl"
                label={t("webLinkUrl")}
                disabled={isSubmitting}
              />
            </Box>
            <Buttons viewport={viewportType}>
              <Button
                variant={ButtonVariant.OUTLINED}
                text={t("common:button.cancel")}
                width={ButtonWidth.GROW}
                onClick={onClose}
                disabled={isSubmitting}
              />
              <Button
                variant={ButtonVariant.GREEN}
                text={t("common:button.save")}
                width={ButtonWidth.GROW}
                type={ButtonType.SUBMIT}
                isLoading={isSubmitting}
              />
            </Buttons>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

EditLinkDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  link: PropTypes.object,
  links: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  domainOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  allProducts: PropTypes.array.isRequired,
};

EditLinkDialog.defaultProps = {
  link: null,
};

export default EditLinkDialog;
