import React from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { useSelector } from "react-redux";

import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import FormSelectField from "common/formFields/FormSelectField";
import Button, {
  ButtonType,
  ButtonVariant,
} from "common/basicComponents/Button";
import { productsWithVersionsToSelect } from "utils/ProductUtils";

const Container = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: flex-end;

  & > *:not(button) {
    flex-grow: 1;
    flex-basis: 0;
    max-height: 4.125rem;
    white-space: nowrap;
  }
`;

const DesktopSearchForm = () => {
  const { t } = useTranslation("links");
  const { allProducts } = useSelector((state) => state.products);
  const { resetForm, isSubmitting } = useFormikContext();

  return (
    <Container>
      {/* <FormSelectField
        id="product"
        name="product"
        label={t("product")}
        disabled={isSubmitting}
        options={productsWithVersionsToSelect(allProducts)}
      /> */}
      <FormInputField
        id="setSearch"
        name="setSearch"
        label={t("webLinkUrl")}
        disabled={isSubmitting}
        type={FormInputFieldType.TEXT}
      />
      <Button
        type={ButtonType.SUBMIT}
        minWidth="100px"
        disabled={isSubmitting}
        text={t("common:button.search")}
      />
      <Button
        type={ButtonType.SUBMIT}
        variant={ButtonVariant.OUTLINED}
        minWidth="100px"
        onClick={() => {
          resetForm();
        }}
        text={t("common:button.clear")}
      />
    </Container>
  );
};

export default DesktopSearchForm;
