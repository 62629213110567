import { createSlice } from "@reduxjs/toolkit";
import { clearAuthData, setCurrentPartner } from "components/auth/AuthSlice";

const initialState = {
  brands: [],
};

export const brandSlice = createSlice({
  name: "brands",
  initialState,
  reducers: {
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
    builder.addCase(setCurrentPartner, () => initialState);
  },
});

export const { setBrands } = brandSlice.actions;
export default brandSlice.reducer;
