import { createSlice } from "@reduxjs/toolkit";
import { clearAuthData, setCurrentPartner } from "components/auth/AuthSlice";

const initialState = {
  domains: [],
};

export const domainSlice = createSlice({
  name: "domains",
  initialState,
  reducers: {
    setDomains: (state, action) => {
      state.domains = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
    builder.addCase(setCurrentPartner, () => initialState);
  },
});

export const { setDomains } = domainSlice.actions;
export default domainSlice.reducer;
