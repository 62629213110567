import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Typography from "common/basicComponents/Typography";
import { TypographyVariant } from "common/basicComponents/Typography";
import { TypographyWeight } from "common/basicComponents/Typography";
import Box from "common/basicComponents/Box";
import Button from "common/basicComponents/Button";
import { useTranslation } from "react-i18next";
import { ButtonSize } from "common/basicComponents/Button";
import Chips from "common/basicComponents/Chips";
import { ChipsVariant } from "common/basicComponents/Chips";
import { ButtonVariant } from "common/basicComponents/Button";

const Card = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  overflow: hidden;
`;

const DataCell = styled.div`
  padding: ${({ row }) => (row ? "1.25rem 0" : "1rem 0")};
  border-top: ${({ theme, withoutBorder }) =>
    withoutBorder ? null : "1px solid " + theme.colors.grey[100]};
  display: flex;
  justify-content: space-between;
  ${({ row }) => !row && "flex-direction: column;"}
  flex-grow: 1;
  flex-basis: 0;
`;

const ButtonsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const BannerCard = ({ data, onEdit, onDelete }) => {
  const { t } = useTranslation("totems");

  return (
    <Card>
      <DataCell withoutBorder>
        <Typography
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("banners.name")}
        />
        <Typography variant={TypographyVariant.BODY2} text={data.banner_name} />
      </DataCell>
      <DataCell>
        <Typography
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("banners.type")}
        />
        <Typography
          variant={TypographyVariant.BODY2}
          text={t(`banners.types.${data.banner_type}`)}
        />
      </DataCell>
      <DataCell row>
        <Typography
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("banners.duration")}
        />
        <Typography
          weight={TypographyWeight.BOLD}
          variant={TypographyVariant.BODY2}
          text={data.banner_duration}
        />
      </DataCell>
      <DataCell row>
        <Typography
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("banners.displayedTime")}
        />
        <Typography
          weight={TypographyWeight.BOLD}
          variant={TypographyVariant.BODY2}
          text={data.banner_displayed_time}
        />
      </DataCell>
      <DataCell row>
        <Typography
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("status")}
        />
        <Chips
          text={
            data.banner_active
              ? t("banners.statuses.active")
              : t("banners.statuses.inactive")
          }
          variant={data.banner_active ? ChipsVariant.GREEN : ChipsVariant.ROSE}
        />
      </DataCell>

      <ButtonsContainer width="100%" padding="1.25rem 0 0 0">
        <Button
          width="100%"
          text={t("common:button.edit")}
          size={ButtonSize.SMALL}
          onClick={() => onEdit(data)}
        />
        <Button
          width="100%"
          text={t("common:button.delete")}
          variant={ButtonVariant.ERROR}
          size={ButtonSize.SMALL}
          onClick={() => onDelete(data)}
        />
      </ButtonsContainer>
    </Card>
  );
};

BannerCard.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default BannerCard;
