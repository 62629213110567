import React from "react";
import { ViewportType, useViewport } from "utils/ViewportContext";
import VersionMediumCard from "./VersionMediumCard";
import VersionSmallCard from "./VersionSmallCard";

const VersionCard = (props) => {
  const { type } = useViewport();
  if (type > ViewportType.MOBILE) return <VersionMediumCard {...props} />;
  else return <VersionSmallCard {...props} />;
};

export default VersionCard;
