import React, { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import ReactSelect from "react-select";
import {
  DropdownIndicator,
  Option,
  NoOptionsMessage,
  MultiValueLabel,
  MultiValueRemove,
  ValueContainer,
  Menu,
} from "./SelectComponents";
import Typography, { TypographyVariant, TypographyWeight } from "../Typography";

export const SelectSize = {
  SMALL: "small",
  NORMAL: "normal",
  LARGE: "large",
};

const getSelectMultiValueStyle = (theme) => css`
  background: ${theme.colors.grey[200]} content-box;
  min-width: fit-content;
  max-width: fit-content;
  display: flex;
  border-right: 0.5rem solid transparent;
  .react-select__multi-value__label {
    padding: 0.25rem 0.5rem;
    display: flex;
    gap: 0.25rem;
  }
  .react-select__multi-value__remove {
    padding: 0.25rem;
    display: flex;
  }
`;
const getSelectColors = (theme, error, pseudo = "default") => {
  if (error) {
    return css`
      border: 1px solid ${theme.colors.secondary[600]};
      background: ${theme.colors.white};
      color: ${theme.colors.black};
      outline: none;
      caret-color: ${theme.colors.secondary[200]};
      .react-select__dropdown-indicator {
        color: ${theme.colors.secondary[600]};
      }
    `;
  }
  return {
    default: css`
      background: ${theme.colors.white};
      border: 1px solid;
      border-color: ${theme.colors.secondary[100]};
      border-image: ${theme.colors.gradient[20]} 1;
      color: ${theme.colors.black};
      outline: none;
      caret-color: ${theme.colors.black};
      .react-select__dropdown-indicator {
        color: ${theme.colors.black};
      }
    `,
    disabled: css`
      border: 1px solid ${theme.colors.grey[300]};
      background: ${theme.colors.grey[50]};
      color: ${theme.colors.grey[500]};
      .react-select__dropdown-indicator {
        color: ${theme.colors.grey[500]};
      }
    `,
    hover: css`
      background: ${theme.colors.white};
      border-image: ${theme.colors.gradient[60]} 1;
      color: ${theme.colors.black};
    `,
    active: css`
      background: ${theme.colors.white};
      border-image: ${theme.colors.gradient[100]} 1;
      color: ${theme.colors.black};
    `,
  }[pseudo];
};

const getSelectStyle = (size) =>
  ({
    [SelectSize.SMALL]: css`
      .react-select__control {
        width: 4rem;
        height: 2rem;
        .react-select__value-container {
          padding: 0.3rem 0 0.3rem 0.5rem;
        }
        .react-select__dropdown-indicator {
          padding: 0.2rem 0.25rem;
        }
        .react-select__single-value,
        .react-select__input-container {
          line-height: 1.25rem;
          font-size: 0.875rem;
        }
      }
      .react-select__option {
        padding: 0.375rem 0.5rem;
      }
    `,
    [SelectSize.NORMAL]: css`
      .react-select__control {
        height: 2.5rem;
        padding: 0.5rem 0.75rem;
        .react-select__single-value,
        .react-select__input-container {
          line-height: 1.25rem;
          font-size: 0.875rem;
        }
      }
      .react-select__option {
        padding: 0.625rem 0.75rem;
      }
    `,
    [SelectSize.LARGE]: css`
      .react-select__control {
        height: 3rem;
        padding: 0.625rem 0.75rem;
        .react-select__single-value,
        .react-select__input-container {
          line-height: 1.5rem;
          font-size: 1rem;
        }
      }
      .react-select__option {
        padding: 0.875rem 0.75rem;
      }
    `,
  }[size]);

const StyledLabel = styled.label`
  width: 100%;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey[500] : theme.colors.black};
  .label-text {
    display: inline-block;
  }
`;

const StyledSelect = styled(ReactSelect)`
  display: block;
  width: 100%;
  margin: 0.25rem 0;
  ${({ size }) => getSelectStyle(size)}

  .react-select__dropdown-indicator {
    transition: 0.2s ease-in-out;
  }
  .react-select__control--is-focused.react-select__control--menu-is-open
    .react-select__dropdown-indicator {
    transform: rotateX(180deg);
  }
  .react-select__control {
    ${({ theme, error }) => getSelectColors(theme, error, "default")};
  }
  .react-select__control--is-disabled {
    ${({ theme, error }) => getSelectColors(theme, error, "disabled")};
  }
  .react-select__control--is-focused,
  .react-select__control--menu-is-open {
    ${({ theme, error }) => getSelectColors(theme, error, "active")};
  }
  .react-select__control:hover:not(.react-select__control--is-focused) {
    ${({ theme, error }) => getSelectColors(theme, error, "hover")};
  }
  .react-select__menu {
    z-index: 2;
    border: 1px solid;
    ${({ theme, error }) => getSelectColors(theme, error, "active")};
  }
  .top-menu {
    border-bottom: none;
  }
  .bottom-menu {
    border-top: none;
  }
  .react-select__control {
    line-height: 0.875rem;
    min-height: 2rem;
  }
  .react-select__option {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;

    &:hover:not(.react-select__option--is-selected) {
      background: ${({ theme }) => theme.colors.primary[25]};
    }
  }
  .react-select__option--is-selected {
    background: ${({ theme }) => theme.colors.primary[50]};
  }
  .react-select__placeholder {
    color: ${({ theme }) => theme.colors.secondary[200]};
  }
  .react-select__value-container > div {
    display: flex;
    flex-wrap: nowrap;
  }
  .react-select__multi-value {
    ${({ theme }) => getSelectMultiValueStyle(theme)};
  }
  .react-select__menu-notice {
    color: ${({ theme }) => theme.colors.grey[400]};
    padding: 0.625rem;
  }
`;

const HelperMultiValue = styled.div`
  position: absolute;
  z-index: -1;
  visibility: hidden;
  ${({ theme }) => getSelectMultiValueStyle(theme)};
`;

const CaptionContainer = styled.div`
  min-height: 1rem;
`;

const Select = ({
  id,
  name,
  value,
  onChange,
  disabled,
  label,
  caption,
  error,
  size,
  isMulti,
  isSearchable,
  placeholder,
  onlyTextOptions,
  renderCaption,
  options,
  className,
}) => {
  const { ref: inViewRef, inView } = useInView({ triggerOnce: true });
  const helperRef = useRef();
  const [optionsWithWidth, setOptionsWithWidth] = useState([]);
  const [valueWithWidth, setValueWithWidth] = useState(null);

  useEffect(() => {
    if (isMulti && helperRef?.current?.children?.length) {
      const newOptions = Array.prototype.map.call(
        helperRef.current.children,
        (opt) => {
          const option = {
            ...JSON.parse(opt.getAttribute("option")),
            width: opt.offsetWidth,
          };
          return option;
        }
      );
      setOptionsWithWidth(newOptions);
    }
  }, [options, isMulti, inView]);

  useEffect(() => {
    if (value && isMulti && optionsWithWidth) {
      const newValue = optionsWithWidth.filter(
        (option) => value.filter((v) => v.value === option.value).length > 0
      );
      setValueWithWidth(newValue);
    }
  }, [value, optionsWithWidth, isMulti]);

  return (
    <div name={name} className={className} ref={inViewRef}>
      <StyledLabel disabled={disabled}>
        {label && (
          <Typography
            variant={
              size === SelectSize.LARGE
                ? TypographyVariant.BODY1
                : TypographyVariant.BODY2
            }
            weight={TypographyWeight.SEMIBOLD}
            text={label}
            tag="span"
            className="label-text"
          />
        )}
        {/* // Helper to get width of chips */}
        {isMulti && (
          <div ref={helperRef}>
            {options.map((o) => (
              <HelperMultiValue option={JSON.stringify(o)} key={o.value}>
                <MultiValueLabel
                  data={o}
                  innerProps={{
                    className: "react-select__multi-value__label",
                  }}
                />
                <MultiValueRemove
                  innerProps={{
                    className: "react-select__multi-value__remove",
                  }}
                />
              </HelperMultiValue>
            ))}
          </div>
        )}
        <StyledSelect
          id={id}
          value={isMulti ? valueWithWidth : value}
          onChange={onChange}
          isDisabled={disabled}
          isClearable={false}
          hideSelectedOptions={false}
          blurInputOnSelect={!isMulti}
          closeMenuOnSelect={!isMulti}
          error={error}
          size={size}
          placeholder={placeholder}
          options={isMulti ? optionsWithWidth : options}
          components={{
            DropdownIndicator,
            Option,
            NoOptionsMessage,
            MultiValueLabel,
            MultiValueRemove,
            ValueContainer,
            Menu,
          }}
          className="react-select"
          classNamePrefix="react-select"
          isMulti={isMulti}
          isSearchable={isSearchable}
          onlyTextOptions={onlyTextOptions}
          menuPlacement="auto"
          unstyled
        />
      </StyledLabel>
      {renderCaption && (
        <CaptionContainer>
          {(caption || error) && (
            <Typography
              variant={TypographyVariant.CAPTION}
              weight={TypographyWeight.LIGHT}
              text={error || caption}
            />
          )}
        </CaptionContainer>
      )}
    </div>
  );
};

const OptionPropType = PropTypes.shape({
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  group: PropTypes.string,
  additionalLabel: PropTypes.string,
  width: PropTypes.number,
});

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    OptionPropType,
    PropTypes.arrayOf(OptionPropType),
  ]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  caption: PropTypes.string,
  error: PropTypes.string,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SelectSize)),
  placeholder: PropTypes.string,
  onlyTextOptions: PropTypes.bool,
  renderCaption: PropTypes.bool,
  options: PropTypes.arrayOf(OptionPropType),
  className: PropTypes.string,
};

Select.defaultProps = {
  disabled: false,
  label: "",
  caption: "",
  error: "",
  isMulti: false,
  isSearchable: true,
  size: SelectSize.NORMAL,
  placeholder: "",
  onlyTextOptions: false,
  renderCaption: true,
  options: [],
  onChange: () => {},
  className: null,
};

export default Select;
