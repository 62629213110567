import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import { setReport, setVisualReport, setImportData } from "./ImportSlice";

export const getReport = () => {
  return apiClient
    .get(ApiEndpoints.IMPORT_REPORT)
    .then((res) => {
      const report = res?.data?.body;
      if (!report) return false;

      store.dispatch(setReport(report));
      return true;
    })
    .catch(() => false);
};

export const getVisualReport = (ids) => {
  return apiClient
    .post(ApiEndpoints.IMPORT_VISUAL_REPORT, { ids })
    .then((res) => {
      const report = res?.data?.body;
      if (!report) return false;

      store.dispatch(setVisualReport(report));
      return true;
    })
    .catch(() => false);
};

export const getImportData = () => {
  return apiClient
    .get(ApiEndpoints.IMPORT_DATA)
    .then((res) => {
      const data = res?.data?.body;
      if (!data) return false;

      store.dispatch(setImportData(data));
      return true;
    })
    .catch(() => false);
};

export const updateImportData = (csvUrl) => {
  return apiClient
    .post(ApiEndpoints.IMPORT_DATA, { csvUrl })
    .then((res) => {
      const data = res?.data?.body;
      if (!data) return false;

      store.dispatch(setImportData(data));
      return true;
    })
    .catch(() => false);
};

export const startImport = (csvUrl) => {
  return apiClient
    .post(ApiEndpoints.IMPORT_RUN, { csvUrl })
    .then(() => true)
    .catch(() => false);
};

export const notifyAboutImport = (partnerName) => {
  return apiClient
    .post(ApiEndpoints.IMPORT_NOTIFY, { partnerName })
    .then(() => true)
    .catch(() => false);
};

export const notifyAboutImportComplete = (subject, text) => {
  return apiClient
    .post(ApiEndpoints.IMPORT_NOTIFY_COMPLETE, { subject, text })
    .then(() => true)
    .catch(() => false);
};
