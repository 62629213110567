import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

export const TypographyVariant = {
  H1: "h1",
  H2: "h2",
  H3: "h3",
  H4: "h4",
  H5: "h5",
  SUBTITLE1: "subtitle1",
  SUBTITLE2: "subtitle2",
  BODY1: "body1",
  BODY2: "body2",
  BUTTON: "button",
  CAPTION: "caption",
  OVERLINE: "overline",
  CHART: "chart",
};

export const TypographyWeight = {
  LIGHT: "light",
  REGULAR: "regular",
  SEMIBOLD: "semibold",
  BOLD: "bold",
};

const getTypographyStyle = (variant) =>
  ({
    [TypographyVariant.H1]: css`
      line-height: 5.75rem;
      font-size: 4.5rem;
    `,
    [TypographyVariant.H2]: css`
      line-height: 4rem;
      font-size: 3rem;
    `,
    [TypographyVariant.H3]: css`
      line-height: 2.5rem;
      font-size: 2rem;
    `,
    [TypographyVariant.H4]: css`
      line-height: 2rem;
      font-size: 1.5rem;
    `,
    [TypographyVariant.H5]: css`
      line-height: 1.75rem;
      font-size: 1.25rem;
    `,
    [TypographyVariant.SUBTITLE1]: css`
      line-height: 1.5rem;
      font-size: 1rem;
      letter-spacing: 0.02em;
    `,
    [TypographyVariant.SUBTITLE2]: css`
      line-height: 1.25rem;
      font-size: 0.875rem;
      letter-spacing: 0.02em;
    `,
    [TypographyVariant.BODY1]: css`
      line-height: 1.5rem;
      font-size: 1rem;
    `,
    [TypographyVariant.BODY2]: css`
      line-height: 1.25rem;
      font-size: 0.875rem;
    `,
    [TypographyVariant.BUTTON]: css`
      line-height: 1.25rem;
      font-size: 0.875rem;
      letter-spacing: 0.08em;
      text-transform: uppercase;
    `,
    [TypographyVariant.CAPTION]: css`
      line-height: 1rem;
      font-size: 0.75rem;
    `,
    [TypographyVariant.OVERLINE]: css`
      line-height: 0.875rem;
      font-size: 0.625rem;
      letter-spacing: 0.08em;
      text-transform: uppercase;
    `,
    [TypographyVariant.CHART]: css`
      line-height: 0.75rem;
      font-size: 0.5rem;
      letter-spacing: 0.08em;
    `,
  }[variant]);

const StyledTypography = styled.div`
  font-weight: ${({ theme, weight }) => theme.fonts.fontWeight[weight]};
  ${({ variant }) => getTypographyStyle(variant)}
`;

const variantMapping = {
  [TypographyVariant.H1]: "h1",
  [TypographyVariant.H2]: "h2",
  [TypographyVariant.H3]: "h3",
  [TypographyVariant.H4]: "h4",
  [TypographyVariant.H5]: "h5",
  [TypographyVariant.SUBTITLE1]: "h6",
  [TypographyVariant.SUBTITLE2]: "h6",
  [TypographyVariant.BODY1]: "p",
  [TypographyVariant.BODY2]: "p",
  [TypographyVariant.BUTTON]: "span",
  [TypographyVariant.CAPTION]: "p",
  [TypographyVariant.OVERLINE]: "p",
  [TypographyVariant.CHART]: "p",
};

const Typography = ({ variant, weight, text, tag, className }) => (
  <StyledTypography
    as={tag || variantMapping[variant]}
    variant={variant}
    weight={weight}
    className={className}
  >
    {text}
  </StyledTypography>
);

Typography.propTypes = {
  variant: PropTypes.oneOf(Object.values(TypographyVariant)),
  weight: PropTypes.oneOf(Object.values(TypographyWeight)),
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  tag: PropTypes.string,
  className: PropTypes.string,
};

Typography.defaultProps = {
  variant: TypographyVariant.BODY2,
  weight: TypographyWeight.REGULAR,
  tag: null,
  className: null,
};

export default Typography;
