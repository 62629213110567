import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Button, {
  ButtonVariant,
  ButtonType,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import WarningDialog from "common/basicComponents/WarningDialog";
import Page from "common/page/Page";
import Content from "common/page/Content";
import Header from "common/page/Header";
import { getTagGroups } from "components/tagGroups/TagGroupActions";
import EditTagsDialog from "./dialogs/EditTagGroupDialog";
import TagsTable from "./list/TagGroupTable";
import { addTagGroup, deleteTagGroup, editTagGroup } from "./TagGroupActions";

const Tags = () => {
  const { t } = useTranslation("tags");
  const { tagGroups } = useSelector((state) => state.tagGroups);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getTagGroups().finally(() => {
      setLoading(false);
    });
  }, []);

  const [editDialog, setEditDialog] = useState({
    open: false,
    tag: null,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    tag: null,
  });

  const handleSave = (tagGroup, form) => {
    if (tagGroup.id) {
      editTagGroup(tagGroup, form).then((ok) => {
        if (!ok) return;
        getTagGroups();
        setEditDialog({ tag: null, open: false });
      });
    } else {
      addTagGroup(tagGroup, form).then((ok) => {
        if (!ok) return;
        getTagGroups();
        setEditDialog({ tag: null, open: false });
      });
    }
  };

  const handleDelete = () => {
    deleteTagGroup(deleteDialog.tag).then((ok) => {
      if (!ok) return;
      getTagGroups();
      setDeleteDialog({ ...deleteDialog, open: false });
    });
  };

  return (
    <Page>
      <Header
        title={t("title")}
        button={
          <Button
            minWidth="13rem"
            type={ButtonType.BUTTON}
            icon={IconName.PLUS}
            variant={ButtonVariant.GREEN}
            text={t("addTagGroup")}
            onClick={() => setEditDialog({ open: true, tag: null })}
          />
        }
      />
      <Content>
        <TagsTable
          tagGroups={tagGroups}
          total={tagGroups.length}
          loading={loading}
          onEdit={(tag) => setEditDialog({ open: true, tag })}
          onDelete={(tag) => setDeleteDialog({ open: true, tag })}
        />
      </Content>
      <EditTagsDialog
        open={editDialog.open}
        tag={editDialog.tag}
        onClose={() => setEditDialog({ ...editDialog, open: false })}
        onSave={handleSave}
      />
      <WarningDialog
        open={deleteDialog.open}
        data={deleteDialog.tag}
        name={deleteDialog.tag?.tag_group_name}
        onClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
        onAction={handleDelete}
        title={t("deleteAlertTitle")}
        warningButtonText={t("deleteButtonTitle")}
      />
    </Page>
  );
};

export default Tags;
