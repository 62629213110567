import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import Typography from "common/basicComponents/Typography";
import { TypographyVariant } from "common/basicComponents/Typography";
import FormToggleField, {
  ToggleVariant,
} from "common/formFields/FormToggleField";

const Cell = styled.td`
  height: 4rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const ButtonsCell = styled(Cell)`
  width: 6.25rem;
  min-width: 6.25rem;
  button {
    display: inline-flex;
    margin: 0 0.25rem;
  }
`;

const TextCell = styled(Cell)`
  padding: 0.75rem;
  & > span {
    overflow-wrap: anywhere;
  }
`;

const EffectsRow = ({ data, disabled, index, onEdit, onDelete }) => {
  return (
    <>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.name}
          tag="span"
        />
      </Cell>
      <TextCell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.fbxFile?.url || ""}
          tag="span"
        />
      </TextCell>
      <ButtonsCell centered={true}>
        <FormToggleField
          id={`effects[${index}].active`}
          name={`effects[${index}].active`}
          variant={ToggleVariant.CLASSIC}
        />
      </ButtonsCell>
      <ButtonsCell centered={true}>
        <Button
          icon={IconName.EDIT}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onEdit(data)}
          disabled={disabled}
        />
        <Button
          icon={IconName.TRASH}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onDelete(data)}
          disabled={disabled}
        />
      </ButtonsCell>
    </>
  );
};

EffectsRow.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

EffectsRow.defaultProps = {
  disabled: false,
};

export default EffectsRow;
