import axios from "axios";
import i18n from "i18n/i18n";

import { REACT_APP_BACKEND_URL } from "constants/env";
import {
  addNotification,
  NotificationVariant,
} from "components/notifications/NotificationSlice";
import { AUTH_TOKEN, PARTNER } from "constants/StorageKeys";
import { clearAuthData } from "components/auth/AuthSlice";
import store from "redux/store";
import { setFormError } from "utils/FormUtils";

export const externalApiClient = axios.create({
  timeout: 60000,
});

externalApiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    handleError(error);
    return Promise.reject(error);
  }
);

export const apiClient = axios.create({
  baseURL: `${REACT_APP_BACKEND_URL}`,
  timeout: 120000,
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    handleError(error);
    return Promise.reject(error);
  }
);

const handleError = (err) => {
  let errorMessage;

  switch (err?.response?.status) {
    case 404:
      store.dispatch(
        addNotification({
          variant: NotificationVariant.ERROR,
          message: i18n.t("common:backendErrors.notFound"),
        })
      );
      break;

    case 401:
    case 403:
      errorMessage =
        err?.response?.data?.message ||
        err?.response?.data?.body ||
        "unauthorized";
      store.dispatch(
        addNotification({
          variant: NotificationVariant.ERROR,
          message: i18n.t([
            `common:backendErrors.${errorMessage}`,
            "common:backendErrors.unspecific",
          ]),
        })
      );
      localStorage.removeItem(AUTH_TOKEN);
      sessionStorage.removeItem(PARTNER);
      setAuthorizationHeader(null, () => {});
      store.dispatch(clearAuthData());
      break;
    case 400:
      break;
    case 409:
    case 418:
    case 422:
    case 500: {
      errorMessage = err?.response?.data || err?.response?.statusText;
      const message =
        typeof errorMessage === "object"
          ? i18n
              .t([
                `common:backendErrors.${errorMessage.code}`,
                "common:backendErrors.unspecific",
              ])
              .replace(/\$field\$/gi, errorMessage.field)
              .replace(/\$value\$/gi, errorMessage.value)
          : i18n.t([
              `common:backendErrors.${errorMessage}`,
              "common:backendErrors.unspecific",
            ]);
      store.dispatch(
        addNotification({ message, variant: NotificationVariant.ERROR })
      );
      break;
    }
    default:
      store.dispatch(
        addNotification({
          message: i18n.t(
            err.code === "ECONNABORTED"
              ? "common:checkConnection"
              : "common:backendErrors.unspecific"
          ),
          variant: NotificationVariant.ERROR,
        })
      );
      break;
  }
};

export const handleFormError = (err, form) => {
  if (err?.response?.status !== 400) return false;
  const formErrors = err?.response?.data?.errors;
  if (form && formErrors) {
    setFormError(form, formErrors);
  } else {
    store.dispatch(
      addNotification({
        variant: NotificationVariant.ERROR,
        message: i18n.t("common:backendErrors.unspecific"),
      })
    );
  }
  return false;
};

export const setAuthorizationHeader = (token) => {
  if (token) {
    apiClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete apiClient.defaults.headers.common.Authorization;
  }
};

export const setPartnerHeader = (partnerId) => {
  if (partnerId != null) {
    apiClient.defaults.headers.common["partner-id"] = partnerId;
  } else {
    delete apiClient.defaults.headers.common["partner-id"];
  }
};
