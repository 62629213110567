import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";

const Cell = styled.td`
  height: 2.75rem;
  padding: 0rem 0.75rem 0rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const ApplicationsRow = ({ data }) => {
  return (
    <>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.partner_name}
          tag="span"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.app_android_version || ""}
          tag="span"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.app_ios_test_version || ""}
          tag="span"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.app_android_version || ""}
          tag="span"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.app_android_test_version || ""}
          tag="span"
        />
      </Cell>
    </>
  );
};

ApplicationsRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ApplicationsRow;
