import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledRadio = styled.input`
  appearance: none;

  width: 1rem;
  min-width: 1rem;
  height: 1rem;
  padding: 0.3rem;
  background: linear-gradient(${({ theme }) => theme.colors.white} 0 0)
      padding-box,
    ${({ theme }) => theme.colors.gradient[20]} border-box;
  border: 1px solid transparent;
  border-radius: 50%;

  &:hover {
    background: linear-gradient(${({ theme }) => theme.colors.white} 0 0)
        padding-box,
      ${({ theme }) => theme.colors.gradient[60]} border-box;
  }

  &:checked {
    border: 1px solid transparent;
    padding: 0.18rem;
    background: linear-gradient(${({ theme }) => theme.colors.black} 0 0)
        content-box,
      linear-gradient(${({ theme }) => theme.colors.white} 0 0) padding-box,
      ${({ theme }) => theme.colors.gradient[100]} border-box;
  }
`;
const Radio = ({ checked, id, readOnly }) => (
  <StyledRadio type="radio" checked={checked} id={id} readOnly={readOnly} />
);

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  readOnly: PropTypes.bool,
};

Radio.defaultProps = {
  checked: false,
  readOnly: false,
};

export default Radio;
