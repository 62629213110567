import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFormikContext, useField } from "formik";
import Input, { InputSize } from "common/basicComponents/Input";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { IconName } from "common/basicComponents/Icon";
import { InputType } from "common/basicComponents/Input";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";
import { SketchPicker } from "react-color";
import Dialog from "common/basicComponents/Dialog";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { translate } from "utils/FormUtils";

const StyledColorInputContainer = styled.div`
  position: relative;
  svg {
    border: 1px solid;
    border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
    color: ${({ value }) =>
      value ? `rgb(${value.r}, ${value.g}, ${value.b})` : "transparent"};
  }
`;

const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: ${({ viewportType }) =>
    viewportType === ViewportType.MOBILE ? "column-reverse" : "row"};
  gap: ${({ viewportType }) =>
    viewportType === ViewportType.MOBILE ? "1.25rem" : "2rem"};
  padding-top: 1.25rem;
  button {
    width: 100%;
  }
`;

const StyledDesktopPickerContainer = styled.div`
  position: fixed;
  inset: 0;
`;

const StyledPopover = styled.div`
  position: absolute;
  z-index: 10;
  left: 0;
  margin-top: -1rem;
  padding: 2rem 1rem;
  border: 1px solid ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.white};
  input {
    width: 100% !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem;
    text-align: center;
    border: 1px solid !important;
    border-image: ${({ theme }) => theme.colors.gradient[20]} 1 !important;
    box-shadow: none !important;
  }
  label {
    font-size: 0.875rem !important;
    letter-spacing: 0.08em;
  }
`;

const StyledPickerContainer = styled.div`
  input {
    width: 100% !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem;
    text-align: center;
    border: 1px solid !important;
    border-image: ${({ theme }) => theme.colors.gradient[20]} 1 !important;
    box-shadow: none !important;
  }
  label {
    font-size: 0.875rem !important;
    letter-spacing: 0.08em;
  }
`;

const StyledDialogContent = styled.div`
  padding: 1.25rem 0;
`;

const COLOR_REGEX = /^R: [0-9]{0,3} G: [0-9]{0,3} B: [0-9]{0,3}$/;

const FormColorField = ({ id, name, label, disabled, size, onError }) => {
  const { t } = useTranslation();
  const { type: viewportType } = useViewport();
  const { isSubmitting, submitCount } = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const colorFieldRef = useRef();
  const [colorDialogOpen, setColorDialogOpen] = useState(false);
  const [pickerColor, setPickerColor] = useState();

  useEffect(() => {
    if (submitCount > 0 && meta.touched && meta.error && onError) {
      onError();
    }
  }, [meta, submitCount, onError]);

  const handleChange = ({ target }) => {
    if (!COLOR_REGEX?.test(target.value)) return;
    const rgb = target.value.split(" ");
    helpers.setValue({
      r: rgb[1],
      g: rgb[3],
      b: rgb[5],
    });
  };

  const handlePickerClose = () => {
    setColorDialogOpen(false);
    if (colorFieldRef.current) {
      colorFieldRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <StyledColorInputContainer value={field.value} ref={colorFieldRef}>
      <Input
        type={InputType.TEXT}
        id={id}
        name={name}
        label={label}
        error={
          submitCount > 0 && meta.touched && meta.error
            ? translate(
                meta.error.r || meta.error.g || meta.error.b || meta.error
              )
            : ""
        }
        value={
          field.value
            ? "R: " +
              field.value.r +
              " G: " +
              field.value.g +
              " B: " +
              field.value.b
            : null
        }
        onChange={handleChange}
        disabled={isSubmitting || disabled}
        size={size}
        icon={IconName.SQUARE}
        iconAction={() => {
          setPickerColor(field.value);
          setColorDialogOpen(true);
        }}
      />
      {viewportType === ViewportType.DESKTOP ? (
        <>
          {colorDialogOpen ? (
            <StyledPopover>
              <StyledDesktopPickerContainer
                onClick={() => setColorDialogOpen(false)}
              />
              <SketchPicker
                styles={{
                  picker: {
                    width: "15rem",
                  },
                  controls: {
                    display: "flex",
                    padding: "1rem 0 0.75rem 0",
                    transform: "scale(1, 1.5)",
                  },
                  activeColor: {
                    borderRadius: 0,
                    background: field.value
                      ? `rgb(${field.value.r}, ${field.value.g}, ${field.value.b})`
                      : null,
                    position: "absolute",
                    inset: 0,
                  },
                }}
                disableAlpha={true}
                color={field.value}
                name={field.name}
                presetColors={[]}
                onChange={(newColor) => {
                  delete newColor.rgb.a;
                  helpers.setValue(newColor.rgb);
                }}
              />
            </StyledPopover>
          ) : null}
        </>
      ) : (
        <Dialog open={colorDialogOpen} onClose={handlePickerClose}>
          <StyledDialogContent>
            <StyledPickerContainer>
              <SketchPicker
                styles={{
                  picker: {
                    width: "100%",
                    margin: "auto",
                  },
                  saturation: {
                    width: "100%",
                    paddingBottom: "min(75%, calc(80vh - 10rem))",
                    position: "relative",
                    overflow: "hidden",
                  },
                  controls: {
                    display: "flex",
                    padding: "1rem 0 0.75rem 0",
                    transform: "scale(1, 1.5)",
                  },
                  activeColor: {
                    borderRadius: 0,
                    background: `rgb(${pickerColor?.r}, ${pickerColor?.g}, ${pickerColor?.b})`,
                    position: "absolute",
                    inset: 0,
                  },
                }}
                disableAlpha={true}
                color={pickerColor}
                name={field.name}
                presetColors={[]}
                onChange={(newColor) => {
                  delete newColor.rgb.a;
                  setPickerColor(newColor.rgb);
                }}
              />
            </StyledPickerContainer>
            <StyledButtonContainer viewportType={viewportType}>
              <Button
                text={t("button.cancel")}
                variant={ButtonVariant.OUTLINED}
                onClick={handlePickerClose}
              />

              <Button
                text={t("button.ok")}
                variant={ButtonVariant.GREEN}
                onClick={() => {
                  helpers.setValue(pickerColor);
                  handlePickerClose();
                }}
              />
            </StyledButtonContainer>
          </StyledDialogContent>
        </Dialog>
      )}
    </StyledColorInputContainer>
  );
};

FormColorField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(InputSize)),
  onError: PropTypes.func,
};

FormColorField.defaultProps = {
  label: null,
  disabled: false,
  size: InputSize.NORMAL,
  onError: () => {},
};

export default FormColorField;
