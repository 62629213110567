import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Chips, { ChipsVariant } from "common/basicComponents/Chips";
import { ExtendedUserRoles } from "constants/UserRoles";

const Card = styled.div`
  min-width: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "40%" : "100%"};
  background: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1.25rem;
`;

const FieldLabel = styled(Typography)`
  padding-top: 1rem;
`;

const FieldValue = styled(Typography)`
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
  word-wrap: break-word;
  padding-bottom: 1rem;
`;

const ChipsContainer = styled.div`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  ${({ theme, showDivider }) =>
    showDivider && "border-top: 1px solid" + theme.colors.grey[200]};
`;

const ButtonsContainer = styled.div`
  padding-top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const getColor = (role) =>
  ({
    [ExtendedUserRoles.USER]: ChipsVariant.LIGHT_GREY,
    [ExtendedUserRoles.ADMIN]: ChipsVariant.GREY,
    [ExtendedUserRoles.SUPERADMIN]: ChipsVariant.DARK_GREY,
  }[role]);

const UserCard = ({ data, onEdit, onDelete }) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("users");
  return (
    <Card viewport={viewportType}>
      <FieldLabel
        text={t("email")}
        variant={TypographyVariant.BODY2}
        weight={TypographyWeight.BOLD}
        tag="span"
      />
      <FieldValue
        text={data.user_email}
        variant={TypographyVariant.SUBTITLE2}
      />
      <ChipsContainer showDivider={true}>
        <Typography
          text={t("role")}
          variant={TypographyVariant.BODY2}
          weight={TypographyWeight.BOLD}
          tag="span"
          showDivider={false}
        />
        <Chips variant={getColor(data.user_role)} text={data.user_role} />
      </ChipsContainer>
      <ChipsContainer showDivider={true}>
        <Typography
          text={t("active")}
          variant={TypographyVariant.BODY2}
          weight={TypographyWeight.BOLD}
          tag="span"
        />
        <Chips
          variant={data.user_active ? ChipsVariant.GREEN : ChipsVariant.ROSE}
          text={data.user_active ? t("active") : t("inactive")}
        />
      </ChipsContainer>
      <ButtonsContainer>
        <Button
          text={t("common:button.edit")}
          variant={ButtonVariant.CONTAINED}
          size={ButtonSize.SMALL}
          onClick={() => onEdit(data)}
        />
        <Button
          text={t("common:button.delete")}
          variant={ButtonVariant.ERROR}
          size={ButtonSize.SMALL}
          onClick={() => onDelete(data)}
        />
      </ButtonsContainer>
    </Card>
  );
};

UserCard.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default UserCard;
