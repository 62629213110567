import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ViewportType } from "utils/ViewportContext";
import Table from "common/Table";
import TagsCard from "./TagGroupCard";
import TagsRow from "./TagGroupRow";

const TagsTable = ({ tagGroups, loading, total, onEdit, onDelete }) => {
  const { t } = useTranslation("tags");

  const headers = [
    {
      text: t("tagGroupName"),
      maxWidth: "25%",
    },
    {
      text: t("title"),
      maxWidth: "65%",
    },
    {
      text: t("common:actions"),
      centered: true,
      maxWidth: "1rem",
    },
  ];

  return (
    <Table
      headers={headers}
      data={tagGroups}
      total={total}
      tableRow={TagsRow}
      tableCard={TagsCard}
      pagination={false}
      cardViewportBreakpoint={ViewportType.MOBILE}
      keyPropertyName="id"
      loading={loading}
      itemProps={{
        onEdit,
        onDelete,
      }}
    />
  );
};

TagsTable.propTypes = {
  tagGroups: PropTypes.array,
  total: PropTypes.number,
  loading: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

TagsTable.defaultProps = {
  tagGroups: [],
  loading: false,
  total: 0,
};

export default TagsTable;
