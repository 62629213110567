import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ThreeJSModes from "constants/ThreeJsModes";
import { dataURLtoBlob } from "utils/FileUtils";
import { initThreeApp } from "./ThreeApp";
import { reloadObject } from "./Object3D";
import ProductTypes from "constants/ProductTypes";
import { FileState } from "common/formFields/FormFileField";

const ThumbnailMaker = ({
  size,
  objectScale,
  fileUrls,
  index,
  onThumbReady,
  productType,
  values,
  generate,
}) => {
  let threeApp;

  const makeThumbnail = async () => {
    try {
      const canvasElement = document.getElementById(
        `thumbnail-canvas-${index}`
      );

      const modelFbxLink = fileUrls.modelFbx;

      const textureLinks = {
        albedo: fileUrls.albedo,
        metallic: fileUrls.metallic,
        normal: fileUrls.normal,
      };

      Object.keys(textureLinks).forEach(
        (k) => textureLinks[k] === null && delete textureLinks[k]
      );

      if (!threeApp) {
        threeApp = await initThreeApp(
          canvasElement,
          productType,
          ThreeJSModes.THUMBNAIL_MODE
        );
      }

      await reloadObject(
        threeApp.scene,
        threeApp.sceneTop,
        modelFbxLink,
        values.earRotationWeb,
        textureLinks,
        objectScale,
        productType,
        ThreeJSModes.THUMBNAIL_MODE
      );

      const data = canvasElement.toDataURL();
      const blob = dataURLtoBlob(data);
      if (typeof onThumbReady === "function") {
        onThumbReady(blob);
      }
    } catch {
      if (typeof onThumbReady === "function") {
        onThumbReady(null);
      }
    }
  };

  useEffect(() => {
    if (!generate) return;
    if (
      !fileUrls.modelFbx ||
      [FileState.NEW, FileState.REPLACED, FileState.UPLOADED].includes(
        values.thumbFile?.uploadState
      )
    ) {
      onThumbReady(null);
      return;
    }
    makeThumbnail();
    // eslint-disable-next-line
  }, [generate]);

  if (!fileUrls.modelFbx) {
    return null;
  }

  return (
    <canvas
      id={`thumbnail-canvas-${index}`}
      style={{ display: "none" }}
      width={size.width}
      height={size.height}
    ></canvas>
  );
};

ThumbnailMaker.propTypes = {
  size: PropTypes.exact({
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  objectScale: PropTypes.number.isRequired,
  fileUrls: PropTypes.shape({
    modelFbx: PropTypes.string,
    albedo: PropTypes.string,
    metallic: PropTypes.string,
    normal: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onThumbReady: PropTypes.func.isRequired,
  productType: PropTypes.string,
  values: PropTypes.object.isRequired,
  generate: PropTypes.bool.isRequired,
};

ThumbnailMaker.defaultProps = {
  productType: ProductTypes.GLASSES,
};

export default ThumbnailMaker;
