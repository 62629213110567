import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useParams } from "react-router-dom";
import VersionTable from "./list/VersionTable";
import { updateVersion } from "components/totems/TotemActions";
import { changeVersionsOrder } from "components/totems/TotemActions";
import WarningDialog from "common/basicComponents/WarningDialog";
import { getTotemVersions } from "components/totems/TotemActions";
import {
  addNotification,
  NotificationVariant,
} from "components/notifications/NotificationSlice";
import { deleteVersion } from "components/totems/TotemActions";
import { productsWithVersionsToSelect } from "utils/ProductUtils";
import { addVersion } from "components/totems/TotemActions";
import AddVersionDialog from "./AddVersionDialog";

const Versions = ({
  loading,
  formLoading,
  setFormLoading,
  addVersionDialog,
  setAddVersionDialog,
}) => {
  const { t } = useTranslation("totems");
  const { id: totemId } = useParams();
  const dispatch = useDispatch();
  const { versions } = useSelector((state) => state.totems);
  const { allProducts } = useSelector((state) => state.products);

  const [deleteDialog, setDeleteDialog] = useState({
    show: false,
    version: null,
  });

  const [dataReloading, setDataReloading] = useState(false);
  const [visibleVersions, setVisibleVersions] = useState([]);

  useEffect(() => {
    setVisibleVersions(versions);
  }, [versions]);

  const handleChangeActiveVersion = (version) => {
    updateVersion(totemId, version.id, !version.version_active);
  };

  const handleReorder = (fromId, toId, direction) => {
    //reorder local state so that the user sees the result immediately
    const newVersions = Array.from(visibleVersions);
    const fromIndex = newVersions.findIndex((p) => p.id === fromId);
    const toIndex = newVersions.findIndex((p) => p.id === toId);
    const [removed] = newVersions.splice(fromIndex, 1);
    newVersions.splice(toIndex, 0, removed);
    setVisibleVersions(newVersions);

    //send request and when succeeded - reorder products in redux store
    changeVersionsOrder(fromId, toId, direction, totemId);
  };

  const handleVersionDelete = (version) => {
    deleteVersion(totemId, version.id).then((ok) => {
      if (ok) {
        dispatch(
          addNotification({
            variant: NotificationVariant.SUCCESS,
            message: t("common:form.deletedSuccessfully"),
          })
        );
        setDeleteDialog({ ...deleteDialog, show: false });
        setDataReloading(true);
        getTotemVersions(totemId).finally(() => setDataReloading(false));
      }
    });
  };

  const handleVersionSave = (values, setSubmitting) => {
    setFormLoading(true);
    setSubmitting(true);
    addVersion(totemId, values.versionId).then((ok) => {
      if (ok) {
        dispatch(
          addNotification({
            variant: NotificationVariant.SUCCESS,
            message: t("common:form.savedSuccessfully"),
          })
        );
        setAddVersionDialog(false);
        setDataReloading(true);
        getTotemVersions(totemId).finally(() => setDataReloading(false));
      }
      setFormLoading(false);
      setSubmitting(false);
    });
  };

  return (
    <>
      <WarningDialog
        open={deleteDialog.show}
        onClose={() => setDeleteDialog({ ...deleteDialog, show: false })}
        name={deleteDialog?.version?.version_name || ""}
        onAction={() => handleVersionDelete(deleteDialog?.version)}
        title={t("versions.deleteDialogTitle")}
        warningButtonText={t("common:button.delete")}
      />
      <AddVersionDialog
        open={addVersionDialog}
        loading={formLoading}
        onClose={() => setAddVersionDialog(false)}
        onSave={handleVersionSave}
        productOptions={productsWithVersionsToSelect(allProducts)}
      />
      <VersionTable
        loading={loading || dataReloading || formLoading}
        onDelete={(data) => {
          setDeleteDialog({
            show: true,
            version: data,
          });
        }}
        onReorder={handleReorder}
        onChangeActiveVersion={handleChangeActiveVersion}
        versions={visibleVersions}
      />
    </>
  );
};

Versions.propTypes = {
  loading: PropTypes.bool.isRequired,
  formLoading: PropTypes.bool.isRequired,
  addVersionDialog: PropTypes.bool.isRequired,
  setAddVersionDialog: PropTypes.func.isRequired,
  setFormLoading: PropTypes.func.isRequired,
};

export default Versions;
