import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Page from "common/page/Page";
import Header from "common/page/Header";
import Content from "common/page/Content";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import {
  addPushNotifications,
  deletePushNotifications,
  getPushNotifications,
} from "./PushNotificationsActions";
import PushNotificationTable from "./list/PushNotificationTable";
import PushNotificationSearch from "./list/PushNotificationSearch";
import EditPushNotificationDialog from "./dialogs/EditPushNotificationDialog";
import WarningDialog from "common/basicComponents/WarningDialog";
import { StatusOptions } from "constants/NotificationOptions";
import { getAllReceivers } from "utils/PushNotificationUtils";

const PushNotifications = () => {
  const { t } = useTranslation("notifications");

  const { pushNotifications } = useSelector((state) => state.pushNotifications);
  const { partner } = useSelector((state) => state.auth);

  const [notificationData, setNotificationData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [editDialog, setEditDialog] = useState({
    open: false,
    pushNotification: null,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    pushNotification: null,
  });

  useEffect(() => {
    setLoading(true);
    getPushNotifications({ statusVal: StatusOptions.ALL }).finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const today = new Date().toISOString();
    const newRecivers = pushNotifications.map((data) => {
      const notRecived =
        (data.onesignal_data?.stats?.android?.failed || 0) +
        (data.onesignal_data?.stats?.ios?.failed || 0);
      const allReceivers =
        data.notification_timestamp > today
          ? getAllReceivers(partner) || 0
          : notRecived + data.onesignal_data?.successful || 0;
      const planned = data.notification_timestamp > today;
      const newData = { ...data };
      newData.receivers = allReceivers;
      newData.planned = planned;
      // newData.notRecived = notRecived;
      return newData;
    });
    setNotificationData(newRecivers);
  }, [partner, pushNotifications]);

  const handleSave = (pushNotifications) => {
    addPushNotifications(pushNotifications).then((ok) => {
      if (!ok) return;
      getPushNotifications({ statusVal: StatusOptions.ALL }).finally(() => {
        setLoading(false);
      });
      setEditDialog({ pushNotification: null, open: false });
    });
  };

  const handleDelete = () => {
    deletePushNotifications(deleteDialog.pushNotification).then((ok) => {
      if (!ok) return;
      getPushNotifications({ statusVal: StatusOptions.ALL }).finally(() => {
        setLoading(false);
      });
      setDeleteDialog({ ...deleteDialog, open: false });
    });
  };

  const handleSearch = (searchParams) => {
    getPushNotifications({
      ...searchParams,
    });
  };

  return (
    <Page>
      <Header
        title={t("title")}
        subtitles={[
          [t("receiversNumber") + ":", getAllReceivers(partner) || "-"],
          // [
          //   t("uninstalations") + ":",
          //   notificationData[0]?.notRecived - notificationData[1]?.notRecived ||
          //     "-",
          // ], TODO
        ]}
        button={
          <Button
            variant={ButtonVariant.GREEN}
            text={t("addNotification")}
            icon={IconName.PLUS}
            minWidth="12.5rem"
            onClick={() =>
              setEditDialog({ open: true, pushNotification: null })
            }
          />
        }
      >
        <PushNotificationSearch onSearch={handleSearch} />
      </Header>
      <Content>
        <PushNotificationTable
          pushNotifications={notificationData}
          total={pushNotifications?.length}
          loading={loading}
          onDelete={(pushNotification) =>
            setDeleteDialog({ open: true, pushNotification })
          }
        />
        <EditPushNotificationDialog
          open={editDialog.open}
          pushNotifications={editDialog.pushNotification}
          onClose={() => setEditDialog({ ...editDialog, open: false })}
          onSave={handleSave}
        />
        <WarningDialog
          open={deleteDialog.open}
          data={deleteDialog.pushNotification}
          name={deleteDialog.pushNotification?.notification_title}
          onClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
          onAction={handleDelete}
          title={t("deleteAlertTitle")}
          warningButtonText={t("deleteButtonTitle")}
        />
      </Content>
    </Page>
  );
};

export default PushNotifications;
