import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Chart, Tooltip, Legend, ArcElement } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Box from "common/basicComponents/Box";
import { ViewportMinBreakpoints } from "utils/ViewportContext";
import { TypographyWeight } from "common/basicComponents/Typography";
import Typography from "common/basicComponents/Typography";

Chart.register(ArcElement, Tooltip, Legend);

const ChartBox = styled(Box)`
  letter-spacing: 0.02rem;
  height: 100%;
  width: 100%;
  max-width: min(100%, 35rem);
  margin: auto;
  padding: 0 2rem 1.25rem 2rem;
  justify-content: space-around;
  align-items: stretch;
  display: flex;
  flex-direction: column-reverse;
  min-height: 25rem;
  max-height: 25rem;
  gap: 2.5rem;
  @media screen and (min-width: ${ViewportMinBreakpoints.TABLET}px) {
    flex-direction: row;
    min-height: unset;
    gap: 1rem;
    flex-wrap: wrap;
  }
`;

const LegendBox = styled(Box)`
  width: 9rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: center;
  @media screen and (min-width: ${ViewportMinBreakpoints.TABLET}px) {
    align-items: flex-start;
  }
`;

const LegendItemBox = styled(Box)`
  display: flex;
  gap: 0.75rem;
`;

const ColorBox = styled(Box)`
  background: ${({ color }) => color};
`;

const PieBox = styled(Box)`
  max-width: 100%;
  @media screen and (min-width: ${ViewportMinBreakpoints.TABLET}px) {
    max-width: max(calc(100% - 10rem), 10rem);
  }
  flex-grow: 1;
  min-width: 10rem;
`;

const TotalTypography = styled(Typography)`
  text-transform: uppercase;
`;

const PieChart = ({ data }) => {
  const { t } = useTranslation("analyticsDashboard");

  const labels = useMemo(() => data.map((d) => d.label), [data]);
  const values = useMemo(
    () => data.map((d) => d.data.reduce((sum, v) => sum + v, 0)),
    [data]
  );
  const sum = useMemo(() => values.reduce((sum, v) => sum + v, 0), [values]);
  const colors = useMemo(() => data.map((d) => d.backgroundColor), [data]);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        font: {
          weight: 600,
          family: "Open Sans",
          size: 16,
        },
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(1) + "%";
          return percentage;
        },
        color: "#fff",
      },
      legend: {
        display: false,
      },
    },
  };

  const chartData = {
    labels,
    datasets: [
      {
        data: values,
        backgroundColor: colors,
        borderColor: colors,
      },
    ],
  };

  return (
    <ChartBox>
      <LegendBox>
        <LegendItemBox>
          <TotalTypography text={t("total")} />
          <Typography weight={TypographyWeight.BOLD} text={sum} />
        </LegendItemBox>
        {values.map((v, idx) => (
          <LegendItemBox key={idx}>
            <ColorBox width="2rem" height="1rem" color={colors[idx]} />
            <Typography text={labels[idx]} />
            <Typography text={v} weight={TypographyWeight.BOLD} />
          </LegendItemBox>
        ))}
      </LegendBox>
      <PieBox>
        <Pie data={chartData} options={options} plugins={[ChartDataLabels]} />
      </PieBox>
    </ChartBox>
  );
};

PieChart.propTypes = {
  data: PropTypes.array.isRequired,
};

export default PieChart;
