import { createSlice } from "@reduxjs/toolkit";
import { clearAuthData, setCurrentPartner } from "components/auth/AuthSlice";

const initialState = {
  sets: [],
  setsTotal: 0,
  search: {},
};

export const setsSlice = createSlice({
  name: "sets",
  initialState,
  reducers: {
    appendSets: (state, action) => {
      if (state.sets.length != action.payload.meta?.offset) return;
      state.sets = state.sets.concat(action.payload.sets);
      state.setsTotal = Number(action.payload.meta?.count);
    },
    setSearch: (state, action) => {
      state.sets = [];
      state.setsTotal = null;
      state.search = action.payload;
    },
    clearSets: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
    builder.addCase(setCurrentPartner, () => initialState);
  },
});

export const { appendSets, setSearch, clearSets } = setsSlice.actions;
export default setsSlice.reducer;
