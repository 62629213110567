import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import Chips, { ChipsVariant } from "common/basicComponents/Chips";

const Cell = styled.td`
  height: 4.5rem;
  padding: 0rem 0.75rem 0rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;
const ButtonsCell = styled(Cell)`
  min-width: 6.5rem;
  button {
    display: inline-flex;
    margin: 0 0.25rem;
  }
`;

const BrandRow = ({ data, onEdit, onDelete }) => {
  const { t } = useTranslation("brands");
  const { partner } = useSelector((state) => state.auth);

  return (
    <>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.brand_name}
          tag="span"
        />
      </Cell>
      <Cell centered={true}>
        <Chips
          variant={
            !data.from_main_partner || partner.main_partner
              ? ChipsVariant.YELLOW
              : ChipsVariant.GREY
          }
          text={
            !data.from_main_partner || partner.main_partner
              ? t("ownBrand")
              : t("global")
          }
        />
      </Cell>
      <ButtonsCell centered={true}>
        {(partner.main_partner
          ? data.from_main_partner
          : !data.from_main_partner) && (
          <>
            <Button
              icon={IconName.EDIT}
              variant={ButtonVariant.TEXT}
              size={ButtonSize.XSMALL}
              onClick={() => onEdit(data)}
            />
            <Button
              icon={IconName.TRASH}
              variant={ButtonVariant.TEXT}
              size={ButtonSize.XSMALL}
              onClick={() => onDelete(data)}
            />
          </>
        )}
      </ButtonsCell>
    </>
  );
};

BrandRow.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

BrandRow.defaultProps = {};

export default BrandRow;
