import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

import Accordion from "common/basicComponents/Accordion";
import Box from "common/basicComponents/Box";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { useViewport, ViewportType } from "utils/ViewportContext";
import FormToggleField from "common/formFields/FormToggleField";
import { ToggleVariant } from "common/formFields/FormToggleField";
import { NumberType } from "common/formFields/FormInputField";
import { IconName } from "common/basicComponents/Icon";
import Tooltip from "common/basicComponents/Tooltip";

const AccordionContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem 0.625rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  align-items: baseline;
`;

const Group = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  width: 100%;
  align-items: baseline;
`;

const DetailsForm = ({ disabled }) => {
  const { t } = useTranslation("totems");
  const { type: viewportType } = useViewport();
  const { values } = useFormikContext();
  const [accordionHasErrors, setAccordionHasErrors] = useState(false);

  useEffect(() => {
    if (accordionHasErrors) setAccordionHasErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Accordion text={t("details")} defaultOpen forceOpen={accordionHasErrors}>
      <AccordionContent>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.TABLET ? "50%" : "100%"}
          grow={1}
        >
          <FormInputField
            type={FormInputFieldType.TEXT}
            name="totemName"
            id="totemName"
            label={t("totemName")}
            disabled={disabled}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          padding="0 0.625rem"
          width={viewportType > ViewportType.TABLET ? "50%" : "100%"}
          grow={1}
        >
          <FormInputField
            type={FormInputFieldType.COPY}
            name="licenseKey"
            id="licenseKey"
            label={t("licenseKey")}
            disabled
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Group>
          <Box
            padding="0 0.625rem"
            width={viewportType === ViewportType.MOBILE ? "100%" : undefined}
            grow={1}
            className="totemStatusBox"
          >
            <FormInputField
              type={FormInputFieldType.TEXT}
              name="totemStatus"
              id="totemStatus"
              label={t("status")}
              disabled
              onError={() => setAccordionHasErrors(true)}
            />
            <Tooltip
              anchorSelect=".totemStatusBox"
              text={values.totemStatusTooltip || ""}
            />
          </Box>
          <Box padding="0 0.625rem" width="9rem">
            <FormToggleField
              id={"totemActive"}
              name={"totemActive"}
              label={t("totemLicenseActive")}
              variant={ToggleVariant.SWITCH_COLOR}
              switchWidth="100%"
              disabled={disabled}
            />
          </Box>
        </Group>
        <Group>
          <Box
            padding="0 0.625rem"
            width={viewportType === ViewportType.MOBILE ? "100%" : undefined}
            grow={1}
            basis="15rem"
          >
            <FormInputField
              type={FormInputFieldType.NUMBER}
              icon={IconName.FACE}
              numberCheck={NumberType.NON_NEGATIVE_DECIMAL}
              id={"faceDistanceLimit"}
              name={"faceDistanceLimit"}
              label={t("faceDistanceLimit")}
              disabled={disabled}
              caption={t("faceDistanceLimitHelper")}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box
            padding="0 0.625rem"
            width={viewportType === ViewportType.MOBILE ? "100%" : undefined}
            grow={1}
            basis="15rem"
          >
            <FormInputField
              type={FormInputFieldType.NUMBER}
              icon={IconName.CLOCK}
              numberCheck={NumberType.POSITIVE_INTEGER}
              id={"changeProductSeconds"}
              name={"changeProductSeconds"}
              label={t("changeProductSeconds")}
              disabled={disabled}
              caption={t("changeProductSecondsHelper")}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
          <Box
            padding="0 0.625rem"
            width={viewportType === ViewportType.MOBILE ? "100%" : undefined}
            grow={1}
            basis="15rem"
          >
            <FormInputField
              type={FormInputFieldType.NUMBER}
              icon={IconName.CLOCK}
              numberCheck={NumberType.NON_NEGATIVE_INTEGER}
              id={"hideTryonSeconds"}
              name={"hideTryonSeconds"}
              label={t("hideTryonSeconds")}
              disabled={disabled}
              caption={t("hideTryonSecondsHelper")}
              onError={() => setAccordionHasErrors(true)}
            />
          </Box>
        </Group>
      </AccordionContent>
    </Accordion>
  );
};

DetailsForm.propTypes = {
  disabled: PropTypes.bool,
};

DetailsForm.defaultProps = {
  disabled: false,
};

export default DetailsForm;
