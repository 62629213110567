import Page from "common/page/Page";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";

import { ViewportType, useViewport } from "utils/ViewportContext";
import cloudImage from "../../assets/notFound/cloud.svg";
import Typography from "common/basicComponents/Typography";
import { TypographyWeight } from "common/basicComponents/Typography";
import { TypographyVariant } from "common/basicComponents/Typography";
import Button from "common/basicComponents/Button";
import { ButtonVariant } from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import { ButtonSize } from "common/basicComponents/Button";
import Content from "common/page/Content";

const getImagePosition = (viewportType) =>
  ({
    [ViewportType.MOBILE]: "10%",
    [ViewportType.TABLET]: "calc(50% - 14.25rem)",
    [ViewportType.DESKTOP]: "7.5rem",
  }[viewportType]);

const getContentPadding = (viewportType) =>
  ({
    [ViewportType.MOBILE]: css`
      padding: 2.5rem 0 1.25rem;
    `,
    [ViewportType.TABLET]: css`
      padding: 2.5rem 0 0;
    `,
    [ViewportType.DESKTOP]: css`
      padding: 6.25rem 7.5rem 1rem 6.25rem;
    `,
  }[viewportType]);

const getFontSize = (type) =>
  ({
    [ViewportType.DESKTOP]: "8.75rem",
    [ViewportType.TABLET]: "7.5rem",
    [ViewportType.MOBILE]: "5rem",
  }[type]);

const Image = styled.div`
  background-image: url(${cloudImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  z-index: -1;
  bottom: ${({ viewportType }) =>
    viewportType > ViewportType.MOBILE ? "7.5rem" : "5rem"};
  right: ${({ viewportType }) => getImagePosition(viewportType)};
  height: ${({ viewportType }) =>
    viewportType > ViewportType.MOBILE ? "18.875rem" : "13.125rem"};
  width: ${({ viewportType }) =>
    viewportType > ViewportType.MOBILE ? "28.5rem" : "80%"};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${({ viewportType }) => getContentPadding(viewportType)};
  align-items: ${({ viewportType }) =>
    viewportType === ViewportType.DESKTOP ? "flex-start" : "center"};
  gap: 1.25rem;
  white-space: pre-line;
  text-align: ${({ viewportType }) =>
    viewportType < ViewportType.DESKTOP ? "center" : "left"};
  width: ${({ viewportType }) =>
    viewportType === ViewportType.DESKTOP ? "100%" : null};
  button {
    margin-top: 1.25rem;
  }
`;

const TitleContainer = styled(Typography)`
  font-size: ${({ type }) => getFontSize(type)};
  line-height: ${({ type }) => getFontSize(type)};
`;

const NotFound = () => {
  const navigate = useNavigate();
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("notFound");

  return (
    <Page>
      <Content>
        <MessageContainer viewportType={viewportType}>
          <TitleContainer
            type={viewportType}
            variant={TypographyVariant.H1}
            weight={TypographyWeight.BOLD}
            text="404"
          />
          <Typography
            variant={
              viewportType > ViewportType.MOBILE
                ? TypographyVariant.H3
                : TypographyVariant.H4
            }
            weight={TypographyWeight.BOLD}
            text={t("pageNotFound")}
          />
          <Typography
            variant={
              viewportType > ViewportType.MOBILE
                ? TypographyVariant.H5
                : TypographyVariant.BODY1
            }
            weight={TypographyWeight.REGULAR}
            text={t("404Msg")}
          />
          <Button
            width={viewportType === ViewportType.MOBILE ? "100%" : "12.5rem"}
            variant={ButtonVariant.OUTLINED}
            size={ButtonSize.NORMAL}
            icon={IconName.LEFT_ARROW}
            onClick={() => navigate(-1)}
            text={t("goBack").toUpperCase()}
          />
        </MessageContainer>
        <Image viewportType={viewportType} />
      </Content>
    </Page>
  );
};

export default NotFound;
