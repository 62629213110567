import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Chips, { ChipsVariant } from "common/basicComponents/Chips";

const Card = styled.div`
  min-width: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "40%" : "100%"};
  background: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1.25rem;
`;

const FieldLabel = styled(Typography)`
  padding-top: 1rem;
  ${({ theme, showDivider }) =>
    showDivider && "border-top: 1px solid" + theme.colors.grey[200]};
`;

const FieldValue = styled(Typography)`
  text-align: left;
  word-wrap: break-word;
  padding-bottom: 1rem;
`;

const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0rem;
`;

const ButtonsContainer = styled.div`
  padding-top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const BrandCard = ({ data, onEdit, onDelete }) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("brands");
  const { partner } = useSelector((state) => state.auth);
  return (
    <Card viewport={viewportType}>
      <FieldLabel
        text={t("brandName")}
        variant={TypographyVariant.BODY2}
        weight={TypographyWeight.BOLD}
        tag="span"
        showDivider={false}
      />
      <FieldValue
        text={data.brand_name}
        variant={TypographyVariant.SUBTITLE2}
      />
      <FieldLabel
        text={t("type")}
        variant={TypographyVariant.BODY2}
        weight={TypographyWeight.BOLD}
        tag="span"
        showDivider={true}
      />
      <ChipsContainer>
        <Chips
          variant={
            !data.from_main_partner || partner.main_partner
              ? ChipsVariant.YELLOW
              : ChipsVariant.GREY
          }
          text={
            !data.from_main_partner || partner.main_partner
              ? t("ownBrand")
              : t("global")
          }
        />
      </ChipsContainer>
      {(partner.main_partner
        ? data.from_main_partner
        : !data.from_main_partner) && (
        <ButtonsContainer>
          <>
            <Button
              text={t("common:button.edit")}
              variant={ButtonVariant.CONTAINED}
              size={ButtonSize.SMALL}
              onClick={() => onEdit(data)}
            />
            <Button
              text={t("common:button.delete")}
              variant={ButtonVariant.ERROR}
              size={ButtonSize.SMALL}
              onClick={() => onDelete(data)}
            />
          </>
        </ButtonsContainer>
      )}
    </Card>
  );
};

BrandCard.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default BrandCard;
