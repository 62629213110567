import { replace, forEach } from "lodash";

const prepareUrl = (urlSchema, params) => {
  let url = urlSchema;
  forEach(params, (param, key) => {
    url = replace(url, `:${key}`, param);
  });
  return url;
};

export { prepareUrl };
