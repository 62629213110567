import React from "react";
import { Formik, Form } from "formik";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import Dialog from "common/basicComponents/Dialog";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonType,
} from "common/basicComponents/Button";
import Box from "common/basicComponents/Box";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { useViewport, ViewportType } from "utils/ViewportContext";
import FormSelectField from "common/formFields/FormSelectField";
import { StatusOptions } from "constants/StatusOptions";
import { constantToSelect } from "utils/FormUtils";
import ValidationSchema from "./UserForm.schema";
import { ExtendedUserRoles, UserRoles } from "constants/UserRoles";

const Buttons = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "row" : "column-reverse"};
  margin-top: 2.5rem;
  width: 100%;
`;

const EditUsersDialog = ({ open, user, onSave, onClose }) => {
  const { t } = useTranslation("users");
  const { type: viewportType } = useViewport();
  const { partner } = useSelector((state) => state.auth);

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    if (user?.id) {
      onSave({
        id: user?.id,
        role: values.role.value,
        active: values.status.value === StatusOptions.ACTIVE,
      });
    } else {
      onSave({
        email: values.email,
        role: values.role.value,
        active: values.status.value === StatusOptions.ACTIVE,
      });
    }
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding="0 0 2.5rem">
        <Typography
          variant={TypographyVariant.H4}
          weight={TypographyWeight.BOLD}
          text={user ? t("editUser") : t("addUser")}
        />
      </Box>
      <Formik
        initialValues={{
          id: user?.id,
          email: user?.user_email,
          role: user?.user_role
            ? { value: user.user_role, label: t(`roles.${user.user_role}`) }
            : {
                value: ExtendedUserRoles.USER,
                label: t(`roles.${ExtendedUserRoles.USER}`),
              },
          status: user?.user_active
            ? {
                value: StatusOptions.ACTIVE,
                label: t(`statuses.${StatusOptions.ACTIVE}`),
              }
            : {
                value: StatusOptions.INACTIVE,
                label: t(`statuses.${StatusOptions.INACTIVE}`),
              },
        }}
        onSubmit={onSubmit}
        validationSchema={ValidationSchema}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form>
            <Box width="100%">
              <FormInputField
                type={FormInputFieldType.TEXT}
                name="email"
                id="email"
                label={t("email")}
                disabled={isSubmitting || user?.id}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormSelectField
                name="role"
                id="role"
                label={t("role")}
                options={constantToSelect(
                  partner.main_partner ? ExtendedUserRoles : UserRoles,
                  "users:roles"
                )}
                disabled={isSubmitting}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormSelectField
                name="status"
                id="status"
                label={t("status")}
                options={constantToSelect(StatusOptions, "users:statuses")}
                disabled={isSubmitting}
              />
            </Box>
            <Buttons viewport={viewportType}>
              <Box
                width={viewportType < ViewportType.TABLET ? "100%" : "50%"}
                padding={
                  viewportType < ViewportType.TABLET
                    ? "0.625rem 0"
                    : "0 1rem 0 0"
                }
              >
                <Button
                  variant={ButtonVariant.OUTLINED}
                  text={t("common:button.cancel")}
                  minWidth="13rem"
                  width="100%"
                  onClick={onClose}
                  disabled={isSubmitting}
                />
              </Box>
              <Box
                width={viewportType < ViewportType.TABLET ? "100%" : "50%"}
                padding={
                  viewportType < ViewportType.TABLET
                    ? "0.625rem 0"
                    : "0 0 0 1rem"
                }
              >
                <Button
                  variant={ButtonVariant.GREEN}
                  text={t("common:button.save")}
                  minWidth="13rem"
                  width="100%"
                  type={ButtonType.SUBMIT}
                  isLoading={isSubmitting}
                />
              </Box>
            </Buttons>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

EditUsersDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  user: PropTypes.object,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

EditUsersDialog.defaultProps = {
  user: null,
};

export default EditUsersDialog;
