import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment/moment";

import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import Chips, { ChipsVariant } from "common/basicComponents/Chips";
import Box from "common/basicComponents/Box";

const Card = styled.div`
  min-width: 100%;
  background: ${({ theme }) => theme.colors.white};
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  overflow: hidden;
`;

const FieldLabel = styled(Typography)`
  text-align: left;
  padding-bottom: 0.25rem;
  word-wrap: break-word;
  padding-top: 1rem;
  ${({ theme, showDivider }) =>
    showDivider && "border-top: 1px solid" + theme.colors.grey[200]};
`;

const ContentContainer = styled(Typography)`
  padding-bottom: 1rem;
`;

const RowDirectionConatiner = styled.div`
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  ${({ theme, showDivider }) =>
    showDivider && "border-top: 1px solid" + theme.colors.grey[200]};
`;

const ButtonsContainer = styled.div`
  margin-top: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const PushNotificationCard = ({ data, onDelete }) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("notifications");
  return (
    <Card viewport={viewportType}>
      <FieldLabel
        variant={TypographyVariant.SUBTITLE2}
        weight={TypographyWeight.BOLD}
        text={t("title")}
        showDivider={false}
      />
      <ContentContainer
        variant={TypographyVariant.SUBTITLE2}
        text={data.notification_title}
      />
      <FieldLabel
        variant={TypographyVariant.SUBTITLE2}
        weight={TypographyWeight.BOLD}
        text={t("message")}
        showDivider={true}
      />
      <ContentContainer
        variant={TypographyVariant.SUBTITLE2}
        text={data.notification_message}
      />
      <FieldLabel
        variant={TypographyVariant.SUBTITLE2}
        weight={TypographyWeight.BOLD}
        text={t("sendDate")}
        showDivider={true}
      />
      <ContentContainer
        variant={TypographyVariant.SUBTITLE2}
        text={
          data.notification_timestamp
            ? moment(data.notification_timestamp).format("L, LT")
            : "-"
        }
      />
      <RowDirectionConatiner showDivider={true}>
        <Typography
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("receivers")}
          showDivider={true}
        />
        <Box show={data.planned || !!data?.onesignal_data}>
          <Typography
            variant={TypographyVariant.SUBTITLE2}
            text={data.onesignal_data?.successful || 0}
            tag="span"
          />
          <Typography
            variant={TypographyVariant.SUBTITLE2}
            weight={TypographyWeight.BOLD}
            text={` / ${data.receivers || 0}`}
            tag="span"
          />
        </Box>
      </RowDirectionConatiner>
      <RowDirectionConatiner showDivider={true}>
        <Typography
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("status")}
        />
        <Chips
          variant={data.planned ? ChipsVariant.VIOLET : ChipsVariant.GREY}
          text={data.planned ? t("search.planned") : t("search.sent")}
        />
      </RowDirectionConatiner>
      {data.planned && (
        <ButtonsContainer>
          <Button
            text={t("common:button.delete")}
            variant={ButtonVariant.ERROR}
            size={ButtonSize.SMALL}
            onClick={() => onDelete(data)}
          />
        </ButtonsContainer>
      )}
    </Card>
  );
};

PushNotificationCard.propTypes = {
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PushNotificationCard;
