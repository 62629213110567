import React from "react";
import PropTypes from "prop-types";
import Table from "common/Table";
import { ViewportType } from "utils/ViewportContext";
import { useTranslation } from "react-i18next";
import BannerRow from "./BannerRow";
import BannerCard from "./BannerCard";

const BannerTable = ({ loading, onEdit, onDelete, banners }) => {
  const { t } = useTranslation("totems");

  const headers = [
    { text: t("banners.name") },
    { text: t("banners.type") },
    { text: t("banners.duration"), centered: true },
    { text: t("banners.displayedTime"), centered: true },
    { text: t("banners.status"), centered: true, maxWidth: "5.5rem" },
    {
      text: t("actions"),
      centered: true,
      maxWidth: "5.5rem",
    },
  ];

  return (
    <Table
      headers={headers}
      data={banners}
      tableRow={BannerRow}
      tableCard={BannerCard}
      cardViewportBreakpoint={ViewportType.MOBILE}
      keyPropertyName="banner_id"
      loading={loading}
      itemProps={{
        onEdit,
        onDelete,
      }}
      pagination={false}
    />
  );
};

BannerTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  banners: PropTypes.array,
};

BannerTable.defaultProps = {
  banners: [],
};

export default BannerTable;
