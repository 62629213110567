import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Typography, { TypographyVariant, TypographyWeight } from "./Typography";
import Icon, { IconName } from "./Icon";
import Button, {
  ButtonIconAlign,
  ButtonSize,
  ButtonVariant,
  ButtonWidth,
} from "./Button";

const AccordionSize = {
  NORMAL: "normal",
  SMALL: "small",
};

const getAccordionSize = (type) =>
  ({
    [ViewportType.DESKTOP]: AccordionSize.NORMAL,
    [ViewportType.TABLET]: AccordionSize.NORMAL,
    [ViewportType.MOBILE]: AccordionSize.SMALL,
  }[type]);

const getAccordionStyle = (size) =>
  ({
    [AccordionSize.NORMAL]: css`
      max-height: 4.25rem;
      height: 4.25rem;
    `,
    [AccordionSize.SMALL]: css`
      max-height: 4rem;
      height: 4rem;
    `,
  }[size]);

const StyledAccordion = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};
  width: 100%;
  display: flex;
  ${({ lockedOpen }) => !lockedOpen && "cursor: pointer;"}
  align-items: center;
  padding: 0 1.25rem;
  gap: 1.5rem;
  .accordion-title {
    flex-grow: 1;
    margin: auto;
  }
  .arrow-icon {
    transform: ${({ isOpen }) => (isOpen ? "rotateX(180deg)" : "none")};
  }
  ${({ size }) => getAccordionStyle(size)}
`;

const Accordion = ({
  buttonAction,
  buttonText,
  buttonIcon,
  buttonVariant,
  buttonDisabled,
  buttonSize,
  text,
  children,
  size,
  buttonWidth,
  defaultOpen,
  lockedOpen,
  forceOpen,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen || lockedOpen || forceOpen);
  const { type: viewportType } = useViewport();
  const accordionSize = size || getAccordionSize(viewportType);

  useEffect(() => {
    setIsOpen((isOpen) => isOpen || forceOpen);
  }, [forceOpen]);

  return (
    <div>
      <StyledAccordion
        size={accordionSize}
        isOpen={isOpen || lockedOpen}
        lockedOpen={lockedOpen}
        onClick={() => !lockedOpen && setIsOpen((prev) => !prev)}
      >
        {!lockedOpen && (
          <Icon className="arrow-icon" name={IconName.DOWN} size="2rem" />
        )}
        <Typography
          variant={
            viewportType === ViewportType.MOBILE
              ? TypographyVariant.SUBTITLE1
              : TypographyVariant.H5
          }
          weight={TypographyWeight.BOLD}
          tag="span"
          className="accordion-title"
          text={text}
        />
        {(buttonText || buttonIcon) && (
          <div onClick={(e) => e.stopPropagation()}>
            <Button
              text={viewportType !== ViewportType.MOBILE && buttonText}
              variant={buttonVariant}
              size={buttonSize}
              width={buttonWidth}
              icon={buttonIcon}
              iconAlign={ButtonIconAlign.LEFT}
              onClick={buttonAction}
              disabled={buttonDisabled}
            />
          </div>
        )}
      </StyledAccordion>
      <div
        style={{
          display: isOpen ? "" : "none",
        }}
      >
        {children}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  buttonAction: PropTypes.func,
  buttonText: PropTypes.string,
  buttonIcon: PropTypes.string,
  buttonVariant: PropTypes.oneOf(Object.values(ButtonVariant)),
  buttonSize: PropTypes.oneOf(Object.values(ButtonSize)),
  buttonWidth: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(ButtonWidth)),
    PropTypes.number,
    PropTypes.string,
  ]),
  buttonDisabled: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  lockedOpen: PropTypes.bool,
  text: PropTypes.string,
  size: PropTypes.oneOf(Object.values(AccordionSize)),
  forceOpen: PropTypes.bool,
};

Accordion.defaultProps = {
  buttonAction: () => {},
  buttonText: null,
  buttonSize: ButtonVariant.NORMAL,
  buttonVariant: ButtonVariant.CONTAINED,
  buttonDisabled: false,
  size: null,
  defaultOpen: false,
  lockedOpen: false,
  text: "",
};

export default Accordion;
