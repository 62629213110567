import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Dialog from "common/basicComponents/Dialog";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import Box from "common/basicComponents/Box";
import { getProductLog } from "../ProductActions";
import { findProductChanges } from "utils/ProductUtils";
import { objectToCamelCase } from "utils/CommonUtils";
import Input, { InputType } from "common/basicComponents/Input";
import CurrencyInput from "common/basicComponents/CurrencyInput";
import BooleanChangeItem from "./BooleanChangeItem";

const DialogContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 2.5rem;
`;

const SectionHeader = styled.div`
  padding-bottom: 0.5rem;
  margin-bottom: 1.75rem;

  border-bottom: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[100]} 1;

  & > * {
    background: ${({ theme }) => theme.colors.gradient[100]};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ChangeType = {
  BEFORE: "Before",
  AFTER: "After",
};

const LogDetailsDialog = ({ open, logId, onClose }) => {
  const { t } = useTranslation("product");
  const { productLog } = useSelector((state) => state.products);
  const { partner } = useSelector((state) => state.auth);
  const [changes, setChanges] = useState([]);

  useEffect(() => {
    if (open && logId) {
      getProductLog(logId);
    }
  }, [open, logId]);

  useEffect(() => {
    if (!productLog) {
      setChanges([]);
      return;
    }
    setChanges(
      findProductChanges(
        objectToCamelCase(productLog.old_row_data),
        objectToCamelCase(productLog.new_row_data)
      )
    );
  }, [productLog]);

  const getTranslation = (keys) => {
    if (Array.isArray(keys)) {
      return keys.map((key) => t(key)).join(" ");
    }
    return t(keys);
  };

  const getChangeComponent = (type, change) => {
    if (change.showAsCheckbox)
      return (
        <BooleanChangeItem
          key={change.key + type}
          label={getTranslation(change.label)}
          value={type === ChangeType.BEFORE ? change.oldValue : change.newValue}
        />
      );
    if (change.showAsPrice)
      return (
        <CurrencyInput
          key={change.key + type}
          id={change.key + type}
          name={change.key + type}
          label={getTranslation(change.label)}
          value={
            type === ChangeType.BEFORE
              ? change.oldValue?.toString()
              : change.newValue?.toString()
          }
          currency={partner.partner_currency}
          readOnly
        />
      );
    return (
      <Input
        key={change.key + type}
        id={change.key + type}
        name={change.key + type}
        label={getTranslation(change.label)}
        value={
          type === ChangeType.BEFORE
            ? change.oldValue?.toString()
            : change.newValue?.toString()
        }
        type={InputType.TEXT}
        readOnly
      />
    );
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box width="100%">
          <Typography
            variant={TypographyVariant.H4}
            weight={TypographyWeight.BOLD}
            text={productLog?.title || ""}
          />
        </Box>
        <Box width="50%" padding="0 0.625rem 0 0">
          <SectionHeader>
            <Typography
              variant={TypographyVariant.SUBTITLE1}
              weight={TypographyWeight.SEMIBOLD}
              text={t("log.before").toUpperCase()}
              tag="span"
            />
          </SectionHeader>
          <SectionContent>
            {changes?.map((change) =>
              getChangeComponent(ChangeType.BEFORE, change)
            )}
          </SectionContent>
        </Box>
        <Box width="50%" padding="0 0 0 0.625rem">
          <SectionHeader>
            <Typography
              variant={TypographyVariant.SUBTITLE1}
              weight={TypographyWeight.SEMIBOLD}
              text={t("log.after").toUpperCase()}
              tag="span"
            />
          </SectionHeader>
          <SectionContent>
            {changes?.map((change) =>
              getChangeComponent(ChangeType.AFTER, change)
            )}
          </SectionContent>
          <Box padding="2rem 0 0">
            <Button
              variant={ButtonVariant.OUTLINED}
              text={t("common:button.cancel")}
              width="100%"
              onClick={onClose}
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

LogDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  logId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

LogDetailsDialog.defaultProps = {
  logId: null,
};

export default LogDetailsDialog;
