import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useFormikContext, useField } from "formik";
import Select from "common/basicComponents/Select";
import { SelectSize } from "common/basicComponents/Select";
import { translate } from "utils/FormUtils";

const FormSelectField = ({
  id,
  name,
  label,
  caption,
  options,
  onChange,
  disabled,
  placeholder,
  isMulti,
  onlyTextOptions,
  size,
  onError,
}) => {
  const { isSubmitting, submitCount } = useFormikContext();
  const [field, meta, helpers] = useField(name);

  useEffect(() => {
    if (submitCount > 0 && meta.touched && meta.error && onError) {
      onError();
    }
  }, [meta, submitCount, onError]);

  return (
    <Select
      id={id}
      name={name}
      value={field.value}
      onChange={(option) => {
        helpers.setValue(option);
        onChange(option);
      }}
      disabled={isSubmitting || disabled}
      label={label}
      caption={caption}
      error={
        submitCount > 0 && meta.touched && meta.error
          ? translate(meta.error.value || meta.error)
          : ""
      }
      size={size}
      placeholder={placeholder}
      options={options}
      isMulti={isMulti}
      onlyTextOptions={onlyTextOptions}
    />
  );
};

FormSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  caption: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      group: PropTypes.string,
      additionalLabel: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  onlyTextOptions: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(SelectSize)),
  onError: PropTypes.func,
};

FormSelectField.defaultProps = {
  label: "",
  caption: "",
  options: [],
  onChange: () => {},
  disabled: false,
  placeholder: "",
  isMulti: false,
  onlyTextOptions: false,
  size: SelectSize.NORMAL,
  onError: () => {},
};

export default FormSelectField;
