import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Header from "common/page/Header";
import Content from "common/page/Content";
import Page from "common/page/Page";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import UserTable from "./list/UserTable";
import WarningDialog from "common/basicComponents/WarningDialog";
import EditUsersDialog from "./dialogs/EditUsersDialog";
import { addUser, deleteUser, editUser, getUsers } from "./UserActions";
import { clearUsers } from "./UserSlice";
import { PageSizes } from "common/Table/Pagination";

const UserList = () => {
  const { t } = useTranslation("users");
  const { users, usersTotal } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [meta, setMeta] = useState({ page: 1, pageSize: PageSizes[0] });
  const [visibleUsers, setVisibleUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    const maximumVisible = Math.min(usersTotal, meta.page * meta.pageSize);

    if (maximumVisible - users?.length > 0) return;

    setVisibleUsers(
      users.slice((meta.page - 1) * meta.pageSize, maximumVisible)
    );
  }, [meta, users, usersTotal]);

  useEffect(() => {
    setLoading(true);
    const maximumVisible = Math.min(
      usersTotal || meta.pageSize,
      meta.page * meta.pageSize
    );
    const offset = users?.length;
    const limit = maximumVisible - offset;

    if (usersTotal > 0 && limit <= 0) {
      setLoading(false);
      return;
    }

    getUsers({ offset, limit }).finally(() => {
      setLoading(false);
    });
  }, [meta, users?.length, usersTotal]);

  const [editDialog, setEditDialog] = useState({
    open: false,
    user: null,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    user: null,
  });

  const handleSave = (user, form) => {
    if (user.id) {
      editUser(user, form).then((ok) => {
        if (!ok) return;
        dispatch(clearUsers());
        setMeta({ page: 1, pageSize: PageSizes[0] });
        setEditDialog({ user: null, open: false });
      });
    } else {
      addUser(user, form).then((ok) => {
        if (!ok) return;
        dispatch(clearUsers());
        setMeta({ page: 1, pageSize: PageSizes[0] });
        setEditDialog({ user: null, open: false });
      });
    }
  };

  const handleDelete = () => {
    deleteUser(deleteDialog.user).then((ok) => {
      if (!ok) return;
      dispatch(clearUsers());
      setMeta({ page: 1, pageSize: PageSizes[0] });
      setDeleteDialog({ ...deleteDialog, open: false });
    });
  };

  return (
    <Page>
      <Header
        title={t("title")}
        subtitles={[[t("totalNumber"), (usersTotal || "").toString()]]}
        button={
          <Button
            minWidth={200}
            variant={ButtonVariant.GREEN}
            icon={IconName.PLUS}
            text={t("addUser")}
            onClick={() => setEditDialog({ open: true, user: null })}
          />
        }
      />
      <Content>
        <UserTable
          users={visibleUsers}
          total={usersTotal}
          meta={meta}
          onMetaChange={setMeta}
          loading={loading}
          onEdit={(user) => setEditDialog({ open: true, user })}
          onDelete={(user) => setDeleteDialog({ open: true, user })}
        />
        <EditUsersDialog
          open={editDialog.open}
          user={editDialog.user}
          onClose={() => setEditDialog({ ...editDialog, open: false })}
          onSave={handleSave}
        />
        <WarningDialog
          open={deleteDialog.open}
          data={deleteDialog.user}
          name={deleteDialog.user?.user_email}
          onClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
          onAction={handleDelete}
          title={t("deleteAlertTitle")}
          warningButtonText={t("deleteButtonTitle")}
        />
      </Content>
    </Page>
  );
};

export default UserList;
