import _ from "lodash";

import TotemStatus from "constants/TotemStatus";
import i18n from "i18n/i18n";
import { FileState } from "common/formFields/FormFileField";

const checkTotemStatus = (totem) => {
  if (new Date() > new Date(totem.totem_expiration_date)) {
    return TotemStatus.EXPIRED;
  } else if (!totem.totem_deactivation_date && totem.totem_active) {
    return TotemStatus.ACTIVE;
  } else if (
    totem.totem_deactivation_date &&
    new Date(totem.totem_last_activity) <
      new Date(totem.totem_deactivation_date)
  ) {
    return TotemStatus.INACTIVE_PENDING;
  } else if (
    (!totem.totem_active &&
      new Date(totem.totem_last_activity) >
        new Date(totem.totem_deactivation_date)) ||
    new Date(totem.totem_last_activity) > new Date(totem.totem_expiration_date)
  ) {
    return TotemStatus.INACTIVE_CONFIRMED;
  }
};

const checkIfTotemActive = (totem) => {
  const status = checkTotemStatus(totem);
  return status === TotemStatus.ACTIVE;
};

const getTotemStatus = (totem) => {
  const status = checkTotemStatus(totem);
  return i18n.t(`totems:totemStatus.${status}`);
};

const getTotemStatusShort = (totem) => {
  const status = checkTotemStatus(totem);
  return i18n.t(`totems:totemStatusShort.${status}`);
};

const getTotemStatusTooltip = (totem) => {
  const status = checkTotemStatus(totem);
  return i18n.t(`totems:tooltips.${status}`);
};

const checkIfTotemDeletable = (totem) => {
  const status = checkTotemStatus(totem);
  return (
    status === TotemStatus.EXPIRED || status === TotemStatus.INACTIVE_CONFIRMED
  );
};

const totemFileNames = [
  "logoFile",
  "topImageFile",
  "middleImageFile",
  "bottomImageFile",
];

const prepareTotemFormData = (totem) => {
  const formData = new FormData();
  const totemData = _.cloneDeep(totem);

  totemFileNames.forEach((filename) => {
    if (totemData[filename]) {
      if (
        [FileState.NEW, FileState.REPLACED].includes(
          totemData[filename]?.uploadState
        )
      )
        totemData[`${filename}New`] = true;
      if (
        [FileState.DELETED, FileState.REPLACED].includes(
          totemData[filename]?.uploadState
        )
      )
        totemData[`${filename}Deleted`] = true;

      formData.append(`${filename}`, totemData[filename]?.file);
      delete totemData[filename];
    }
  });

  formData.append("totemData", JSON.stringify(totemData));
  return formData;
};

const bannerFileName = "banner_file";
const prepareBannerFormData = (banner) => {
  const formData = new FormData();
  const bannerData = _.cloneDeep(banner);

  if (bannerData[bannerFileName]) {
    formData.append(`file`, bannerData[bannerFileName].file);
    delete bannerData[bannerFileName];
  }

  formData.append("bannerData", JSON.stringify(bannerData));
  return formData;
};

const totemsToSelect = (totemList) => {
  const result = [];
  totemList.forEach((totem) =>
    result.push({
      label: totem.totem_name || "-",
      value: totem.totem_id,
    })
  );
  return result;
};

export {
  checkIfTotemActive,
  checkTotemStatus,
  checkIfTotemDeletable,
  getTotemStatus,
  getTotemStatusShort,
  getTotemStatusTooltip,
  prepareTotemFormData,
  prepareBannerFormData,
  totemsToSelect,
};
