import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

import EditorTool from "constants/EditorTool";
import { IconName } from "common/basicComponents/Icon";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonIconAlign,
} from "common/basicComponents/Button";

const SidePanel = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > span {
    background: ${({ theme }) => theme.colors.gradient[100]};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: pre-line;
  }
`;

const ToolButtons = styled.div`
  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
  width: 100%;

  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & .smart-plus:not(:hover):not(:active):not(:disabled) {
    color: ${({ theme }) => theme.colors.success[700]};
  }

  & .smart-minus:not(:hover):not(:active):not(:disabled) {
    color: ${({ theme }) => theme.colors.secondary[700]};
  }
`;

const EditorSidePanel = ({ hintText, tool, setTool, loading }) => {
  const { t } = useTranslation("wizard");

  return (
    <SidePanel>
      <Typography
        variant={TypographyVariant.BUTTON}
        weight={TypographyWeight.BOLD}
        text={hintText}
      />
      <div>
        <Typography
          variant={TypographyVariant.OVERLINE}
          weight={TypographyWeight.REGULAR}
          text={t("editMask")}
        />
        <ToolButtons>
          <Button
            variant={
              tool === EditorTool.SMART_PLUS
                ? ButtonVariant.TEXT
                : ButtonVariant.OUTLINED
            }
            icon={IconName.MAGIC_WAND}
            iconAlign={ButtonIconAlign.LEFT}
            text={t("intelligentKeep")}
            className="smart-plus"
            onClick={() => setTool(EditorTool.SMART_PLUS)}
            disabled={loading}
          />
          <Button
            variant={
              tool === EditorTool.SMART_MINUS
                ? ButtonVariant.TEXT
                : ButtonVariant.OUTLINED
            }
            icon={IconName.MAGIC_WAND}
            iconAlign={ButtonIconAlign.LEFT}
            text={t("intelligentRemove")}
            className="smart-minus"
            onClick={() => setTool(EditorTool.SMART_MINUS)}
            disabled={loading}
          />
          <Button
            variant={
              tool === EditorTool.PLUS
                ? ButtonVariant.TEXT
                : ButtonVariant.GREEN
            }
            icon={IconName.PLUS}
            iconAlign={ButtonIconAlign.LEFT}
            text={t("manualKeep")}
            onClick={() => setTool(EditorTool.PLUS)}
            disabled={loading}
          />
          <Button
            variant={
              tool === EditorTool.MINUS
                ? ButtonVariant.TEXT
                : ButtonVariant.ERROR
            }
            icon={IconName.MINUS}
            iconAlign={ButtonIconAlign.LEFT}
            text={t("manualRemove")}
            onClick={() => setTool(EditorTool.MINUS)}
            disabled={loading}
          />
        </ToolButtons>
      </div>
    </SidePanel>
  );
};

EditorSidePanel.propTypes = {
  hintText: PropTypes.string.isRequired,
  tool: PropTypes.string.isRequired,
  setTool: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default EditorSidePanel;
