import i18n from "i18n/i18n";

const TermsStructure = [
  {
    text: i18n.t("terms:contents.services.title"),
    children: [
      {
        text: i18n.t("terms:contents.services.text")[0],
      },
      {
        text: i18n.t("terms:contents.services.text")[1],
        children: [
          {
            text: i18n.t("terms:contents.services.text")[2],
          },
          {
            text: i18n.t("terms:contents.services.text")[3],
          },
          {
            text: i18n.t("terms:contents.services.text")[4],
          },
        ],
      },
    ],
  },
  {
    text: i18n.t("terms:contents.restrictions.title"),
    children: [
      {
        text: i18n.t("terms:contents.restrictions.text")[0],
      },
      {
        text: i18n.t("terms:contents.restrictions.text")[1],
      },
      {
        text: i18n.t("terms:contents.restrictions.text")[2],
      },
      {
        text: i18n.t("terms:contents.restrictions.text")[3],
      },
      {
        text: i18n.t("terms:contents.restrictions.text")[4],
      },
    ],
  },
  {
    text: i18n.t("terms:contents.confidentiality.title"),
    children: [
      {
        text: i18n.t("terms:contents.confidentiality.text")[0],
      },
      {
        text: i18n.t("terms:contents.confidentiality.text")[1],
      },
      {
        text: i18n.t("terms:contents.confidentiality.text")[2],
      },
      {
        text: i18n.t("terms:contents.confidentiality.text")[3],
      },
      {
        text: i18n.t("terms:contents.confidentiality.text")[4],
      },
      {
        text: i18n.t("terms:contents.confidentiality.text")[5],
      },
      {
        text: i18n.t("terms:contents.confidentiality.text")[6],
      },
      {
        text: i18n.t("terms:contents.confidentiality.text")[7],
      },
      {
        text: i18n.t("terms:contents.confidentiality.text")[8],
      },
      {
        text: i18n.t("terms:contents.confidentiality.text")[9],
      },
      {
        text: i18n.t("terms:contents.confidentiality.text")[10],
      },
    ],
  },
  {
    text: i18n.t("terms:contents.fees.title"),
    children: [
      {
        text: i18n.t("terms:contents.fees.text")[0],
      },
      {
        text: i18n.t("terms:contents.fees.text")[1],
      },
      {
        text: i18n.t("terms:contents.fees.text")[2],
      },
      {
        text: i18n.t("terms:contents.fees.text")[3],
      },
      {
        text: i18n.t("terms:contents.fees.text")[4],
      },
      {
        text: i18n.t("terms:contents.fees.text")[5],
      },
      {
        text: i18n.t("terms:contents.fees.text")[6],
      },
      {
        text: i18n.t("terms:contents.fees.text")[7],
      },
      {
        text: i18n.t("terms:contents.fees.text")[8],
      },
    ],
  },
  {
    text: i18n.t("terms:contents.term.title"),
    children: [
      {
        text: i18n.t("terms:contents.term.text")[0],
      },
      {
        text: i18n.t("terms:contents.term.text")[1],
      },
    ],
  },
  {
    text: i18n.t("terms:contents.warranty.title"),
    children: [
      {
        text: i18n.t("terms:contents.warranty.text")[0],
      },
      {
        text: i18n.t("terms:contents.warranty.text")[1],
        children: [
          {
            text: i18n.t("terms:contents.warranty.text")[2],
          },
          {
            text: i18n.t("terms:contents.warranty.text")[3],
          },
        ],
      },
      {
        text: i18n.t("terms:contents.warranty.text")[4],
        children: [
          {
            text: i18n.t("terms:contents.warranty.text")[5],
          },
          {
            text: i18n.t("terms:contents.warranty.text")[6],
          },
          {
            text: i18n.t("terms:contents.warranty.text")[7],
          },
        ],
      },
    ],
  },
  {
    text: i18n.t("terms:contents.liability.title"),
    children: [
      {
        text: i18n.t("terms:contents.liability.text")[0],
      },
      {
        text: i18n.t("terms:contents.liability.text")[1],
      },
      {
        text: i18n.t("terms:contents.liability.text")[2],
      },
      {
        text: i18n.t("terms:contents.liability.text")[3],
      },
      {
        text: i18n.t("terms:contents.liability.text")[4],
      },
    ],
  },
  {
    text: i18n.t("terms:contents.miscellaneous.title"),
    children: [
      {
        text: i18n.t("terms:contents.miscellaneous.text")[0],
      },
      {
        text: i18n.t("terms:contents.miscellaneous.text")[1],
      },
      {
        text: i18n.t("terms:contents.miscellaneous.text")[2],
      },
      {
        text: i18n.t("terms:contents.miscellaneous.text")[3],
      },
      {
        text: i18n.t("terms:contents.miscellaneous.text")[4],
      },
    ],
  },
];

export default TermsStructure;
