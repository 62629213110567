import theme from "theme/theme";

const EditorToolBrush = {
  SMART_PLUS: {
    color: theme.colors.success[700],
    operation: "source-over",
  },
  SMART_MINUS: {
    color: theme.colors.secondary[700],
    operation: "source-over",
  },
  PLUS: {
    color: theme.colors.success[500],
    operation: "source-over",
  },
  MINUS: {
    color: theme.colors.secondary[500],
    operation: "source-over",
  },
};

export default EditorToolBrush;
