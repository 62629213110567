import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import DateInput, { DateInputSize } from "common/basicComponents/DateInput";
import { translate } from "utils/FormUtils";

const FormDateField = ({
  label,
  disabled,
  id,
  name,
  size,
  disablePastDates,
  onError,
}) => {
  const [field, meta, helpers] = useField(name);
  const { isSubmitting, submitCount } = useFormikContext();

  useEffect(() => {
    if (submitCount > 0 && meta.touched && meta.error && onError) {
      onError();
    }
  }, [meta, submitCount, onError]);

  return (
    <DateInput
      id={id}
      name={name}
      label={label}
      size={size}
      error={
        submitCount > 0 && meta.touched && meta.error
          ? translate(meta.error)
          : ""
      }
      disabled={isSubmitting || disabled}
      disablePast={disablePastDates}
      value={field.value}
      onChange={(value) => helpers.setValue(value)}
    />
  );
};

FormDateField.defaultProps = {
  label: "",
  disabled: false,
  size: DateInputSize.NORMAL,
  disablePastDates: false,
  onError: PropTypes.func,
};

FormDateField.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(DateInputSize)),
  disablePastDates: PropTypes.bool,
  onError: () => {},
};

export default FormDateField;
export { DateInputSize };
