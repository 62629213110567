import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import {
  addNotification,
  NotificationVariant,
} from "components/notifications/NotificationSlice";
import { REACT_APP_GOOGLE_CLIENT_ID } from "constants/env";
import { login } from "../AuthActions";
import { getCurrentLocale } from "i18n/i18n";

const ButtonContainer = styled.div`
  & > div {
    display: flex;
    justify-content: center;
  }
  & iframe {
    margin: 0 !important;
  }
`;

const LoginButton = () => {
  const { t } = useTranslation("auth");
  const dispatch = useDispatch();
  const ref = useRef(null);

  const handleCallbackResponse = useCallback(
    (res) => {
      if (res && res.credential) {
        login(res.credential);
      } else {
        dispatch(
          addNotification({
            message: t("loginFailed"),
            variant: NotificationVariant.ERROR,
          })
        );
      }
    },
    [dispatch, t]
  );

  const initializeGoogle = useCallback(() => {
    if (!window.google) return;
    window.google.accounts.id.initialize({
      client_id: REACT_APP_GOOGLE_CLIENT_ID,
      callback: handleCallbackResponse,
    });
    window.google.accounts.id.renderButton(ref.current, {
      size: "large",
      shape: "rectangular",
      locale: getCurrentLocale(),
    });
  }, [handleCallbackResponse]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://accounts.google.com/gsi/client?hl=" + getCurrentLocale();
    script.onload = initializeGoogle;
    script.async = true;
    script.id = "google-client-script";
    document.head.appendChild(script);
  }, [initializeGoogle]);

  return <ButtonContainer ref={ref} />;
};

export default LoginButton;
