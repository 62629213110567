import React from "react";
import { Form } from "formik";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { useViewport, ViewportType } from "utils/ViewportContext";
import DesktopSearchForm from "./DesktopSearchForm";
import MobileSearchForm from "./MobileSearchForm";
import CustomFormik from "common/basicComponents/CustomFormik";
import { StatusOptions } from "constants/NotificationOptions";

const PushNotificationSearch = ({ onSearch }) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("notifications");

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    onSearch({
      statusVal: values.statusVal.value,
    });
    setSubmitting(false);
  };

  return (
    <CustomFormik
      initialValues={{
        statusVal: { label: t("search.all"), value: StatusOptions.ALL },
      }}
      onSubmit={onSubmit}
      countChangedFields
    >
      <Form>
        {viewportType === ViewportType.DESKTOP ? (
          <DesktopSearchForm />
        ) : (
          <MobileSearchForm />
        )}
      </Form>
    </CustomFormik>
  );
};

PushNotificationSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default PushNotificationSearch;
