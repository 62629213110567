import React from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import Logo, { LogoVariant } from "common/basicComponents/Logo";
import Select from "common/basicComponents/Select";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import NavigationItem from "./NavigationItem";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ActionsContainer = styled.div`
  min-width: 17rem;
  max-width: 17rem;
  height: 100vh;
  background: ${({ theme }) => theme.colors.white};
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 2.25rem 1.5rem 1.25rem 1.5rem;
  border-right: 1px solid ${({ theme }) => theme.colors.black};
  overflow: hidden;
`;

const NavigationLogo = styled(Logo)`
  min-height: 6.25rem;
`;

const PartnerSelect = styled(Select)`
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1.75rem;
`;

const PageList = styled.div`
  width: calc(100% + 0.875rem);
  margin-left: 0.875rem;
  padding-right: 0.375rem;
  flex-grow: 1;
  margin-bottom: 1.5rem;
  overflow-y: auto;
  scrollbar-gutter: stable;
`;

const DesktopNavigation = ({
  pages,
  partners,
  partner,
  onPartnerChanged,
  onPageChanged,
  onLogout,
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <ActionsContainer>
      <NavigationLogo variant={LogoVariant.VERTICAL} />
      <PartnerSelect
        id="partner-select"
        name="partner-select"
        onlyTextOptions
        renderCaption={false}
        options={partners.map((p) => ({
          value: p.id,
          label: p.partner_name,
        }))}
        value={{
          value: partner?.id || "",
          label: partner?.partner_name || "",
        }}
        onChange={(opt) => {
          onPartnerChanged(partners.find((p) => p.id === opt.value));
        }}
      />
      <PageList>
        {pages.map((p) => (
          <NavigationItem
            key={p.path}
            icon={p.icon}
            text={p.name}
            selected={location.pathname.startsWith(p.path)}
            onClick={() => onPageChanged(p)}
          />
        ))}
      </PageList>
      <Button
        variant={ButtonVariant.CONTAINED}
        size={ButtonSize.NORMAL}
        text={t("auth:logout")}
        onClick={onLogout}
        width="100%"
      />
    </ActionsContainer>
  );
};

const PartnerPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  partner_name: PropTypes.string.isRequired,
});

DesktopNavigation.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  partners: PropTypes.arrayOf(PartnerPropType).isRequired,
  partner: PartnerPropType,
  onPartnerChanged: PropTypes.func.isRequired,
  onPageChanged: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

DesktopNavigation.defaultProps = {
  partner: null,
};

export default DesktopNavigation;
