import React, { useMemo } from "react";
import { createRoot } from "react-dom/client";
import { Provider, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import theme from "theme/theme";
import GlobalStyle from "theme/globalStyle";
import { ViewportProvider } from "utils/ViewportContext";
import "moment/locale/pl";
import store from "redux/store";
import "i18n/i18n";
import { getCurrentLocale } from "i18n/i18n";
import Notification from "components/notifications/Notification";
import MainLayout from "layouts/Main";
import AuthLayout from "layouts/Auth";
import { setUserInSession } from "components/auth/AuthActions";
import { getActivePages, termsAccepted, getDefaultPage } from "utils/PageUtils";
import RouterPaths from "constants/RouterPaths";
import NotFound from "components/error/NotFound";

const unauthenticatedRoutes = [
  {
    path: RouterPaths.ROOT,
    element: <AuthLayout />,
  },
  {
    path: "*",
    element: <Navigate to={RouterPaths.ROOT} />,
  },
];

const getAuthenticatedRoutes = (partner, user) => {
  const activePages = getActivePages(partner, user);
  const defaultPage = getDefaultPage(partner, user);
  const redirectAlways = !termsAccepted(partner, user) && defaultPage;

  return [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        ...activePages,
        {
          path: RouterPaths.ROOT,
          element: partner && user ? <Navigate to={defaultPage.path} /> : <></>,
        },
        {
          path: "*",
          element: redirectAlways ? (
            <Navigate to={defaultPage.path} />
          ) : (
            <>{user && partner ? <NotFound /> : <></>}</>
          ),
        },
      ],
    },
  ];
};

setUserInSession();

const RoutingApp = () => {
  const { isAuthenticated, user, partner } = useSelector((state) => state.auth);
  const router = useMemo(
    () =>
      createBrowserRouter(
        isAuthenticated
          ? getAuthenticatedRoutes(partner, user)
          : unauthenticatedRoutes
      ),
    [isAuthenticated, partner, user]
  );

  return <RouterProvider router={router} />;
};

const root = createRoot(document.getElementById("root"));
const locale = getCurrentLocale();
root.render(
  <Provider store={store}>
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
      <ViewportProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <RoutingApp />
          <Notification />
        </ThemeProvider>
      </ViewportProvider>
    </LocalizationProvider>
  </Provider>
);
