import React from "react";
import styled from "styled-components";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import PropTypes from "prop-types";

const StyledCell = styled.td`
  height: 6rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const TableRow = ({ data }) => (
  <>
    <StyledCell>
      <Typography
        variant={TypographyVariant.BODY2}
        text={data.one}
        tag="span"
      />
    </StyledCell>
    <StyledCell>
      <Typography
        variant={TypographyVariant.BODY2}
        text={data.two}
        tag="span"
      />
    </StyledCell>
    <StyledCell centered={true}>
      <Typography
        variant={TypographyVariant.BODY2}
        text={data.three}
        tag="span"
      />
    </StyledCell>
  </>
);

TableRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TableRow;
