import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticated: false,
  user: null,
  partners: [],
  partner: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload;
    },
    setPartners: (state, action) => {
      state.partners = action.payload;
    },
    setCurrentPartner: (state, action) => {
      state.partner = action.payload;
      if (state.user && action.payload)
        state.user.role = action.payload.user_role;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearAuthData: () => initialState,
  },
});

export const {
  setAuthData,
  setPartners,
  setCurrentPartner,
  setUser,
  clearAuthData,
} = authSlice.actions;
export default authSlice.reducer;
