import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Table from "common/Table";
import { ViewportType } from "utils/ViewportContext";
import UsersRow from "./UserRow";
import UserCard from "./UserCard";

const UserContent = styled.div`
  width: 100%;
`;

const UserTable = ({
  users,
  total,
  meta,
  onMetaChange,
  loading,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation("users");

  const headers = [
    {
      text: t("no"),
      centered: true,
      maxWidth: "5%",
    },
    {
      text: t("email"),
      maxWidth: "65%",
    },
    {
      text: t("role"),
      centered: true,
      maxWidth: "10%",
    },
    {
      text: t("status"),
      centered: true,
      maxWidth: "10%",
    },
    {
      text: t("actions"),
      centered: true,
      maxWidth: "10%",
    },
  ];

  return (
    <UserContent>
      <Table
        headers={headers}
        data={users}
        total={total}
        keyPropertyName="id"
        tableRow={UsersRow}
        tableCard={UserCard}
        cardViewportBreakpoint={ViewportType.MOBILE}
        meta={meta}
        onMetaChange={onMetaChange}
        loading={loading}
        itemProps={{
          onEdit,
          onDelete,
        }}
        noDataText={t("noData")}
      />
    </UserContent>
  );
};

UserTable.propTypes = {
  users: PropTypes.array,
  total: PropTypes.number,
  meta: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  onMetaChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

UserTable.defaultProps = {
  users: [],
  total: 0,
  loading: false,
};

export default UserTable;
