const tagGroupsToSelect = (groups) => {
  if (!groups) return null;

  const tags = [];
  groups.forEach((group) => {
    if (!group.tags) return;
    group.tags.forEach((tag) => {
      tags.push({
        value: tag.id,
        label: `${group.tag_group_name}: ${tag.tag_name}`,
        id: tag.id,
        tagGroupId: group.id,
      });
    });
  });

  return tags.sort((a, b) => a.label.localeCompare(b.label));
};

const tagSelectToList = (tagSelectOptions) => {
  if (!tagSelectOptions) return [];

  return tagSelectOptions.map((t) => {
    return {
      id: t.id,
    };
  });
};

const tagListToSelect = (tagList) => {
  if (!tagList) return null;

  return tagList.map((t) => {
    return {
      value: t.id,
      label: `${t.tag_group_name}: ${t.tag_name}`,
      id: t.id,
      tagGroupId: t.tag_group_id,
    };
  });
};

const findChangedTags = (initialList, currentList) => {
  const initial = initialList || [];
  const current = currentList || [];
  if (!initial || !current) return { new: [], deleted: [] };
  return {
    new: current.filter((cp) => !initial.find((ip) => ip.id === cp.id)),
    deleted: initial.filter((ip) => !current.find((cp) => cp.id === ip.id)),
  };
};

const tagGroupToPropertyGroup = (tagGroup) => {
  return {
    ...tagGroup,
    deletedPropertyIds: tagGroup.deletedTagIds,
    deletedTagIds: undefined,
    properties: (tagGroup.tags || []).map(tagToProperty),
    tags: undefined,
  };
};

const tagToProperty = (tag) => {
  return {
    ...tag,
    propertyGroupId: tag.tagGroupId,
    tagGroupId: undefined,
  };
};

const propertyToTag = (prop) => {
  return {
    ...prop,
    tag_group_id: prop.property_group_id,
    property_group_id: undefined,
    tag_group_name: prop.property_group_name,
    property_group_name: undefined,
    tag_name: prop.property_name,
    property_name: undefined,
  };
};

const propertyGroupToTagGroup = (propGroup) => {
  return {
    ...propGroup,
    tag_group_name: propGroup.property_group_name,
    property_group_name: undefined,
    tags: (propGroup.properties || []).map(propertyToTag),
    properties: undefined,
  };
};

const productPropertiesToTags = (product) => {
  if (!product) return null;
  return {
    ...product,
    tags: (product.properties || []).map(propertyToTag),
    properties: undefined,
    versions: product.versions.map((v) => ({
      ...v,
      tags: (v.properties || []).map(propertyToTag),
      properties: undefined,
    })),
  };
};

export {
  tagToProperty,
  tagSelectToList,
  tagGroupsToSelect,
  tagListToSelect,
  findChangedTags,
  tagGroupToPropertyGroup,
  propertyGroupToTagGroup,
  productPropertiesToTags,
};
