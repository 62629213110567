import { PriceRegex } from "utils/ProductUtils";
import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    published: Yup.bool()
      .required("common:validator.required")
      .test(
        "published_version",
        "common:validator.cannotPublish",
        function (value) {
          const publishedVersions = this.parent?.versions?.filter(
            (v) => v.published === true
          );
          if (value && publishedVersions.length === 0) return false;
          return true;
        }
      ),
    name: Yup.string()
      .required("common:validator.required")
      .max(200, { code: "common:validator.maxChars", params: { max: 200 } }),
    brand: Yup.object()
      .shape({
        value: Yup.string().nullable(),
      })
      .nullable(),
    type: Yup.object()
      .shape({
        value: Yup.string().required("common:validator.required"),
      })
      .required("common:validator.required"),
    modelType: Yup.object().shape({
      value: Yup.string().required("common:validator.required"),
    }),
    tags: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.string().nullable(),
        })
      )
      .nullable(),
    aspectRatioIos: Yup.number(),
    aspectRatioAndroid: Yup.number(),
    aspectRatioWeb: Yup.number(),
    positionYIos: Yup.number(),
    positionYAndroid: Yup.number(),
    positionYWeb: Yup.number(),
    positionZIos: Yup.number(),
    positionZAndroid: Yup.number(),
    positionZWeb: Yup.number(),
    rotationIos: Yup.number(),
    rotationAndroid: Yup.number(),
    rotationWeb: Yup.number(),
    earRotationWeb: Yup.number(),
    earpieceDistanceScale: Yup.number(),
    useMetallicGlass: Yup.bool().required("common:validator.required"),
    versions: Yup.array()
      .of(
        Yup.object().shape({
          published: Yup.bool().required("common:validator.required"),
          name: Yup.string()
            .required("common:validator.required")
            .max(200, {
              code: "common:validator.maxChars",
              params: { max: 200 },
            }),
          code: Yup.string()
            .nullable()
            .max(50, {
              code: "common:validator.maxChars",
              params: { max: 50 },
            }),
          sku: Yup.string()
            .nullable()
            .max(50, {
              code: "common:validator.maxChars",
              params: { max: 50 },
            }),
          price: Yup.string()
            .nullable()
            .matches(PriceRegex, "common:validator.priceFormat"),
          specialPrice: Yup.string()
            .nullable()
            .matches(PriceRegex, "common:validator.priceFormat"),
          pageLink: Yup.string()
            .max(200, {
              code: "common:validator.maxChars",
              params: { max: 200 },
            })
            .nullable(),
        })
      )
      .required("product:versionsRequired"),
  });
};
