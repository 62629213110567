import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { toBase64 } from "utils/WizardUtils";
import FileInput from "common/basicComponents/FileInput";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";

const PreviewContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  flex-basis: 0;
  ${({ viewport }) =>
    viewport === ViewportType.DESKTOP
      ? css`
          height: 100%;
        `
      : css`
          min-height: 16.25rem;
          width: 100%;
        `}
`;

const ImagePreview = ({
  title,
  icon,
  id,
  externalError,
  file,
  onFileUploaded,
  disabled,
  showActionButton,
  actionName,
  onAction,
}) => {
  const { t } = useTranslation("autocut");
  const { type: viewportType } = useViewport();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (externalError) setError(externalError);
  }, [externalError]);

  const handleDrop = async (files) => {
    if (_.isEmpty(files)) {
      onFileUploaded(null);
      return;
    }

    const newFile = files[0];
    if (newFile.type !== "image/png" && newFile.type !== "image/jpeg") {
      setError(
        t("common:validator.unsupportedExtension", {
          ext: ".png, .jpg, .jpeg",
        })
      );
      return;
    }

    await new Promise((resolve) => {
      const image = new Image();
      image.onload = () => {
        newFile.width = image.width;
        newFile.height = image.height;
        resolve();
      };
      const url = URL.createObjectURL(newFile);
      image.src = url;
    });

    if (newFile.width < 1024 && newFile.height < 1024) {
      setError(t("common:validator.minImgSize", { minSize: 1024 }));
      return;
    }

    setError(null);
    newFile.src = await toBase64(newFile);
    onFileUploaded(newFile);
  };

  return (
    <PreviewContainer viewport={viewportType}>
      <FileInput
        id={`preview-${id}`}
        name={`preview-${id}`}
        label={title}
        hintIcon={icon}
        hintText={t("common:form.fileDragDropHint")}
        smallHintText={t("imageSize", { width: 1024, height: 1024 })}
        onChange={handleDrop}
        error={error}
        accept=".jpg,.png,.jpeg"
        src={file?.src}
        showDeleteButton={!!file}
        caption={file?.name}
        disabled={disabled}
        showActionButton={showActionButton}
        actionName={actionName}
        onAction={onAction}
      />
    </PreviewContainer>
  );
};

ImagePreview.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  externalError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  file: PropTypes.object,
  onFileUploaded: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showActionButton: PropTypes.bool,
  onAction: PropTypes.func,
  actionName: PropTypes.string,
};

ImagePreview.defaultProps = {
  externalError: null,
  file: null,
  disabled: false,
  showActionButton: false,
  onAction: () => {},
  actionName: null
};

export default ImagePreview;
