import { createSlice } from "@reduxjs/toolkit";
import { clearAuthData, setCurrentPartner } from "components/auth/AuthSlice";

const initialState = {
  analytics: {},
  date: null,
  status: null,
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    setAnalytics: (state, action) => {
      state.analytics = action.payload.data;
      state.date = action.payload.date;
      state.status = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
    builder.addCase(setCurrentPartner, () => initialState);
  },
});

export const { setAnalytics } = analyticsSlice.actions;
export default analyticsSlice.reducer;
