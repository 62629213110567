export default {
  NOTIFICATIONS_ACTIVE: "notifications_active",
  WEB_ACTIVE: "web_active",
  QRCODES_ACTIVE: "qrcodes_active",
  MOBILE_ACTIVE: "mobile_active",
  TOTEM_ACTIVE: "totem_active",
  IMPORT_ACTIVE: "autocut_active",
  ANALYTICS_DASHBOARD_ACTIVE: "analytics_dashboard_active",
  MARKETPLACE_VISIBLE: "marketplace_visible",
  BILLING_ACTIVE: "billing_active",
  SETS_ACTIVE: "sets_active"
};
