import React from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import styled from "styled-components";

import FormSelectField from "common/formFields/FormSelectField";
import Button, { ButtonType } from "common/basicComponents/Button";
import { constantToSelect } from "utils/FormUtils";
import { StatusOptions } from "constants/NotificationOptions";

const Container = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: flex-end;

  & > *:not(button) {
    max-height: 4.125rem;
    white-space: nowrap;
  }
`;

const DesktopSearchForm = () => {
  const { t } = useTranslation("notifications");
  const { isSubmitting } = useFormikContext();

  return (
    <Container>
      <FormSelectField
        id="statusVal"
        name="statusVal"
        label={t("status")}
        disabled={isSubmitting}
        options={constantToSelect(StatusOptions, "notifications:search")}
      />
      <Button
        type={ButtonType.SUBMIT}
        minWidth="100px"
        disabled={isSubmitting}
        text={t("common:button.search")}
      />
    </Container>
  );
};

export default DesktopSearchForm;
