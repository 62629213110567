import React from "react";
import { Form } from "formik";
import PropTypes from "prop-types";

import { useViewport, ViewportType } from "utils/ViewportContext";
import DesktopSearchForm from "./DesktopSearchForm";
import MobileSearchForm from "./MobileSearchForm";
import CustomFormik from "common/basicComponents/CustomFormik";

const SetSearch = ({ onSearch }) => {
  const { type: viewportType } = useViewport();

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    onSearch({
      setSearch: values.setSearch,
    });
    setSubmitting(false);
  };

  return (
    <CustomFormik
      initialValues={{
        linkSearch: "",
        product: { label: "", value: "" },
      }}
      onSubmit={onSubmit}
      countChangedFields
    >
      <Form>
        {viewportType === ViewportType.DESKTOP ? (
          <DesktopSearchForm />
        ) : (
          <MobileSearchForm />
        )}
      </Form>
    </CustomFormik>
  );
};

SetSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default SetSearch;
