import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import { setApplications } from "./ApplicationsSlice";

export const getApplications = (params) => {
  return apiClient
    .get(ApiEndpoints.APPS, { params })
    .then((res) => {
      const applications = res?.data?.body;
      if (!applications) return false;
      store.dispatch(
        setApplications({
          appsList: applications.partners,
          minVersion: applications.oneapp_min_version,
        })
      );
      return true;
    })
    .catch(() => false);
};

export const updateMinVersion = (minVersion) => {
  return apiClient
    .post(ApiEndpoints.APPS_MIN_VERSION, { minVersion })
    .then(() => {
      return true;
    })
    .catch(() => false);
};
