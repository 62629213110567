import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import { TypographyWeight } from "common/basicComponents/Typography";

import Toggle from "common/basicComponents/Toggle";

const Cell = styled.td`
  height: 4.5rem;
  padding: 0 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const TextCell = styled(Cell)`
  min-width: 7.5rem;
`;

const ThumbCell = styled(Cell)`
  width: 0;
  min-width: 9rem;
`;

const ButtonsCell = styled(Cell)`
  width: 0;
  min-width: 5.5rem;
  button {
    display: inline-flex;
    margin: 0 0.25rem;
  }
`;

const Thumb = styled.img`
  height: 2.5rem;
  width: 5rem;
  object-fit: contain;
`;

const ThumbPlaceholder = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[400]};
`;

const GreyTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

const VersionRow = ({ data, onChangeActiveVersion, onDelete }) => {
  const { t } = useTranslation("common");
  return (
    <>
      <ThumbCell centered={true} className="show-tooltip">
        {data.product_thumb_link ? (
          <Thumb src={data.product_thumb_link} />
        ) : (
          <ThumbPlaceholder>{t("notAdded")}</ThumbPlaceholder>
        )}
      </ThumbCell>
      <TextCell className="show-tooltip">
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.version_name}
        />
        {data.version_name_owner !== data.version_name && (
          <GreyTypography
            variant={TypographyVariant.CAPTION}
            weight={TypographyWeight.LIGHT}
            text={data.version_name_owner}
          />
        )}
      </TextCell>
      <TextCell className="show-tooltip">
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.product_name}
        />
        {data.product_name_owner !== data.product_name && (
          <GreyTypography
            variant={TypographyVariant.CAPTION}
            weight={TypographyWeight.LIGHT}
            text={data.product_name_owner}
          />
        )}
      </TextCell>

      <Cell centered={true}>
        <Toggle
          id="totemVersionActive"
          name="totemVersionActive"
          value={data.version_active}
          onChange={() => onChangeActiveVersion(data)}
        />
      </Cell>
      <ButtonsCell centered={true}>
        <Button
          icon={IconName.TRASH}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onDelete(data)}
        />
      </ButtonsCell>
    </>
  );
};

VersionRow.propTypes = {
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeActiveVersion: PropTypes.func.isRequired,
};

export default VersionRow;
