import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PropTypes from "prop-types";

import { useViewport, ViewportType } from "utils/ViewportContext";
import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Box from "common/basicComponents/Box";

const ColumnCard = styled.div`
  min-width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  overflow: hidden;
`;

const RowCard = styled.div`
  min-width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FieldLabel = styled(Typography)`
  text-align: left;
  padding: 0.25rem 
  word-wrap: break-word;
  padding-top: 1rem;
`;

const ContentContainer = styled(Typography)`
  padding: 0.25rem 0 1rem;
  ${({ theme, showDivider }) =>
    showDivider && "border-bottom: 1px solid" + theme.colors.grey[200]};
`;

const ApplicationCard = ({ data }) => {
  const { t } = useTranslation("apps");
  const { type: viewportType } = useViewport();

  return (
    <ColumnCard>
      <FieldLabel
        variant={TypographyVariant.SUBTITLE2}
        weight={TypographyWeight.BOLD}
        text={t("name")}
      />
      <ContentContainer
        variant={TypographyVariant.SUBTITLE2}
        text={data.partner_name}
        showDivider={true}
      />
      <RowCard viewport={viewportType}>
        <Box width={viewportType === ViewportType.MOBILE ? "50%" : "25%"}>
          <FieldLabel
            variant={TypographyVariant.SUBTITLE2}
            weight={TypographyWeight.BOLD}
            text={t("appStore")}
          />
          <ContentContainer
            variant={TypographyVariant.SUBTITLE2}
            text={data.app_ios_version || "-"}
          />
        </Box>
        <Box width={viewportType === ViewportType.MOBILE ? "50%" : "25%"}>
          <FieldLabel
            variant={TypographyVariant.SUBTITLE2}
            weight={TypographyWeight.BOLD}
            text={t("testflight")}
          />
          <ContentContainer
            variant={TypographyVariant.SUBTITLE2}
            text={data.app_ios_test_version || "-"}
          />
        </Box>
        <Box width={viewportType === ViewportType.MOBILE ? "50%" : "25%"}>
          <FieldLabel
            variant={TypographyVariant.SUBTITLE2}
            weight={TypographyWeight.BOLD}
            text={t("googlePlay")}
          />
          <ContentContainer
            variant={TypographyVariant.SUBTITLE2}
            text={data.app_android_version || "-"}
          />
        </Box>
        <Box width={viewportType === ViewportType.MOBILE ? "50%" : "25%"}>
          <FieldLabel
            variant={TypographyVariant.SUBTITLE2}
            weight={TypographyWeight.BOLD}
            text={t("internalTestShort")}
          />
          <ContentContainer
            variant={TypographyVariant.SUBTITLE2}
            text={data.app_android_test_version || "-"}
          />
        </Box>
      </RowCard>
    </ColumnCard>
  );
};

ApplicationCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ApplicationCard;
