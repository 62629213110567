import React from "react";
import { useTranslation } from "react-i18next";
import Button, {
  ButtonVariant,
  ButtonType,
} from "common/basicComponents/Button";

const REGISTER_URL =
  "https://accounts.google.com/signup/v2/webcreateaccount?continue=https%3A%2F%2Faccounts.google.com%2FManageAccount%3Fnc%3D1&dsh=S788943628%3A1637848037323619&biz=false&flowName=GlifWebSignIn&flowEntry=SignUp";

const CreateAccountButton = () => {
  const { t } = useTranslation("auth");

  return (
    <Button
      onClick={() =>
        window.open(REGISTER_URL, "_blank", `width=450,height=600`)
      }
      type={ButtonType.BUTTON}
      variant={ButtonVariant.TEXT}
      text={t("createGoogleAccount")}
    />
  );
};

export default CreateAccountButton;
