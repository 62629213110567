import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { ReactComponent as Model2D24 } from "../../assets/icons/2D24.svg";
import { ReactComponent as Model3D24 } from "../../assets/icons/3D24.svg";
import { ReactComponent as AlignCenter24 } from "../../assets/icons/AlignCenter24.svg";
import { ReactComponent as AlignJustify24 } from "../../assets/icons/AlignJustify24.svg";
import { ReactComponent as AlignLeft24 } from "../../assets/icons/AlignLeft24.svg";
import { ReactComponent as AlignRight24 } from "../../assets/icons/AlignRight24.svg";
import { ReactComponent as Beanie24 } from "../../assets/icons/Beanie24.svg";
import { ReactComponent as TextBackground24 } from "../../assets/icons/BG24.svg";
import { ReactComponent as Bold24 } from "../../assets/icons/Bold24.svg";
import { ReactComponent as BrandsVariant12 } from "../../assets/icons/Brands212.svg";
import { ReactComponent as Brands12 } from "../../assets/icons/Brands12.svg";
import { ReactComponent as Calendar24 } from "../../assets/icons/Calendar24.svg";
import { ReactComponent as Categories12 } from "../../assets/icons/Categories12.svg";
import { ReactComponent as Chart12 } from "../../assets/icons/Chart12.svg";
import { ReactComponent as Chart24 } from "../../assets/icons/Chart24.svg";
import { ReactComponent as Checkmark16 } from "../../assets/icons/Checkmark16.svg";
import { ReactComponent as CheckmarkBackground12 } from "../../assets/icons/Check12.svg";
import { ReactComponent as Clip12 } from "../../assets/icons/Clip12.svg";
import { ReactComponent as Clock24 } from "../../assets/icons/Clock24.svg";
import { ReactComponent as Close12 } from "../../assets/icons/Close12.svg";
import { ReactComponent as Close24 } from "../../assets/icons/Close24.svg";
import { ReactComponent as Close32 } from "../../assets/icons/Close32.svg";
import { ReactComponent as Copy24 } from "../../assets/icons/Copy24.svg";
import { ReactComponent as Counter24 } from "../../assets/icons/Counter24.svg";
import { ReactComponent as Cursor64 } from "../../assets/icons/Cursor64.svg";
import { ReactComponent as Dashboard12 } from "../../assets/icons/Dashboard12.svg";
import { ReactComponent as Delete24 } from "../../assets/icons/Delete24.svg";
import { ReactComponent as DotMenu24 } from "../../assets/icons/DotMenu24.svg";
import { ReactComponent as Door24 } from "../../assets/icons/Door24.svg";
import { ReactComponent as DownArrow20 } from "../../assets/icons/DownArrow20.svg";
import { ReactComponent as Download24 } from "../../assets/icons/Download24.svg";
import { ReactComponent as Down32 } from "../../assets/icons/Down32.svg";
import { ReactComponent as Earrings24 } from "../../assets/icons/Earrings24.svg";
import { ReactComponent as Edit24 } from "../../assets/icons/Edit24.svg";
import { ReactComponent as EditOutlined24 } from "../../assets/icons/EditOutlined24.svg";
import { ReactComponent as Error24 } from "../../assets/icons/Error24.svg";
import { ReactComponent as Eye24 } from "../../assets/icons/Eye24.svg";
import { ReactComponent as Face24 } from "../../assets/icons/Face24.svg";
import { ReactComponent as File48 } from "../../assets/icons/File48.svg";
import { ReactComponent as Filters24 } from "../../assets/icons/Filters324.svg";
import { ReactComponent as Filter24 } from "../../assets/icons/Filter24.svg";
import { ReactComponent as Glasses24 } from "../../assets/icons/GlassesType24.svg";
import { ReactComponent as Goggles24 } from "../../assets/icons/Goggles24.svg";
import { ReactComponent as Head24 } from "../../assets/icons/Heade24.svg";
import { ReactComponent as Import12 } from "../../assets/icons/Import12.svg";
import { ReactComponent as Info32 } from "../../assets/icons/Info32.svg";
import { ReactComponent as Info64 } from "../../assets/icons/Info64.svg";
import { ReactComponent as Italic24 } from "../../assets/icons/Italic24.svg";
import { ReactComponent as LeftArrow20 } from "../../assets/icons/LeftArrow20.svg";
import { ReactComponent as Link24 } from "../../assets/icons/Link24.svg";
import { ReactComponent as List24 } from "../../assets/icons/List24.svg";
import { ReactComponent as MagicWand20 } from "../../assets/icons/MagicWand20.svg";
import { ReactComponent as MagicWand24 } from "../../assets/icons/MagicWand24.svg";
import { ReactComponent as MagicWand48 } from "../../assets/icons/MagicWand48.svg";
import { ReactComponent as Marketplace12 } from "../../assets/icons/Marketplace12.svg";
import { ReactComponent as Messenger24 } from "../../assets/icons/Messenger24.svg";
import { ReactComponent as Minus32 } from "../../assets/icons/MinusBig32.svg";
import { ReactComponent as Minus20 } from "../../assets/icons/Minus20.svg";
import { ReactComponent as Minus24 } from "../../assets/icons/Minus24.svg";
import { ReactComponent as Object24 } from "../../assets/icons/Object24.svg";
import { ReactComponent as Picture24 } from "../../assets/icons/Picture24.svg";
import { ReactComponent as Play24 } from "../../assets/icons/Play24.svg";
import { ReactComponent as Plus20 } from "../../assets/icons/Plus20.svg";
import { ReactComponent as Plus24 } from "../../assets/icons/Plus24.svg";
import { ReactComponent as Plus32 } from "../../assets/icons/PlusBig32.svg";
import { ReactComponent as PreviewSingle24 } from "../../assets/icons/Preview124-1.svg";
import { ReactComponent as PreviewMulti24 } from "../../assets/icons/Preview124.svg";
import { ReactComponent as Products12 } from "../../assets/icons/Products12.svg";
import { ReactComponent as HamburgerClose32 } from "../../assets/icons/Property1=Close32.svg";
import { ReactComponent as GlassesFrontWithLenses80 } from "../../assets/icons/Property1=FrontwithLenses80.svg";
import { ReactComponent as GlassesFrontWithoutLenses80 } from "../../assets/icons/Property1=FrontwithoutLenses80.svg";
import { ReactComponent as Hamburger32 } from "../../assets/icons/Property1=Hamburger32.svg";
import { ReactComponent as HamburgerHover32 } from "../../assets/icons/Property1=HamburgerHover32.svg";
import { ReactComponent as GlassesLeftSide80 } from "../../assets/icons/Property1=LeftSide80.svg";
import { ReactComponent as GlassesRightSide80 } from "../../assets/icons/Property1=RightSide80.svg";
import { ReactComponent as Push12 } from "../../assets/icons/Push12.svg";
import { ReactComponent as RightArrow20 } from "../../assets/icons/RightArrow20.svg";
import { ReactComponent as Rocket48 } from "../../assets/icons/Rocket48.svg";
import { ReactComponent as Rubber20 } from "../../assets/icons/Rubber20.svg";
import { ReactComponent as Rubber24 } from "../../assets/icons/Rubber24.svg";
import { ReactComponent as Save24 } from "../../assets/icons/Save24.svg";
import { ReactComponent as Scissors24 } from "../../assets/icons/Scissors24.svg";
import { ReactComponent as Search24 } from "../../assets/icons/Search24.svg";
import { ReactComponent as Settings12 } from "../../assets/icons/Settings12.svg";
import { ReactComponent as Success32 } from "../../assets/icons/Success32.svg";
import { ReactComponent as Success64 } from "../../assets/icons/Success64.svg";
import { ReactComponent as Square24 } from "../../assets/icons/Square24.svg";
import { ReactComponent as Tags12 } from "../../assets/icons/Tags12.svg";
import { ReactComponent as Text24 } from "../../assets/icons/Text24.svg";
import { ReactComponent as ToTop24 } from "../../assets/icons/ToTop24.svg";
import { ReactComponent as TotemsVariant12 } from "../../assets/icons/Totems212.svg";
import { ReactComponent as Totems12 } from "../../assets/icons/Totems12.svg";
import { ReactComponent as Trash16 } from "../../assets/icons/Trash16.svg";
import { ReactComponent as Trash24 } from "../../assets/icons/Trash24.svg";
import { ReactComponent as TrashOutlined24 } from "../../assets/icons/TrashOutlined24.svg";
import { ReactComponent as Underline24 } from "../../assets/icons/Underline24.svg";
import { ReactComponent as UnderlineColor24 } from "../../assets/icons/UnderlineColor24.svg";
import { ReactComponent as Undo24 } from "../../assets/icons/Undo24.svg";
import { ReactComponent as UpArrow20 } from "../../assets/icons/UpArrow20.svg";
import { ReactComponent as Upload24 } from "../../assets/icons/Upload24.svg";
import { ReactComponent as User12 } from "../../assets/icons/User12.svg";
import { ReactComponent as Web12 } from "../../assets/icons/Web12.svg";
import { ReactComponent as ZoomIn24 } from "../../assets/icons/ZoomIn24.svg";
import { ReactComponent as ZoomOut24 } from "../../assets/icons/ZoomOut24.svg";
import { ReactComponent as Shopping24 } from "../../assets/icons/Shopping24.svg"

export const IconName = {
  ALIGN_CENTER: "alignCenter",
  ALIGN_JUSTIFY: "alignJustify",
  ALIGN_LEFT: "alignLeft",
  ALIGN_RIGHT: "alignRight",
  BEANIE: "beanie",
  BOLD: "bold",
  BRANDS_VARIANT: "brandsVariant",
  BRANDS: "brands",
  CALENDAR: "calendar",
  CATEGORIES: "categories",
  CHART: "chart",
  CHECKMARK: "checkmark",
  CHECKMARK_BACKGROUND: "checkmarkBackground",
  CLIP: "clip",
  CLOCK: "clock",
  CLOSE: "close",
  COPY: "copy",
  COUNTER: "counter",
  CURSOR_ADD: "cursorAdd",
  DASHBOARD: "dashboard",
  DELETE: "delete",
  DOOR: "door",
  DOT_MENU: "dotMenu",
  DOWN_ARROW: "downArrow",
  DOWNLOAD: "download",
  DOWN: "down",
  EARRINGS: "earrings",
  EDIT: "edit",
  EDIT_OUTLINED: "editOutlined",
  ERROR: "error",
  EYE: "eye",
  FACE: "face",
  FILE: "file",
  FILTERS: "filters",
  FILTER: "filter",
  GLASSES_FRONT_WITH_LENSES: "glassesFrontWithLenses",
  GLASSES_FRONT_WITHOUT_LENSES: "glassesFrontWithoutLenses",
  GLASSES_LEFT_SIDE: "glassesLeftSide",
  GLASSES_RIGHT_SIDE: "glassesRightSide",
  GLASSES: "glasses",
  GOGGLES: "goggles",
  HAMBURGER: "hamburger",
  HAMBURGER_CLOSE: "hamburgerClose",
  HAMBURGER_HOVER: "hamburgerHover",
  HEAD: "head",
  IMPORT: "import",
  INFO: "info",
  ITALIC: "italic",
  LEFT_ARROW: "leftArrow",
  LINK: "link",
  LIST: "list",
  MAGIC_WAND: "magicWand",
  MARKETPLACE: "marketplace",
  MESSENGER: "messenger",
  MINUS: "minus",
  MODEL2D: "model2d",
  MODEL3D: "model3d",
  OBJECT: "object",
  PICTURE: "picture",
  PLAY: "play",
  PLUS: "plus",
  PREVIEW_SINGLE: "previewSingle",
  PREVIEW_MULTI: "previewMulti",
  PRODUCTS: "products",
  PUSH: "push",
  RIGHT_ARROW: "rightArrow",
  ROCKET: "rocket",
  RUBBER: "rubber",
  SAVE: "save",
  SCISSORS: "scissors",
  SEARCH: "search",
  SETTINGS: "settings",
  SUCCESS: "success",
  SQUARE: "square",
  TAGS: "tags",
  TEXT: "text",
  TEXT_BACKGROUND: "textBackground",
  TO_TOP: "toTop",
  TOTEMS_VARIANT: "totemsVariant",
  TOTEMS: "totems",
  TRASH: "trash",
  TRASH_OUTLINED: "trashOutlined",
  UNDERLINE_COLOR: "underlineColor",
  UNDERLINE: "underline",
  UNDO: "undo",
  UP_ARROW: "upArrow",
  UPLOAD: "upload",
  USER: "user",
  WEB: "web",
  ZOOM_IN: "zoomIn",
  ZOOM_OUT: "zoomOut",
  SHOPPING: "shopping"
};

const icons = {
  [IconName.ALIGN_CENTER]: {
    1.5: AlignCenter24,
  },
  [IconName.ALIGN_JUSTIFY]: {
    1.5: AlignJustify24,
  },
  [IconName.ALIGN_LEFT]: {
    1.5: AlignLeft24,
  },
  [IconName.ALIGN_RIGHT]: {
    1.5: AlignRight24,
  },
  [IconName.BEANIE]: {
    1.5: Beanie24,
  },
  [IconName.BOLD]: {
    1.5: Bold24,
  },
  [IconName.BRANDS_VARIANT]: {
    0.75: BrandsVariant12,
  },
  [IconName.BRANDS]: {
    0.75: Brands12,
  },
  [IconName.CALENDAR]: {
    1.5: Calendar24,
  },
  [IconName.CATEGORIES]: {
    0.75: Categories12,
  },
  [IconName.CHART]: {
    0.75: Chart12,
    1.5: Chart24,
  },
  [IconName.CHECKMARK]: {
    1: Checkmark16,
  },
  [IconName.CHECKMARK_BACKGROUND]: {
    0.75: CheckmarkBackground12,
  },
  [IconName.CLIP]: {
    0.75: Clip12,
  },
  [IconName.CLOCK]: {
    1.5: Clock24,
  },
  [IconName.CLOSE]: {
    0.75: Close12,
    1.5: Close24,
    2: Close32,
  },
  [IconName.COPY]: {
    1.5: Copy24,
  },
  [IconName.COUNTER]: {
    1.5: Counter24,
  },
  [IconName.CURSOR_ADD]: {
    4: Cursor64,
  },
  [IconName.DASHBOARD]: {
    0.75: Dashboard12,
  },
  [IconName.DELETE]: {
    1.5: Delete24,
  },
  [IconName.DOOR]: {
    1.5: Door24,
  },
  [IconName.DOWN_ARROW]: {
    1.25: DownArrow20,
  },
  [IconName.DOT_MENU]: {
    1.5: DotMenu24,
  },
  [IconName.DOWNLOAD]: {
    1.5: Download24,
  },
  [IconName.DOWN]: {
    2: Down32,
  },
  [IconName.EARRINGS]: {
    1.5: Earrings24,
  },
  [IconName.EDIT]: {
    1.5: Edit24,
  },
  [IconName.EDIT_OUTLINED]: {
    1.5: EditOutlined24,
  },
  [IconName.ERROR]: {
    1.5: Error24,
  },
  [IconName.EYE]: {
    1.5: Eye24,
  },
  [IconName.FACE]: {
    1.5: Face24,
  },
  [IconName.FILE]: {
    3: File48,
  },
  [IconName.FILTERS]: {
    1.5: Filters24,
  },
  [IconName.FILTER]: {
    1.5: Filter24,
  },
  [IconName.GLASSES_FRONT_WITH_LENSES]: {
    5: GlassesFrontWithLenses80,
  },
  [IconName.GLASSES_FRONT_WITHOUT_LENSES]: {
    5: GlassesFrontWithoutLenses80,
  },
  [IconName.GLASSES_LEFT_SIDE]: {
    5: GlassesLeftSide80,
  },
  [IconName.GLASSES_RIGHT_SIDE]: {
    5: GlassesRightSide80,
  },
  [IconName.GLASSES]: {
    1.5: Glasses24,
  },
  [IconName.GOGGLES]: {
    1.5: Goggles24,
  },
  [IconName.HAMBURGER]: {
    2: Hamburger32,
  },
  [IconName.HAMBURGER_CLOSE]: {
    2: HamburgerClose32,
  },
  [IconName.HAMBURGER_HOVER]: {
    2: HamburgerHover32,
  },
  [IconName.HEAD]: {
    1.5: Head24,
  },
  [IconName.IMPORT]: {
    0.75: Import12,
  },
  [IconName.INFO]: {
    2: Info32,
    4: Info64,
  },
  [IconName.ITALIC]: {
    1.5: Italic24,
  },
  [IconName.LEFT_ARROW]: {
    1.25: LeftArrow20,
  },
  [IconName.LINK]: {
    1.5: Link24,
  },
  [IconName.LIST]: {
    1.5: List24,
  },
  [IconName.MAGIC_WAND]: {
    1.25: MagicWand20,
    1.5: MagicWand24,
    3: MagicWand48,
  },
  [IconName.MARKETPLACE]: {
    0.75: Marketplace12,
  },
  [IconName.MESSENGER]: {
    1.5: Messenger24,
  },
  [IconName.MINUS]: {
    2: Minus32,
    1.25: Minus20,
    1.5: Minus24,
  },
  [IconName.MODEL2D]: {
    1.5: Model2D24,
  },
  [IconName.MODEL3D]: {
    1.5: Model3D24,
  },
  [IconName.OBJECT]: {
    1.5: Object24,
  },
  [IconName.PICTURE]: {
    1.5: Picture24,
  },
  [IconName.PLAY]: {
    1.5: Play24,
  },
  [IconName.PLUS]: {
    1.25: Plus20,
    1.5: Plus24,
    2: Plus32,
  },
  [IconName.PREVIEW_SINGLE]: {
    1.5: PreviewSingle24,
  },
  [IconName.PREVIEW_MULTI]: {
    1.5: PreviewMulti24,
  },
  [IconName.PRODUCTS]: {
    0.75: Products12,
  },
  [IconName.PUSH]: {
    0.75: Push12,
  },
  [IconName.RIGHT_ARROW]: {
    1.25: RightArrow20,
  },
  [IconName.ROCKET]: {
    3: Rocket48,
  },
  [IconName.RUBBER]: {
    1.25: Rubber20,
    1.5: Rubber24,
  },
  [IconName.SAVE]: {
    1.25: Save24,
  },
  [IconName.SCISSORS]: {
    1.5: Scissors24,
  },
  [IconName.SEARCH]: {
    1.5: Search24,
  },
  [IconName.SETTINGS]: {
    0.75: Settings12,
  },
  [IconName.SUCCESS]: {
    2: Success32,
    4: Success64,
  },
  [IconName.SQUARE]: {
    1.5: Square24,
  },
  [IconName.TAGS]: {
    0.75: Tags12,
  },
  [IconName.TEXT]: {
    1.5: Text24,
  },
  [IconName.TEXT_BACKGROUND]: {
    1.5: TextBackground24,
  },
  [IconName.TO_TOP]: {
    1.5: ToTop24,
  },
  [IconName.TOTEMS_VARIANT]: {
    0.75: TotemsVariant12,
  },
  [IconName.TOTEMS]: {
    0.75: Totems12,
  },
  [IconName.TRASH]: {
    1: Trash16,
    1.5: Trash24,
  },
  [IconName.TRASH_OUTLINED]: {
    1.5: TrashOutlined24,
  },
  [IconName.UNDERLINE_COLOR]: {
    1.5: UnderlineColor24,
  },
  [IconName.UNDERLINE]: {
    1.5: Underline24,
  },
  [IconName.UNDO]: {
    1.5: Undo24,
  },
  [IconName.UP_ARROW]: {
    1.25: UpArrow20,
  },
  [IconName.UPLOAD]: {
    1.5: Upload24,
  },
  [IconName.USER]: {
    0.75: User12,
  },
  [IconName.WEB]: {
    0.75: Web12,
  },
  [IconName.ZOOM_IN]: {
    1.5: ZoomIn24,
  },
  [IconName.ZOOM_OUT]: {
    1.5: ZoomOut24,
  },
  [IconName.SHOPPING]: {
    1.5: Shopping24
  }
};

const findClosestSize = (size, availableSizes) => {
  if (!availableSizes || availableSizes.length === 0) return null;
  return availableSizes.sort(
    (s1, s2) => Math.abs(size - s1) - Math.abs(size - s2)
  )[0];
};

const getIcon = (name, size = "1rem") => {
  if (!icons[name]) return null;

  const parsedSize = parseFloat(size, 10);
  if (icons[name][parsedSize]) return icons[name][parsedSize];

  const bestSize = findClosestSize(parsedSize, Object.keys(icons[name]));
  return icons[name][bestSize];
};

// gradient or color can be set using fill: url(#gradient) or fill: color
const Icon = ({ name, size, className }) => {
  const SelectedIcon = getIcon(name, size);

  return <SelectedIcon className={className} />;
};

Icon.propTypes = {
  size: PropTypes.string,
  name: PropTypes.oneOf(Object.values(IconName)),
  className: PropTypes.string,
};

Icon.defaultProps = {
  name: IconName.PLUS,
  size: "1.5rem",
  className: null,
};

const StyledIcon = styled(Icon)`
  ${({ size }) => `
    width: ${size};
    height: ${size};
  `}
`;

export default StyledIcon;
