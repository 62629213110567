import { configureStore } from "@reduxjs/toolkit";
import analyticsReducer from "components/analytics/AnalyticsSlice";
import authReducer from "components/auth/AuthSlice";
import brandReducer from "components/brands/BrandSlice";
import domainReducer from "components/domains/DomainSlice";
import importReducer from "components/import/ImportSlice";
import linkReducer from "components/links/LinkSlice";
import notificationReducer from "components/notifications/NotificationSlice";
import productReducer from "components/products/ProductSlice";
import pushNotificationsReducer from "components/pushNotifications/PushNotificationsSlice";
import tagGroupsReducer from "components/tagGroups/TagGroupSlice";
import totemReducer from "components/totems/TotemSlice";
import typeReducer from "components/types/TypeSlice";
import userReducer from "components/users/UserSlice";
import marketplaceReducer from "components/marketplace/MarketplaceSlice";
import applicationsReducer from "components/applications/ApplicationsSlice";
import billingReducer from "components/billing/BillingSlice";
import setsReducer from "components/sets/SetsSlice";

const store = configureStore({
  reducer: {
    analytics: analyticsReducer,
    auth: authReducer,
    brands: brandReducer,
    domains: domainReducer,
    import: importReducer,
    links: linkReducer,
    marketplace: marketplaceReducer,
    notification: notificationReducer,
    products: productReducer,
    pushNotifications: pushNotificationsReducer,
    tagGroups: tagGroupsReducer,
    totems: totemReducer,
    types: typeReducer,
    users: userReducer,
    applications: applicationsReducer,
    billing: billingReducer,
    sets: setsReducer
  },
});

export default store;
