import React, { useEffect } from "react";
import AuthComponent from "components/auth/Auth";
import { REACT_APP_DEV_MODE, REACT_APP_DEV_AUTH_TOKEN } from "constants/env";
import { login } from "components/auth/AuthActions";

const Auth = () => {
  useEffect(() => {
    if (REACT_APP_DEV_MODE) {
      login(REACT_APP_DEV_AUTH_TOKEN);
    }
  }, []);

  return <AuthComponent />;
};

export default Auth;
