import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Typography from "common/basicComponents/Typography";
import { TypographyVariant } from "common/basicComponents/Typography";
import { TypographyWeight } from "common/basicComponents/Typography";

const Cell = styled.td`
  padding: 0.75rem;
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const TopFiveRow = ({ data }) => {
  return (
    <>
      <Cell>
        <Typography variant={TypographyVariant.BODY2} text={data.name} />
      </Cell>
      <Cell centered>
        <Typography
          variant={TypographyVariant.BODY2}
          weight={TypographyWeight.BOLD}
          text={data.count}
        />
      </Cell>
    </>
  );
};

TopFiveRow.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TopFiveRow;
