import React, { useEffect, useRef, useState, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import EditorBottomMenu from "./EditorBottomMenu";
import { renderCanvas } from "utils/WizardUtils";
import background from "assets/checkboardBackground.png";
import EditorTool from "constants/EditorTool";
import EditorToolBrush from "constants/EditorToolBrush";
import { IconName } from "common/basicComponents/Icon";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import Button, {
  ButtonType,
  ButtonVariant,
} from "common/basicComponents/Button";
import Loader from "common/basicComponents/Loader";
import EditorSidePanel from "./EditorSidePanel";

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 2rem 5rem 2.5rem 5rem;
  gap: 2.5rem;
`;

const MainPanel = styled.div`
  width: calc(100vh - 11.25rem);
`;

const SidePanel = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > span {
    background: ${({ theme }) => theme.colors.gradient[100]};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: pre-line;
  }
`;

const CanvasContainer = styled.div`
  margin-top: 0.5rem;
  position: relative;

  width: 100%;
  padding-bottom: 100%;

  & .undo-button {
    position: absolute;
    z-index: 2;
    top: 1.25rem;
    left: 1.25rem;
  }
`;

const Background = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
`;

const FloatLoader = styled.div`
  position: absolute;
  inset: 0;
  z-index: 2;
`;

const Canvas = styled.canvas`
  position: absolute;
  margin: auto auto;
  z-index: 1;
  inset: 0;
`;

const EditorCanvas = ({
  onEdit,
  onUndo,
  isUndoPossible,
  backgroundImg,
  maskImg,
  loading,
  hintText,
  photoTitle,
}) => {
  const [zoom, setZoom] = useState(1);
  const [tool, setTool] = useState(EditorTool.SMART_MINUS);
  const [previewOriginal, setPreviewOriginal] = useState(false);

  const canvasContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const bgImgRef = useRef(null);
  const maskImgRef = useRef(null);
  const cursorRef = useRef({
    x: -100,
    y: -100,
    pressed: false,
    visible: false,
  });
  const positionRef = useRef({ x: 0, y: 0 });
  const brushRadius = 10;

  useEffect(() => {
    bgImgRef.current = new Image();
    bgImgRef.current.src = backgroundImg;
  }, [backgroundImg]);

  useEffect(() => {
    maskImgRef.current = new Image();
    maskImgRef.current.src = maskImg;
  }, [maskImg]);

  const render = useCallback(() => {
    const ctx = contextRef.current;
    if (!ctx || !bgImgRef.current.naturalWidth) return;

    const { canvas } = ctx;
    renderCanvas(
      canvas,
      bgImgRef.current,
      canvas.width,
      maskImgRef.current.naturalWidth && !previewOriginal
        ? maskImgRef.current
        : null,
      {
        scale: zoom,
        position: positionRef.current,
      }
    );
    if (!loading && cursorRef.current.visible) {
      const pointer = cursorRef.current;
      ctx.globalCompositeOperation = "source-over";
      ctx.beginPath();
      ctx.fillStyle = EditorToolBrush[tool].color;
      ctx.arc(pointer.x, pointer.y, brushRadius, 0, Math.PI * 2, true);
      ctx.fill();
    }

    window.requestAnimationFrame(render);
  }, [loading, previewOriginal, zoom, tool]);

  const applyCurrentTransforms = useCallback(
    (point) => {
      let newX = point.x;
      let newY = point.y;
      newX -= canvasRef.current.width / 2;
      newY -= canvasRef.current.height / 2;
      newX /= zoom;
      newY /= zoom;
      newX += canvasRef.current.width / 2 - positionRef.current.x;
      newY += canvasRef.current.height / 2 - positionRef.current.y;
      newX /= canvasRef.current.width / bgImgRef.current.width;
      newY /= canvasRef.current.height / bgImgRef.current.height;
      newX = Math.round(newX);
      newY = Math.round(newY);
      return { x: newX, y: newY };
    },
    [zoom]
  );

  const updateCanvasSize = () => {
    const canvasSize = Math.min(
      canvasContainerRef.current.clientWidth,
      canvasContainerRef.current.clientHeight
    );
    canvasRef.current.width = canvasSize;
    canvasRef.current.height = canvasSize;
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (e.target.id !== "loader") {
        cursorRef.current.x = -50;
        cursorRef.current.y = -50;
        return;
      }
      const diffX = e.offsetX - cursorRef.current.x;
      const diffY = e.offsetY - cursorRef.current.y;

      if (
        Math.abs(diffX) < brushRadius / 2 &&
        Math.abs(diffY) < brushRadius / 2
      )
        return;

      cursorRef.current.x = e.offsetX;
      cursorRef.current.y = e.offsetY;
      if (!cursorRef.current.pressed || previewOriginal) return;

      const newPoint = applyCurrentTransforms(cursorRef.current);
      const scale = bgImgRef.current.width / canvasRef.current.width / zoom;
      const newBrushRadius = Math.round(brushRadius * scale);

      onEdit(newPoint.x, newPoint.y, newBrushRadius, tool);
    },
    [applyCurrentTransforms, onEdit, zoom, tool, previewOriginal]
  );

  const handleMouseDown = useCallback(() => {
    cursorRef.current.pressed = true;
  }, []);

  const handleMouseUp = useCallback(
    (e) => {
      cursorRef.current.pressed = false;
      cursorRef.current.x = e.offsetX;
      cursorRef.current.y = e.offsetY;

      if (previewOriginal) return;

      const newPoint = applyCurrentTransforms(cursorRef.current);

      const scale = bgImgRef.current.width / canvasRef.current.width / zoom;
      const newBrushRadius = Math.round(brushRadius * scale);

      onEdit(newPoint.x, newPoint.y, newBrushRadius, tool, true);
    },
    [applyCurrentTransforms, onEdit, zoom, tool, previewOriginal]
  );

  const handleMouseLeave = useCallback(() => {
    cursorRef.current.pressed = false;
    cursorRef.current.visible = false;
  }, []);

  const handleMouseEnter = useCallback(() => {
    if (!previewOriginal) cursorRef.current.visible = true;
  }, [previewOriginal]);

  const onKeyDown = useCallback(
    (event) => {
      if (event.ctrlKey && event.key === "z") {
        if (!previewOriginal) onUndo();
      }
    },
    [onUndo, previewOriginal]
  );

  useEffect(() => {
    cursorRef.current.visible = !previewOriginal;
  }, [previewOriginal]);

  useEffect(() => {
    const canvas = canvasRef.current;
    contextRef.current = canvas.getContext("2d");
    if (!contextRef.current) return;

    const container = canvasContainerRef.current;

    container.addEventListener("mousemove", handleMouseMove);
    container.addEventListener("mousedown", handleMouseDown);
    container.addEventListener("mouseup", handleMouseUp);
    container.addEventListener("mouseleave", handleMouseLeave);
    container.addEventListener("mouseenter", handleMouseEnter);
    document.addEventListener("keydown", onKeyDown);
    window.addEventListener("resize", updateCanvasSize);

    contextRef.current.lineJoin = "round";
    contextRef.current.lineCap = "round";
    contextRef.current.lineWidth = brushRadius;

    render();

    return () => {
      container.removeEventListener("mousemove", handleMouseMove);
      container.removeEventListener("mousedown", handleMouseDown);
      container.removeEventListener("mouseup", handleMouseUp);
      container.removeEventListener("mouseleave", handleMouseLeave);
      container.removeEventListener("mouseenter", handleMouseEnter);
      document.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("resize", updateCanvasSize);
    };
  }, [
    handleMouseMove,
    handleMouseDown,
    handleMouseUp,
    handleMouseLeave,
    handleMouseEnter,
    render,
    onKeyDown,
  ]);

  useEffect(() => {
    updateCanvasSize();
  }, []);

  return (
    <MainContainer>
      <EditorSidePanel
        hintText={hintText}
        tool={tool}
        setTool={setTool}
        loading={loading || previewOriginal}
      />
      <MainPanel>
        <Typography
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.SEMIBOLD}
          text={photoTitle}
        />
        <CanvasContainer ref={canvasContainerRef}>
          <FloatLoader id="loader">
            <Loader show={loading} />
          </FloatLoader>
          {!cursorRef.current.pressed && !previewOriginal && (
            <Button
              type={ButtonType.BUTTON}
              variant={ButtonVariant.OUTLINED}
              icon={IconName.UNDO}
              onClick={onUndo}
              disabled={!isUndoPossible}
              className="undo-button"
            />
          )}
          <Background src={background} alt="img" />
          <Canvas ref={canvasRef} />
          {(!cursorRef.current.pressed || previewOriginal) && (
            <EditorBottomMenu
              zoom={zoom}
              setZoom={setZoom}
              previewOriginal={previewOriginal}
              setPreviewOriginal={setPreviewOriginal}
              canvasRef={canvasRef}
              positionRef={positionRef}
              cursorRef={cursorRef}
              disabled={loading}
            />
          )}
        </CanvasContainer>
      </MainPanel>
      <SidePanel />
    </MainContainer>
  );
};

EditorCanvas.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onUndo: PropTypes.func.isRequired,
  isUndoPossible: PropTypes.bool.isRequired,
  backgroundImg: PropTypes.string.isRequired,
  maskImg: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  hintText: PropTypes.string.isRequired,
  photoTitle: PropTypes.string.isRequired,
};

EditorCanvas.defaultProps = {
  maskImg: null,
};

export default EditorCanvas;
