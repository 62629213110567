import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const StyledBox = styled.div`
  ${({ width }) => width != null && `width: ${width};`};
  ${({ height }) =>
    height != null &&
    css`
      height: ${height};
      min-height: ${height};
    `}
  ${({ padding }) => padding != null && `padding: ${padding};`};
  ${({ margin }) => margin != null && `margin: ${margin};`};
  ${({ grow }) => grow != null && `flex-grow: ${grow};`};
  ${({ shrink }) => shrink != null && `flex-shrink: ${shrink};`};
  ${({ basis }) => basis != null && `flex-basis: ${basis};`};
  ${({ hidden }) => hidden && `visibility: hidden;`};
`;

const Box = ({
  children,
  width,
  height,
  grow,
  shrink,
  basis,
  padding,
  margin,
  className,
  show,
  hidden,
}) => {
  if (!show) return <></>;
  return (
    <StyledBox
      className={className}
      width={width}
      height={height}
      padding={padding}
      margin={margin}
      grow={grow}
      shrink={shrink}
      basis={basis}
      hidden={hidden}
    >
      {children}
    </StyledBox>
  );
};

Box.propTypes = {
  children: PropTypes.node,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  grow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shrink: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  basis: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  show: PropTypes.bool,
  hidden: PropTypes.bool,
};

Box.defaultProps = {
  children: null,
  width: null,
  height: null,
  grow: null,
  shrink: null,
  basis: null,
  padding: null,
  margin: null,
  className: null,
  show: true,
  hidden: false,
};

export default Box;
