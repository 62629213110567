import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import Chips, { ChipsVariant } from "common/basicComponents/Chips";
import { useViewport, ViewportType } from "utils/ViewportContext";

const Cell = styled.td`
  height: 4.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[200]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const TagsContainer = styled.div`
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ viewport }) =>
    viewport > ViewportType.TABLET ? "0.5rem" : "0.25rem"};
`;

const ButtonsCell = styled(Cell)`
  min-width: 5.5rem;
  button {
    display: inline-flex;
    margin: 0 0.25rem;
  }
`;

const TagsRow = ({ data, onEdit, onDelete }) => {
  const { type: viewportType } = useViewport();
  return (
    <>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.tag_group_name}
          tag="span"
        />
      </Cell>
      <Cell>
        <TagsContainer viewport={viewportType}>
          {data.tags.map((element) => (
            <Chips
              key={element.id}
              variant={ChipsVariant.VIOLET}
              text={element.tag_name}
            />
          ))}
        </TagsContainer>
      </Cell>
      <ButtonsCell width="10%" centered={true}>
        <Button
          icon={IconName.EDIT}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onEdit(data)}
        />
        <Button
          icon={IconName.TRASH}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onDelete(data)}
        />
      </ButtonsCell>
    </>
  );
};

TagsRow.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TagsRow;
