import React, { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

import { IconName } from "common/basicComponents/Icon";
import Button, {
  ButtonType,
  ButtonVariant,
} from "common/basicComponents/Button";

const Menu = styled.div`
  position: absolute;
  left: 1.25rem;
  right: 1.25rem;
  bottom: 1.25rem;
  z-index: 2;

  display: flex;
`;

const ZoomContainer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  gap: 0.625rem;
`;

const MoveButtons = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  gap: 0.625rem;
  justify-content: flex-end;

  & button:first-child {
    position: absolute;
    top: -3.125rem;
    right: 3.125rem;
  }
`;

const EditorBottomMenu = ({
  disabled,
  zoom,
  setZoom,
  previewOriginal,
  setPreviewOriginal,
  canvasRef,
  positionRef,
}) => {
  const { t } = useTranslation("wizard");

  const onMove = useCallback(
    (xTranslation, yTranslation) => {
      const xLimit = Math.round((canvasRef.current.width * (1 - 1 / zoom)) / 2);
      const yLimit = Math.round(
        (canvasRef.current.height * (1 - 1 / zoom)) / 2
      );
      positionRef.current.x += xTranslation;
      positionRef.current.y += yTranslation;
      positionRef.current.x = Math.max(
        Math.min(positionRef.current.x, xLimit),
        -xLimit
      );
      positionRef.current.y = Math.max(
        Math.min(positionRef.current.y, yLimit),
        -yLimit
      );
    },
    [canvasRef, positionRef, zoom]
  );

  useEffect(() => {
    if (zoom >= 1) onMove(0, 0);
  }, [onMove, zoom]);

  return (
    <Menu>
      <ZoomContainer>
        {!previewOriginal && (
          <>
            <Button
              disabled={disabled}
              type={ButtonType.BUTTON}
              variant={ButtonVariant.OUTLINED}
              onClick={() => {
                setZoom((z) => z + 0.1);
              }}
              icon={IconName.ZOOM_IN}
            />
            <Button
              disabled={disabled}
              type={ButtonType.BUTTON}
              variant={ButtonVariant.OUTLINED}
              onClick={() => {
                setZoom((z) => z - 0.1);
              }}
              icon={IconName.ZOOM_OUT}
            />
          </>
        )}
      </ZoomContainer>
      <Button
        disabled={disabled}
        type={ButtonType.BUTTON}
        variant={
          previewOriginal
            ? ButtonVariant.OUTLINED_GRADIENT
            : ButtonVariant.OUTLINED
        }
        onClick={() => setPreviewOriginal((po) => !po)}
        text={t("previewOriginal")}
      />

      <MoveButtons>
        {zoom > 1 && !previewOriginal && (
          <>
            <Button
              disabled={disabled}
              type={ButtonType.BUTTON}
              variant={ButtonVariant.OUTLINED}
              onClick={() => onMove(0, -1)}
              onPress={() => onMove(0, -5)}
              icon={IconName.UP_ARROW}
            />
            <Button
              disabled={disabled}
              type={ButtonType.BUTTON}
              variant={ButtonVariant.OUTLINED}
              onClick={() => onMove(-1, 0)}
              onPress={() => onMove(-5, 0)}
              icon={IconName.LEFT_ARROW}
            />
            <Button
              disabled={disabled}
              type={ButtonType.BUTTON}
              variant={ButtonVariant.OUTLINED}
              onClick={() => onMove(0, 1)}
              onPress={() => onMove(0, 5)}
              icon={IconName.DOWN_ARROW}
            />
            <Button
              disabled={disabled}
              type={ButtonType.BUTTON}
              variant={ButtonVariant.OUTLINED}
              onClick={() => onMove(1, 0)}
              onPress={() => onMove(5, 0)}
              icon={IconName.RIGHT_ARROW}
            />
          </>
        )}
      </MoveButtons>
    </Menu>
  );
};

EditorBottomMenu.propTypes = {
  disabled: PropTypes.bool.isRequired,
  zoom: PropTypes.number.isRequired,
  setZoom: PropTypes.func.isRequired,
  previewOriginal: PropTypes.bool.isRequired,
  setPreviewOriginal: PropTypes.func.isRequired,
  canvasRef: PropTypes.object.isRequired,
  positionRef: PropTypes.object.isRequired,
};

export default EditorBottomMenu;
