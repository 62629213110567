import { cloneDeep, sortBy } from "lodash";
import { ChipsVariant } from "common/basicComponents/Chips";
import i18n from "i18n/i18n";
import { FileState, InitialFileState } from "common/formFields/FormFileField";
import store from "redux/store";
import { tagGroupsToSelect } from "utils/TagUtils";
import { tagToProperty } from "./TagUtils";

export const PriceRegex = /^(([1-9][0-9]{0,9})|0)(.[0-9]{2})?$/;

export const ProductFilenameSeparator = /_(A|M|N|front|lenses|left|right)\./;

const ProductModelFilename = "F.fbx";

const VersionFiles = {
  A: "albedoFile",
  M: "metallicFile",
  N: "normalFile",
  front: "frontFile",
  frontFull: "frontFullFile",
  lenses: "lensesFile",
  left: "leftFile",
  right: "rightFile",
};

const VersionFileFields = Object.values(VersionFiles);

export const productsWithVersionsToSelect = (products) => {
  return products.map((p) => ({
    value: p.id,
    label: p.product_name,
    versions: p.versions?.map((v) => ({
      value: v.partners_version_id,
      label: v.version_name,
    })),
  }));
};

export const getProductTags = (user, partner, product) => [
  {
    text: i18n.t("marketplace"),
    show: !partner.main_partner && product.from_main_partner,
    variant: ChipsVariant.VIOLET,
  },
  {
    text: i18n.t("shared"),
    show: product.from_other_partner,
    variant: ChipsVariant.GREEN,
  },
  {
    text: i18n.t("fbx"),
    show: product.product_model_file_fbx_uploaded,
    variant: ChipsVariant.ROSE,
  },
  {
    text: i18n.t("2D"),
    show: product.is_2d,
    variant: ChipsVariant.GREY,
  },
];

export const findDeletedVersions = (initialVersions, currentVersions) => {
  return initialVersions.filter(
    (iv) => !currentVersions.find((cv) => cv.id === iv.id)
  );
};

export const prepareProductFormData = (product) => {
  const formData = new FormData();
  const productData = cloneDeep(product);
  const fileUrlData = {};

  if (
    [FileState.NEW, FileState.REPLACED].includes(
      productData.modelFileFbx?.uploadState
    )
  )
    productData.modelFileFbxNew = true;
  if (
    [FileState.DELETED, FileState.REPLACED].includes(
      productData.modelFileFbx?.uploadState
    )
  )
    productData.modelFileFbxDeleted = true;

  formData.append("modelFileFbx", productData.modelFileFbx?.file);
  delete productData.modelFileFbx;

  productData.versions.forEach((version, index) => {
    version.properties = version.tags?.map(tagToProperty);
    delete version.tags;
    version.newProperties = version.newTags?.map(tagToProperty);
    delete version.newTags;
    version.deletedProperties = version.deletedTags?.map(tagToProperty);
    delete version.deletedTags;

    [...Object.values(VersionFileFields), "thumbFile"].forEach((filename) => {
      if (version[filename]) {
        if (
          [FileState.NEW, FileState.REPLACED].includes(
            version[filename]?.uploadState
          )
        )
          productData.versions[index][`${filename}New`] = true;
        if (
          [FileState.DELETED, FileState.REPLACED].includes(
            version[filename]?.uploadState
          )
        )
          productData.versions[index][`${filename}Deleted`] = true;

        formData.append(
          `versions[${index}].${filename}`,
          version[filename]?.file
        );
        if (!version[filename]?.file && version[filename]?.url) {
          fileUrlData[`versions.${index}.${filename}`] = version[filename].url;
        }
        delete version[filename];
      }
    });
  });

  productData.properties = productData.tags?.map(tagToProperty);
  delete productData.tags;
  productData.newProperties = productData.newTags?.map(tagToProperty);
  delete productData.newTags;
  productData.deletedProperties = productData.deletedTags?.map(tagToProperty);
  delete productData.deletedTags;

  formData.append("productData", JSON.stringify(productData));
  formData.append("fileUrlData", JSON.stringify(fileUrlData));
  return formData;
};

export const prepareAutocutFormData = (frontFile, leftFile, rightFile) => {
  const formData = new FormData();
  formData.append("frontFile", frontFile);
  formData.append("leftFile", leftFile);
  formData.append("rightFile", rightFile);
  return formData;
};

export const loadThumbnails = (product) => {
  product.versions = product.versions.map((v) => {
    v.thumbFile = { ...InitialFileState };
    if (v.version_thumb_link) {
      v.thumbFile.uploadState = FileState.UPLOADED;
      v.thumbFile.url = v.version_thumb_link;
    }
    for (const field of VersionFileFields) {
      v[field] = { ...InitialFileState };
    }
    return v;
  });
};

export const loadProductFiles = (zipData, productData) => {
  const product = cloneDeep(productData);

  product.modelFileFbx = { ...InitialFileState };
  loadThumbnails(product);

  const promArray = [];
  if (zipData.file(ProductModelFilename)) {
    promArray.push(
      zipData
        .file(ProductModelFilename)
        .async("blob")
        .then((file) => {
          product.modelFileFbx = {
            ...product.modelFileFbx,
            file,
            uploadState: FileState.UPLOADED,
          };
        })
    );
  }
  Object.keys(zipData.files).forEach((file) => {
    const [versionId, fileSymbol] = file.split(ProductFilenameSeparator);
    if (!versionId || !fileSymbol) return;
    promArray.push(
      zipData
        .file(file)
        .async("blob")
        .then((file) => {
          const versionIndex = product.versions.findIndex(
            (v) => v.id === versionId
          );
          if (versionIndex >= 0) {
            product.versions[versionIndex][VersionFiles[fileSymbol]] = {
              ...product.versions[versionIndex][VersionFiles[fileSymbol]],
              file,
              uploadState: FileState.UPLOADED,
            };
          }
        })
    );
  });

  return Promise.all(promArray).then(() => product);
};

const translationKeys = {
  productModelFileFbxUploaded: "modelFileFbx",
  productAspectRatioIos: ["aspectRatio", "iOS"],
  productAspectRatioAndroid: ["aspectRatio", "android"],
  productAspectRatioWeb: ["aspectRatio", "browser"],
  productPositionYIos: ["positionY", "iOS"],
  productPositionYAndroid: ["positionY", "android"],
  productPositionYWeb: ["positionY", "browser"],
  productPositionZIos: ["positionZ", "iOS"],
  productPositionZAndroid: ["positionZ", "android"],
  productPositionZWeb: ["positionZ", "browser"],
  productRotationIos: ["rotation", "iOS"],
  productRotationAndroid: ["rotation", "android"],
  productRotationWeb: ["rotation", "browser"],
  productEarRotationWeb: ["earRotation", "browser"],
  productPublished: "publishedApp",
  productUseMetallicGlass: "useMetallicGlass",
  productEarpieceDistanceScale: "earpieceDistanceScale",
  versionName: "version.name",
  versionCode: "version.code",
  versionShopUrl: "version.pageLink",
  versionPrice: "version.price",
  versionSpecialPrice: "version.specialPrice",
  versionThumbLink: "version.thumb",
  versionTextureAlbedoUploaded: "version.albedoFile",
  versionTextureMetallicUploaded: "version.metallicFile",
  versionTextureNormalUploaded: "version.normalFile",
  versionFrontUploaded: "version.frontFile",
  versionLensesUploaded: "version.lensesFile",
  versionLeftUploaded: "version.leftFile",
  versionRightUploaded: "version.rightFile",
  versionOrder: "version.order",
  versionPublished: "version.publishedApp",
  versionSku: "version.sku",
};

const ignoredFields = [
  "updatedAt",
  "versionAutocutPreview",
  "versionThumbSmallLink",
  "productLastChangedBy",
  "partnersProductLastChangedBy",
  "versionLastChangedBy",
  "partnersVersionLastChangedBy",
  "productInitialFilesZipLink",
  "productPhotosZipLink",
  "productModelFileUploaded",
  "fromMainPartner",
  "fromOtherPartner",
];

export const findProductChanges = (oldValuesInput, newValuesInput) => {
  let oldValues = oldValuesInput;
  let newValues = newValuesInput;

  if (oldValues?.productDimensions)
    oldValues = {
      ...oldValues,
      ...oldValues.productDimensions,
      productDimensions: undefined,
    };

  if (newValues?.productDimensions)
    newValues = {
      ...newValues,
      ...newValues.productDimensions,
      productDimensions: undefined,
    };

  const uniqueKeys = Object.keys({ ...oldValues, ...newValues });
  const changes = uniqueKeys
    .map((key) => {
      if (oldValues?.[key] === newValues?.[key] || ignoredFields.includes(key))
        return;

      if (key === "propertyId") {
        const { tagGroups } = store.getState().tagGroups;
        const tagList = tagGroupsToSelect(tagGroups);
        return {
          key: "tag",
          label: "tag",
          oldValue: oldValues?.propertyId
            ? tagList.find((t) => t.id === oldValues?.propertyId).label
            : "",
          newValue: newValues?.propertyId
            ? tagList.find((t) => t.id === newValues?.propertyId).label
            : "",
          showAsCheckbox: false,
          showAsPrice: false,
        };
      }
      if (key === "brandId") {
        const { brands } = store.getState().brands;
        return {
          key: "brand",
          label: "brand",
          oldValue: oldValues[key]
            ? brands.find((b) => b.id === oldValues[key]).brand_name
            : "",
          newValue: newValues[key]
            ? brands.find((b) => b.id === newValues[key]).brand_name
            : "",
          showAsCheckbox: false,
          showAsPrice: false,
        };
      }
      if (key === "ownerId") {
        const { partners } = store.getState().auth;
        return {
          key: "owner",
          label: "owner",
          oldValue: oldValues[key]
            ? partners.find((b) => b.id === oldValues[key]).partner_name
            : "",
          newValue: newValues[key]
            ? partners.find((b) => b.id === newValues[key]).partner_name
            : "",
          showAsCheckbox: false,
          showAsPrice: false,
        };
      }
      if (key === "productFilesZipLink")
        return {
          key,
          label: translationKeys[key] || key,
          oldValue: i18n.t("product:productFilesZipLinkChanged"),
          newValue: i18n.t("product:productFilesZipLinkChanged"),
          showAsCheckbox: false,
          showAsPrice: false,
        };

      return {
        key,
        label: translationKeys[key] || key,
        oldValue: oldValues[key],
        newValue: newValues[key],
        showAsCheckbox:
          typeof oldValues[key] == "boolean" ||
          typeof oldValues[key] == "boolean",
        showAsPrice: key.endsWith("Price"),
      };
    })
    .filter((elem) => elem);
  return sortBy(changes, "key");
};
