import { useEffect } from "react";
import PropTypes from "prop-types";

import { useFormikContext } from "formik";

const FormObserver = ({ onDirtyChanged, onValidationErrors }) => {
  const { dirty, submitCount, isValid, errors } = useFormikContext();
  useEffect(() => {
    onDirtyChanged(dirty);
  }, [dirty, onDirtyChanged]);

  useEffect(() => {
    if (submitCount > 0 && !isValid) {
      onValidationErrors(errors);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitCount, isValid]);

  return null;
};

FormObserver.propTypes = {
  onDirtyChanged: PropTypes.func,
  onValidationErrors: PropTypes.func,
};

FormObserver.defaultProps = {
  onDirtyChanged: () => {},
  onValidationErrors: () => {},
};

export default FormObserver;
