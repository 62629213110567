import React, { useRef, forwardRef } from "react";
import styled, { css, useTheme } from "styled-components";
import _ from "lodash";
import PropTypes from "prop-types";
import Typography, { TypographyVariant, TypographyWeight } from "./Typography";
import Icon from "./Icon";
import Loader from "./Loader";

export const ButtonType = {
  BUTTON: "button",
  SUBMIT: "submit",
};

export const ButtonVariant = {
  CONTAINED: "contained",
  OUTLINED: "outlined",
  OUTLINED_GRADIENT: "outlinedGradient",
  TEXT: "text",
  GREEN: "green",
  ERROR: "error",
  CUSTOM: "custom",
};

export const ButtonSize = {
  XXSMALL: "xxsmall",
  XSMALL: "xsmall",
  SMALL: "small",
  NORMAL: "normal",
  LARGE: "large",
};

const ButtonUIType = {
  TEXT: "text",
  ICON: "icon",
};

export const ButtonWidth = {
  AUTO: "auto",
  GROW: "grow",
};

export const ButtonIconAlign = {
  LEFT: "left",
  CENTER: "center",
};

const getButtonColors = (theme, variant, pseudo = "default") =>
  ({
    [ButtonVariant.CUSTOM]: {
      default: null,
      disabled: null,
      hover: null,
      active: null,
    },
    [ButtonVariant.CONTAINED]: {
      default: css`
        background: ${theme.colors.black};
        color: ${theme.colors.white};
        border: none;
      `,
      disabled: css`
        background: ${theme.colors.grey[200]};
        color: ${theme.colors.grey[600]};
        border: none;
        & svg {
          fill: ${theme.colors.grey[600]};
        }
      `,
      hover: css`
        color: ${theme.colors.white};
        background-image: ${theme.colors.gradient[100]};
        border: none;
      `,
      active: css`
        color: ${theme.colors.white};
        background-color: transparent;
        background-image: ${theme.colors.gradient[80]};
        border: none;
      `,
    },
    [ButtonVariant.OUTLINED]: {
      default: css`
        background: ${theme.colors.white};
        color: ${theme.colors.black};
        border: 1px solid;
      `,
      disabled: css`
        background: ${theme.colors.grey[100]};
        color: ${theme.colors.grey[500]};
        border: 1px solid ${theme.colors.grey[500]};
        & svg {
          fill: ${theme.colors.grey[500]};
        }
      `,
      hover: css`
        border-image: ${theme.colors.gradient[100]} 1;
        background-image: ${theme.colors.gradient[100]};
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        & svg {
          fill: url(#gradient);
        }
        &::before {
          content: "";
          background-color: ${theme.colors.white};
          position: absolute;
          inset: 0;
          z-index: -1;
        }
      `,
      active: css`
        border: 1px solid;
        border-image: ${theme.colors.gradient[80]} 1;
        background-image: ${theme.colors.gradient[80]};
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        & svg {
          fill: url(#gradient);
          opacity: 80%;
        }
        &::before {
          content: "";
          background-color: ${theme.colors.white};
          position: absolute;
          inset: 0;
          z-index: -1;
        }
      `,
    },
    [ButtonVariant.OUTLINED_GRADIENT]: {
      default: css`
        border-image: ${theme.colors.gradient[20]} 1;
        background-image: ${theme.colors.gradient[20]};
        background-clip: text;
        -webkit-background-clip: text;
        color: black;
        & svg {
          fill: ${theme.colors.black};
        }
        &::before {
          content: "";
          background-color: ${theme.colors.white};
          position: absolute;
          inset: 0;
          z-index: -1;
        }
      `,
      disabled: css`
        background: ${theme.colors.grey[100]};
        color: ${theme.colors.grey[500]};
        border: 1px solid ${theme.colors.grey[500]};
        & svg {
          fill: ${theme.colors.grey[500]};
        }
      `,
      hover: css`
        border-image: ${theme.colors.gradient[60]} 1;
        background-image: ${theme.colors.gradient[60]};
        background-clip: text;
        -webkit-background-clip: text;
        color: black;
        & svg {
          fill: ${theme.colors.black};
        }
        &::before {
          content: "";
          background-color: ${theme.colors.white};
          position: absolute;
          inset: 0;
          z-index: -1;
        }
      `,
      active: css`
        border-image: ${theme.colors.gradient[100]} 1;
        background-image: ${theme.colors.gradient[100]};
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        & svg {
          fill: url(#gradient);
        }
        &::before {
          content: "";
          background-color: ${theme.colors.white};
          position: absolute;
          inset: 0;
          z-index: -1;
        }
      `,
    },
    [ButtonVariant.TEXT]: {
      default: css`
        background: transparent;
        color: ${theme.colors.black};
        border: none;
      `,
      disabled: css`
        background: transparent;
        color: ${theme.colors.grey[500]};
        border: none;
        & svg {
          fill: ${theme.colors.grey[500]};
          opacity: 100%;
        }
      `,
      hover: css`
        background: transparent;
        background-image: ${theme.colors.gradient[100]};
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        border: none;
        & svg {
          fill: url(#gradient);
          opacity: 100%;
        }
      `,
      active: css`
        background: transparent;
        background-image: ${theme.colors.gradient[80]};
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        border: none;
        & svg {
          fill: url(#gradient);
          opacity: 80%;
        }
      `,
    },

    [ButtonVariant.GREEN]: {
      default: css`
        background: ${theme.colors.success[700]};
        color: ${theme.colors.white};
        border: none;
      `,
      disabled: css`
        background: ${theme.colors.grey[200]};
        color: ${theme.colors.grey[600]};
        border: none;
        & svg {
          fill: ${theme.colors.grey[600]};
        }
      `,
      hover: css`
        background: ${theme.colors.success[500]};
        color: ${theme.colors.white};
        border: none;
      `,
      active: css`
        background: ${theme.colors.success[400]};
        color: ${theme.colors.white};
        border: none;
      `,
    },
    [ButtonVariant.ERROR]: {
      default: css`
        background: ${theme.colors.secondary[700]};
        color: ${theme.colors.white};
        border: none;
      `,
      disabled: css`
        background: ${theme.colors.grey[200]};
        color: ${theme.colors.grey[600]};
        border: none;
        & svg {
          fill: ${theme.colors.grey[600]};
        }
      `,
      hover: css`
        background: ${theme.colors.secondary[500]};
        color: ${theme.colors.white};
        border: none;
      `,
      active: css`
        background: ${theme.colors.secondary[400]};
        color: ${theme.colors.white};
        border: none;
      `,
    },
  }[variant][pseudo]);

const getButtonStyle = (theme, type, size) =>
  ({
    [ButtonUIType.TEXT]: {
      [ButtonSize.SMALL]: css`
        padding: 0.375rem 0.75rem;
        height: 2rem;
      `,
      [ButtonSize.NORMAL]: css`
        padding: 0.625rem 0.75rem;
        height: 2.5rem;
      `,
      [ButtonSize.LARGE]: css`
        padding: 0.875rem 1rem;
        height: 3rem;
      `,
    },
    [ButtonUIType.ICON]: {
      [ButtonSize.XXSMALL]: css`
        width: 1rem;
        height: 1rem;
      `,
      [ButtonSize.XSMALL]: css`
        width: 1.5rem;
        height: 1.5rem;
      `,
      [ButtonSize.SMALL]: css`
        width: 2rem;
        height: 2rem;
      `,
      [ButtonSize.NORMAL]: css`
        width: 2.5rem;
        height: 2.5rem;
      `,
    },
  }[type][size]);

const getButtonIconSize = (type, size) =>
  ({
    [ButtonUIType.TEXT]: {
      [ButtonSize.SMALL]: "1.25rem",
      [ButtonSize.NORMAL]: "1.25rem",
      [ButtonSize.LARGE]: "1.5rem",
    },
    [ButtonUIType.ICON]: {
      [ButtonSize.XXSMALL]: "1rem",
      [ButtonSize.XSMALL]: "1.25rem",
      [ButtonSize.SMALL]: "1.5rem",
      [ButtonSize.NORMAL]: "1.5rem",
      [ButtonSize.LARGE]: "2rem",
    },
  }[type][size]);

const getButtonWidthStyle = (width, minWidth) => {
  if (width === ButtonWidth.AUTO)
    return css`
      width: auto;
      min-width: ${minWidth}${typeof minWidth == "number" ? "px" : ""};
    `;
  if (width === ButtonWidth.GROW)
    return css`
      flex-grow: 1;
      flex-basis: 0;
      min-width: ${minWidth}${typeof minWidth == "number" ? "px" : ""};
    `;

  return css`
    width: ${width}${typeof width == "number" ? "px" : ""};
    min-width: ${minWidth}${typeof minWidth == "number" ? "px" : ""};
  `;
};
const StyledButton = styled.button`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;

  ${({ width, minWidth }) =>
    width || minWidth ? getButtonWidthStyle(width, minWidth) : null};

  ${({ theme, UItype, size, variant }) =>
    variant !== ButtonVariant.CUSTOM && getButtonStyle(theme, UItype, size)};
  ${({ theme, variant, isActive }) =>
    getButtonColors(theme, variant, !isActive ? "default" : "active")};

  &:hover {
    ${({ theme, variant }) => getButtonColors(theme, variant, "hover")};
  }

  &:active {
    ${({ theme, variant }) => getButtonColors(theme, variant, "active")}
  }

  &:disabled {
    ${({ theme, variant }) => getButtonColors(theme, variant, "disabled")};
  }
  & > :not(.loader) {
    visibility: ${({ isLoading }) => (isLoading ? "hidden" : "visible")};
  }

  span {
    text-wrap: nowrap;
    flex-grow: ${({ iconAlign }) =>
      iconAlign === ButtonIconAlign.LEFT ? 1 : null};
  }
`;

const StyledLoaderContainer = styled.div`
  position: absolute;
  inset: 0;
`;

const IconBadge = styled.div`
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
`;

const Button = forwardRef(
  (
    {
      icon,
      iconAlign,
      variant,
      size,
      width,
      minWidth,
      text,
      badge,
      onClick,
      onPress,
      disabled,
      type,
      className,
      isLoading,
      isActive,
      form,
    },
    ref
  ) => {
    const UItype = _.isEmpty(text) ? ButtonUIType.ICON : ButtonUIType.TEXT;
    const { colors } = useTheme();
    const intervalRef = useRef();

    const start = () => {
      onClick();
      if (onPress) {
        intervalRef.current = setInterval(onPress, 200);
      }
    };
    const end = () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };

    return (
      <StyledButton
        variant={variant}
        type={type}
        UItype={UItype}
        size={size}
        onMouseDown={start}
        onMouseUp={end}
        onMouseOut={end}
        disabled={disabled || isLoading}
        className={className}
        isLoading={isLoading}
        isActive={isActive}
        width={text?.length ? width : null}
        minWidth={text?.length ? minWidth : null}
        iconAlign={iconAlign}
        form={form}
        ref={ref}
      >
        {icon && <Icon name={icon} size={getButtonIconSize(UItype, size)} />}
        {text && (
          <Typography
            variant={TypographyVariant.BUTTON}
            weight={TypographyWeight.SEMIBOLD}
            text={text}
          />
        )}
        {text && badge ? badge : null}
        {!text && badge ? <IconBadge>{badge}</IconBadge> : null}
        {isLoading && (
          <StyledLoaderContainer className="loader">
            <Loader show={isLoading} height={15} color={colors.grey[500]} />
          </StyledLoaderContainer>
        )}
      </StyledButton>
    );
  }
);

const checkIfTextOrIcon = (props, _propName, componentName) => {
  if (!props.text && !props.icon) {
    return new Error(
      `One of 'text' or 'icon' is required by '${componentName}' component.`
    );
  }
  return null;
};

Button.displayName = "button";

Button.propTypes = {
  type: PropTypes.oneOf(Object.values(ButtonType)),
  variant: PropTypes.oneOf(Object.values(ButtonVariant)),
  size: PropTypes.oneOf(Object.values(ButtonSize)),
  width: PropTypes.oneOfType([
    PropTypes.oneOf(Object.values(ButtonWidth)),
    PropTypes.number,
    PropTypes.string,
  ]),
  minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconAlign: PropTypes.oneOf(Object.values(ButtonIconAlign)),
  icon: checkIfTextOrIcon,
  text: checkIfTextOrIcon,
  badge: PropTypes.node,
  onClick: PropTypes.func,
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isActive: PropTypes.bool,
  form: PropTypes.string,
};

Button.defaultProps = {
  type: ButtonType.BUTTON,
  variant: ButtonVariant.CONTAINED,
  size: ButtonSize.NORMAL,
  width: ButtonWidth.AUTO,
  minWidth: null,
  iconAlign: ButtonIconAlign.CENTER,
  icon: null,
  text: null,
  badge: null,
  onClick: () => {},
  onPress: null,
  disabled: false,
  className: null,
  isLoading: false,
  isActive: false,
  form: null,
};

export default Button;
