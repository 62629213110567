import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import { formatTimestamp } from "utils/DateTimeUtils";
import { checkIfTotemActive } from "utils/TotemUtils";
import Chips from "common/basicComponents/Chips";
import { ChipsVariant } from "common/basicComponents/Chips";
import { getTotemStatusShort } from "utils/TotemUtils";

const Cell = styled.td`
  height: 4.5rem;
  padding: 0 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const ButtonsCell = styled(Cell)`
  min-width: 5.5rem;
  button {
    display: inline-flex;
    margin: 0 0.25rem;
  }
`;

const DateTimeCell = styled(Cell)`
  width: 13rem;
  max-width: 13rem;
`;

const TotemRow = ({ data, number, onEdit }) => {
  const isActive = checkIfTotemActive(data);
  const statusShort = getTotemStatusShort(data);

  return (
    <>
      <Cell centered={true}>
        <Typography
          variant={TypographyVariant.BODY2}
          text={number}
          tag="span"
        />
      </Cell>
      <Cell centered={true}>
        <Chips
          text={statusShort}
          variant={isActive ? ChipsVariant.GREEN : ChipsVariant.ROSE}
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.totem_name || ""}
          tag="span"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.totem_device_name}
          tag="span"
        />
      </Cell>
      <DateTimeCell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={formatTimestamp(data.totem_expiration_date)}
          tag="span"
        />
      </DateTimeCell>
      <DateTimeCell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={formatTimestamp(data.totem_last_activity)}
          tag="span"
        />
      </DateTimeCell>
      <ButtonsCell centered={true}>
        <Button
          icon={IconName.EDIT}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onEdit(data)}
        />
      </ButtonsCell>
    </>
  );
};

TotemRow.propTypes = {
  data: PropTypes.object.isRequired,
  number: PropTypes.number.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default TotemRow;
