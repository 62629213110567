import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Icon, { IconName } from "common/basicComponents/Icon";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";

const Item = styled.div`
  height: 2.5rem;
  width: 100%;
  padding: 0.625rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;

  ${({ mobile }) => mobile && "justify-content: center;"}

  ${({ theme, selected }) =>
    selected ? `background: ${theme.colors.grey[200]}` : null};

  &:hover {
    ${({ theme, selected }) =>
      selected ? null : `background: ${theme.colors.grey[100]}`};
  }
  & span {
    user-select: none;
  }
`;

const NavigationItem = ({ icon, text, selected, mobile, onClick }) => {
  return (
    <Item selected={selected} onClick={onClick} mobile={mobile}>
      {icon && <Icon name={icon} size="0.75rem" />}
      <Typography
        variant={TypographyVariant.BUTTON}
        weight={selected ? TypographyWeight.SEMIBOLD : TypographyWeight.REGULAR}
        text={text}
      />
    </Item>
  );
};

NavigationItem.propTypes = {
  icon: PropTypes.oneOf(Object.values(IconName)),
  text: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  mobile: PropTypes.bool,
  onClick: PropTypes.func,
};

NavigationItem.defaultProps = {
  icon: null,
  selected: false,
  mobile: false,
  onClick: () => {},
};

export default NavigationItem;
