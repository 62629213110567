import { createSlice } from "@reduxjs/toolkit";
import { clearAuthData, setCurrentPartner } from "components/auth/AuthSlice";

const initialState = {
  links: [],
  linksTotal: 0,
  search: {},
};

export const linkSlice = createSlice({
  name: "links",
  initialState,
  reducers: {
    appendLinks: (state, action) => {
      if (state.links.length != action.payload.meta?.offset) return;
      state.links = state.links.concat(action.payload.links);
      state.linksTotal = Number(action.payload.meta?.count);
    },
    setSearch: (state, action) => {
      state.links = [];
      state.linksTotal = null;
      state.search = action.payload;
    },
    clearLinks: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(clearAuthData, () => initialState);
    builder.addCase(setCurrentPartner, () => initialState);
  },
});

export const { appendLinks, setSearch, clearLinks } = linkSlice.actions;
export default linkSlice.reducer;
