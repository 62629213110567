const colors = {
  primary: {
    25: "#F8F5FF",
    50: "#F1EAFF",
    100: "#D1BBFF",
    200: "#B28CFF",
    300: "#935DFF",
    400: "#6B2BEC",
    500: "#5419CA",
    600: "#3F0CA8",
    700: "#2E0286",
    800: "#210064",
    900: "#160042",
  },
  secondary: {
    25: "#FFF6FB",
    50: "#FFECF7",
    100: "#FFCAE9",
    200: "#FFA8DC",
    300: "#FF86CE",
    400: "#FF64C1",
    500: "#FF42B3",
    600: "#E32898",
    700: "#BA1176",
    800: "#910157",
    900: "#68003E",
  },
  grey: {
    25: "#FCFDFD",
    50: "#F9FAFB",
    100: "#F2F4F7",
    200: "#E4E7EC",
    300: "#D0D5DD",
    400: "#98A2B3",
    500: "#667085",
    600: "#475467",
    700: "#344054",
    800: "#1D2939",
    900: "#101828",
  },
  success: {
    25: "#F3FEFD",
    50: "#CDF8F4",
    100: "#A9F0EA",
    200: "#87E7DF",
    300: "#67DBD1",
    400: "#48CDC3",
    500: "#2CBEB2",
    600: "#1BA99D",
    700: "#0D8F84",
    800: "#047168",
    900: "#006155",
  },
  alert: {
    25: "#FFFFEB",
    50: "#FFFFCE",
    100: "#FFFFB2",
    200: "#FFFF97",
    300: "#FFFF7E",
    400: "#FFFF66",
    500: "#FFFF4F",
    600: "#DFDF3C",
    700: "#BFBF2B",
    800: "#9F9F1D",
    900: "#808012",
  },
  error: {
    25: "#FFE6E6",
    50: "#FFBFBF",
    100: "#FF9999",
    200: "#FF7674",
    300: "#FF5552",
    400: "#FF3532",
    500: "#FF1714",
    600: "#DF0805",
    700: "#BF0000",
    800: "#9F0000",
    900: "#800000",
  },
  white: "#FFFFFF",
  black: "#101828",
  gradient: {
    100: "linear-gradient(96.34deg, #BA1176 0%, #6B2BEC 100%)",
    80: "linear-gradient(96.34deg, rgba(186, 17, 118, 0.8) 0%, rgba(107, 43, 236, 0.8) 100%)",
    60: "linear-gradient(96.34deg, rgba(186, 17, 118, 0.6) 0%, rgba(107, 43, 236, 0.6) 100%)",
    40: "linear-gradient(96.34deg, rgba(186, 17, 118, 0.4) 0%, rgba(107, 43, 236, 0.4) 100%)",
    20: "linear-gradient(96.34deg, rgba(186, 17, 118, 0.2) 0%, rgba(107, 43, 236, 0.2) 100%)",
  },
};

const fonts = {
  letterSpacing: "0.08em",
  fontWeight: {
    light: 300,
    regular: 400,
    semibold: 600,
    bold: 700,
  },
};

export default {
  colors,
  fonts,
};
