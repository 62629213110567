import React from "react";
import styled, { css } from "styled-components";
import ReactModal from "react-modal";
import PropTypes from "prop-types";
import { useViewport, ViewportType } from "utils/ViewportContext";

ReactModal.setAppElement("#root");

export const DialogSize = {
  NORMAL: "normal",
  LARGE: "large",
};

const getDialogSize = (viewportType, size) =>
  ({
    [ViewportType.MOBILE]: {
      [DialogSize.NORMAL]: css`
        width: 100%;
      `,
      [DialogSize.LARGE]: css`
        width: 100%;
      `,
    },
    [ViewportType.TABLET]: {
      [DialogSize.NORMAL]: css`
        min-width: 80%;
      `,
      [DialogSize.LARGE]: css`
        min-width: 90%;
      `,
    },
    [ViewportType.DESKTOP]: {
      [DialogSize.NORMAL]: css`
        min-width: 40%;
      `,
      [DialogSize.LARGE]: css`
        min-width: 60%;
      `,
    },
  }[viewportType][size]);

const StyledModal = styled.div`
  margin: auto 1.25rem;
  padding: ${({ viewportType }) =>
    viewportType > ViewportType.MOBILE ? "2.5rem" : "2.5rem 1.25rem"};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black};
  box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
  max-width: calc(100% - 2.5rem);
  ${({ viewportType, size }) => getDialogSize(viewportType, size)};
`;

const StyledOverlay = styled.div`
  position: fixed;
  inset: 0;
  padding: 1.25rem 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: flex-start;
  overflow: auto;
  justify-content: center;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  opacity: 0;
  transition: opacity 400ms ease-in-out;
  &[class*="--after-open"] {
    opacity: 1;
    z-index: 3;
  }
  &[class*="--before-close"] {
    opacity: 0;
  }
`;

const Dialog = ({ open, onClose, children, size }) => {
  const { type: viewportType } = useViewport();

  return (
    <ReactModal
      isOpen={open}
      closeTimeoutMS={400}
      onRequestClose={onClose}
      className="modal"
      overlayClassName="overlay"
      contentElement={(props, children) => (
        <StyledModal
          {...props}
          viewportType={viewportType}
          size={size}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </StyledModal>
      )}
      overlayElement={(props, contentElement) => (
        <StyledOverlay {...props} onClick={onClose}>
          {contentElement}
        </StyledOverlay>
      )}
    >
      {children}
    </ReactModal>
  );
};

Dialog.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.values(DialogSize)),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

Dialog.defaultProps = {
  size: DialogSize.NORMAL,
};

export default Dialog;
