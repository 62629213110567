import React, { useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { components } from "react-select";
import Icon, { IconName } from "../Icon";
import Typography, { TypographyVariant, TypographyWeight } from "../Typography";
import Radio from "../Radio";
import Checkbox, { CheckboxSize, CheckboxType } from "../Checkbox";
import { useTranslation } from "react-i18next";

const TypographyOptionContainer = styled.div`
  flex-grow: 1;
  display: flex;
  gap: 0.5rem;
  row-gap: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  .additional-label {
    margin-top: 0.15rem;
    color: ${({ theme }) => theme.colors.grey[400]};
  }
  span {
    overflow-wrap: anywhere;
    white-space: pre-line;
  }
`;

const HiddenOptionsContainer = styled.div`
  display: flex;
  margin-right: 0.5rem;
  flex-wrap: nowrap;
  align-items: center;
  gap: 0.5rem;
  div {
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    padding: 0.25rem;
    width: 1.375rem;
    justify-content: center;
  }
`;

export const DropdownIndicator = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.DropdownIndicator {...props}>
    <Icon name={IconName.DOWN_ARROW} size="1.5rem" />
  </components.DropdownIndicator>
);

export const NoOptionsMessage = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.NoOptionsMessage {...props}>
      <Typography variant={TypographyVariant.BODY2} text={t("noData")} />
    </components.NoOptionsMessage>
  );
};

export const ValueContainer = ({ children, isMulti, ...props }) => {
  const ref = useRef();
  const [chips, otherChildren] = children;
  const visibleChips = [];
  const widthLimit = 0.8 * (ref.current?.offsetParent?.offsetWidth || 0);
  let actPos = 0;

  if (!isMulti) {
    return (
      <components.ValueContainer
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        isMulti={isMulti}
      >
        {children}
      </components.ValueContainer>
    );
  }

  if (Array.isArray(chips)) {
    chips?.map((c) => {
      const chipsWidth = c.props.data.width;
      if (actPos + chipsWidth < widthLimit) {
        actPos += chipsWidth;
        visibleChips.push(c);
      }
      return c;
    });
  }

  return (
    <components.ValueContainer
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isMulti={isMulti}
    >
      <div ref={ref}>
        {visibleChips}
        {visibleChips.length < (chips?.length || 0) && (
          <HiddenOptionsContainer>
            <Typography
              variant={TypographyVariant.OVERLINE}
              weight={TypographyWeight.BOLD}
              text="..."
              tag="span"
            />
            <div>
              <Typography
                variant={TypographyVariant.OVERLINE}
                weight={TypographyWeight.BOLD}
                text={String(chips.length)}
                tag="span"
              />
            </div>
          </HiddenOptionsContainer>
        )}
        {otherChildren}
      </div>
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  isMulti: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export const MultiValueLabel = ({ data, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.MultiValueLabel {...props}>
    {data.group && (
      <Typography
        variant={TypographyVariant.OVERLINE}
        weight={TypographyWeight.BOLD}
        text={`${data.group}: `}
        tag="span"
      />
    )}
    <Typography
      variant={TypographyVariant.OVERLINE}
      text={data.label}
      tag="span"
    />
  </components.MultiValueLabel>
);

MultiValueLabel.propTypes = {
  data: PropTypes.shape({
    group: PropTypes.string,
    label: PropTypes.string.isRequired,
    additionalLabel: PropTypes.string,
  }),
};

MultiValueLabel.defaultProps = {
  data: PropTypes.shape({
    group: "",
    additionalLabel: "",
  }),
};

export const MultiValueRemove = ({ ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.MultiValueRemove {...props}>
    <Icon name={IconName.CLOSE} size="0.875rem" />
  </components.MultiValueRemove>
);

export const Option = ({ data, selectProps, isSelected, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.Option {...props} isSelected={isSelected}>
    {!selectProps.isMulti && !selectProps.onlyTextOptions && (
      <Radio id={data.label} checked={isSelected} readOnly />
    )}
    <TypographyOptionContainer>
      {data.group && (
        <Typography
          text={`${data.group}:`}
          variant={TypographyVariant.BODY2}
          weight={TypographyWeight.BOLD}
          tag="span"
        />
      )}
      <Typography
        text={data.label}
        variant={TypographyVariant.BODY2}
        tag="span"
      />
      {data.additionalLabel && (
        <Typography
          text={data.additionalLabel}
          variant={TypographyVariant.OVERLINE}
          weight={TypographyWeight.LIGHT}
          className="additional-label"
          tag="span"
        />
      )}
    </TypographyOptionContainer>
    {selectProps.isMulti && !selectProps.onlyTextOptions && (
      <Checkbox
        id={data.label}
        value={isSelected}
        readOnly
        size={CheckboxSize.SMALL}
        type={CheckboxType.OUTLINED}
      />
    )}
  </components.Option>
);

Option.propTypes = {
  data: PropTypes.shape({
    group: PropTypes.string,
    label: PropTypes.string.isRequired,
    additionalLabel: PropTypes.string,
  }),
  selectProps: PropTypes.shape({
    isMulti: PropTypes.bool.isRequired,
    onlyTextOptions: PropTypes.bool.isRequired,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
};

Option.defaultProps = {
  data: PropTypes.shape({
    group: "",
    additionalLabel: "",
  }),
};

export const Menu = ({ children, placement, ...props }) => {
  return (
    <components.Menu
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      placement={placement}
      className={placement === "top" ? "top-menu" : "bottom-menu"}
    >
      {children}
    </components.Menu>
  );
};

Menu.propTypes = {
  placement: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
