import React, { useState } from "react";
import styled from "styled-components";
import { PropTypes } from "prop-types";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo, { LogoVariant } from "common/basicComponents/Logo";
import Select from "common/basicComponents/Select";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import NavigationItem from "./NavigationItem";
import { ViewportMinBreakpoints } from "utils/ViewportContext";

const NavigationBar = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  height: 4.5rem;
  padding: 1.25rem;

  @media screen and (min-width: ${ViewportMinBreakpoints.TABLET}px) {
    padding: 1.25rem 2rem;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  & button:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 4.5rem;
  left: 0;
  right: 0;
  z-index: 3;
  overflow-y: scroll;
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};

  transition: height 0.5s;
  height: calc(100% - 4.5rem);
  ${({ open }) => !open && "height: 0;"}

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PartnerSelect = styled(Select)`
  width: 17.5rem;
  margin-top: 2.25rem;
  margin-bottom: 1rem;
`;

const LogoutButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

const MobileNavigation = ({
  pages,
  partners,
  partner,
  onPartnerChanged,
  onPageChanged,
  onLogout,
}) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();

  return (
    <>
      <NavigationBar>
        <Logo variant={LogoVariant.HORIZONTAL} />
        <Button
          variant={ButtonVariant.TEXT}
          size={ButtonSize.LARGE}
          icon={open ? IconName.HAMBURGER_CLOSE : IconName.HAMBURGER}
          onClick={() => setOpen(!open)}
        />
      </NavigationBar>
      <DropdownMenu open={open}>
        <PartnerSelect
          id="partner-select"
          name="partner-select"
          onlyTextOptions
          renderCaption={false}
          options={partners.map((p) => ({
            value: p.id,
            label: p.partner_name,
          }))}
          value={{
            value: partner?.id || "",
            label: partner?.partner_name || "",
          }}
          onChange={(opt) => {
            setOpen(false);
            onPartnerChanged(partners.find((p) => p.id === opt.value));
          }}
        />
        {pages.map((p) => (
          <NavigationItem
            key={p.path}
            text={p.name}
            onClick={() => {
              setOpen(false);
              onPageChanged(p);
            }}
            mobile={true}
            selected={location.pathname.startsWith(p.path)}
          />
        ))}
        <LogoutButton
          variant={ButtonVariant.CONTAINED}
          size={ButtonSize.NORMAL}
          text={t("auth:logout")}
          onClick={onLogout}
        />
      </DropdownMenu>
    </>
  );
};

const PartnerPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  partner_name: PropTypes.string.isRequired,
});

MobileNavigation.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
  partners: PropTypes.arrayOf(PartnerPropType).isRequired,
  partner: PartnerPropType,
  onPartnerChanged: PropTypes.func.isRequired,
  onPageChanged: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};

MobileNavigation.defaultProps = {
  partner: null,
};

export default MobileNavigation;
