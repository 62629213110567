import i18n from "i18n/i18n";
import { IconName } from "common/basicComponents/Icon";
import ProductTypes from "constants/ProductTypes";

const TypeIcons = {
  [ProductTypes.HAT]: IconName.BEANIE,
  [ProductTypes.GLASSES]: IconName.GLASSES,
  [ProductTypes.GOGGLES]: IconName.GOGGLES,
  [ProductTypes.EARRINGS]: IconName.EARRINGS,
  [ProductTypes.DOOR]: IconName.DOOR,
  [ProductTypes.OBJECT]: IconName.OBJECT,
};

const typesToSelect = (typeList) => {
  return typeList
    .map((type) => ({
      code: type.type_name,
      label: i18n.t(`types:${type.type_name}`),
      value: type.id,
      icon: TypeIcons[type.type_name],
    }))
    .sort((a, b) => a.code.localeCompare(b.code));
};

export { typesToSelect };
