import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Typography, { TypographyVariant } from "./Typography";

export const ChipsVariant = {
  YELLOW: "yellow",
  GREY: "grey",
  VIOLET: "violet",
  GREEN: "green",
  ROSE: "rose",
  BLACK: "black",
  DARK_GREY: "darkGrey",
  LIGHT_GREY: "lightGrey",
};

const getChipsColors = (theme, variant) =>
  ({
    [ChipsVariant.YELLOW]: css`
      background: ${theme.colors.alert[50]};
      color: ${theme.colors.black};
    `,
    [ChipsVariant.GREY]: css`
      background: ${theme.colors.grey[200]};
      color: ${theme.colors.black};
    `,
    [ChipsVariant.VIOLET]: css`
      background: ${theme.colors.primary[50]};
      color: ${theme.colors.black};
    `,
    [ChipsVariant.GREEN]: css`
      background: ${theme.colors.success[50]};
      color: ${theme.colors.black};
    `,
    [ChipsVariant.ROSE]: css`
      background: ${theme.colors.secondary[50]};
      color: ${theme.colors.black};
    `,
    [ChipsVariant.BLACK]: css`
      background: ${theme.colors.black};
      color: ${theme.colors.white};
    `,
    [ChipsVariant.DARK_GREY]: css`
      background: ${theme.colors.grey[300]};
      color: ${theme.colors.black};
    `,
    [ChipsVariant.LIGHT_GREY]: css`
      background: ${theme.colors.grey[50]};
      color: ${theme.colors.black};
    `,
  }[variant]);

const StyledChips = styled.span`
  padding: 0.25rem 0.5rem;
  font-size: 0.625rem;
  line-height: 0.875rem;
  white-space: nowrap;
  display: inline-block;
  ${({ theme, variant }) => getChipsColors(theme, variant)}
`;

const Chips = ({ variant, text }) => {
  return (
    <StyledChips variant={variant}>
      <Typography variant={TypographyVariant.OVERLINE} text={text} tag="span" />
    </StyledChips>
  );
};

Chips.propTypes = {
  variant: PropTypes.oneOf(Object.values(ChipsVariant)),
  text: PropTypes.string.isRequired,
};

Chips.defaultProps = {
  variant: ChipsVariant.YELLOW,
};

export default Chips;
