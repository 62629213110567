import React, { useState } from "react";
import PropTypes from "prop-types";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styled, { useTheme } from "styled-components";
import Typography, { TypographyVariant, TypographyWeight } from "./Typography";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Button, { ButtonSize } from "./Button";
import { IconName } from "./Icon";

export const RangeDisplayMode = {
  AUTO: "auto",
  BUTTONS: "buttons",
  SLIDER: "slider",
};

const getMarks = (min, max, labelStep, currentValue) => {
  const marks = {};
  for (let i = min * 100; i <= max * 100; i += labelStep * 100) {
    const item = i / 100;
    marks[item] = (
      <Typography
        variant={TypographyVariant.OVERLINE}
        text={item.toString()}
        className={item <= currentValue ? "mark-active" : "mark-inactive"}
      />
    );
  }
  return marks;
};

const StyledLabel = styled.label`
  width: 100%;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey[500] : theme.colors.black};
  .rc-slider {
    height: 2rem;
    margin-left: 0.375rem;
    margin-top: 0.5rem;
    width: calc(100% - 0.75rem);
  }
  .rc-slider-disabled {
    background-color: transparent;
  }
  .mark-active {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.grey[500] : theme.colors.black};
  }
  .mark-inactive {
    color: ${({ theme }) => theme.colors.grey[400]};
  }
  .label-text {
    display: inline-block;
  }
`;

const RangeInput = styled.div`
  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
  width: 100%;
  padding: 0.5rem;
  background: ${({ theme }) => theme.colors.white};

  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const RangeInputValue = styled.div`
  background-color: ${({ theme }) => theme.colors.grey[100]};
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Range = ({
  label,
  min,
  max,
  step,
  longStep,
  markStep,
  value,
  onChange,
  disabled,
  displayMode,
  suffix,
}) => {
  const theme = useTheme();
  const { type: viewportType } = useViewport();
  const [pressed, setPressed] = useState(false);

  if (
    (displayMode === RangeDisplayMode.AUTO &&
      viewportType !== ViewportType.MOBILE) ||
    displayMode === RangeDisplayMode.SLIDER
  )
    return (
      <StyledLabel disabled={disabled}>
        {label && (
          <Typography
            variant={TypographyVariant.OVERLINE}
            text={label}
            tag="span"
            className="label-text"
          />
        )}
        <Slider
          dots
          min={min}
          max={max}
          step={step}
          marks={getMarks(min, max, markStep, value)}
          value={value}
          defaultValue={value}
          onChange={onChange}
          disabled={disabled}
          onBeforeChange={() => setPressed(true)}
          onAfterChange={() => setPressed(false)}
          handleStyle={{
            backgroundColor: disabled
              ? theme.colors.grey[400]
              : theme.colors.primary[400],
            borderColor: disabled
              ? theme.colors.grey[400]
              : theme.colors.primary[400],
            opacity: 1,
            width: "0.5rem",
            height: "0.5rem",
            marginTop: "-2px",
            boxShadow: pressed
              ? `0 0 0 5px ${theme.colors.primary[200]}`
              : "0 0 0 0",
            cursor: disabled ? "default" : "grab",
          }}
          trackStyle={{
            backgroundImage: disabled
              ? theme.colors.grey[400]
              : theme.colors.gradient[100],
          }}
          railStyle={{ backgroundColor: theme.colors.grey[200] }}
          dotStyle={{
            width: "0.375rem",
            height: "0.375rem",
            backgroundColor: "white",
            border: `1px solid ${theme.colors.grey[400]}`,
            bottom: "-1px",
          }}
          activeDotStyle={{
            width: "0.375rem",
            height: "0.375rem",
            backgroundColor: "white",
            border: `1px solid ${theme.colors.black}`,
            bottom: "-1px",
          }}
        />
      </StyledLabel>
    );

  return (
    <>
      <StyledLabel disabled={disabled}>
        {label && (
          <Typography
            variant={TypographyVariant.OVERLINE}
            text={label}
            tag="span"
          />
        )}
      </StyledLabel>
      <RangeInput>
        <Button
          icon={IconName.MINUS}
          size={ButtonSize.SMALL}
          onClick={() => onChange(value - step)}
          onPress={() => longStep && onChange(value - longStep)}
          disabled={value - step < min || disabled}
        />
        <RangeInputValue>
          <Typography
            variant={TypographyVariant.BODY2}
            weight={TypographyWeight.SEMIBOLD}
            text={value.toString() + suffix}
          />
        </RangeInputValue>
        <Button
          icon={IconName.PLUS}
          size={ButtonSize.SMALL}
          onClick={() => onChange(value + step)}
          onPress={() => longStep && onChange(value + longStep)}
          disabled={value + step > max || disabled}
        />
      </RangeInput>
    </>
  );
};

Range.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number,
  longStep: PropTypes.number,
  markStep: PropTypes.number,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  displayMode: PropTypes.string,
  suffix: PropTypes.string,
};

Range.defaultProps = {
  label: "",
  disabled: false,
  step: 1,
  longStep: null,
  markStep: 1,
  onChange: () => {},
  displayMode: RangeDisplayMode.AUTO,
  suffix: "",
};

export default Range;
