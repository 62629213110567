import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import Button, {
  ButtonVariant,
  ButtonSize,
  ButtonWidth,
} from "common/basicComponents/Button";
import { useViewport } from "utils/ViewportContext";
import Box from "common/basicComponents/Box";
import { IconName } from "common/basicComponents/Icon";
import Toggle from "common/basicComponents/Toggle";

const Card = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1.25rem;
`;

const Thumb = styled.img`
  height: 4.5rem;
  width: 9rem;
  object-fit: contain;
  align-self: center;
`;

const ThumbPlaceholder = styled.div`
  height: 4.5rem;
  width: 9rem;
  align-self: center;
  text-transform: uppercase;
  text-align: center;
  line-height: 3.25rem;
  color: ${({ theme }) => theme.colors.grey[400]};
`;

const DataCell = styled.div`
  padding: 1.25rem 0;
  border-top: ${({ theme, border }) =>
    border ? "1px solid " + theme.colors.grey[100] : "none"};

  display: flex;
  justify-content: space-between;
  ${({ column }) => column && "flex-direction: column;"}
`;

const FieldName = styled(Typography)`
  padding-right: 1rem;
`;

const Title = styled.div`
  text-align: center;
  padding: 1.25rem 0;
  word-wrap: break-word;
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
`;

const GreyTypography = styled(Typography)`
  color: ${({ theme }) => theme.colors.grey[500]};
`;

const VersionSmallCard = ({
  data,
  onDelete,
  onChangeActiveVersion,
  enableReorder,
  moveUpEnabled,
  onMoveUp,
  moveDownEnabled,
  onMoveDown,
}) => {
  const { type: viewportType } = useViewport();
  const { t } = useTranslation("totems");

  return (
    <Card viewport={viewportType}>
      {data.product_thumb_link ? (
        <Thumb src={data.product_thumb_link} />
      ) : (
        <ThumbPlaceholder>{t("common:notAdded")}</ThumbPlaceholder>
      )}
      <Title>
        <Typography
          variant={TypographyVariant.H5}
          weight={TypographyWeight.BOLD}
          text={data.product_name}
        />
        {data.product_name_owner !== data.product_name && (
          <GreyTypography
            variant={TypographyVariant.SUBTITLE2}
            text={data.product_name_owner}
          />
        )}
      </Title>
      <DataCell column>
        <FieldName
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("versions.version")}
        />
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.version_name}
        />
        {data.version_name_owner !== data.version_name && (
          <GreyTypography
            variant={TypographyVariant.CAPTION}
            weight={TypographyWeight.LIGHT}
            text={data.version_name_owner}
          />
        )}
      </DataCell>
      <DataCell border>
        <FieldName
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("versions.active")}
        />
        <Toggle
          id="totemVersionActive"
          name="totemVersionActive"
          value={data.version_active}
          onChange={() => onChangeActiveVersion(data)}
        />
      </DataCell>
      <Buttons>
        {enableReorder && (
          <>
            <Box padding="0 0.625rem 0 0">
              <Button
                icon={IconName.UP_ARROW}
                variant={ButtonVariant.OUTLINED}
                size={ButtonSize.SMALL}
                onClick={onMoveUp}
                disabled={!moveUpEnabled}
              />
            </Box>
            <Box padding="0 1.25rem 0 0">
              <Button
                icon={IconName.DOWN_ARROW}
                variant={ButtonVariant.OUTLINED}
                size={ButtonSize.SMALL}
                onClick={onMoveDown}
                disabled={!moveDownEnabled}
              />
            </Box>
            <Button
              text={t("common:button.delete")}
              variant={ButtonVariant.ERROR}
              size={ButtonSize.SMALL}
              onClick={() => onDelete(data)}
              width={ButtonWidth.GROW}
            />
          </>
        )}
      </Buttons>
    </Card>
  );
};

VersionSmallCard.propTypes = {
  data: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeActiveVersion: PropTypes.func.isRequired,
  enableReorder: PropTypes.bool,
  moveUpEnabled: PropTypes.bool,
  onMoveUp: PropTypes.func,
  moveDownEnabled: PropTypes.bool,
  onMoveDown: PropTypes.func,
};

VersionSmallCard.defaultProps = {
  enableReorder: false,
  moveUpEnabled: false,
  onMoveUp: () => {},
  moveDownEnabled: false,
  onMoveDown: () => {},
};

export default VersionSmallCard;
