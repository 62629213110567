import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.core.css";

import EditorToolbar from "./EditorToolbar";
import Typography, { TypographyVariant, TypographyWeight } from "../Typography";
import Icon, { IconName } from "../Icon";

const EditorContainer = styled.div`
  height: 16.5rem;
  position: relative;
`;

const EditorText = styled.div`
  height: 100%;
  overflow-y: scroll;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
  color: ${({ theme }) => theme.colors.gradient[20]} 1;
  & .ql-editor::before {
    color: ${({ theme }) => theme.colors.black};
    font-style: normal;
  }

  ${({ theme, error }) =>
    error &&
    `
      border: 1px solid ${theme.colors.secondary[600]};
      color: ${theme.colors.black};
    `}

  ${({ theme, disabled }) =>
    disabled &&
    `
      border: 1px solid ${theme.colors.grey[300]};
      background: ${theme.colors.grey[50]};
      color: ${theme.colors.grey[500]};
      & .ql-editor::before {
        color: ${theme.colors.grey[500]};
      }
  `}
`;

const CaptionContainer = styled.div`
  min-height: 1rem;
`;

const ErrorIcon = styled(Icon)`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  fill: ${({ theme }) => theme.colors.secondary[600]};
`;

const TextEditor = ({ id, error, disabled, placeholder, value, onChange }) => {
  const quillRef = useRef();
  const [format, setFormat] = useState({});

  useEffect(() => {
    if (!quillRef.current) return;
    const editor = quillRef.current.editor;
    editor.on("selection-change", (range, _, source) => {
      if (source === "api" || !range) return;
      setFormat(editor.getFormat());
    });
  }, []);

  return (
    <div id={id}>
      <EditorToolbar
        id="toolbar"
        currentFormat={format}
        onChange={(param, value) => {
          if (!quillRef.current) return;
          const editor = quillRef.current?.editor;
          editor.format(param, value);
          setFormat(editor.getFormat());
        }}
        disabled={disabled}
      />
      <EditorContainer>
        <EditorText id="text-editor" disabled={disabled} error={error}>
          <ReactQuill
            ref={quillRef}
            modules={{
              toolbar: "#toolbar",
            }}
            theme={false}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onChange={onChange}
          />
        </EditorText>
        {error && <ErrorIcon name={IconName.ERROR} size="2rem" />}
      </EditorContainer>
      <CaptionContainer>
        {error && (
          <Typography
            variant={TypographyVariant.CAPTION}
            weight={TypographyWeight.LIGHT}
            text={error}
          />
        )}
      </CaptionContainer>
    </div>
  );
};

TextEditor.propTypes = {
  id: PropTypes.string.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

TextEditor.defaultProps = {
  error: "",
  disabled: false,
  placeholder: null,
  value: undefined,
  onChange: () => {},
};

export default TextEditor;
