import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";

const ViewportType = {
  MOBILE: 0,
  TABLET: 1,
  DESKTOP: 2,
};

const ViewportMinBreakpoints = {
  MOBILE: 0,
  TABLET: 620,
  DESKTOP: 992,
};

const ViewportContext = createContext({});

const ViewportProvider = ({ children }) => {
  const [type, setType] = useState(ViewportType.DESKTOP);
  const value = useMemo(
    () => ({
      type,
    }),
    [type]
  );

  const handleWindowResize = () => {
    const width = Math.min(window.screen?.width, window.innerWidth);
    if (width >= ViewportMinBreakpoints.DESKTOP) setType(ViewportType.DESKTOP);
    else if (width >= ViewportMinBreakpoints.TABLET)
      setType(ViewportType.TABLET);
    else setType(ViewportType.MOBILE);
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <ViewportContext.Provider value={value}>
      {children}
    </ViewportContext.Provider>
  );
};

ViewportProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useViewport = () => {
  const { type } = useContext(ViewportContext);
  return { type };
};

export { useViewport, ViewportProvider, ViewportType, ViewportMinBreakpoints };
