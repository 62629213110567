import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Dialog from "common/basicComponents/Dialog";
import Typography, {
  TypographyVariant,
  TypographyWeight,
} from "common/basicComponents/Typography";
import { useTranslation } from "react-i18next";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { ButtonWidth } from "common/basicComponents/Button";
import { Formik, Form } from "formik";
import Box from "common/basicComponents/Box";
import FormSelectField from "common/formFields/FormSelectField";
import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import { ButtonType } from "common/basicComponents/Button";
import ValidationSchema from "./SetValidationSchema";
import { useViewport } from "utils/ViewportContext";
import { ViewportType } from "utils/ViewportContext";
import FormToggleField, {
  ToggleVariant,
} from "common/formFields/FormToggleField";
import { productsWithVersionsToSelect } from "utils/ProductUtils";

const Buttons = styled.div`
  display: flex;
  flex-direction: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "row" : "column-reverse"};
  width: 100%;
  gap: ${({ viewport }) =>
    viewport > ViewportType.MOBILE ? "2rem" : "1.25rem"};
  margin-top: 2.5rem;
`;

const EditSetDialog = ({
  open,
  set,
  sets,
  onSave,
  onClose,
  allProducts,
  domainOptions,
}) => {
  const { t } = useTranslation("sets");
  const { type: viewportType } = useViewport();
  const [primaryVersionOptions, setPrimaryVersionOptions] = useState([]);
  const [secondaryVersionOptions, setSecondaryVersionOptions] = useState([]);
  const [hideUsed, setHideUsed] = useState(false);
  const [unassignedProductsWithVersions, setUnassignedProductsWithVersions] =
    useState([]);
  const [visibleOptions, setVisibleOptions] = useState([]);

  useEffect(() => {
    setVisibleOptions(hideUsed ? unassignedProductsWithVersions : allProducts);
  }, [allProducts, hideUsed, unassignedProductsWithVersions]);

  useEffect(() => {
    if (!set?.primary_product_id) return;
    const primaryProduct = visibleOptions.find((p) => p.value === set.primary_product_id);
    const secondaryProduct = visibleOptions.find((p) => p.value === set.secondary_product_id);
    if (primaryProduct) {
      setPrimaryVersionOptions(primaryProduct.versions);
    }
    if (secondaryProduct) {
      setSecondaryVersionOptions(secondaryProduct.versions);
    }
  }, [set, visibleOptions, allProducts, hideUsed]);

  useEffect(() => {
    if (!allProducts) return;
    if (!sets) return;
    setUnassignedProductsWithVersions(
      allProducts
        .map((product) => ({
          ...product,
          versions: product.versions.filter(
            (version) =>
              !sets.map((item) => item.partners_primary_version_id).includes(version.partners_version_id)
              && !sets.map((item) => item.partners_secondary_product_version_id).includes(version.partners_version_id))
        }))
        .filter((product) => product.versions.length > 0)
    );
  }, [allProducts, sets]);

  const onSubmit = (values, form) => {
    form.setSubmitting(true);
    onSave(
      {
        id: set?.set_id,
        setName: values.setName,
        primaryVersionId: values.primaryVersion.value,
        primaryVersionAlias: values.primaryVersionAlias,
        secondaryVersionId: values.secondaryVersion.value,
        secondaryVersionAlias: values.secondaryVersionAlias,
        domainId: values.domain.value,
        webLinkUrl: values.webLinkUrl?.trim(),
      },
      form
    );
    form.setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Box padding="0 0 2.5rem">
        <Typography
          variant={TypographyVariant.H4}
          weight={TypographyWeight.BOLD}
          text={set?.set_id ? t("editSetTitle") : t("addSetTitle")}
        />
      </Box>
      <Formik
        initialValues={{
          primaryVersion: {
            label: set?.primary_version_name || "",
            value: set?.partners_primary_version_id || "",
          },
          primaryProduct: {
            label: set?.primary_product_name || "",
            value: set?.primary_product_id || "",
          },
          secondaryVersion: {
            label: set?.secondary_version_name || "",
            value: set?.partners_secondary_product_version_id || "",
          },
          secondaryProduct: {
            label: set?.secondary_product_name || "",
            value: set?.secondary_product_id || "",
          },
          domain: {
            label: set?.domain_name || "",
            value: set?.domain_id || "",
          },
          webLinkUrl: set?.web_link_url,
          hideUsed: hideUsed,
          setName: set?.set_name,
          primaryVersionAlias: set?.primary_product_alias,
          secondaryVersionAlias: set?.secondary_product_alias
        }}
        // validationSchema={ValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form noValidate>
            <FormToggleField
              id="hideUsed"
              name="hideUsed"
              label={t("hideUsed")}
              align="center"
              variant={ToggleVariant.SWITCH_COLOR}
              switchWidth="47%"
              onChange={(hideUsed) => setHideUsed(hideUsed)}
            />
            <Box width="100%">
              <FormSelectField
                name="primaryProduct"
                id="primaryProduct"
                label={t("primaryProduct")}
                options={productsWithVersionsToSelect(visibleOptions)}
                disabled={isSubmitting}
                onChange={(product) => {
                  setFieldValue("primaryVersion", { label: "", value: "" });
                  setPrimaryVersionOptions(product.versions);
                }}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormSelectField
                name="primaryVersion"
                id="primaryVersion"
                label={t("primaryVersion")}
                options={primaryVersionOptions}
                disabled={isSubmitting}
              />
            </Box>
            <Box width="100%">
              <FormSelectField
                name="secondaryProduct"
                id="secondaryProduct"
                label={t("secondaryProduct")}
                options={productsWithVersionsToSelect(visibleOptions)}
                disabled={isSubmitting}
                onChange={(product) => {
                  setFieldValue("secondaryVersion", { label: "", value: "" });
                  setSecondaryVersionOptions(product.versions);
                }}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormSelectField
                name="secondaryVersion"
                id="secondaryVersion"
                label={t("secondaryVersion")}
                options={secondaryVersionOptions}
                disabled={isSubmitting}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormSelectField
                name="domain"
                id="domain"
                label={t("domain")}
                options={domainOptions}
                disabled={isSubmitting}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormInputField
                type={FormInputFieldType.TEXT}
                name="webLinkUrl"
                id="webLinkUrl"
                label={t("webLinkUrl")}
                disabled={isSubmitting}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormInputField
                type={FormInputFieldType.TEXT}
                name="setName"
                id="setName"
                label={t("setName")}
                disabled={isSubmitting}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormInputField
                type={FormInputFieldType.TEXT}
                name="primaryVersionAlias"
                id="primaryVersionAlias"
                label={t("primaryVersionAlias")}
                disabled={isSubmitting}
              />
            </Box>
            <Box padding="0.5rem 0 0" width="100%">
              <FormInputField
                type={FormInputFieldType.TEXT}
                name="secondaryVersionAlias"
                id="secondaryVersionAlias"
                label={t("secondaryVersionAlias")}
                disabled={isSubmitting}
              />
            </Box>
            <Buttons viewport={viewportType}>
              <Button
                variant={ButtonVariant.OUTLINED}
                text={t("common:button.cancel")}
                width={ButtonWidth.GROW}
                onClick={onClose}
                disabled={isSubmitting}
              />
              <Button
                variant={ButtonVariant.GREEN}
                text={t("common:button.save")}
                width={ButtonWidth.GROW}
                type={ButtonType.SUBMIT}
                isLoading={isSubmitting}
              />
            </Buttons>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

EditSetDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  set: PropTypes.object,
  sets: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  domainOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  allProducts: PropTypes.array.isRequired,
};

EditSetDialog.defaultProps = {
  set: null,
};

export default EditSetDialog;
