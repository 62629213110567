import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Typography from "common/basicComponents/Typography";
import { TypographyVariant } from "common/basicComponents/Typography";
import { TypographyWeight } from "common/basicComponents/Typography";
import Box from "common/basicComponents/Box";
import Button from "common/basicComponents/Button";
import { useTranslation } from "react-i18next";
import { ButtonSize } from "common/basicComponents/Button";
import { checkIfTotemActive } from "utils/TotemUtils";
import { formatTimestamp } from "utils/DateTimeUtils";
import Chips from "common/basicComponents/Chips";
import { ChipsVariant } from "common/basicComponents/Chips";
import { ViewportMinBreakpoints } from "utils/ViewportContext";
import { getTotemStatusShort } from "utils/TotemUtils";

const Card = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  overflow: hidden;
`;

const DataCell = styled.div`
  padding: ${({ row }) => (row ? "1.25rem 0" : "1rem 0")};
  border-top: ${({ theme, withoutBorder }) =>
    withoutBorder ? null : "1px solid " + theme.colors.grey[100]};
  display: flex;
  justify-content: space-between;
  ${({ row }) => !row && "flex-direction: column;"}
  flex-grow: 1;
  flex-basis: 0;
`;

const StatusDataCell = styled(DataCell)`
  order: 1;
  @media screen and (min-width: ${ViewportMinBreakpoints.TABLET}px) {
    order: 0;
  }
`;

const DatesBox = styled(Box)`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${ViewportMinBreakpoints.TABLET}px) {
    flex-direction: row;
    gap: 1.25rem;
  }
`;

const ButtonBox = styled(Box)`
  order: 2;
`;

const TotemCard = ({ data, onEdit }) => {
  const { t } = useTranslation("totems");
  const isActive = checkIfTotemActive(data);
  const statusShort = getTotemStatusShort(data);

  return (
    <Card>
      <DataCell withoutBorder>
        <Typography
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("totemName")}
        />
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.totem_name || ""}
        />
      </DataCell>
      <DataCell>
        <Typography
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("totemDeviceName")}
        />
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.totem_device_name}
        />
      </DataCell>
      <StatusDataCell row>
        <Typography
          variant={TypographyVariant.SUBTITLE2}
          weight={TypographyWeight.BOLD}
          text={t("status")}
        />
        <Chips
          text={statusShort}
          variant={isActive ? ChipsVariant.GREEN : ChipsVariant.ROSE}
        />
      </StatusDataCell>
      <DatesBox>
        <DataCell>
          <Typography
            variant={TypographyVariant.SUBTITLE2}
            weight={TypographyWeight.BOLD}
            text={t("totemExpirationDate")}
          />
          <Typography
            variant={TypographyVariant.BODY2}
            text={formatTimestamp(data.totem_expiration_date)}
          />
        </DataCell>
        <DataCell>
          <Typography
            variant={TypographyVariant.SUBTITLE2}
            weight={TypographyWeight.BOLD}
            text={t("totemLastActivity")}
          />
          <Typography
            variant={TypographyVariant.BODY2}
            text={formatTimestamp(data.totem_last_activity)}
          />
        </DataCell>
      </DatesBox>
      <ButtonBox width="100%" padding="1.25rem 0 0 0">
        <Button
          width="100%"
          text={t("common:button.edit")}
          size={ButtonSize.SMALL}
          onClick={() => onEdit(data)}
        />
      </ButtonBox>
    </Card>
  );
};

TotemCard.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default TotemCard;
