import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import styled, { css } from "styled-components";
import { ViewportType } from "utils/ViewportContext";
import { useViewport } from "utils/ViewportContext";
import { IconName } from "common/basicComponents/Icon";
import { ButtonWidth } from "common/basicComponents/Button";
import { checkIfTotemDeletable } from "utils/TotemUtils";
import { LoaderType } from "../EditTotem";

const getHeaderStyle = (viewport) =>
  ({
    [ViewportType.DESKTOP]: css`
      gap: 1.25rem;
    `,
    [ViewportType.TABLET]: css`
      gap: 1.25rem;
      flex-grow: 1;
    `,
    [ViewportType.MOBILE]: css`
      gap: 0.625rem;
      flex-grow: 1;
    `,
  }[viewport]);

const HeaderButtons = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ viewport }) => getHeaderStyle(viewport)}
`;

const EditHeaderButtons = ({
  formLoader,
  activeTab,
  onCopy,
  onDelete,
  onBannerAdd,
  onVersionAdd,
}) => {
  const { t } = useTranslation("totems");
  const { type: viewportType } = useViewport();
  const { totem } = useSelector((state) => state.totems);

  const [totemDeletable, setTotemDeletable] = useState(false);

  useEffect(() => {
    if (totem) {
      setTotemDeletable(checkIfTotemDeletable(totem));
    }
  }, [totem]);

  let buttons = <></>;

  switch (activeTab) {
    case 0:
      buttons = (
        <>
          {totemDeletable && viewportType === ViewportType.MOBILE && (
            <Button
              variant={ButtonVariant.ERROR}
              icon={IconName.TRASH_OUTLINED}
              onClick={onDelete}
              disabled={formLoader !== LoaderType.INITIAL}
            />
          )}
          <Button
            variant={ButtonVariant.OUTLINED}
            text={
              viewportType > ViewportType.TABLET
                ? t("editButtons.copy")
                : t("editButtons.copyShort")
            }
            onClick={onCopy}
            width={viewportType > ViewportType.TABLET ? null : ButtonWidth.GROW}
            minWidth={viewportType > ViewportType.TABLET ? "12rem" : null}
            disabled={formLoader !== LoaderType.INITIAL}
            isLoading={formLoader === LoaderType.COPY}
          />
          {totemDeletable && viewportType !== ViewportType.MOBILE && (
            <Button
              variant={ButtonVariant.ERROR}
              text={t("editButtons.delete")}
              onClick={onDelete}
              width={
                viewportType > ViewportType.TABLET ? null : ButtonWidth.GROW
              }
              minWidth={viewportType > ViewportType.TABLET ? "6rem" : null}
              disabled={formLoader !== LoaderType.INITIAL}
              isLoading={formLoader === LoaderType.DELETE}
            />
          )}
          <Button
            type="submit"
            variant={ButtonVariant.GREEN}
            text={t("editButtons.save")}
            width={viewportType > ViewportType.TABLET ? null : ButtonWidth.GROW}
            minWidth={viewportType > ViewportType.TABLET ? "6rem" : null}
            disabled={formLoader !== LoaderType.INITIAL}
            isLoading={formLoader === LoaderType.SAVE}
            form="totem-general-info-form"
          />
        </>
      );
      break;
    case 1:
      buttons = (
        <Button
          variant={ButtonVariant.GREEN}
          text={t("editButtons.addBanner")}
          icon={IconName.PLUS}
          onClick={onBannerAdd}
          width={viewportType > ViewportType.TABLET ? null : ButtonWidth.GROW}
          minWidth={viewportType > ViewportType.TABLET ? "14rem" : null}
          disabled={formLoader !== LoaderType.INITIAL}
          isLoading={formLoader === LoaderType.BANNER_ADD}
        />
      );
      break;
    case 2:
      buttons = (
        <Button
          variant={ButtonVariant.GREEN}
          text={t("editButtons.addVersion")}
          icon={IconName.PLUS}
          onClick={onVersionAdd}
          width={viewportType > ViewportType.TABLET ? null : ButtonWidth.GROW}
          minWidth={viewportType > ViewportType.TABLET ? "14rem" : null}
          disabled={formLoader !== LoaderType.INITIAL}
          isLoading={formLoader === LoaderType.VERSION}
        />
      );
      break;
    default:
      break;
  }
  return <HeaderButtons viewport={viewportType}>{buttons}</HeaderButtons>;
};

EditHeaderButtons.propTypes = {
  formLoader: PropTypes.string.isRequired,
  activeTab: PropTypes.number.isRequired,
  onCopy: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onBannerAdd: PropTypes.func.isRequired,
  onVersionAdd: PropTypes.func.isRequired,
};

export default EditHeaderButtons;
