import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import { IconName } from "common/basicComponents/Icon";
import { TypographyWeight } from "common/basicComponents/Typography";
import { getProductTags } from "utils/ProductUtils";
import Chips from "common/basicComponents/Chips";

const Cell = styled.td`
  height: 4.5rem;
  padding: 0 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const TextCell = styled(Cell)`
  width: 38%;
  min-width: 7.5rem;
`;

const TagsCell = styled(Cell)`
  width: 20%;
  & > span {
    margin: 0 0.125rem;
  }
`;

const ThumbCell = styled(Cell)`
  width: 0;
  min-width: 9rem;
`;

const ButtonsCell = styled(Cell)`
  width: 0;
  min-width: 8.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
`;

const Thumb = styled.img`
  height: 2.5rem;
  width: 5rem;
  object-fit: contain;
`;

const ThumbPlaceholder = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[400]};
`;

const ProductRow = ({
  data,
  user,
  partner,
  onEdit,
  onMoveUp,
  moveUpEnabled,
  onMoveDown,
  moveDownEnabled,
}) => {
  const { t } = useTranslation("common");
  const externalProduct =
    (!partner.main_partner && data.from_main_partner) ||
    data.from_other_partner;

  return (
    <>
      <ThumbCell centered={true} className="show-tooltip">
        {data.product_thumb_link ? (
          <Thumb src={data.product_thumb_link} />
        ) : (
          <ThumbPlaceholder>{t("notAdded")}</ThumbPlaceholder>
        )}
      </ThumbCell>
      <TextCell className="show-tooltip">
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.product_name}
          tag="span"
        />
      </TextCell>
      <TagsCell className="show-tooltip">
        {getProductTags(user, partner, data).map(
          (tag) =>
            tag.show && (
              <Chips key={tag.text} variant={tag.variant} text={tag.text} />
            )
        )}
      </TagsCell>
      <Cell centered={true} className="show-tooltip">
        <Typography
          variant={TypographyVariant.BODY2}
          text={
            externalProduct ? data.assigned_versions : data.published_versions
          }
          tag="span"
        />
        <Typography
          variant={TypographyVariant.BODY2}
          weight={TypographyWeight.BOLD}
          text={` / ${
            externalProduct ? data.published_versions : data.all_versions
          }`}
          tag="span"
        />
      </Cell>
      <ButtonsCell centered={true}>
        <ButtonsContainer>
          <Button
            icon={IconName.EDIT}
            variant={ButtonVariant.CONTAINED}
            size={ButtonSize.XSMALL}
            onClick={() => onEdit(data)}
          />
          <Button
            icon={IconName.UP_ARROW}
            variant={ButtonVariant.OUTLINED}
            size={ButtonSize.XSMALL}
            onClick={onMoveUp}
            disabled={!moveUpEnabled}
          />
          <Button
            icon={IconName.DOWN_ARROW}
            variant={ButtonVariant.OUTLINED}
            size={ButtonSize.XSMALL}
            onClick={onMoveDown}
            disabled={!moveDownEnabled}
          />
        </ButtonsContainer>
      </ButtonsCell>
    </>
  );
};

ProductRow.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  partner: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  moveUpEnabled: PropTypes.bool.isRequired,
  moveDownEnabled: PropTypes.bool.isRequired,
};

export default ProductRow;
