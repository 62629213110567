import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { useSelector } from "react-redux";
import _ from "lodash";

import FormInputField, {
  FormInputFieldType,
} from "common/formFields/FormInputField";
import FormSelectField from "common/formFields/FormSelectField";
import Button, {
  ButtonType,
  ButtonSize,
  ButtonVariant,
} from "common/basicComponents/Button";
import {
  useViewport,
  ViewportType,
  ViewportMinBreakpoints,
} from "utils/ViewportContext";
import Box from "common/basicComponents/Box";
import { IconName } from "common/basicComponents/Icon";
import Typography, {
  TypographyWeight,
  TypographyVariant,
} from "common/basicComponents/Typography";
import { productsWithVersionsToSelect } from "utils/ProductUtils";
import FilterBadge from "common/basicComponents/FilterBadge";

const MiniContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  ${({ viewport }) =>
    viewport > ViewportType.MOBILE && "margin-bottom: 0.5rem;"}

  & > *:not(button) {
    max-height: 2.75rem;
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.white};
  z-index: 3;
  border-bottom: 1px solid ${({ theme }) => theme.colors.black};

  display: ${({ open }) => (open ? "flex" : "none")};
  padding: 2.5rem 1.25rem;
  height: 100%;
  overflow: hidden;

  flex-direction: column;
  gap: 2.5rem;
  @media screen and (min-width: ${ViewportMinBreakpoints.TABLET}px) {
    padding: 2.5rem 2rem;
  }
`;

const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const MobileSearchForm = () => {
  const { t } = useTranslation("links");
  const { type: viewportType } = useViewport();
  const { resetForm, isSubmitting, submitForm } = useFormikContext();
  const { allProducts } = useSelector((state) => state.products);
  const [open, setOpen] = useState(false);

  return (
    <>
      <MiniContainer viewport={viewportType}>
        <Box
          width={viewportType > ViewportType.MOBILE ? "50%" : null}
          grow={viewportType === ViewportType.MOBILE ? 1 : 0}
          padding={
            viewportType > ViewportType.MOBILE ? "0 1rem 0 0" : "0 0.25rem 0 0"
          }
        >
          <FormInputField
            id="linkSearchMini"
            name="linkSearch"
            placeholder={_.capitalize(t("search"))}
            disabled={isSubmitting}
            type={FormInputFieldType.SEARCH}
          />
        </Box>
        <Box
          width={viewportType > ViewportType.MOBILE ? "50%" : null}
          padding={
            viewportType > ViewportType.MOBILE ? "0 0 0 1rem" : "0 0 0 0.25rem"
          }
        >
          <Button
            type={ButtonType.BUTTON}
            variant={ButtonVariant.OUTLINED}
            width="100%"
            onClick={() => setOpen(true)}
            text={
              viewportType > ViewportType.MOBILE && t("common:button.filters")
            }
            icon={IconName.FILTERS}
            badge={<FilterBadge />}
          />
        </Box>
        <input style={{ display: "none" }} type="submit" />
      </MiniContainer>
      <Container open={open}>
        <Header>
          <Typography
            variant={TypographyVariant.H4}
            weight={TypographyWeight.BOLD}
            text={t("filters")}
          />
          <Button
            type={ButtonType.BUTTON}
            variant={ButtonVariant.TEXT}
            onClick={() => setOpen(false)}
            icon={IconName.CLOSE}
            size={ButtonSize.LARGE}
          />
        </Header>
        <Fields>
          <FormInputField
            id="linkSearch"
            name="linkSearch"
            label={t("webLinkUrl")}
            disabled={isSubmitting}
            type={FormInputFieldType.TEXT}
          />
          <FormSelectField
            id="product"
            name="product"
            label={t("product")}
            disabled={isSubmitting}
            options={productsWithVersionsToSelect(allProducts)}
          />
        </Fields>
        <Buttons>
          <Button
            type={ButtonType.BUTTON}
            disabled={isSubmitting}
            text={t("common:button.search")}
            onClick={() => {
              setOpen(false);
              submitForm();
            }}
          />
          <Button
            type={ButtonType.BUTTON}
            variant={ButtonVariant.OUTLINED}
            onClick={() => {
              resetForm();
              setOpen(false);
              submitForm();
            }}
            text={t("common:button.clear")}
          />
        </Buttons>
      </Container>
    </>
  );
};

export default MobileSearchForm;
