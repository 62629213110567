import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Accordion from "common/basicComponents/Accordion";
import Box from "common/basicComponents/Box";
import { useViewport, ViewportType } from "utils/ViewportContext";
import FormColorField from "common/formFields/FormColorField";

const AccordionContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 1.25rem 0.625rem;
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`;

const ColorsForm = ({ disabled }) => {
  const { t } = useTranslation("totems");
  const { type: viewportType } = useViewport();
  const [accordionHasErrors, setAccordionHasErrors] = useState(false);
  const { values } = useFormikContext();

  useEffect(() => {
    if (accordionHasErrors) setAccordionHasErrors(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Accordion
      text={t("colors.title")}
      defaultOpen
      forceOpen={accordionHasErrors}
    >
      <AccordionContent>
        <Box
          width={viewportType === ViewportType.MOBILE ? "100%" : "50%"}
          padding="0 0.625rem"
        >
          <FormColorField
            id={"fontColor"}
            name={"fontColor"}
            label={t("colors.fontColor")}
            disabled={disabled}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          width={viewportType === ViewportType.MOBILE ? "100%" : "50%"}
          padding="0 0.625rem"
        >
          <FormColorField
            id={"backgroundColor"}
            name={"backgroundColor"}
            label={t("colors.backgroundColor")}
            disabled={disabled}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          width={viewportType === ViewportType.MOBILE ? "100%" : "50%"}
          padding="0 0.625rem"
        >
          <FormColorField
            id={"progressBarBackground"}
            name={"progressBarBackground"}
            label={t("colors.progressBarBackground")}
            disabled={disabled}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
        <Box
          width={viewportType === ViewportType.MOBILE ? "100%" : "50%"}
          padding="0 0.625rem"
        >
          <FormColorField
            id={"progressBarFront"}
            name={"progressBarFront"}
            label={t("colors.progressBarFront")}
            disabled={disabled}
            onError={() => setAccordionHasErrors(true)}
          />
        </Box>
      </AccordionContent>
    </Accordion>
  );
};

ColorsForm.propTypes = {
  disabled: PropTypes.bool,
};

ColorsForm.defaultProps = {
  disabled: false,
};

export default ColorsForm;
