import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Typography from "common/basicComponents/Typography";
import { useViewport, ViewportType } from "utils/ViewportContext";
import { TypographyWeight } from "common/basicComponents/Typography";
import { TypographyVariant } from "common/basicComponents/Typography";
import { ViewportMinBreakpoints } from "utils/ViewportContext";

const HeaderContainer = styled.div`
  top: 0;
  width: 100%;
  padding: ${({ type }) => (type === ViewportType.MOBILE ? "1.25rem" : "2rem")};
  padding-bottom: 1.25rem;
  ${({ biggerMargin }) =>
    biggerMargin &&
    css`
      @media screen and (min-width: ${ViewportMinBreakpoints.DESKTOP}px) {
        margin-bottom: 0.625rem;
      }
    `}
`;

const SubtitlesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 0.25rem;
  padding-bottom: 0.5rem;
`;

const SubtitleContainer = styled.div`
  display: flex;
  gap: 0.25rem;
`;

const HeaderTopSection = styled.section`
  display: flex;
  justify-content: space-between;
`;
const ButtonContainer = styled.div`
  padding: 0.5rem 0;
  button {
    width: 100%;
  }
  ${({ biggerMargin }) =>
    biggerMargin &&
    css`
      margin-top: 0.75rem;
      @media screen and (min-width: ${ViewportMinBreakpoints.TABLET}px) {
        margin-top: 0.5rem;
      }
    `}
`;
const Header = ({ children, title, subtitles, button, className }) => {
  const { type } = useViewport();

  return (
    <HeaderContainer
      type={type}
      className={className}
      biggerMargin={!children && (!subtitles || subtitles.length === 0)}
    >
      <HeaderTopSection>
        <Typography
          variant={
            type === ViewportType.DESKTOP
              ? TypographyVariant.H3
              : TypographyVariant.H4
          }
          text={title}
          tag="h1"
          weight={TypographyWeight.BOLD}
        />
        {type === ViewportType.DESKTOP && button}
      </HeaderTopSection>
      {subtitles?.length > 0 && (
        <SubtitlesContainer>
          {subtitles.map((subtitle) => (
            <SubtitleContainer key={subtitle}>
              {subtitle.map((part, idx) => (
                <Typography
                  key={part}
                  variant={TypographyVariant.OVERLINE}
                  text={part}
                  tag="span"
                  weight={
                    idx === 0 ? TypographyWeight.LIGHT : TypographyWeight.BOLD
                  }
                />
              ))}
            </SubtitleContainer>
          ))}
        </SubtitlesContainer>
      )}
      {children}
      {type !== ViewportType.DESKTOP && button && (
        <ButtonContainer
          biggerMargin={!children && (!subtitles || subtitles.length === 0)}
        >
          {button}
        </ButtonContainer>
      )}
    </HeaderContainer>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitles: PropTypes.arrayOf(
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ),
  button: PropTypes.node,
  className: PropTypes.string,
};

Header.defaultProps = {
  children: null,
  subtitles: [],
  button: null,
  className: null,
};

export default Header;
