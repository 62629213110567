import _ from "lodash";
import i18n from "i18n/i18n";

const errorsTranslationNamespace = "common:backendErrors.validation.";

export const translate = (error) => {
  if (typeof error === "string") return i18n.t(error);
  return i18n.t(error.code, error.params);
};

export const constantToSelect = (data, translationNamespace) => {
  const result = [];
  Object.keys(data).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(data, key) && data[key] != null) {
      const label = !_.isEmpty(translationNamespace)
        ? i18n.t(`${translationNamespace}.${data[key]}`)
        : data[key];

      result.push({
        value: data[key],
        label,
      });
    }
  });
  return result;
};

export const setFormError = (form, errors) => {
  form.setErrors(parseFormErrors(errors));
  scrollToError(errors);
};

export const parseFormErrors = (errors) => {
  const formErrors = {};
  Object.keys(errors).forEach((name) => {
    if (Object.prototype.hasOwnProperty.call(errors, name)) {
      const re = /\.([0-9]+)\./g;
      const formName = name.replace(re, "[$1].");
      formErrors[formName] = `${errorsTranslationNamespace}${errors[name][0]}`;
    }
  });
  return formErrors;
};

export const scrollToError = (errors) => {
  const keys = Object.keys(errors);
  let element = null;
  if (keys[0] !== "versions") {
    element = document.getElementsByName(keys[0])[0];
  } else if (typeof errors[keys[0]] === "object") {
    const versions = errors[keys[0]];
    const versionsKeys = Object.keys(versions);
    if (!_.isEmpty(versionsKeys)) {
      const number = versionsKeys[0];
      const field = Object.keys(versions[number])[0];
      element = document.getElementsByName(`versions[${number}].${field}`)[0];
    }
  }
  if (!_.isEmpty(element)) {
    element?.scrollIntoView({ behavior: "smooth", block: "end" });
  }
};
