import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Typography, { TypographyVariant, TypographyWeight } from "./Typography";
import Button, { ButtonSize, ButtonType, ButtonVariant } from "./Button";
import { IconName } from "./Icon";

export const InputSize = {
  NORMAL: "normal",
  LARGE: "large",
};

export const InputType = {
  TEXT: "text",
  NUMBER: "number",
};

export const InputAlign = {
  LEFT: "left",
  RIGHT: "right",
};

export const getInputColors = (theme, error, pseudo = "default") => {
  if (error) {
    return css`
      border: 1px solid ${theme.colors.secondary[600]};
      background: ${theme.colors.white};
      color: ${theme.colors.black};
      outline: none;
      caret-color: ${theme.colors.secondary[200]};
    `;
  }
  return {
    default: css`
      background: ${theme.colors.white};
      border: 1px solid;
      border-image: ${theme.colors.gradient[20]} 1;
      color: ${theme.colors.black};
      outline: none;
      caret-color: ${theme.colors.black};
    `,
    disabled: css`
      border: 1px solid ${theme.colors.grey[300]};
      background: ${theme.colors.grey[50]};
      color: ${theme.colors.grey[500]};
    `,
    readOnly: css`
      background: ${theme.colors.white};
      border-image: ${theme.colors.gradient[20]} 1;
      color: ${theme.colors.black};
    `,
    hover: css`
      background: ${theme.colors.white};
      border-image: ${theme.colors.gradient[60]} 1;
      color: ${theme.colors.black};
    `,
    active: css`
      background: ${theme.colors.white};
      border-image: ${theme.colors.gradient[100]} 1;
      color: ${theme.colors.black};
    `,
  }[pseudo];
};

export const getInputStyle = (size, multiline, rows) =>
  ({
    [InputSize.NORMAL]: css`
      line-height: 1.25rem;
      font-size: 0.875rem;
      padding: 0.5rem 0.75rem;
      height: ${multiline ? `${2.5 + ((rows || 1) - 1) * 1.25}rem` : "2.5rem"};
    `,
    [InputSize.LARGE]: css`
      line-height: 1.5rem;
      font-size: 1rem;
      padding: 0.625rem 0.75rem;
      height: ${multiline ? `${3 + ((rows || 1) - 1) * 1.5}rem` : "3rem"};
    `,
  }[size]);

const StyledInput = styled.input`
  ${({ theme, error }) => getInputColors(theme, error, "default")};
  ${({ size, multiline, rows }) => getInputStyle(size, multiline, rows)}

  &::placeholder {
    color: ${({ theme }) => theme.colors.secondary[200]};
    opacity: 1;
  }

  display: block;
  width: 100%;
  margin: 0.25rem 0;
  resize: none;

  &:hover {
    ${({ theme, error }) => getInputColors(theme, error, "hover")};
  }

  &:focus,
  &:focus-visible {
    ${({ theme, error }) => getInputColors(theme, error, "active")}
  }

  &:disabled {
    ${({ theme, error, readOnly }) =>
      getInputColors(theme, error, readOnly ? "readOnly" : "disabled")};
  }
`;

const StyledLabel = styled.label`
  width: 100%;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey[500] : theme.colors.black};
  .label-text {
    display: inline-block;
  }
`;

const CaptionContainer = styled.div`
  min-height: 1rem;
  text-align: ${({ captionAlign, error }) =>
    error ? InputAlign.LEFT : captionAlign};
`;
const InputContainer = styled.div`
  position: relative;
  & button {
    position: absolute;
    right: ${({ iconAlign }) => (iconAlign === InputAlign.RIGHT ? 0 : null)};
    left: ${({ iconAlign }) => (iconAlign === InputAlign.LEFT ? 0 : null)};
    top: 0;
    bottom: 0;
    margin: auto 0.75rem auto 0.5rem;
    pointer-events: ${({ iconAction }) => (!iconAction ? "none" : "inherit")};
  }
  & svg {
    fill: ${({ theme, error }) =>
      error ? theme.colors.secondary[600] : theme.colors.black};
  }
  & .input {
    padding-right: ${({ error, icon, iconAlign }) =>
      (error || icon) && iconAlign === InputAlign.RIGHT ? "2.75rem" : ""};
    padding-left: ${({ error, icon, iconAlign }) =>
      (error || icon) && iconAlign === InputAlign.LEFT ? "2.75rem" : ""};
  }
`;
const Input = ({
  id,
  name,
  error,
  caption,
  label,
  disabled,
  readOnly,
  value,
  placeholder,
  size,
  type,
  icon,
  iconAction,
  iconActionAlwaysEnabled,
  iconAlign,
  captionAlign,
  multiline,
  rows,
  onChange,
}) => (
  <div>
    <StyledLabel disabled={disabled}>
      {label && (
        <Typography
          variant={
            size === InputSize.LARGE
              ? TypographyVariant.BODY1
              : TypographyVariant.BODY2
          }
          weight={TypographyWeight.SEMIBOLD}
          text={label}
          tag="span"
          className="label-text"
        />
      )}
      <InputContainer
        icon={icon}
        error={error}
        iconAction={iconAction}
        iconAlign={iconAlign}
      >
        <StyledInput
          className="input"
          id={id}
          name={name}
          error={error}
          disabled={disabled || readOnly}
          value={value || ""}
          placeholder={placeholder}
          size={size}
          type={type}
          onChange={onChange}
          as={multiline ? "textarea" : null}
          rows={rows}
          multiline={multiline}
          spellCheck={false}
          readOnly={readOnly}
        />
        {(icon || error) && (
          <Button
            type={ButtonType.BUTTON}
            icon={icon || IconName.ERROR}
            variant={ButtonVariant.TEXT}
            size={ButtonSize.SMALL}
            onClick={iconAction}
            disabled={iconActionAlwaysEnabled ? false : disabled}
          />
        )}
      </InputContainer>
    </StyledLabel>
    <CaptionContainer captionAlign={captionAlign} error={error}>
      {(caption || error) && (
        <Typography
          variant={TypographyVariant.CAPTION}
          weight={TypographyWeight.LIGHT}
          text={error || caption}
        />
      )}
    </CaptionContainer>
  </div>
);

Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  caption: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(Object.values(InputSize)),
  type: PropTypes.oneOf(Object.values(InputType)).isRequired,
  icon: PropTypes.string,
  iconAction: PropTypes.func,
  iconActionAlwaysEnabled: PropTypes.bool,
  iconAlign: PropTypes.oneOf(Object.values(InputAlign)),
  captionAlign: PropTypes.oneOf(Object.values(InputAlign)),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  onChange: PropTypes.func,
};

Input.defaultProps = {
  error: "",
  label: "",
  caption: "",
  disabled: false,
  readOnly: false,
  value: undefined,
  placeholder: null,
  size: InputSize.NORMAL,
  icon: null,
  iconAction: null,
  iconActionAlwaysEnabled: false,
  iconAlign: InputAlign.RIGHT,
  captionAlign: InputAlign.LEFT,
  multiline: false,
  rows: null,
  onChange: () => {},
};

export default Input;
