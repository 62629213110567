import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";
import styled from "styled-components";
import PropTypes from "prop-types";
import theme from "theme/theme";

const StyledLoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = ({ show, color, height }) => {
  return (
    <>
      {show && (
        <StyledLoaderContainer>
          <ScaleLoader
            loading={show}
            radius={0}
            color={color}
            height={height}
          />
        </StyledLoaderContainer>
      )}
    </>
  );
};

Loader.propTypes = {
  show: PropTypes.bool.isRequired,
  color: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Loader.defaultProps = {
  color: theme.colors.success[400],
  height: 35,
};

export default Loader;
