import Table from "common/Table";
import React from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { ViewportType } from "utils/ViewportContext";
import ApplicationsRow from "./ApplicationsRow";
import ApplicationCard from "./ApplicationsCard";

const ApplicationsTable = ({ appList, total, meta, onMetaChange, loading }) => {
  const { t } = useTranslation("apps");

  const headers = [
    {
      text: t("name"),
      maxWidth: "20%",
    },
    {
      text: t("appStore"),
      maxWidth: "20%",
    },
    {
      text: t("testflight"),
      maxWidth: "20%",
    },
    {
      text: t("googlePlay"),
      maxWidth: "20%",
    },
    {
      text: t("internalTest"),
    },
  ];

  return (
    <>
      <Table
        headers={headers}
        data={appList}
        total={total}
        tableRow={ApplicationsRow}
        tableCard={ApplicationCard}
        cardViewportBreakpoint={ViewportType.TABLET}
        keyPropertyName="id"
        meta={meta}
        onMetaChange={onMetaChange}
        loading={loading}
      />
    </>
  );
};

ApplicationsTable.propTypes = {
  appList: PropTypes.array,
  total: PropTypes.number,
  meta: PropTypes.shape({
    page: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  onMetaChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

ApplicationsTable.defaultProps = {
  appList: [],
  total: 0,
  loading: false,
};

export default ApplicationsTable;
