import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    url: Yup.string()
      .max(50, { code: "common:validator.maxChars", params: { max: 50 } })
      .required("common:validator.required"),
    text: Yup.string()
      .max(50, { code: "common:validator.maxChars", params: { max: 50 } })
      .required("common:validator.required"),
  });
};
