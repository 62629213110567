import React, { useState, useRef } from "react";
import styled, { css } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { v4 as uuidv4 } from "uuid";
import Page from "common/page/Page";
import Header from "common/page/Header";
import Content from "common/page/Content";
import Button, { ButtonVariant } from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import { addNotification } from "components/notifications/NotificationSlice";
import { NotificationVariant } from "common/basicComponents/Notification";
import RouterPaths from "constants/RouterPaths";
import { prepareUrl } from "utils/UrlUtils";
import { tagSelectToList } from "utils/TagUtils";
import ProductForm, {
  initialProductValues,
  initialVersionValues,
} from "./form/ProductForm";
import ValidationSchema from "./form/ProductForm.schema";
import { addProduct } from "./ProductActions";
import { FileState } from "common/formFields/FormFileField";
import { useViewport, ViewportType } from "utils/ViewportContext";
import { ButtonWidth } from "common/basicComponents/Button";
import ModelType from "constants/ModelType";

const getHeaderStyle = (viewport) =>
  ({
    [ViewportType.DESKTOP]: css`
      gap: 1.25rem;
      margin-top: 1.5rem;
    `,
    [ViewportType.TABLET]: css`
      gap: 2rem;
      margin-top: 1.875rem;
    `,
    [ViewportType.MOBILE]: css`
      gap: 0.625rem;
      margin-top: 1.25rem;
    `,
  }[viewport]);

const HeaderButtons = styled.div`
  display: flex;
  ${({ viewport }) => getHeaderStyle(viewport)}
`;

const AddProduct = () => {
  const { t } = useTranslation("product");
  const navigate = useNavigate();
  const saveBtnRef = useRef();
  const addVersionBtnRef = useRef();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { type: viewportType } = useViewport();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values, form) => {
    form.setSubmitting(false);
    if (!isLoading) return;

    const newProduct = {
      ...values,
      is2d: values.modelType.value === ModelType.MODEL2D,
      productLastChangedBy: user.id,
      brandId: values.brand?.value,
      typeId: values.type.value,
      tags: tagSelectToList(values.tags),
      productDimensions: {
        glassesWidth: values.glassesWidth,
        lensHeight: values.lensHeight,
      },
    };

    delete newProduct.brand;
    delete newProduct.type;

    if (newProduct.versions) {
      newProduct.versions.map((v) => {
        v.tags = tagSelectToList(v.tags);
        v.pageLink = v.pageLink?.trim();
        if (
          !v.thumbFile ||
          v.thumbFile?.uploadState === FileState.DELETED ||
          v.thumbFile?.uploadState === FileState.NONE
        ) {
          v.thumbFile.uploadState =
            v.thumbFile?.uploadState === FileState.DELETED
              ? FileState.REPLACED
              : FileState.NEW;
          v.thumbFile.file = v.autoGeneratedThumbFile;
        }
        return v;
      });
    }

    addProduct(newProduct, form).then((res) => {
      setLoading(false);
      if (res) {
        dispatch(
          addNotification({
            variant: NotificationVariant.SUCCESS,
            message: t("common:form.savedSuccessfully"),
          })
        );
        navigate(prepareUrl(RouterPaths.EDIT_PRODUCT, { id: res }), {
          replace: true,
        });
      }
    });
  };

  return (
    <Page>
      <Header title={t("addProductTitle")}>
        <HeaderButtons viewport={viewportType}>
          <Button
            variant={ButtonVariant.OUTLINED}
            icon={IconName.LEFT_ARROW}
            text={viewportType > ViewportType.MOBILE && t("common:button.back")}
            onClick={() => navigate(-1)}
            width={
              viewportType === ViewportType.TABLET
                ? ButtonWidth.GROW
                : ButtonWidth.AUTO
            }
          />
          {viewportType > ViewportType.TABLET && (
            <div style={{ flexGrow: 1 }} />
          )}
          <Button
            variant={ButtonVariant.CONTAINED}
            icon={IconName.PLUS}
            text={viewportType > ViewportType.MOBILE && t("addVersion")}
            width={viewportType > ViewportType.TABLET ? null : ButtonWidth.GROW}
            minWidth={viewportType > ViewportType.TABLET ? "11.25rem" : null}
            ref={addVersionBtnRef}
            disabled={isLoading}
          />
          <Button
            variant={ButtonVariant.GREEN}
            text={t("common:button.save")}
            width={viewportType > ViewportType.TABLET ? null : ButtonWidth.GROW}
            minWidth={viewportType > ViewportType.TABLET ? "11.25rem" : null}
            ref={saveBtnRef}
            isLoading={isLoading}
          />
        </HeaderButtons>
      </Header>
      <Content>
        <Formik
          initialValues={{
            ...initialProductValues,
            versions: [{ ...initialVersionValues, key: uuidv4() }],
          }}
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          <ProductForm
            saveBtnRef={saveBtnRef}
            addVersionBtnRef={addVersionBtnRef}
            isLoading={isLoading}
            setLoading={(value) => {
              setLoading(value);
            }}
          />
        </Formik>
      </Content>
    </Page>
  );
};

export default AddProduct;
