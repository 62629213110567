import React from "react";
import { useTranslation } from "react-i18next";
import { Form } from "formik";
import PropTypes from "prop-types";

import { OwnershipOptions, PublishingOptions } from "./ProductSearchOptions";
import { useViewport, ViewportType } from "utils/ViewportContext";
import DesktopSearchForm from "./DesktopSearchForm";
import MobileSearchForm from "./MobileSearchForm";
import CustomFormik from "common/basicComponents/CustomFormik";

const ProductSearch = ({ onSearch }) => {
  const { t } = useTranslation("product");
  const { type: viewportType } = useViewport();

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    onSearch({
      productNameSearch: values.productName,
      publishedSearch:
        values.status.value === PublishingOptions.ALL
          ? null
          : values.status.value === PublishingOptions.PUBLISHED,
      otherPartnerSearch:
        values.owner.value === OwnershipOptions.ALL
          ? null
          : values.owner.value === OwnershipOptions.OTHER_PARTNER,
    });
    setSubmitting(false);
  };

  return (
    <CustomFormik
      initialValues={{
        productName: "",
        owner: { label: t("search.all"), value: OwnershipOptions.ALL },
        status: { label: t("search.all"), value: PublishingOptions.ALL },
      }}
      onSubmit={onSubmit}
      countChangedFields
    >
      <Form>
        {viewportType === ViewportType.DESKTOP ? (
          <DesktopSearchForm />
        ) : (
          <MobileSearchForm />
        )}
      </Form>
    </CustomFormik>
  );
};

ProductSearch.propTypes = {
  onSearch: PropTypes.func.isRequired,
};

export default ProductSearch;
