import React from "react";
import styled from "styled-components";
import Typography, {
  TypographyVariant,
} from "common/basicComponents/Typography";
import PropTypes from "prop-types";
import Button, {
  ButtonVariant,
  ButtonSize,
} from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import { useTranslation } from "react-i18next";

const Cell = styled.td`
  height: 4.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey[100]};
  text-align: ${({ centered }) => (centered ? "center" : "left")};
`;

const ButtonsCell = styled(Cell)`
  min-width: 5.5rem;
  button {
    display: inline-flex;
    margin: 0 0.25rem;
  }
`;

const Thumb = styled.img`
  height: 3.5rem;
  width: 7rem;
  object-fit: contain;
`;

const ThumbPlaceholder = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey[400]};
`;

const SetRow = ({ data, onEdit, onDelete }) => {
  const primaryThumbLink = data.primary_version_thumb_small_link || data.primary_version_thumb_link;
  const secondaryThumbLink = data.secondary_version_thumb_small_link || data.secondary_version_thumb_link;

  const { t } = useTranslation("common");
  return (
    <>
      <Cell centered={true}>
        {primaryThumbLink ? (
          <Thumb src={primaryThumbLink} />
        ) : (
          <ThumbPlaceholder>{t("notAdded")}</ThumbPlaceholder>
        )}
      </Cell>
      <Cell centered={true}>
        {secondaryThumbLink ? (
          <Thumb src={secondaryThumbLink} />
        ) : (
          <ThumbPlaceholder>{t("notAdded")}</ThumbPlaceholder>
        )}
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.set_name}
          tag="span"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.secondary_product_name + " " + data.secondary_version_name}
          tag="div"
        />
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.primary_product_name + " " + data.primary_version_name}
          tag="div"
        />
      </Cell>
      <Cell>
        <Typography
          variant={TypographyVariant.BODY2}
          text={data.domain_name + data.web_link_url}
          tag="span"
        />
      </Cell>
      <ButtonsCell centered={true}>
        <Button
          icon={IconName.EDIT}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onEdit(data)}
        />
        <Button
          icon={IconName.TRASH}
          variant={ButtonVariant.TEXT}
          size={ButtonSize.XSMALL}
          onClick={() => onDelete(data)}
        />
      </ButtonsCell>
    </>
  );
};

SetRow.propTypes = {
  data: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SetRow;
