import { apiClient } from "api/ApiClient";
import ApiEndpoints from "api/ApiEndpoints";
import store from "redux/store";
import { appendSets } from "./SetsSlice";
import { handleFormError } from "api/ApiClient";

export const getSets = (searchParams) => {
  if (searchParams) searchParams.limit = null;
  return apiClient
    .get(ApiEndpoints.SETS, { params: searchParams })
    .then((res) => {
      const sets = res?.data?.body;
      const meta = res?.data?.meta;
      if (!sets || !meta) return false;
      store.dispatch(appendSets({ sets, meta }));
      return true;
    })
    .catch(() => false);
};

export const addSet = (set, form) => {
  return apiClient
    .post(ApiEndpoints.SETS, set)
    .then((res) => res?.data?.id)
    .catch((err) => handleFormError(err, form));
};

export const editSet = (id, set, form) => {
  return apiClient
    .put(`${ApiEndpoints.SETS}${id}`, set)
    .then(() => true)
    .catch((err) => handleFormError(err, form));
};

export const deleteSet = (id) => {
  return apiClient
    .delete(`${ApiEndpoints.SETS}${id}`)
    .then(() => true)
    .catch(() => false);
};