import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Table from "common/Table";
import { ViewportType } from "utils/ViewportContext";
import BrandRow from "./BrandRow";
import BrandCard from "./BrandCard";
import Box from "common/basicComponents/Box";

const BrandTable = ({ brands, loading, total, onEdit, onDelete }) => {
  const { t } = useTranslation("brands");

  const headers = [
    {
      text: t("brandName"),
    },
    {
      text: t("type"),
      centered: true,
      maxWidth: "6.5rem",
    },
    {
      text: t("actions"),
      centered: true,
      maxWidth: "6.5rem",
    },
  ];

  return (
    <Box width="100%">
      <Table
        headers={headers}
        data={brands}
        total={total}
        keyPropertyName="id"
        tableRow={BrandRow}
        tableCard={BrandCard}
        loading={loading}
        cardViewportBreakpoint={ViewportType.MOBILE}
        itemProps={{
          onEdit,
          onDelete,
        }}
        noDataText={t("noData")}
      />
    </Box>
  );
};

BrandTable.propTypes = {
  brands: PropTypes.array,
  total: PropTypes.number,
  loading: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

BrandTable.defaultProps = {
  brands: [],
  loading: false,
  total: 0,
};

export default BrandTable;
