import React from "react";
import PropTypes from "prop-types";
import { useFormikContext, useField } from "formik";
import Toggle from "common/basicComponents/Toggle";
import Switch, { SwitchVariant } from "common/basicComponents/Switch";
import i18n from "../../i18n/i18n";

export const ToggleVariant = {
  CLASSIC: "classic",
  SWITCH_COLOR: "switch_color",
  SWITCH_BLACK: "switch_black",
};

const FormToggleField = ({
  id,
  name,
  label,
  leftOption,
  rightOption,
  disabled,
  switchWidth,
  variant,
  onChange,
}) => {
  const { isSubmitting } = useFormikContext();
  const [field, , helpers] = useField(name);

  const changeHandler = (value) => {
    helpers.setValue(value);
    onChange(value);
  };

  return variant === ToggleVariant.CLASSIC ? (
    <Toggle
      id={id}
      name={name}
      value={field.value}
      onChange={changeHandler}
      disabled={isSubmitting || disabled}
      leftOption={leftOption}
      rightOption={rightOption}
    />
  ) : (
    <Switch
      id={id}
      name={name}
      label={label}
      width={switchWidth}
      leftOption={leftOption}
      rightOption={rightOption}
      variant={
        variant === ToggleVariant.SWITCH_COLOR
          ? SwitchVariant.COLOR
          : SwitchVariant.BLACK
      }
      value={field.value}
      onChange={changeHandler}
      disabled={isSubmitting || disabled}
    />
  );
};

FormToggleField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(Object.values(ToggleVariant)),
  leftOption: PropTypes.string,
  rightOption: PropTypes.string,
  onChange: PropTypes.func,
  switchWidth: PropTypes.string,
};

FormToggleField.defaultProps = {
  label: null,
  disabled: false,
  switchWidth: "fit-content",
  variant: ToggleVariant.CLASSIC,
  leftOption: i18n.t("yes"),
  rightOption: i18n.t("no"),
  onChange: () => {},
};

export default FormToggleField;
