import Table from "common/Table";
import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { ViewportType } from "utils/ViewportContext";
import Tooltip from "common/basicComponents/Tooltip";
import VersionRow from "./VersionRow";
import VersionCard from "../VersionCard";

const VersionTable = ({
  versions,
  onReorder,
  loading,
  onDelete,
  onChangeActiveVersion,
}) => {
  const { t } = useTranslation("totems");

  const headers = [
    {
      text: t("versions.thumbnail"),
      centered: true,
    },
    {
      text: t("versions.version"),
    },
    {
      text: t("versions.product"),
    },
    {
      text: t("versions.active"),
      centered: true,
      maxWidth: "5.5rem",
    },
    {
      text: t("actions"),
      centered: true,
      maxWidth: "5.5rem",
    },
  ];

  return (
    <>
      <Table
        headers={headers}
        data={versions}
        tableRow={VersionRow}
        tableCard={VersionCard}
        cardViewportBreakpoint={ViewportType.TABLET}
        keyPropertyName="id"
        loading={loading}
        itemProps={{
          onDelete,
          onChangeActiveVersion,
        }}
        enableReorder
        onReorder={onReorder}
      />
      <Tooltip anchorSelect=".show-tooltip" text={t("dragDropInfo")} />
    </>
  );
};

VersionTable.propTypes = {
  versions: PropTypes.array,
  loading: PropTypes.bool,
  onReorder: PropTypes.func,
  onDelete: PropTypes.func,
  onChangeActiveVersion: PropTypes.func,
};

VersionTable.defaultProps = {
  versions: [],
  loading: false,
  onReorder: () => {},
  onDelete: () => {},
  onChangeActiveVersion: () => {},
};

export default VersionTable;
