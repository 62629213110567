import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import face from "assets/face.png";
import Box from "common/basicComponents/Box";
import { getVisualReport } from "../ImportActions";
import Input, { InputType } from "common/basicComponents/Input";
import Accordion from "common/basicComponents/Accordion";
import { prepareUrl } from "utils/UrlUtils";
import RouterPaths from "constants/RouterPaths";
import { useViewport, ViewportType } from "utils/ViewportContext";
import Typography, {
  TypographyWeight,
} from "common/basicComponents/Typography";
import { ButtonVariant } from "common/basicComponents/Button";
import { TypographyVariant } from "common/basicComponents/Typography";
import { IconName } from "common/basicComponents/Icon";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0rem 1rem;
  padding: 1rem;
  width: 100%;
  background: ${({ theme }) => theme.colors.white};
`;

const ItemData = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  flex-wrap: wrap;
  column-gap: 1rem;
  width: ${({ viewport }) =>
    viewport !== ViewportType.TABLET ? "100%" : "65%"};
  order: -1;
`;

const PreviewWhite = styled.img`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
`;

const PreviewBlack = styled.img`
  background: ${({ theme }) => theme.colors.black};
  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
`;

const FacePreviewContainer = styled.div`
  flex-grow: 1;
  ${({ viewport }) => viewport !== ViewportType.DESKTOP && "order: -1;"}
`;

const FacePreview = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
`;

const FacePreviewCrop = styled.div`
  position: absolute;
  width: 54%;
  height: 0;
  padding-bottom: 54%;
  top: 23%;
  left: 23%;
  overflow: hidden;
`;

const GlassesOnFacePreview = styled.img`
  position: absolute;
  width: 300%;
  left: -100%;
`;

const FaceImage = styled.img`
  position: absolute;
  width: 100%;
  border: 1px solid;
  border-image: ${({ theme }) => theme.colors.gradient[20]} 1;
`;

const FacePreviewLabel = styled(Typography)`
  padding-bottom: 0.25rem;
`;

const PaddingLabel = styled(Typography)`
  padding-bottom: 0.25rem;
  ${({ viewport }) =>
    viewport !== ViewportType.DESKTOP && "padding-top: 1rem;"};
`;

const VisualReportContent = () => {
  const { report, visualReport } = useSelector((state) => state.import);
  const { t } = useTranslation("import");
  const { type: viewportType } = useViewport();
  const navigate = useNavigate();

  useEffect(() => {
    if (!report || !report["SUCCESS"]) return;
    const ids = report["SUCCESS"];
    getVisualReport(ids);
  }, [report]);

  return visualReport?.map((item) => (
    <Box key={item.external_id} padding="0 0 1.25rem 0">
      <Accordion
        text={item.product_name}
        buttonText={t("common:button.edit")}
        buttonIcon={IconName.EDIT}
        buttonAction={() =>
          navigate(
            prepareUrl(RouterPaths.EDIT_PRODUCT, {
              id: item.partners_product_id,
            })
          )
        }
        buttonVariant={ButtonVariant.GREEN}
        buttonWidth="11.25rem"
        lockedOpen
      >
        <ContentContainer viewport={viewportType}>
          <ItemData viewport={viewportType}>
            <Box
              width={viewportType !== ViewportType.DESKTOP ? "100%" : undefined}
              grow={viewportType === ViewportType.DESKTOP ? 1 : undefined}
            >
              <Input
                type={InputType.TEXT}
                name="name"
                id="name"
                label={t("name")}
                value={item.product_name}
                readOnly
              />
            </Box>
            <Box
              width={
                viewportType !== ViewportType.DESKTOP
                  ? "45%"
                  : "calc((100% - 1rem)/4)"
              }
              grow={viewportType === ViewportType.DESKTOP ? undefined : 1}
            >
              <Input
                type={InputType.TEXT}
                name="version"
                id="version"
                label={t("version")}
                value={item.version_name}
                readOnly
              />
            </Box>
            <Box
              width={
                viewportType !== ViewportType.DESKTOP
                  ? "45%"
                  : "calc((100% - 1rem)/4)"
              }
              grow={viewportType === ViewportType.DESKTOP ? undefined : 1}
            >
              <Input
                type={InputType.TEXT}
                name="id"
                id="id"
                label={t("id")}
                value={item.external_id}
                readOnly
              />
            </Box>
          </ItemData>
          <Box
            width={
              viewportType !== ViewportType.DESKTOP
                ? "100%"
                : "calc((100% - 1rem)*3/4)"
            }
          >
            <PaddingLabel
              type={TypographyVariant.BODY2}
              weight={TypographyWeight.SEMIBOLD}
              text={t("whiteBoard")}
              viewport={viewportType}
            />
            <PreviewWhite src={item.version_autocut_preview} width="100%" />
          </Box>
          <FacePreviewContainer viewport={viewportType}>
            <FacePreviewLabel
              type={TypographyVariant.BODY2}
              weight={TypographyWeight.SEMIBOLD}
              text={t("facePreview")}
            />
            <FacePreview viewport={viewportType}>
              <FaceImage src={face} />
              <FacePreviewCrop>
                <GlassesOnFacePreview src={item.version_autocut_preview} />
              </FacePreviewCrop>
            </FacePreview>
          </FacePreviewContainer>

          <Box
            width={
              viewportType !== ViewportType.DESKTOP
                ? "100%"
                : "calc((100% - 1rem)*3/4)"
            }
          >
            <PaddingLabel
              type={TypographyVariant.BODY2}
              weight={TypographyWeight.SEMIBOLD}
              text={t("blackBoard")}
            />
            <PreviewBlack src={item.version_autocut_preview} width="100%" />
          </Box>
        </ContentContainer>
      </Accordion>
    </Box>
  ));
};

export default VisualReportContent;
