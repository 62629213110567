import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { useTheme } from "styled-components";
import { useFormikContext } from "formik";
import { v4 as uuidv4 } from "uuid";

import Box from "common/basicComponents/Box";
import Table from "common/Table";
import DomainRow from "../domains/DomainRow";
import { ViewportType } from "utils/ViewportContext";
import EditDomainDialog from "../domains/dialog/EditDomainDialog";
import DomainCard from "../domains/DomainCard";
import Accordion from "common/basicComponents/Accordion";
import { ButtonVariant } from "common/basicComponents/Button";
import { IconName } from "common/basicComponents/Icon";
import WarningDialog from "common/basicComponents/WarningDialog";

const DomainsContent = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 2.5rem 1.25rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
`;

const Domains = () => {
  const { t } = useTranslation("domains");
  const { colors } = useTheme();
  const { values, setFieldValue, isSubmitting } = useFormikContext();

  const [editDialog, setEditDialog] = useState({
    open: false,
    domain: null,
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    domain: null,
  });

  const handleSave = (domain) => {
    if (domain.domainId) {
      const newDomains = [...values.domains];
      const editedIndex = newDomains.findIndex(
        (d) => d.domainId === domain.domainId
      );
      newDomains[editedIndex] = domain;
      setFieldValue("domains", newDomains);
    } else {
      domain.domainId = uuidv4();
      setFieldValue("domains", [...values.domains, domain]);
    }
    setEditDialog({ ...editDialog, open: false });
  };

  const handleDelete = () => {
    const newDomains = [...values.domains];
    const deletedIndex = newDomains.findIndex(
      (d) => d.domainId === deleteDialog.domain.domainId
    );
    newDomains.splice(deletedIndex, 1);
    setFieldValue("domains", newDomains);
    setDeleteDialog({ ...deleteDialog, open: false });
  };

  const headers = [
    {
      text: t("domainAdress"),
      maxWidth: "90%",
    },
    {
      text: t("actions"),
      centered: true,
      maxWidth: "1rem",
    },
  ];

  return (
    <Accordion
      text={t("domains")}
      buttonText={t("addDomain")}
      buttonIcon={IconName.PLUS}
      buttonAction={() => setEditDialog({ open: true, domain: null })}
      buttonVariant={ButtonVariant.CONTAINED}
      buttonWidth="11.25rem"
      buttonDisabled={isSubmitting}
    >
      <DomainsContent>
        <Box width="100%">
          <Table
            headers={headers}
            data={values.domains || []}
            total={values.domains ? values.domains.length : 0}
            dividerColor={colors.grey[200]}
            tableRow={DomainRow}
            tableCard={DomainCard}
            pagination={false}
            cardViewportBreakpoint={ViewportType.MOBILE}
            keyPropertyName="domainUrl"
            onMetaChange={() => {}}
            itemProps={{
              onEdit: (domain) => setEditDialog({ open: true, domain }),
              onDelete: (domain) => setDeleteDialog({ open: true, domain }),
              disabled: isSubmitting,
            }}
            noDataText={t("noData")}
          />
        </Box>
        <EditDomainDialog
          data={editDialog.domain}
          open={editDialog.open}
          onClose={() => setEditDialog({ ...editDialog, open: false })}
          onSave={handleSave}
        />
        <WarningDialog
          open={deleteDialog.open}
          data={deleteDialog.domain}
          name={deleteDialog.domain?.domainUrl}
          onClose={() => setDeleteDialog({ ...deleteDialog, open: false })}
          onAction={handleDelete}
          title={t("deleteAlertTitle")}
          warningButtonText={t("deleteButtonTitle")}
        />
      </DomainsContent>
    </Accordion>
  );
};

export default Domains;
