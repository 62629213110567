import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ViewportMinBreakpoints } from "utils/ViewportContext";

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden auto;
  @media screen and (min-width: ${ViewportMinBreakpoints.DESKTOP}px) {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
`;

const Page = ({ children }) => {
  return <PageContainer>{children}</PageContainer>;
};

Page.propTypes = {
  children: PropTypes.node,
};

Page.defaultProps = {
  children: null,
};

export default Page;
