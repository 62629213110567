import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  setup: null,
  current: {}
};

export const billingSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    setSetup: (state, action) => {
      state.setup = action.payload;
    },
    setCurrent: (state, action) => {
      state.current = action.payload
    }
  },
});

export const { setSetup, setCurrent } = billingSlice.actions;

export default billingSlice.reducer;
